import styled from "styled-components";
import {device} from "../../../config/devices";

export const StyledText = styled.p`
    display: flex;
    align-items: flex-start;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    color: #585866;
    padding-left: 16px;
`;

export const StyledEmail = styled.a`
  color: #ff5277;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  @media ${device.tablet} {
    margin-left: 4px;
  }
`;

export const StyledImage = styled.img`
    margin-top: 16px;
    width: 150px;
`;
export const ImageContainer = styled.div`
    display: flex;
    flex: 1;
    align-content: center;
    justify-content: center;
`;

export const StyledSmallText = styled.p`
    display: flex;
    margin-top: 16px;
    align-items: flex-start;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    color: #585866;
    padding-left: 16px;
    flex-wrap: wrap;
    overflow-wrap: normal;
`;
