import {
  LeadIndustryType,
  SeniorityType,
  TAnnualSalaryRange,
  TCurrentlyAccruedPtoDays,
  TEmploymentStatus,
  THearAboutUsFrom,
  THowToUseFunds,
  TPayoutsAtTermination,
  TPtoDueTimes,
  TPtoPolicyDesc,
  TPtoRolloverLimitValues,
  TPtoRolloverLimits,
  TYearlyPtoDays,
} from "services/api/types";
import { analytics } from "..";
import { Timer } from "./utils";
import { PlaidLinkError } from "react-plaid-link";
import moment from "moment";

export const btcEvents = {
  step1: {
    timer: new Timer(),
    onload(searchParams: URLSearchParams) {
      this.timer.init();
      analytics.trackEvent({
        event: "View Calculator PTO Page",
        params: {
          Origin: document.referrer,
          UTM_Source: searchParams.get("utm_source"),
          UTM_Medium: searchParams.get("utm_medium"),
          UTM_Campaign: searchParams.get("utm_campaign"),
          UTM_content: searchParams.get("utm_content"),
        },
      });
    },
    leavePage(data: TBtcStep1Data) {
      this.timer.update(data.action);
      if (data.action === "Focus") {
        return;
      }
      const params = step1PrepareParams(data);
      analytics.trackEvent({
        event: "Leave PTO Calculator Page",
        params: { ...params, Reason: data.action, "Duration (s)": this.timer.duration },
      });
      analytics.setUserProps(params);
    },
    submit(data: TBtcStep1Data, isSuccess: boolean) {
      if (isSuccess) {
        this.leavePage({ ...data, action: "Calculate For Free" });
        analytics.setUserProps({
          "Payroll on/off": "Payroll is on",
        });
      }
      const params = step1PrepareParams(data);
      analytics.trackEvent({
        event: "Calculate PTO Page",
        params: { ...params, "Completion Status": isSuccess ? "Success" : "Failed" },
        ...(isSuccess ? {} : { "Failed Reason": data.failReason }),
      });
      analytics.setUserProps({
        ...params,
        ...(isSuccess ? { "Last Successful Onboarding Step": "Calculate PTO Page" } : {}),
      });
    },
  },
  step2: {
    timer: new Timer(),
    onload() {
      this.timer.init();
      analytics.trackEvent({
        event: "View Contact Details Page",
        params: { Origin: document.referrer },
      });
    },
    leavePage(data: TBtcStep2Data) {
      this.timer.update(data.action);
      if (data.action === "Focus") {
        return;
      }
      const params = step2PrepareParams(data);
      analytics.trackEvent({
        event: "Leave Contact Details Page",
        params: { ...params, Reason: data.action, "Duration (s)": this.timer.duration },
      });
      analytics.setUserProps(params);
    },
    submit(data: TBtcStep2Data, isSuccess: boolean) {
      if (isSuccess) {
        this.leavePage({ ...data, action: "Get Results Now" });
      }
      const params = step2PrepareParams(data);
      analytics.trackEvent({
        event: "Submit Contact Details Page",
        params: { ...params, "Completion Status": isSuccess ? "Success" : "Failed" },
        ...(isSuccess ? {} : { "Failed Reason": data.failReason }),
      });
      analytics.setUserProps({
        ...params,
        ...(isSuccess ? { "Last Successful Onboarding Step": "Contact Details Page" } : {}),
        $email: data.email,
        $name: `${data.firstName} ${data.lastName}`,
      });
    },
  },
  step3: {
    timer: new Timer(),
    onload() {
      this.timer.init();
      analytics.trackEvent({
        event: "View Financial Details Page",
        params: { Origin: document.referrer },
      });
    },
    leavePage(data: TBtcStep3Data) {
      this.timer.update(data.action);
      if (data.action === "Focus") {
        return;
      }

      const params = step3PrepareParams(data);
      analytics.trackEvent({
        event: "Leave Financial Details Page",
        params: { ...params, Reason: data.action, "Duration (s)": this.timer.duration },
      });
      analytics.setUserProps(params);
    },
    submit(data: TBtcStep3Data, isSuccess: boolean) {
      if (isSuccess) {
        this.leavePage({ ...data, action: "Apply now" });
      }
      const params = step3PrepareParams(data);
      analytics.trackEvent({
        event: "Submit Financial Details Page",
        params: {
          ...params,
          ...(isSuccess
            ? { "Completion Status": "Success", "Duration (s)": this.timer.duration }
            : { "Completion Status": "Failed", "Failed Reason": data.failReason }),
        },
      });
      analytics.setUserProps({
        ...params,
        ...(isSuccess ? { "Last Successful Onboarding Step": "Financial Details Page" } : {}),
      });
    },
    actionButtonClick(actionValue: "Apply Now") {
      analytics.trackEvent({
        event: "Act on Financial Details Page",
        params: { Action: actionValue },
      });
    },
  },
  step4: {
    timer: new Timer(),
    onload() {
      this.timer.init();
      analytics.trackEvent({
        event: "View Personal Details Page",
        params: { Origin: document.referrer },
      });
    },
    leavePage(data: TBtcStep4Data) {
      this.timer.update(data.action);
      if (data.action === "Focus") {
        return;
      }
      const params = step4PrepareParams(data);
      analytics.trackEvent({
        event: "Leave Personal Details Page",
        params: { ...params, Reason: data.action, "Duration (s)": this.timer.duration },
      });
      analytics.setUserProps(params);
    },
    submit(data: TBtcStep4Data, isSuccess: boolean) {
      if (isSuccess) {
        this.leavePage({ ...data, action: "Next" });
      }
      const params = step4PrepareParams(data);
      analytics.trackEvent({
        event: "Submit Personal Details Page",
        params: { ...params, "Completion Status": isSuccess ? "Success" : "Failed" },
        ...(isSuccess ? {} : { "Failed Reason": data.failReason }),
      });
      analytics.setUserProps({
        ...params,
        ...(isSuccess ? { "Last Successful Onboarding Step": "Personal Details Page" } : {}),
      });
      analytics.setSuperProps({ ...params });
    },
  },
  step5: {
    timer: new Timer(),
    onload() {
      this.timer.init();
      analytics.trackEvent({
        event: "View Employment Status Page",
        params: { Origin: document.referrer },
      });
    },
    leavePage(data: TBtcStep5Data) {
      this.timer.update(data.action);
      if (data.action === "Focus") {
        return;
      }
      const params = step5PrepareParams(data);
      analytics.trackEvent({
        event: "Leave Employment Status Page",
        params: { ...params, Reason: data.action, "Duration (s)": this.timer.duration },
      });
      analytics.setUserProps(params);
    },
    submit(data: TBtcStep5Data, isSuccess: boolean) {
      if (isSuccess) {
        this.leavePage({ ...data, action: "Next" });
      }
      const params = step5PrepareParams(data);
      analytics.trackEvent({
        event: "Submit Employment Status",
        params: { ...params, "Completion Status": isSuccess ? "Success" : "Failed" },
        ...(isSuccess ? {} : { "Failed Reason": data.failReason }),
      });
      analytics.setUserProps({
        ...params,
        ...(isSuccess ? { "Last Successful Onboarding Step": "Employment Status Page" } : {}),
      });
    },
    payStubSuccess() {
      analytics.trackEvent({ event: "Pay Stub Uploaded Successfully" });
    },
    attemptUploadPaystab() {
      analytics.incrementProperty("Pay Stub Upload Attempts");
    },
  },
  payroll: {
    timer: new Timer(),
    payrollWidgetTimer: new Timer(),
    onload() {
      this.timer.init();
      analytics.trackEvent({
        event: "View Payroll Connection Fork",
        params: { Origin: document.referrer },
      });
    },
    leavePage(reason: string) {
      this.timer.update(reason);
      if (reason === "Focus") {
        return;
      }
      analytics.trackEvent({
        event: "Leave Payroll Connection Fork Page",
        params: { Reason: reason, "Duration (s)": this.timer.duration },
      });
      if (reason === "I'd rather do it manually") {
        analytics.trackEvent({ event: "Continue Manually", params: { Action: reason } });
        analytics.setUserProps({ "Skip Payroll Connection": "True" });
      }
    },
    onOpenWidget() {
      analytics.trackEvent({ event: "The user opened the widget" });
    },
    expiredToken() {
      analytics.trackEvent({ event: "Pinwheel expired token" });
    },
    acceptConnectingPayroll() {
      analytics.trackEvent({ event: "Accept Connecting Payroll", params: { action: "Next" } });
      this.leavePage("Next");
    },
    continueToConnectionToPayroll(origin: string) {
      analytics.trackEvent({
        event: "Continue To Connection To Payroll",
        params: { Origin: origin },
      });
      analytics.setUserProps({ "User Continued To Payroll Connection?": true });
    },
    completeConnectToPinwheel() {
      analytics.trackEvent({ event: "Complete Connect to Pinwheel" });
    },
    viewSelectPayrollProvider() {
      this.payrollWidgetTimer.init();
      analytics.trackEvent({ event: "View Select Payroll Provider" });
    },
    userClosesPayrollWidget(lastScreen: string | undefined) {
      analytics.trackEvent({
        event: "User closes payroll widget",
        params: { lastScreen: lastScreen },
      });
    },
    searchingForPayroll() {
      analytics.trackEvent({ event: "Searching For Payroll" });
    },
    userEntersCredentials() {
      analytics.trackEvent({ event: "User Enters Credentials" });
    },
    Provider_Confirmation() {
      analytics.trackEvent({ event: "Provider_Confirmation" });
    },
    leaveConnectingPayrollPage(reason: string) {
      this.payrollWidgetTimer.update(reason);
      if (reason === "Focus") {
        return;
      }
      analytics.trackEvent({
        event: "Leave Connecting Payroll Page",
        params: { Reason: reason, "Duration (s)": this.timer.duration },
      });
    },
    selectPlatform(payrollProvider: string, type: "payroll" | "employer") {
      analytics.trackEvent({
        event: "Select Platform",
        params: { "Select Payroll Provider": payrollProvider, Type: type },
      });
    },
    selectedPlatformId(platformId: string) {
      analytics.trackEvent({
        event: "Selected Platform id",
        params: { platformId },
      });
    },
  },
  step6: {
    timer: new Timer(),
    onload() {
      this.timer.init();
      analytics.trackEvent({
        event: "Views PTO Specific Details Page",
        params: { Origin: document.referrer },
      });
    },
    leavePage(data: TBtcStep6Data) {
      this.timer.update(data.action);
      if (data.action === "Focus") {
        return;
      }
      const params = step6PrepareParams(data);
      analytics.trackEvent({
        event: "Leave PTO Specific Details",
        params: { ...params, Reason: data.action, "Duration (s)": this.timer.duration },
      });
      analytics.setUserProps(params);
    },
    submit(data: TBtcStep6Data, isSuccess: boolean) {
      if (isSuccess) {
        this.leavePage({ ...data, action: "Next" });
      }
      const params = step6PrepareParams(data);
      analytics.trackEvent({
        event: "Submit PTO Specific Details",
        params: { ...params, "Completion Status": isSuccess ? "Success" : "Failed" },
        ...(isSuccess ? {} : { "Failed Reason": data.failReason }),
      });
      analytics.setUserProps({
        ...params,
        ...(isSuccess ? { "Last Successful Onboarding Step": "PTO Specific Details Page" } : {}),
      });
    },
  },
  step7: {
    timer: new Timer(),
    onload() {
      this.timer.init();
      analytics.trackEvent({
        event: "View Bank Account Linking Page",
        params: { Origin: document.referrer },
      });
    },
    plaidOpen() {
      analytics.trackEvent({ event: "Connect Bank Account Loaded Successfully" });
      analytics.incrementProperty("User Connect Bank Account Attempts");
    },
    leavePage(data: { action: string }) {
      this.timer.update(data.action);
      if (data.action === "Focus") {
        return;
      }
      analytics.trackEvent({
        event: "Leave Link Bank Account Page",
        params: { Reason: data.action, "Duration (s)": this.timer.duration },
      });
    },
    plaidSelectInstitution(bank: string | null) {
      analytics.trackEvent({
        event: "Plaid - Select Bank",
        params: { "Bank Institution Name": bank },
      });
    },
    plaidAuthenticate() {
      analytics.trackEvent({ event: "Start Bank Account Flow Process" });
    },
    plaidSelectAccount() {
      analytics.trackEvent({ event: "Plaid - Select Bank" });
    },
    plaidError() {},
    plaidSuccess() {
      analytics.trackEvent({
        event: "Complete Connect Bank Account Flow",
        params: { "Duration (s)": this.timer.duration },
      });
    },
    plaidExit(eventName: PlaidLinkError | null) {
      analytics.trackEvent({
        event: "Leave Connect Bank Account Flow",
        params: { Reason: "Exit" },
      });
    },
  },
  step8: {
    onload() {
      analytics.trackEvent({
        event: "View Thank You Page",
        params: {
          "Date Last Completed Application": moment().format("MM/DD/YY hh:mm A"),
          Origin: document.referrer,
        },
      });
    },
  },
};

type TBtcStep1Data = {
  action?: string;
  industry: LeadIndustryType;
  salary: TAnnualSalaryRange;
  seniority: SeniorityType;
  ptoPolicy: TPtoPolicyDesc;
  currentlyAccruedPtoDays: TCurrentlyAccruedPtoDays;
  failReason?: string;
};

const step1PrepareParams = (data: TBtcStep1Data) => ({
  "Industry Type": data.industry,
  "Annual Income (Range)": data.salary,
  "Tenure (Range)": data.seniority,
  "PTO Policy Type": data.ptoPolicy,
  "PTO Days Accrued (Days)": data.currentlyAccruedPtoDays,
});

type TBtcStep2Data = {
  action?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  acceptTermsOfServices?: boolean;
  acceptMarketing?: boolean;
  failReason?: string;
};

const step2PrepareParams = (data: TBtcStep2Data) => ({
  "First Name": data.firstName,
  "Last Name": data.lastName,
  "User Email": data.email,
  "Approve Marketing Content": data.acceptTermsOfServices,
  "Approve T&C": data.acceptMarketing,
});

type TBtcStep3Data = {
  action?: string;
  centsToAdvance?: number;
  howToUseFunds?: THowToUseFunds;
  hearAboutUsFrom?: THearAboutUsFrom;
  failReason?: string;
};

const step3PrepareParams = (data: TBtcStep3Data) => ({
  "Amount In Advance": data.centsToAdvance,
  "Purpose Of Using The Funds": data.howToUseFunds,
  "Source of hearing about source": data.hearAboutUsFrom,
});

type TBtcStep4Data = {
  action?: string;
  phone: string;
  country: string | null | undefined;
  state: string | null | undefined;
  city: string | null | undefined;
  birthDate: string;
  ssn: string;
  failReason?: string;
};

const step4PrepareParams = (data: TBtcStep4Data) => ({
  "Date Of Birth": data.birthDate,
  "Phone Number": data.phone,
  Country: data.country,
  State: data.state,
  City: data.city,
  SSN: !!data.ssn,
});

type TBtcStep5Data = {
  action?: string;
  employmentStatus: TEmploymentStatus;
  companyName: string;
  jobTitle: string;
  isActiveMilitary: boolean;
  payStub1: any;
  optionalPayStub: any;
  failReason?: string;
};

const step5PrepareParams = (data: TBtcStep5Data) => ({
  "Employment Status": data.employmentStatus,
  "Active Military Service": data.isActiveMilitary,
  "Company Name": data.companyName,
  "Job Title": data.jobTitle,
  "Uploaded Paystab": data.payStub1.length > 0,
  "Uploaded Additional File": data.optionalPayStub.length > 0,
});
type TBtcStep6Data = {
  action?: string;
  yearlyPtoDays: TYearlyPtoDays;
  payoutAtTermination: TPayoutsAtTermination;
  ptoDueTime: TPtoDueTimes;
  ptoRolloverLimit: TPtoRolloverLimits;
  ptoRolloverLimitValue: TPtoRolloverLimitValues;
  failReason?: string;
};

const step6PrepareParams = (data: TBtcStep6Data) => ({
  "PTO Days Per Year": data.yearlyPtoDays,
  "PTO Payout at Termination": data.payoutAtTermination,
  "Receival of PTO": data.ptoDueTime,
  "Cap on PTO Days?": data.ptoRolloverLimit,
  "Maximum Days?": data.ptoRolloverLimitValue,
});
