import { CircularProgress } from "@mui/material";
import { SubmittingLoaderText, SubmittingLoaderWrapper } from "../styles";
import { useTranslation } from "react-i18next";

export const SubmittingApplicationLoader = () => {
  const { t } = useTranslation();
  return (
    <SubmittingLoaderWrapper>
      <CircularProgress color="inherit" />
      <SubmittingLoaderText>{t("application_status.submittingApplication")}</SubmittingLoaderText>
    </SubmittingLoaderWrapper>
  );
};
