import styled from "styled-components";
import { Box, TextField } from "@mui/material";
import { device } from "../../../config/devices";

export const StyledApplyAdvanceHeader = styled(Box)`
  display: flex;
  padding: 18px 20px 22px;
  background: #fff;
  flex-direction: column;
`;

export const StyledApplyContainer = styled(Box)`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
`;

export const StyledCSBody = styled(Box)`
  display: flex;
  padding: 0 0 56px;
  background: #fff;
  flex-direction: column;
`;

export const StyledBodyInnerContainer = styled(Box)`
  display: flex;
  background: #fff;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
`;

export const StyledApplyInnerContainer = styled(Box)`
  display: flex;
  background: #f8f8fc;
  flex-direction: column;
`;

export const StyledSweetContainer = styled(Box)`
  flex: 1;
  display: flex;
  flex-direction: row;
  padding-top: 32px;
  flex-direction: column;
  @media ${device.laptop} {
    flex-direction: row;
    justify-content: center;
    gap: 80px;
  }
`;

export const StyledSweetTextContainer = styled.div`
  padding: 24px 20px;
  padding-bottom: 0;
  justify-content: center;
  display: flex;
`;

export const StyledApplyFormWrapper = styled.div`
  padding: 24px 20px;
  background: #fff;
  @media ${device.laptop} {
    background: #f8f8fc;
  }
`;

export const StyledApplyPhoto = styled.img`
  width: 100%;
  flex: 1;
`;

export const StyledPicturesContainer = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  flex-direction: column;
  align-content: center;
  @media ${device.laptop} {
    flex-direction: row;
  }
`;

export const StyledApplyBody = styled(Box)`
  background-color: #fff;
`;

export const StyledPictureCard = styled(Box)`
  width: 200px;
  margin-top: 40px;
`;

export const StyledCardText = styled(Box)`
  width: 200px;
  margin-inline: 20px;
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #242323;
`;

export const StyledApplydHeaderTitle = styled.div`
  display: flex;
  color: #242323;
  font-weight: 500;
  margin-bottom: 6px;
  font-size: 24px;
  line-height: 32px;
  @media ${device.laptop} {
    font-size: 18px;
    line-height: 24px;
  }
`;

export const StyledInfoHeaderTitle = styled.div`
  display: flex;
  color: #242323;
  font-size: 20px;
  font-weight: 500;
  margin-top: 12px;
  margin-bottom: 12px;
  line-height: 32px;
`;

export const StyledInfoHeaderSubtitle = styled.div`
  display: flex;
  color: #242323;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
`;

export const StyledInfoHeaderText = styled.div`
  color: #585866;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
`;

export const StyledCardPicture = styled.img``;

export const Styled56Px = styled.div`
  margin-top: 56px;
`;

export const StyledCurvedBody = styled.div`
  background-color: #f8f8fc;
  @media ${device.laptop} {
    background-color: #fff;
  }
`;

export const Curve1 = styled.img`
  width: 100%;
  display: block;
  background-color: #fff;
  @media ${device.laptop} {
    display: none;
  }
`;

export const Curve3 = styled.img`
  width: 100%;
  display: block;
  background-color: #fff;
  @media ${device.laptop} {
    display: none;
  }
`;

export const Curve4 = styled.img`
  width: 100%;
  display: block;
  /* background-color: #fff; */
  @media ${device.laptop} {
    display: none;
  }
`;

export const Curve2 = styled.img`
  width: 100%;
  display: none;
  @media ${device.laptop} {
    display: block;
  }
`;

export const StyledBlueText = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #585866;
  margin-top: 8px;
  margin-bottom: 16px;

  div {
    display: inline-grid;
  }
`;

export const StyledApplyForPtoBody = styled.div`
  margin: auto;
  @media ${device.laptop} {
    width: 528px;
  }
  @media ${device.mobileS} {
    max-width: 528px;
  }
`;

export const StyledManAndTextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  flex-wrap: wrap;
`;
export const StyledExpectionsWrapper = styled.div`
  margin-bottom: 48px;
  @media ${device.laptop} {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 8px;
  }
`;

export const StyledManPicture = styled.img`
  max-width: 368px;
  width: 100%;
`;

export const ApplyForPtoImg = styled.img<{ hideOnSmall?: boolean }>`
  width: 167px;
  height: 144px;
  margin: 0 auto;
  margin-bottom: 19px;
  display: ${(p) => (p.hideOnSmall ? "none" : "block")};
  @media ${device.laptop} {
    display: block;
    margin: 0;
    margin-bottom: 19px;
  }
`;

export const StyledApplyForm = styled.form`
  display: flex;
  flex-direction: column;
`;

export const StyledTermsOfService = styled.div`
  margin-top: 24px;
  padding: 10px 16px;
  color: #32536a;
  font-size: 12px;
  background-color: #f8f8fc;
`;

export const StyledIsConfusedContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  color: #000000;
  margin: auto;
  max-width: 500px;
  margin-top: 48px;
  padding-bottom: 48px;
  text-align: start;
  padding-left: 24px;
  padding-right: 24px;
  @media ${device.laptop} {
    align-items: center;
    justify-content: center;
    text-align: center;
  }
`;

export const StyledIsConfusedTitle = styled.div`
  font-size: 15px;
  font-weight: 500;
`;

export const StyledHowItWorksHeaderTitle = styled(Box)`
  display: flex;
  font-size: 18px;
  font-weight: 500;
  color: #000000;
  flex-direction: column;
  padding-top: 40px;
  text-align: center;
  line-height: 24px;
`;

export const StyledTermsOfServiceCheckboxWrapper = styled.div`
  padding: 12px 16px;
  color: #32536a;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
`;

export const StyledEmail = styled.a`
  color: #ff5277;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

export const StyledBTCTextFieldMoney = styled(TextField)<{ empty?: 1 | 0 }>`
  & .MuiOutlinedInput-root {
    font-family: "Poppins";
    color: #585866;
    ::placeholder {
      color: #9486a5;
      font-weight: 400;
    }
    &.Mui-focused fieldset {
      border: none;
      box-shadow: 0 0 0 1px #7a56fc, 0 0 0 3px #e2daff;
    }
  }
  width: 100%;
  border: 1px solid #e2daff;
  ::-webkit-date-and-time-value {
    text-align: right;
  }
  ::placeholder {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    color: #32536a;
  }
  input {
    background-color: #fff;
    height: 56px;
    @media ${device.laptop} {
      height: 40px;
    }
    padding: 0px 14px 0px ${(props) => (props.empty ? "14px" : "24px")};
  }
  :after {
    content: "$";
    position: absolute;
    top: 15px;
    @media ${device.laptop} {
      top: 7px;
    }
    left: 14px;
    font-size: 16px;
    color: #32536a;
    opacity: ${(props) => (props.empty ? 0 : 1)};
  }
`;

export const StyledPageTitle = styled.p`
  font-weight: 500;
  color: #242323;
  font-size: 20px;
  line-height: 32px;
  @media ${device.laptop} {
    font-size: 24px;
    line-height: 32px;
  }
`;
