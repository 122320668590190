import React from "react";
import "react-perfect-scrollbar/dist/css/styles.css";
import { useTranslation } from "react-i18next";
import { useStores } from "../../../stores";
import { observer } from "mobx-react";
import {
  StyledLargeText,
  StyledLoanContainer,
  StyledLoanDataContainer,
  StyledText,
} from "./styles";
import { formattedDate } from "../../../utils/date";

export const LoanInfo = observer(() => {
  const { t } = useTranslation();
  const translation = "loanInfo";
  const {
    loanStore: { maturityDate, outstandingBalance },
  } = useStores();

  const formattedPaymentDate = formattedDate(maturityDate);

  return (
    <StyledLoanContainer>
      <StyledLoanDataContainer>
        <StyledLargeText>{t(`${translation}.yourSorbet`)}</StyledLargeText>
        <StyledText>
          {t(`${translation}.outstandingPrinciple`)}

          {outstandingBalance}
        </StyledText>
        <StyledText>
          {t(`${translation}.maturityDate`, {
            maturityDate: formattedPaymentDate,
          })}
        </StyledText>
      </StyledLoanDataContainer>
    </StyledLoanContainer>
  );
});

export default LoanInfo;
