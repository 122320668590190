import { Slide } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { observer } from "mobx-react";
import React from "react";
import { useStores } from "stores";
import { ButtonsContainer, CloseIcon, Description, StyledDialog, Title } from "./styles";
import { useTranslation } from "react-i18next";
import closeIcon from "../../../icons/closeIcon.svg";
import {
  PrimaryButton,
  SecondaryButton,
} from "pages/Btc/ApplicationStatus/components/NMIUploads/styles";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const NoConsentModal = observer(() => {
  const { t } = useTranslation();
  const {
    bankDetailsStore: {
      openNoConsentModal,
      setNoConsentModal,
      newBackupPaymentMethod,
      closeNewBackupAccountModal,
    },
  } = useStores();

  const handleClose = () => {
    setNoConsentModal(false);
  };

  const imSureClick = () => {
    setNoConsentModal(false);
    closeNewBackupAccountModal();
  };

  return (
    <StyledDialog
      open={openNoConsentModal}
      TransitionComponent={Transition}
      keepMounted={false}
      aria-describedby="alert-dialog-slide-description">
      <CloseIcon onClick={handleClose}>
        <img src={closeIcon} alt={"close"} />
      </CloseIcon>
      <Title>
        {t("manageLinkedAccounts.sureYouDonTWantToSetup", {
          bankAccountLast4: newBackupPaymentMethod?.bankAccountLast4,
        })}
      </Title>
      <Description>{t("manageLinkedAccounts.withoutBackupPaymentMethod")}</Description>
      <ButtonsContainer>
        <SecondaryButton onClick={imSureClick}>
          {t("manageLinkedAccounts.imSureDonTSetUpThisAccount")}
        </SecondaryButton>
        <PrimaryButton onClick={handleClose}>{t("manageLinkedAccounts.noGoBack")}</PrimaryButton>
      </ButtonsContainer>
    </StyledDialog>
  );
});
