import { IAnalytics, IEvent } from "./types";
import { gtmService } from "services/gtm";

export default class AnalyticsMixPanelImpl implements IAnalytics {
  trackEvent({ event, params }: IEvent) {
    gtmService.trackProductEvent({ event, params });
    // mixpanel.track(event, { ...params });
  }

  identifyUser(id: string) {
    gtmService.identifyProductUser(id);
    // mixpanel.identify(id);
  }

  setSuperProps(props: Object) {
    gtmService.setProductSuperProps(props);
    // mixpanel.register(props);
  }

  setUserProps(props: object) {
    gtmService.setProductUserProps(props);
    // mixpanel.people.set(name, value);
  }

  incrementProperty(property: string) {
    gtmService.productIncrementProperty(property);
    // mixpanel.people.increment(property)
  }

  reset() {
    gtmService.resetProductUser();
    // reset();
  }
}
