import React from "react";
import Modal from "@mui/material/Modal";
import { useTranslation } from "react-i18next";

import {
  StyledCloseButton,
  StyledModalBody,
  StyledModalButtonsWrapper,
  StyledModalCancelButton,
  StyledModalImage,
  StyledModalSubmitButton,
  StyledModalSubtitle,
  StyledModalTitle,
} from "./styles";
import ExamplePaystub from "../../../../assets/images/paystub.png";

type PayStubsModalProps = {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
};

const style = {
  borderRadius: 4,
  boxShadow: "1px 1px 8px #a6a1a1",
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
};

const PayStubsModal = ({ open, onClose, onSubmit }: PayStubsModalProps) => {
  const { t } = useTranslation();
  const pageTranslationPrefix = "page_PayStubs";

  const cancelClick = () => {
    onClose();
  };
  const submitClick = () => {
    onSubmit();
  };

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <StyledModalBody sx={style}>
        <StyledCloseButton type="button" onClick={onClose} id="modal-pay-stubs-close">
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M0.5 0.5L13.5 13.5M13.5 0.5L0.5 13.5"
              stroke="#242323"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </StyledCloseButton>
        <StyledModalTitle>{t(`${pageTranslationPrefix}.modal.important`)}</StyledModalTitle>
        <StyledModalSubtitle>
          {t(`${pageTranslationPrefix}.modal.isYourPTOVisible`)}
        </StyledModalSubtitle>

        <StyledModalImage src={ExamplePaystub} id={"example-pay-stub-iamge"} />

        <StyledModalButtonsWrapper>
          <StyledModalCancelButton type="button" onClick={cancelClick} id="modal-pay-stubs-cancel">
            {t(`${pageTranslationPrefix}.modal.cancel`)}
          </StyledModalCancelButton>
          <StyledModalSubmitButton type="button" onClick={submitClick} id="modal-pay-stubs-submit">
            {t(`${pageTranslationPrefix}.modal.submit`)}
          </StyledModalSubmitButton>
        </StyledModalButtonsWrapper>
      </StyledModalBody>
    </Modal>
  );
};

export default PayStubsModal;
