import { observer } from "mobx-react";
import { AutoPayApproved } from "./AutoPayApproved";
import { useStores } from "stores";
import { ConsentStatusEnum, DebitAchAuthTypeEnum } from "services/api/types";
import { Consents } from "./Consents";
import { PriorConsent } from "./PriorConsent";
import {
  StyledBoldButton,
  StyledLargeText,
  StyledLoanActionsContainer,
  StyledLoanDataContainer,
  StyledText,
} from "../style";
import { useTranslation } from "react-i18next";
import { DisbursementError } from "../../components/DisbursementError/DisbursementError";
import { currencyFormatter } from "utils/formatters";

type Props = {
  hasOverdue: boolean;
};
const translation = "achDebitAuth";

export const OnCyclePaymentContent = observer(({ hasOverdue }: Props) => {
  const { t } = useTranslation();
  const {
    userStore: { userApprovedAmount },
    loanStore: { nextAmountDue, formattedNextPaymentDate, loanInfo },
    bankDetailsStore: { bankDetails },
    transfersInfoStore: { latestCreditTransfer },
    debitConsentStore: {
      consentInfo,
      isActiveOneTimePay,
      isFirstConsentExist,
      firstConsentError,
      setConsentModal,
    },
  } = useStores();

  if (firstConsentError) {
    return <DisbursementError />;
  }

  let approvedAmount = currencyFormatter(userApprovedAmount);
  if (!isFirstConsentExist) {
    if (latestCreditTransfer) {
      approvedAmount = currencyFormatter(latestCreditTransfer.amount);
    }
    return <PriorConsent approvedAmount={approvedAmount} />;
  }
  if (isActiveOneTimePay) {
    return (
      <>
        <StyledLoanDataContainer>
          <StyledLargeText>
            {t(`${translation}.youHavePayment`, { nextPaymentDate: formattedNextPaymentDate })}
          </StyledLargeText>
          <StyledText>
            {t(`${translation}.amountToBeDebitedValue`, { nextPaymentAmount: nextAmountDue })}
          </StyledText>
          <StyledText>
            {t(`${translation}.linkedAccountEndsWith`, {
              last4digits: bankDetails?.bankAccountLast4,
            })}
          </StyledText>
        </StyledLoanDataContainer>
        <StyledLoanActionsContainer>
          <StyledBoldButton
            id={"single-payment-auto-pay-button"}
            onClick={() => setConsentModal({ type: DebitAchAuthTypeEnum.AUTO_PAY, open: true })}
            disabled={hasOverdue}>
            {t(`${translation}.authorizeAutoPayment`)}
          </StyledBoldButton>
        </StyledLoanActionsContainer>
      </>
    );
  }

  const lateFees = currencyFormatter(loanInfo?.feesAmountCents);
  return consentInfo?.recurring?.status === ConsentStatusEnum.APPROVED ? (
    <AutoPayApproved
      nextAmountDue={nextAmountDue}
      bankDetails={bankDetails}
      formattedNextPaymentDate={formattedNextPaymentDate}
    />
  ) : (
    <Consents
      lateFees={lateFees}
      formattedNextPaymentDate={formattedNextPaymentDate}
      nextAmountDue={nextAmountDue}
      hasOverdue={hasOverdue}
      isActiveOneTimePay={isActiveOneTimePay}
    />
  );
});
