import { useTranslation } from "react-i18next";
import {
  StyledBoldButton,
  StyledLargeText,
  StyledLoanActionsContainer,
  StyledLoanDataContainer,
  StyledSecondaryButton,
  StyledText,
} from "../style";
import { DebitAchAuthTypeEnum } from "services/api/types";
import { useStores } from "stores";

const translation = "achDebitAuth";

type Props = {
  formattedNextPaymentDate: string;
  nextAmountDue: string;
  hasOverdue: boolean;
  isActiveOneTimePay: boolean;
  lateFees: string;
};
export const Consents = ({
  formattedNextPaymentDate,
  nextAmountDue,
  hasOverdue,
  isActiveOneTimePay,
  lateFees,
}: Props) => {
  const {
    debitConsentStore: { setConsentModal },
  } = useStores();
  const { t } = useTranslation();
  return (
    <>
      <StyledLoanDataContainer>
        <StyledLargeText>
          {t(`${translation}.youHavePaymentDue`, { nextPaymentDate: formattedNextPaymentDate })}
        </StyledLargeText>
        <StyledText>
          {t(`${translation}.amountDue`, { nextPaymentAmount: nextAmountDue })}
        </StyledText>
        {hasOverdue ? (
          <StyledText>{t(`${translation}.lateFeesValue`, { fee: lateFees })}</StyledText>
        ) : null}
      </StyledLoanDataContainer>
      <StyledLoanActionsContainer>
        <StyledBoldButton
          onClick={() => setConsentModal({ type: DebitAchAuthTypeEnum.AUTO_PAY, open: true })}
          disabled={hasOverdue}
          id={"set-up-auto-pay"}>
          {t(`${translation}.authorizeAutoPayment`)}
        </StyledBoldButton>

        <StyledSecondaryButton
          onClick={() => setConsentModal({ type: DebitAchAuthTypeEnum.ONE_TIME, open: true })}
          disabled={isActiveOneTimePay || hasOverdue}
          id={"set-up-one-time-pay"}>
          {t(`${translation}.authorizeOneTimePay`)}
        </StyledSecondaryButton>
      </StyledLoanActionsContainer>
    </>
  );
};
