import { EB2cApplicationStatus } from "services/api/types";

import WomenImg from "../../../../assets/images/woman-image.svg";
import DeclinedImg from "../../../../assets/images/woman-declined.svg";
import ApprovedImg from "../../../../assets/images/woman-approved.svg";
import payrollApplicationStatusImg from "../../../../assets/images/payroll-application-status.png";
import {
  PayrollContainer,
  PayrollDescription,
  PayrollImg,
  PayrollInfo,
  PayrollTitle,
  PayrollTryNow,
} from "../styles";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "config/constants";

const statusToImgMapping: Record<EB2cApplicationStatus, { img?: string }> = {
  [EB2cApplicationStatus.UnderReview]: { img: WomenImg },
  [EB2cApplicationStatus.NeedMoreInfo]: { img: undefined },
  [EB2cApplicationStatus.Approved]: { img: ApprovedImg },
  [EB2cApplicationStatus.Declined]: { img: DeclinedImg },
  [EB2cApplicationStatus.Pending]: { img: WomenImg },
  [EB2cApplicationStatus.Frozen]: { img: undefined },
  [EB2cApplicationStatus.Incomplete]: { img: undefined },
  [EB2cApplicationStatus.Canceled]: { img: WomenImg },
};

type Props = {
  status: EB2cApplicationStatus;
  needToConnectPayroll: boolean;
};
const pageTranslationPrefix = "application_status";

export const ApplicationStatusImage = ({ status, needToConnectPayroll }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  // const showPayroll =
  //   (status === EB2cApplicationStatus.Pending || status === EB2cApplicationStatus.UnderReview) &&
  //   needToConnectPayroll;

  if (false) { // TODO wait for backend
    const tryPayrollClick = () => {
      navigate(ROUTES.PAYROLL, { state: { cameFrom: "Application Status Page" } });
    };
    return (
      <PayrollContainer>
        <PayrollInfo>
          <PayrollTitle>{t(`${pageTranslationPrefix}.payroll.getFasterDecision`)}</PayrollTitle>
          <PayrollDescription>
            {t(`${pageTranslationPrefix}.payroll.getFasterDecision`)}
          </PayrollDescription>

          <PayrollTryNow onClick={tryPayrollClick}>
            {t(`${pageTranslationPrefix}.payroll.tryNow`)}
          </PayrollTryNow>
        </PayrollInfo>
        <PayrollImg src={payrollApplicationStatusImg} alt={"payroll img"} />
      </PayrollContainer>
    );
  }

  return <img src={statusToImgMapping[status]?.img} alt={""} />;
};
