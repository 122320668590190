import React from "react";
import { observer } from "mobx-react";
import { useStores } from "stores";
import { useTranslation } from "react-i18next";
import { BTC_ERRORS } from "../../Constants";
import {
  StyledErrorBox,
  StyledErrorBoxClose,
  StyledErrorBoxText,
  StyledErrorBoxTitle,
} from "./styles";
import { Close } from "@mui/icons-material";
import { COMPANY_EMAILS } from "config/constants";

const BtcErrorBox = observer(() => {
  const {
    btcStore: { hideErrorBox, errorText, errorBox },
  } = useStores();
  const onClose = () => {
    hideErrorBox();
  };

  const { t } = useTranslation();
  const translationPrefix = "btc_errors";
  let showEmail = false;
  let errorMessage;
  if (errorText.includes(BTC_ERRORS.LEAD_EXISTS)) {
    errorMessage = t(`${translationPrefix}.leadAlreadyExists`);
  } else if (errorText.includes(BTC_ERRORS.USER_EXISTS)) {
    errorMessage = t(`${translationPrefix}.userAlreadyExists`);
    showEmail = true;
  } else if (errorText && errorText.includes(BTC_ERRORS.UUID_EXPECTED)) {
    errorMessage = t(`${translationPrefix}.toEnsureSecurity`);
  } else if (errorText && errorText.includes(BTC_ERRORS.MAILCHIMP_ERROR)) {
    errorMessage = t(`${translationPrefix}.invalidEmail`);
  } else {
    errorMessage = t(`${translationPrefix}.generalError`);
    showEmail = true;
  }

  return (
    <div>
      {errorBox && (
        <StyledErrorBox>
          <StyledErrorBoxClose>
            <Close onClick={onClose} style={{ color: "#ffff", width: "16px", height: "16px" }} />
          </StyledErrorBoxClose>
          <StyledErrorBoxTitle>{t(`${translationPrefix}.oopsSomethingWrong`)}</StyledErrorBoxTitle>
          <StyledErrorBoxText>
            {errorMessage}
            {showEmail && (
              <StyledErrorBoxText>
                <a
                  href={`mailto:${COMPANY_EMAILS.SUPPORT}?subject=Hey! I have a question`}
                  target="_blank"
                  rel="noreferrer">
                  {COMPANY_EMAILS.SUPPORT}
                </a>
              </StyledErrorBoxText>
            )}
          </StyledErrorBoxText>
        </StyledErrorBox>
      )}
    </div>
  );
});

export default BtcErrorBox;
