import { ICommunityMember, IUserInfo } from "services/api/types";
import PersonIcon from "@mui/icons-material/Person";
import { Avatar, AvatarProps } from "@mui/material";

export interface IUserAvatarProps extends AvatarProps {
  user?: IUserInfo | ICommunityMember;
  size?: number;
}

const getFirstLetter = (str?: string) => (str?.length ? str[0].toUpperCase() : "");

export function UserAvatar({ user, size, ...props }: IUserAvatarProps) {
  if (!user) {
    return (
      <Avatar {...props} sx={{ width: `${size}px`, height: `${size}px` }}>
        <PersonIcon />
      </Avatar>
    );
  }

  const { avatarUrl, firstName, lastName } = user;

  if (props.src || avatarUrl) {
    return (
      <Avatar
        {...props}
        src={props.src || avatarUrl || ""}
        sx={{ width: `${size}px`, height: `${size}px` }}
      />
    );
  }

  const initials = `${getFirstLetter(firstName)}${getFirstLetter(lastName)}`;

  return (
    <Avatar
      {...props}
      style={{
        fontSize: typeof size === "number" ? size * 0.4 : undefined,
        backgroundColor: "#ff5277",
      }}
      sx={{ width: `${size}px`, height: `${size}px` }}>
      {initials}
    </Avatar>
  );
}
