import { useCallback, useEffect, useRef, useState } from "react";
import { apiService } from "services/api/index";
import {
  usePlaidLink,
  PlaidLinkOnSuccessMetadata,
  PlaidLinkOptions,
  PlaidLinkError,
} from "react-plaid-link";
import { useTranslation } from "react-i18next";
import CircularProgress from "@mui/material/CircularProgress";

import {
  StyledPlaidButton,
  StyledInnerButton,
  PlaidWrapper,
  StyledLoadingWrapper,
  StyledDescription,
} from "./styles";
import PlusIcon from "../../assets/icons/plus.png";
import { StyledBTCUploadFileError, StyledSelectorLabel } from "../../pages/Btc/styles";
import { PLAID_LINK } from "config/constants";
import { errorLogger } from "services/logger";
import { btcErrorHandler } from "../../utils/btcErrorHandler";
import { gtmService } from "services/gtm";
import { EVENTS } from "services/analytics/events";
import { analytics } from "../../services/analytics";
import { API_ERROR_CODES } from "services/api/types";
import { LOCAL_STORAGE_KEYS, LocalStorage } from "utils/localStorage";

type Props = {
  error: boolean;
  setPlaidFinished: () => void;
};

const SimplePlaidLink = ({ error, setPlaidFinished }: Props) => {
  const [token, setToken] = useState<string | null>(null);
  const bankIntegrationId = useRef<number | undefined>();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const isOAuthRedirect = window.location.href.includes("?oauth_state_id=");
  const pageTranslationPrefix = "page_bankAuth";

  // get link_token from your server when component mounts
  useEffect(() => {
    if (isOAuthRedirect) {
      setToken(LocalStorage.getItem(LOCAL_STORAGE_KEYS.link_token));
      return;
    }
    const createLinkToken = async () => {
      try {
        const userUuid = LocalStorage.getItem(LOCAL_STORAGE_KEYS.uuid);
        if (userUuid) {
          const { token } = await apiService.getPlaidAccessToken(userUuid);
          setToken(token);
          LocalStorage.setItem(LOCAL_STORAGE_KEYS.link_token, token);
        }
      } catch (error) {
        btcErrorHandler(error, "step7_create_link_token");
      }
    };
    createLinkToken();
  }, []);
  const onSuccess = useCallback(
    async (publicToken: string, _metadata: PlaidLinkOnSuccessMetadata) => {
      try {
        EVENTS.btc.step7.plaidSuccess();
        const userUuid = LocalStorage.getItem(LOCAL_STORAGE_KEYS.uuid);
        if (userUuid) {
          setLoading(true);
          const { bankAccountId } = await apiService.exchangePublicToken(publicToken, userUuid);
          bankIntegrationId.current = bankAccountId;
          setPlaidFinished();
        }
      } catch (error: any) {
        if (error.code === API_ERROR_CODES.BANK_ACCOUNT_ALREADY_EXIST) {
          setPlaidFinished();
        } else {
          btcErrorHandler(error, "step7_exchange_link_token");
        }
      } finally {
        setLoading(false);
      }
    },
    [],
  );
  const config: PlaidLinkOptions = {
    // token must be the same token used for the first initialization of Link
    token,
    onSuccess,
    onEvent: (eventName, metadata) => {
      switch (eventName) {
        case "OPEN":
          EVENTS.btc.step7.plaidOpen();
          break;
        case "SELECT_INSTITUTION":
          EVENTS.btc.step7.plaidSelectInstitution(metadata?.institution_name);
          break;
        case "OPEN_OAUTH":
          EVENTS.btc.step7.plaidAuthenticate();
          break;
        case "SELECT_ACCOUNT":
          EVENTS.btc.step7.plaidSelectAccount();
          break;
        case "ERROR":
          EVENTS.btc.step7.plaidError();
          break;
        default:
          break;
      }

      errorLogger.captureMessage("plaid on event: " + eventName, {
        plaid_metadata: metadata,
        lead_uuid: LocalStorage.getItem(LOCAL_STORAGE_KEYS.uuid),
      });
      gtmService.customEvent({
        event: "linkbank_" + metadata.view_name,
        uuid: LocalStorage.getItem(LOCAL_STORAGE_KEYS.uuid),
      });
    },
    onExit: (eventName: PlaidLinkError | null, metadata) => {
      EVENTS.btc.step7.plaidExit(eventName);
      errorLogger.captureMessage("plaid on exit: " + eventName, {
        plaid_metadata: metadata,
        lead_uuid: LocalStorage.getItem(LOCAL_STORAGE_KEYS.uuid),
      });
    },
  };
  if (isOAuthRedirect) {
    // receivedRedirectUri must include the query params
    config.receivedRedirectUri = window.location.href;
  }

  const { open, ready } = usePlaidLink(config);

  useEffect(() => {
    // If OAuth redirect, instantly open link when it is ready instead of
    // making user click the button
    if (isOAuthRedirect && ready) {
      errorLogger.captureMessage("plaid OAuth redirect", {
        lead_uuid: LocalStorage.getItem(LOCAL_STORAGE_KEYS.uuid),
        link_token: LocalStorage.getItem(LOCAL_STORAGE_KEYS.link_token),
      });
      open();
    }
  }, [ready, open, isOAuthRedirect]);

  const trackStartEvent = () => {
    analytics.trackEvent({ event: "Attempt to Connect Bank Account" });
  };

  return ready ? (
    <PlaidWrapper>
      <StyledSelectorLabel required>
        {t(`${pageTranslationPrefix}.verifyUsingPlaid`)}
      </StyledSelectorLabel>
      <>
        <StyledPlaidButton
          id="verify_using_plaid"
          error={error}
          onClick={() => {
            trackStartEvent();
            open();
          }}>
          <StyledInnerButton>
            <img src={PlusIcon} alt="plaid" />
            <span>{t(`${pageTranslationPrefix}.connect`)}</span>
          </StyledInnerButton>
        </StyledPlaidButton>
        {error ? (
          <StyledBTCUploadFileError>
            {t(`${pageTranslationPrefix}.pleaseConnectBankAccount`)}
          </StyledBTCUploadFileError>
        ) : null}
      </>

      <StyledDescription>
        {t(`${pageTranslationPrefix}.minBalance`)}
        <br />
        <br />
        {t(`${pageTranslationPrefix}.weNeedThisInformation`)}
        <a href={PLAID_LINK} target="_blank" rel="noopener noreferrer">
          {t(`${pageTranslationPrefix}.plaid`)}
        </a>
        {t(`${pageTranslationPrefix}.toVerifyYourInformation`)}
      </StyledDescription>

      {loading ? (
        <StyledLoadingWrapper position="absolute">
          <CircularProgress />
        </StyledLoadingWrapper>
      ) : null}
    </PlaidWrapper>
  ) : (
    <StyledLoadingWrapper>
      <CircularProgress />
    </StyledLoadingWrapper>
  );
};
export default SimplePlaidLink;
