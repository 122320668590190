import { Container, Title, Description } from "./styles";
import successSvg from "../../icons/connectedToPayroll.svg";
import { useTranslation } from "react-i18next";

type Props = {
  accountName: string;
};
export const ConnectedToPayroll = ({ accountName }: Props) => {
  const { t } = useTranslation();
  return (
    <Container>
      <img src={successSvg} alt={"success"} />

      <div>
        <Title>{t("payroll.connectedToPayroll")}</Title>
        <Description>{t("payroll.yourAccountConnected", { accountName })}</Description>
      </div>
    </Container>
  );
};
