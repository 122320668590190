import styled from "styled-components";
import { Box } from "@mui/material";

export const StyledErrorBox = styled(Box)`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 12px 12px 16px 16px;
  background-color: #e43737;
  max-width: 528px;
  width: 100%;
  border-radius: 8px;
  margin-bottom: 24px;
  margin-top: 16px;
`;

export const StyledErrorBoxText = styled(Box)`
  flex-direction: column;
  display: flex;
  flex: 1;
  font-weight: 400;
  font-size: 14px;
  font-family: "Poppins";
  line-height: 24px;
  color: #fff;
  margin-right: 36px;
`;
export const StyledErrorBoxTitle = styled(Box)`
  display: flex;
  flex: 1;
  font-weight: 600;
  font-size: 14px;
  font-family: "Poppins";
  line-height: 24px;
  color: #fff;
`;

export const StyledErrorBoxClose = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;
