export enum IssueIdEnum {
  //unlinkedBankAccount = "unlinkedBankAccount",
  primaryBankAccountUnAuthorized = "primaryBankAccountUnAuthorized",
  offCyclePaymentAuthorization = "offCyclePaymentAuthorization",
  backupBankAccountUnAuthorized = "backupBankAccountUnAuthorized",
  //backupBankAccountUnlinked = "backupBankAccountUnlinked",
  //setUpAutoPay = "setUpAutoPay",
}

export type Issue = {
  id: IssueIdEnum;
  label: string;
  description: string;
  priority: number;
  status: "open" | "resolved";
  title: string;
  cta: string;
  details?: object;
  ctaNow: string;
};
export interface IIssueStore {
  issues: Issue[];
  unresolvedIssue: Issue[];
  activeStep: number;
  showPayrollIssue: boolean;

  prepareIssues: () => void;
  hidePayrollIssue: () => void;
  clearStore: () => void;
}
