import { AuthServices, AuthTypes, IAuthInstance } from "../types";

export const isEmail = (email: string) =>
  email.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/);

class QaLoginInstance implements IAuthInstance {
  name = AuthServices.sorbet;
  init() {}
  authToken: string = "";
  get isInitialized() {
    return true;
  }
  async getAuthToken() {
    return this.authToken;
  }
  async getPhotoUrl() {
    return undefined;
  }

  async loadAuthState() {
    return "qaLogin";
  }

  async logInWith(type: AuthTypes, email?: string, password?: string) {
    if (!email) {
      return { id: null, success: false };
    }
    this.authToken = email;
    return { id: null, success: true };
  }

  async logOut() {}
}

export const qaLoginInstance = new QaLoginInstance();
