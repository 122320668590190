import { useTranslation } from "react-i18next";
import { SecondaryButton, PrimaryButton } from "../Btc/ApplicationStatus/components/NMIUploads/styles";
import {
  Description,
  StyledModal,
  ModalContent,
} from "../Btc/ApplicationStatus/components/NMIUploads/Modals/styles";
import { StyledModalContainer } from "../Home/OnCyclePaymentInfo/Content/PaymentAuth/styles";
import { ButtonsContainer, DeclineModalTitle } from "./styles";
type Props = { open: boolean; onClose: () => void; submitClick: () => void };

export const DeclinedModal = ({ open, onClose, submitClick }: Props) => {
  const { t } = useTranslation();
  const translationPrefix = "sorbetContractDeclined";
  return (
    <StyledModal open={open}>
      <StyledModalContainer>
        <ModalContent>
          <DeclineModalTitle>{t(`${translationPrefix}.areYouSure`)}</DeclineModalTitle>
          <Description>{t(`${translationPrefix}.cannotUndoThis`)}</Description>

          <ButtonsContainer>
            <PrimaryButton onClick={submitClick}>
              {t(`${translationPrefix}.declineAgreement`)}
            </PrimaryButton>

            <SecondaryButton id="decline-go-back-btn" onClick={onClose}>{t(`${translationPrefix}.goBack`)}</SecondaryButton>
          </ButtonsContainer>
        </ModalContent>
      </StyledModalContainer>
    </StyledModal>
  );
};
