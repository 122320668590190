import {
  ButtonsContainer,
  CheckBoxLabel,
  CheckboxContainer,
  CloseIcon,
  Description,
  ModalContainer,
  ModalContainerAutoPayWarning,
  ModalContent,
  StyledModal,
  Title,
} from "./styles";
import closeIcon from "../../../icons/closeIcon.svg";
import {
  PrimaryButton,
  SecondaryButton,
} from "pages/Btc/ApplicationStatus/components/NMIUploads/styles";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { StyledCheckbox } from "pages/Btc/styles";
import { DebitAchAuthTypeEnum, IBankAccount } from "services/api/types";
import { useStores } from "stores";

type Props = {
  openPrimaryAccountConsent: boolean;
  closePrimaryAccountConsent: () => void;
  primaryAccount?: IBankAccount;
};
export const PrimaryAccountConsent = ({
  openPrimaryAccountConsent,
  closePrimaryAccountConsent,
  primaryAccount,
}: Props) => {
  const {
    debitConsentStore: { setConsentModal },
  } = useStores();
  const [openNotAutoPayWarning, setOpenNotAutoPayWarning] = useState(false);
  const [autoPayCheckbox, setAutoPayCheckbox] = useState(false);
  const { t } = useTranslation();

  const oneTimePayClick = () => {
    setOpenNotAutoPayWarning(false);
    setConsentModal({ open: true, type: DebitAchAuthTypeEnum.ONE_TIME });
  };
  const autoPayClick = () => {
    setOpenNotAutoPayWarning(false);
    setConsentModal({ open: true, type: DebitAchAuthTypeEnum.AUTO_PAY });
  };
  const cancelPrimaryConsent = () => {
    setOpenNotAutoPayWarning(false);
    closePrimaryAccountConsent();
  };
  const consentContinueClick = () => {
    closePrimaryAccountConsent();
    if (autoPayCheckbox) {
      autoPayClick();
    } else {
      setOpenNotAutoPayWarning(true);
    }
  };
  const autoPayCheckboxChange = () => {
    setAutoPayCheckbox(!autoPayCheckbox);
  };
  useEffect(() => {
    if (!openPrimaryAccountConsent) {
      setAutoPayCheckbox(false);
    }
  }, [openPrimaryAccountConsent]);

  return (
    <div>
      <StyledModal id={"primary-account-consent-modal"} open={openPrimaryAccountConsent}>
        <ModalContainer>
          <CloseIcon onClick={cancelPrimaryConsent}>
            <img src={closeIcon} alt={"close"} />
          </CloseIcon>
          <ModalContent>
            <Title>
              {t("manageLinkedAccounts.authorizeAccountEnding", {
                bankAccountLast4: primaryAccount?.bankAccountLast4,
              })}
            </Title>
            <Description>
              {t("manageLinkedAccounts.setUpAutoPayOrSingleMonthlyPayment")}
            </Description>

            <CheckboxContainer>
              <StyledCheckbox
                type="checkbox"
                id={"checkboxPrimaryConsent"}
                checked={autoPayCheckbox}
                onChange={autoPayCheckboxChange}
              />

              <CheckBoxLabel htmlFor="checkboxPrimaryConsent">
                {t("manageLinkedAccounts.signUpForAutoPay")}
              </CheckBoxLabel>
            </CheckboxContainer>
          </ModalContent>

          <ButtonsContainer>
            <SecondaryButton onClick={cancelPrimaryConsent}>
              {t("manageLinkedAccounts.cancel")}
            </SecondaryButton>
            <PrimaryButton onClick={consentContinueClick} id={"consentContinueClick"}>
              {t("manageLinkedAccounts.continue")}
            </PrimaryButton>
          </ButtonsContainer>
        </ModalContainer>
      </StyledModal>

      <StyledModal open={openNotAutoPayWarning}>
        <ModalContainerAutoPayWarning>
          <CloseIcon onClick={cancelPrimaryConsent}>
            <img src={closeIcon} alt={"close"} />
          </CloseIcon>
          <ModalContent>
            <Title>{t("achDebitAuth.sureDonTWantAutoPay")}</Title>
            <Description>{t("achDebitAuth.ifYouDonTOptForAutoPay")}</Description>
          </ModalContent>
          <ButtonsContainer>
            <PrimaryButton onClick={autoPayClick}>
              {t("achDebitAuth.authorizeAutoPayment")}
            </PrimaryButton>
            <SecondaryButton id={"continueToManualSinglePayment"} onClick={oneTimePayClick}>
              {t("achDebitAuth.continueToManualSinglePayment")}
            </SecondaryButton>
          </ButtonsContainer>
        </ModalContainerAutoPayWarning>
      </StyledModal>
    </div>
  );
};
