import { observer } from "mobx-react";
import { FAQ_PTO_LINK, PRIVACY_LINK, TERMS_CONDITIONS_LINK } from "config/constants";
import {
  FooterBottom,
  FooterBottomContainer,
  StyledBtcFooter,
  StyledCurvedBottom,
  StyledCurvedTop,
  StyledIconSectionWrapper,
  StyledInnerUrlsContainer,
  StyledLinkToTerms,
  StyledLinkToTermsWithoutUnderline,
  StyledLinkToTermsWrapper,
  StyledDisclaimer,
  StyledDisclaimerText,
} from "../BtcFooter/styles";
import curvedTop from "../../assets/images/curve-top.png";
import curvedBottom from "../../assets/images/curve-bottom.svg";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useStores } from "stores";
import { LeadSponsorEnum } from "services/api/types";
export const FOOTER_ID = "main-footer";

export const Footer = observer(() => {
  const { t } = useTranslation();
  const year = moment().format("YYYY");
  const {
    leadStore: { leadApprovalInfo, leadInfo },
  } = useStores();

  const showLeadBankDisclaimer = leadApprovalInfo?.sponsor === LeadSponsorEnum.lead_bank ||
      leadInfo?.sponsor === LeadSponsorEnum.lead_bank;
  return (
    <StyledBtcFooter id={FOOTER_ID}>
      <StyledCurvedTop src={curvedTop} />
      <FooterBottom data-testid="footer_main_wrapper" hideFooter={false}>
        <FooterBottomContainer>
          <StyledIconSectionWrapper></StyledIconSectionWrapper>
          <StyledLinkToTermsWrapper>
            <StyledInnerUrlsContainer>
              <StyledLinkToTermsWithoutUnderline>
                {t(`footer.allRights`, { year })}
              </StyledLinkToTermsWithoutUnderline>
              <StyledLinkToTerms href={PRIVACY_LINK} target={"_blank"} data-testid="privacy">
                {t(`footer.privacy`)}
              </StyledLinkToTerms>

              <StyledLinkToTerms href={TERMS_CONDITIONS_LINK} target={"_blank"} data-testid="terms">
                {t(`footer.tnc`)}
              </StyledLinkToTerms>

              <StyledLinkToTerms href={FAQ_PTO_LINK} target={"_blank"} data-testid="faq">
                {t(`footer.faq`)}
              </StyledLinkToTerms>
            </StyledInnerUrlsContainer>
          </StyledLinkToTermsWrapper>

          {showLeadBankDisclaimer ? (
            <StyledDisclaimer>
              <StyledDisclaimerText>{t(`footer.leadBankDisclaimer`)}</StyledDisclaimerText>
            </StyledDisclaimer>
          ) : null}
        </FooterBottomContainer>
      </FooterBottom>
      <StyledCurvedBottom src={curvedBottom} />
    </StyledBtcFooter>
  );
});
