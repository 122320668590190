import CircleCheck from "assets/icons/checked.svg";
import printSvg from "assets/icons/print.svg";
import {
  StyledPrintButton,
  StyledSubUnit,
  StyledSuccessSubtitle,
  StyledSuccessText,
  StyledSuccessTitle,
  SuccessModalIcon,
  StyledButtonsContainer,
  StyledPayActionButton,
} from "./styles";
import { useTranslation } from "react-i18next";
import { DebitAchAuthTypeEnum, IBankAccount } from "services/api/types";
import moment from "moment";
import printJS from "print-js";
import { useStores } from "stores";
import { useEffect } from "react";
import { EVENTS, useLeavePageAnalytics } from "services/analytics/events";
import ProgressBarLean from "pages/Btc/Components/ProgressBarLean";

const translation = "achDebitAuth";

type Props = {
  formattedNextPaymentDate: string;
  nextAmountDue: string;
  overdueTotalAmount: string;
  handleClose: () => void;
  bankDetails: IBankAccount | undefined;
  type:
    | DebitAchAuthTypeEnum.AUTO_PAY
    | DebitAchAuthTypeEnum.ONE_TIME
    | DebitAchAuthTypeEnum.OFF_CYCLE;
  file?: File;
  rtpEnabled: boolean;
};
export const PaymentConsentSuccess = ({
  formattedNextPaymentDate,
  nextAmountDue,
  overdueTotalAmount,
  handleClose,
  bankDetails,
  type,
  file,
  rtpEnabled,
}: Props) => {
  const { t } = useTranslation();
  const {
    debitConsentStore: { setConsentModal },
  } = useStores();
  const onPrint = () => {
    if (file) {
      const pdfUrl = URL.createObjectURL(file);
      printJS({ printable: pdfUrl });
    }
  };

  useLeavePageAnalytics((eventType) => {
    if (rtpEnabled) {
      EVENTS.main.expressPayment.leavePage(eventType);
    }
  });

  useEffect(() => {
    if (rtpEnabled) {
      EVENTS.main.expressPayment.onView();
    }
  }, []);

  const handleOnClick = () => {
    if (rtpEnabled) {
      setConsentModal({ showExpressPayment: true, showSuccess: false });
      EVENTS.main.expressPayment.leavePage("Continue to Transfer Funds");
    } else {
      handleClose();
    }
  };

  const renderSuccessText = () => {
    switch (type) {
      case DebitAchAuthTypeEnum.AUTO_PAY:
        return (
          <>
            <StyledSuccessTitle>
              {rtpEnabled ? t(`${translation}.autoPayConfirmed`) : t(`${translation}.sweetAutoPay`)}
            </StyledSuccessTitle>
            <StyledSuccessSubtitle>{t(`${translation}.nextPaymentDate`)}</StyledSuccessSubtitle>
            <StyledSuccessText>{formattedNextPaymentDate}</StyledSuccessText>
            <StyledSuccessSubtitle>{t(`${translation}.amountToBeDebited`)}</StyledSuccessSubtitle>
            <StyledSuccessText>${nextAmountDue}</StyledSuccessText>
          </>
        );
      case DebitAchAuthTypeEnum.ONE_TIME:
        return (
          <>
            <StyledSuccessTitle>
              {rtpEnabled
                ? t(`${translation}.standardConfirmed`, { date: formattedNextPaymentDate })
                : t(`${translation}.sweetOneTimePay`, { date: formattedNextPaymentDate })}
            </StyledSuccessTitle>
            <StyledSuccessSubtitle>{t(`${translation}.amountToBeDebited`)}</StyledSuccessSubtitle>
            <StyledSuccessText>${nextAmountDue}</StyledSuccessText>
          </>
        );
      case DebitAchAuthTypeEnum.OFF_CYCLE: {
        let nextBusinessDay: string;
        const currentDay = moment().day();
        if (currentDay === 5 || currentDay === 6) {
          // Friday or Saturday
          nextBusinessDay = moment().weekday(8).format("MMMM Do YYYY"); // Set to Monday
        } else {
          // Other days
          nextBusinessDay = moment().add("days", 1).format("MMMM Do YYYY"); // Set to next day
        }
        return (
          <>
            <StyledSuccessTitle>
              {t(`${translation}.sweetOneTimePay`, { date: nextBusinessDay })}
            </StyledSuccessTitle>
            <StyledSuccessSubtitle>{t(`${translation}.amountToBeDebited`)}</StyledSuccessSubtitle>
            <StyledSuccessText>{overdueTotalAmount}</StyledSuccessText>
          </>
        );
      }
      default:
        return null;
    }
  };

  return (
    <div>
      <StyledSubUnit>
        {!rtpEnabled ? <SuccessModalIcon src={CircleCheck} /> : <ProgressBarLean progress={60} />}

        {renderSuccessText()}

        <StyledSuccessSubtitle>{t(`${translation}.paymentMethod`)}</StyledSuccessSubtitle>
        <StyledSuccessText>
          {`${bankDetails?.institutionName} **** ${bankDetails?.bankAccountLast4}`}
        </StyledSuccessText>
        <StyledPrintButton onClick={onPrint}>
          <img src={printSvg} alt="print" />
          {t(`${translation}.print`)}
        </StyledPrintButton>
      </StyledSubUnit>

      <StyledButtonsContainer>
        <StyledPayActionButton onClick={handleOnClick} id={`${type}-success-done`}>
          <p>{rtpEnabled ? t(`${translation}.continueTransferFunds`) : t(`${translation}.done`)}</p>
        </StyledPayActionButton>
      </StyledButtonsContainer>
    </div>
  );
};
