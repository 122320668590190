// Other
import React, { useEffect, useState } from "react";
// styles
import {
  FooterBottom,
  FooterBottomContainer,
  FooterBottomText,
  StyledLinkToTerms,
  StyledLinkToTermsWrapper,
  StyledCurvedTop,
  StyledCurvedBottom,
  StyledBtcFooter,
  StyledAsSeenOn,
  StyledAsSeenOnItems,
  StyledIcon,
  StyledSocialIcon,
  StyledIconSectionWrapper,
  StyledInnerUrlsContainer,
  StyledUsedByEmployeesFrom,
  StyledDisclaimer,
  StyledDisclaimerText,
} from "./styles";
import { observer } from "mobx-react";
import {
  COMPANY_EMAILS,
  EMAIL_BODY_TEMPLATES,
  EMAIL_SUBJECTS,
  FAQ_PTO_LINK,
  PRIVACY_LINK,
  SOCIAL_LINKS,
  SORBET_LINK,
  TERMS_CONDITIONS_LINK,
} from "config/constants";
import curvedTop from "../../assets/images/curve-top.png";
import curvedBottom from "../../assets/images/curve-bottom.svg";
import Forbes from "../../assets/logos/forbes.svg";
import TC from "../../assets/logos/TC.svg";
import Times from "../../assets/logos/times.svg";

import Company1 from "../../assets/logos/company1.svg";
import Company2 from "../../assets/logos/company2.svg";
import Company3 from "../../assets/logos/company3.svg";
import Company4 from "../../assets/logos/company4.svg";
import Wallmart from "../../assets/logos/walmart.svg";
import Amazon from "../../assets/logos/amazon.svg";

import Youtube from "../../assets/icons/youtube.svg";
import Linkedin from "../../assets/logos/linkedin.svg";
import IG from "../../assets/logos/instagram.svg";
import Facebook from "../../assets/logos/facebook.svg";
import Twitter from "../../assets/logos/twitter.png";

import { useTranslation } from "react-i18next";
import { mailService } from "../../services/mail";
import { useLocation } from "react-router-dom";
import { LeadSponsorEnum } from "services/api/types";
import { LOCAL_STORAGE_KEYS, LocalStorage } from "utils/localStorage";

export const FOOTER_ID = "btc-footer";

export const BtcFooter = observer(() => {
  const { t } = useTranslation();
  const [showDisclaimer, setDisclaimerVisibility] = useState(true);
  const supportLink = mailService.generateMailUrl({
    to: COMPANY_EMAILS.SUPPORT,
    subject: EMAIL_SUBJECTS.HELP_WITH_MY_APP,
    body: EMAIL_BODY_TEMPLATES.HELP_WITH_MY_APP,
  });

  const location = useLocation();
  useEffect(() => {
    if (location.pathname.match(/cash-advance\/apply-advance/i)) {
      setDisclaimerVisibility(true);
    } else {
      setDisclaimerVisibility(false);
    }
  }, [location.pathname]);

  const showLeadBankDisclaimer =
    LocalStorage.getItem(LOCAL_STORAGE_KEYS.original_sponsor) === LeadSponsorEnum.lead_bank;

  return (
    <StyledBtcFooter id={FOOTER_ID}>
      <StyledCurvedTop src={curvedTop} />
      <FooterBottom data-testid="footer_main_wrapper" hideFooter={false}>
        <FooterBottomContainer>
          <StyledIconSectionWrapper>
            <StyledAsSeenOn>
              <FooterBottomText>{t(`footer.asSeenOn`)}</FooterBottomText>
              <StyledAsSeenOnItems>
                <StyledIcon>
                  <img src={Times} alt="times" />
                </StyledIcon>
                <StyledIcon>
                  <img src={Forbes} alt="Forbes" />
                </StyledIcon>
                <StyledIcon>
                  <img src={TC} alt="TC" />
                </StyledIcon>
              </StyledAsSeenOnItems>
            </StyledAsSeenOn>
            <StyledUsedByEmployeesFrom>
              <FooterBottomText>{t(`footer.usedByEmployeesFrom`)}</FooterBottomText>
              <StyledAsSeenOnItems>
                <StyledIcon>
                  <img src={Amazon} alt="Amazon" />
                </StyledIcon>
                <StyledIcon>
                  <img src={Company3} alt="Company3" />
                </StyledIcon>
                <StyledIcon>
                  <img src={Company4} alt="Company4" />
                </StyledIcon>
                <StyledIcon>
                  <img src={Company1} alt="Company1" />
                </StyledIcon>
                <StyledIcon>
                  <img src={Wallmart} alt="Wallmart" />
                </StyledIcon>
                <StyledIcon>
                  <img src={Company2} alt="Company2" />
                </StyledIcon>
              </StyledAsSeenOnItems>
            </StyledUsedByEmployeesFrom>
          </StyledIconSectionWrapper>
          <StyledLinkToTermsWrapper>
            <StyledInnerUrlsContainer>
              <StyledLinkToTerms href={SORBET_LINK} target={"_blank"} data-testid="url">
                {t(`footer.url`)}
              </StyledLinkToTerms>
              <StyledLinkToTerms href={PRIVACY_LINK} target={"_blank"} data-testid="privacy">
                {t(`footer.privacy`)}
              </StyledLinkToTerms>

              <StyledLinkToTerms href={TERMS_CONDITIONS_LINK} target={"_blank"} data-testid="terms">
                {t(`footer.tnc`)}
              </StyledLinkToTerms>

              <StyledLinkToTerms href={supportLink} data-testid="contactus">
                {t(`footer.contactUs`)}
              </StyledLinkToTerms>

              <StyledLinkToTerms href={FAQ_PTO_LINK} target={"_blank"} data-testid="faq">
                {t(`footer.faq`)}
              </StyledLinkToTerms>
            </StyledInnerUrlsContainer>
            <StyledAsSeenOnItems>
              <StyledSocialIcon
                onClick={() => window.open(SOCIAL_LINKS.FACEBOOK, "_blank")}
                src={Facebook}
              />
              <StyledSocialIcon
                onClick={() => window.open(SOCIAL_LINKS.TWITTER, "_blank")}
                src={Twitter}
              />
              <StyledSocialIcon
                onClick={() => window.open(SOCIAL_LINKS.INSTAGRAM, "_blank")}
                src={IG}
              />
              <StyledSocialIcon
                onClick={() => window.open(SOCIAL_LINKS.LINKEDIN, "_blank")}
                src={Linkedin}
              />
              <StyledSocialIcon
                onClick={() => window.open(SOCIAL_LINKS.YOUTUBE, "_blank")}
                src={Youtube}
              />
            </StyledAsSeenOnItems>
          </StyledLinkToTermsWrapper>
          {showDisclaimer ? (
            <StyledDisclaimer>
              <StyledDisclaimerText>{t(`footer.disclaimer1a`)}</StyledDisclaimerText>
              <StyledDisclaimerText>{t(`footer.disclaimer1b`)}</StyledDisclaimerText>
              <StyledDisclaimerText>{t(`footer.disclaimer2`)}</StyledDisclaimerText>
            </StyledDisclaimer>
          ) : null}
          {showLeadBankDisclaimer ? (
            <StyledDisclaimer>
              <StyledDisclaimerText>{t(`footer.leadBankDisclaimer`)}</StyledDisclaimerText>
            </StyledDisclaimer>
          ) : null}
        </FooterBottomContainer>
      </FooterBottom>

      <StyledCurvedBottom src={curvedBottom} />
    </StyledBtcFooter>
  );
});
