import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { useStores } from "../../../../../../stores";
import { DebitAchAuthTypeEnum } from "../../../../../../services/api/types";
import { StyledResolveButton } from "../../styles";
import { Issue } from "stores/issues/types";

type Props = {
  fromStepper: boolean;
  issue: Issue;
};

const OverDueBalanceStep = observer(({ issue, fromStepper }: Props) => {
  const { t } = useTranslation();
  const {
    debitConsentStore: { setConsentModal },
  } = useStores();

  return (
    <>
      <StyledResolveButton
        fromStepper={fromStepper}
        onClick={() => setConsentModal({ type: DebitAchAuthTypeEnum.OFF_CYCLE, open: true })}>
        {t(issue.cta)}
      </StyledResolveButton>
    </>
  );
});

export default OverDueBalanceStep;
