import { ILogger } from "./factory";

export class MockLogger implements ILogger {
  captureError(error: any, data?: object | undefined) {}
  captureMessage(message: string, data?: object | undefined) {}
  addLoggerGlobalContext({
    id,
    username,
    email,
  }: {
    id: string;
    username?: string;
    email?: string;
  }) {}
  removeLoggerGlobalContext(key: string) {}
}
