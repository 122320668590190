import { createGlobalStyle } from "styled-components";
import { Colors } from "./constants";
import { ERROR_MODAL_WRAPPER_CLASS_NAME } from "components/ErrorModal/styles";
import { device } from "config/devices";

export const NO_ARROW_OVERLAY_CLASSNAME = "noArrowOvelay";

export const GlobalStyle = createGlobalStyle`
  overscroll-behavior: none;
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
  
  .ant-menu {
    color: ${Colors.textColor};

    &.ant-menu-vertical {
      border-right: 0;
      padding: 10px 0;
    }

    .ant-menu-item {
      margin-top: 0;
      height: auto;
      width: 100%;
      line-height: 20px;
      padding-top: 7px;
      padding-bottom: 7px;

      &:hover {
        color: inherit;
        background-color: ${Colors.paleGrey};
      }

      margin-bottom: 0;
      &:not(:last-child) {
        margin-bottom: 0;
      }
    }

    &:not(.ant-menu-item-horizontal) {
      .ant-menu-item-selected {
        background-color: ${Colors.lightBlueGray};
        font-weight: bold;
      }
    }

    .ant-menu-item-selected {
      color: inherit;
    }
  }

  .ant-notification {
    z-index: 1052;
  }

  .ant-modal-root {
    .${ERROR_MODAL_WRAPPER_CLASS_NAME} {
      z-index: 1301;
    }
  }

  .ant-modal-mask {
    background-color: rgba(${Colors.textColorSecondary_rgb}, 0.5);
    background-blend-mode: multiply;
  }


  .ant-popover {
    .ant-popover-content .ant-popover-inner {
      border-radius: 10px;
      box-shadow: 0 2px 12px 0 rgba(119, 113, 155, 0.35);

      .ant-popover-inner-content {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        width: 281px;
        height: 123px;
        border-radius: 10px;
        background-color: #ffffff;

        .ant-select.repeat-every-selector {
          .ant-select-selector {
            display: flex;
            align-items: center;
            justify-content: center;
            border: 0;
            width: 78px;
            height: 19px;
            border-radius: 2px;
            background-color: ${Colors.lightGrey};

            font-size: 12px;
            font-weight: 300;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: ${Colors.textColor};
          }

          .ant-select-arrow {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 15px;
          }
        }
      }
    }
  }

  .${NO_ARROW_OVERLAY_CLASSNAME} {
    .ant-popover-arrow {
      display: none;
    }
    .ant-popover-inner {
      border-radius: 10px;
      overflow: hidden;
    }
    .ant-popover-inner-content {
      height: auto !important; /* TODO: remove the css rule from globalStyle.ts */
      width: auto !important;
      justify-content: flex-start !important;
      padding: 0 !important;
    }
  }

  .ant-select-dropdown {
    border-radius: 10px;
    padding: 10px 0;

    &.repeat-every-dropdown-selector {
      border-radius: 6px;
      box-shadow: 0 2px 20px 0 rgba(119, 113, 155, 0.2);

    }
      .ant-select-item-option {
        align-items: center;
        font-size: 11px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: ${Colors.textColor};
      }

      .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
        background-color: ${Colors.paleGrey};
      }

      .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
        background-color: ${Colors.lightBlueGray};
        font-weight: bold;
      }
  }

  .ant-picker-panels {
    .ant-picker-date-panel {
      .ant-picker-cell-in-view {
        &.ant-picker-cell-in-range.ant-picker-cell-range-hover-end,
        &.ant-picker-cell-in-range.ant-picker-cell-range-hover-start {
          .ant-picker-cell-inner {
            &::after {
              background: #ffc958;
            }
          }
        }
      }
    }
  }

  .ant-picker-dropdown {
    .ant-picker-panel-container {
      .ant-picker-header {
        .ant-picker-header-super-next-btn,
        .ant-picker-header-super-prev-btn {
          display: none;
        }

        .ant-picker-header-view {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.25;
          letter-spacing: 0.2px;
          color: ${Colors.textColor};

          .ant-picker-year-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 6px;
            font-weight: 600;
            width: 42px;
            height: 24px;
            background-color: ${Colors.lightBlueGray};
          }
        }
        .ant-picker-prev-icon,
        .ant-picker-next-icon,
        .ant-picker-super-prev-icon,
        .ant-picker-super-next-icon {
          color: ${Colors.textColor};

          &::after {
            border-width: 3px 0 0 3px;
          }

          &::before {
            border-width: 3px 0 0 3px;
          }
        }
      }

      .ant-picker-body {
        .ant-picker-content {
          thead tr th {
            font-size: 10px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: 0.17px;
            color: ${Colors.orange};
          }

          tbody tr td.ant-picker-cell {
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.25;
            letter-spacing: 0.2px;
            text-align: right;
            color: ${Colors.lightBlueGray};
            &.ant-picker-cell-in-view {
              &.ant-picker-cell-range-hover-start,
              &.ant-picker-cell-range-hover-end {
                &:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end),
                &.ant-picker-cell-range-start-single,
                &.ant-picker-cell-range-end-single {
                  &::after {
                    border: 0;
                  }
                }
              }

              &.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range) {
                &::after {
                  border: 0;
                }
              }

              &.ant-picker-cell-range-hover .ant-picker-cell-inner,
              &.ant-picker-cell-range-hover-start .ant-picker-cell-inner,
              &.ant-picker-cell-range-hover-end .ant-picker-cell-inner {
                background-color: ${Colors.paleGreyTwo};
                border: 0;

                &::after {
                  border: 0;
                }
              }

              &.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range) {
                &::after {
                  border: 0;
                }
              }

              &.ant-picker-cell-range-hover .ant-picker-cell-inner,
              &.ant-picker-cell-range-hover-start .ant-picker-cell-inner,
              &.ant-picker-cell-range-hover-end .ant-picker-cell-inner {
                background-color: #ffc958;
                border: 0;

                &::after {
                  border: 0;
                }
              }

              &.ant-picker-cell-today .ant-picker-cell-inner {
                font-size: 12px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                letter-spacing: 0.2px;
                text-align: right;
                color: ${Colors.orange};

                &::before {
                  border: 0;
                }

                &::after {
                  border: 0px;
                }
              }
            }
            .ant-picker-cell-inner {
              display: flex;
              justify-content: center;

              &::after {
                border: 0;
              }
            }

            &.ant-picker-cell-in-view {
              color: ${Colors.textColor};

              &.ant-picker-cell-in-range::before,
              &.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
              &.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
                background-color: rgba(255, 174, 0, 0.3);
              }

              &.ant-picker-cell-selected .ant-picker-cell-inner,
              &.ant-picker-cell-range-start .ant-picker-cell-inner,
              &.ant-picker-cell-range-end .ant-picker-cell-inner {
                border-radius: 6px;
                background-color: ${Colors.orange};
                font-size: 12px;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.25;
                letter-spacing: 0.2px;
                color: #ffffff;
                display: flex;
                align-items: center;
              }
            }
          }
        }
      }
    }
  }

  .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    cursor: pointer !important;
  }

  .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
    cursor: pointer !important;
  }

  .ant-select-arrow .anticon .anticon-search .ant-select-suffix .bACcQK > svg {
      height: 16px !important;
      width: 16px !important;
  }

  body {
    overscroll-behavior: none;
    background: ${Colors.textColorWhite};
    color: ${Colors.textColor} !important;
    font-family: "Poppins", sans-serif !important;
    font-size: 12px !important;
    @media ${device.mobileS} {
      background: ${Colors.paleGrey};
      
    }
    @media ${device.tablet} {
      background: ${Colors.textColorWhite};
    }
    input[type=number]::-webkit-inner-spin-button, 
    input[type=number]::-webkit-outer-spin-button { 
     -webkit-appearance: none; 
      margin: 0; 
    }
    input {
      
      border: none;
      outline: none;
    }

    button {
      border: none;
      outline: none;
      cursor: pointer;
      background: transparent;
      padding: 0;
    }

    p, h1, h2, h3, h4 {
      margin: 0;
      color: ${Colors.textColor};
    }

    h2 {
      font-size: 26px;
      font-weight: bold;
    }

    h3 {
      font-size: 16px;
      font-weight: 600;
    }

    h4 {
      font-size: 14px;
      font-weight: 500;
    }

    a {
      color: ${Colors.textColor};
      text-decoration: underline;
      &:hover,
      &:active,
      &:focus {
        text-decoration: underline;
      }
    }

    .app {
      height: inherit;
      & > * {
        box-sizing: border-box;
      }

      .mainContainer {
        flex: 1;
        background-color: #f8f8fc;
        .innerContainer {
          height: 100%;
        }
      }
    }
  }
`;
