import styled from "styled-components";
import { Box } from "@mui/material";
import { device } from "config/devices";
import { Link } from "react-router-dom";

export const StyledThankYouWrapper = styled(Box)`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  background: #fff;
  @media ${device.laptop} {
    flex-direction: row;
    margin-left: 56px;
  }
`;

export const ThankYouTitle = styled.h1`
  font-weight: 500;
  color: #242323;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 8px;
`;

export const StyledThankYouBody = styled(Box)`
  display: flex;
  padding: 24px 20px 56px;
  background: #fff;
  flex-direction: column;
  max-width: 528px;
  align-self: center;
`;

export const StyledThankYouContainer = styled(Box)`
  flex: 1;
`;
export const StyledThankYouTipWrapper = styled(Box)`
  padding: 16px;
  background: #f8f8fc;
  border-radius: 8px;
  margin-top: 24px;
`;

export const StyledThankYouTipText = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #585866;
  display: flex;
  flex-direction: column;
  @media ${device.laptop} {
    font-size: 14px;
  }
`;

export const StyledThankYouTipTextBold = styled.span`
  font-weight: 500;
  color: var(--text-icon-dark, #242323);
`;

export const StyledThankYouTrackNote = styled.div`
  margin-bottom: 16px;
  margin-top: 24px;
`;

export const StyledLoginButton = styled(Link)`
  color: var(--text-icon-dark, #242323);
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  display: flex;
  padding: 6px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  line-height: 20px;
  border-radius: 8px;
  border: 1px solid var(--text-icon-dark, #242323);
  text-decoration: none;
  :hover {
    text-decoration: none;
    color: var(--text-icon-dark, #242323);
  }
  display: flex;
  align-self: flex-start;
`;

export const StyledThankYouTipTextBlue = styled.p`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #32536a;
  margin-top: 4px;
`;

export const StyledThankText = styled.p`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #585866;
`;

export const StyledThankYouQuestionsTextPurple = styled.span`
  color: #ff5277;
  a {
    color: #ff5277;
    text-decoration: none;
  }
`;
export const StyledThankYouQuestionsSupportEmail = styled.p`
  color: #ff5277;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  a {
    color: #ff5277;
    text-decoration: none;
  }
`;

export const StyledThankYouDivider = styled(Box)`
  height: 1px;
  background: #e4e2ed;
  margin-top: 24px;
`;

export const StyledThankYouFeedbackWrapper = styled(Box)`
  margin-top: 52px;
  max-width: 304px;
  padding: 0px 15px;
  display: flex;
  flex-direction: column;
`;

export const StyledThankYouFeedbackName = styled.p`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #32536a;
  margin-top: 16px;
`;

export const StyledThankYouQuestionsWrapper = styled(Box)`
  margin-top: 24px;
`;
export const StyledThankYouImageContainer = styled(Box)`
  display: none;
  @media ${device.laptop} {
    display: flex;
    flex: 1;
  }
`;
export const StyledThankYouImage = styled.img`
  width: 100%;
`;
export const StyledCSBodyContainer = styled(Box)`
  justify-content: center;
  display: flex;
`;
export const StyledThankYouFeedbackAvatarWrapper = styled(Box)`
  margin-bottom: 8px;
  position: relative;
  align-self: flex-start;
`;
export const StyledThankYouFeedbackQuote = styled(Box)`
  background-color: #ff5277;
  position: absolute;
  right: 0;
  bottom: 0;
  display: flex;
  border-radius: 12px;
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
`;
export const StyledThankYouFeedbackAvatar = styled.img`
  width: 64px;
  height: 64px;
`;
