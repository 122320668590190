import { PaymentTypeEnum } from "services/api/types";
import {
  RadioButton,
  RadioButtonBody,
  RadioButtonDescription,
  RadioButtonTitle,
  RadioButtonTitleContainer,
  StyledRadioContainer,
} from "./style";

type Props = {
  selected: boolean;
  onClick: () => void;
  title: string;
  description: string;
  type: PaymentTypeEnum;
};
export const CustomRadioButton = ({ selected, onClick, title, description, type }: Props) => {
  return (
    <StyledRadioContainer disabled={selected} selected={selected} onClick={onClick} id={`payment-${type}`}>
      <RadioButton mobileView={false}>
        {selected ? <div className="selected-payment-type" /> : null}
      </RadioButton>

      <RadioButtonBody>
        <RadioButtonTitleContainer>
          <RadioButtonTitle selected={selected}>
            {title}
            {type === PaymentTypeEnum.express_disbursement ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="25"
                viewBox="0 0 12 25"
                fill="none">
                <g clip-path="url(#clip0_2367_14791)">
                  <path
                    d="M3.7931 13.9651H0L8.72414 3.5L7.2069 11.0349H11L2.27586 21.5L3.7931 13.9651Z"
                    fill="#FF5277"
                    stroke="white"
                    stroke-linejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_2367_14791">
                    <rect width="12" height="24" fill="white" transform="translate(0 0.5)" />
                  </clipPath>
                </defs>
              </svg>
            ) : null}
          </RadioButtonTitle>

          <RadioButton mobileView={true}>
            {selected ? <div className="selected-payment-type" /> : null}
          </RadioButton>
        </RadioButtonTitleContainer>
        <RadioButtonDescription selected={selected}>{description}</RadioButtonDescription>
      </RadioButtonBody>
    </StyledRadioContainer>
  );
};
