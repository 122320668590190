import { IUserInfo } from "services/api/types";

export enum VERIFY_FIELDS {
  STREET = "street",
  CITY = "city",
  STATE = "state",
  ZIPCODE = "zipCode",
  COUNTRY = "country",
  COUNTRYCODE = "countryCode",
  PHONENUMBER = "phoneNumber",
}

export enum VERIFY_ERRORS {
  REQUIRED = "is required",
  CORRECT_VALUE = "Please enter correct value!",
  ZIPCODE = "Please, put valid ZIP code!",
}

export interface IFields {
  [VERIFY_FIELDS.STREET]: string;
  [VERIFY_FIELDS.CITY]: string;
  [VERIFY_FIELDS.STATE]: any | never;
  [VERIFY_FIELDS.ZIPCODE]: string;
  [VERIFY_FIELDS.COUNTRY]: any | never;
  [VERIFY_FIELDS.COUNTRYCODE]?: number | string;
  [VERIFY_FIELDS.PHONENUMBER]: string;
}
export type UserFieldName = keyof IUserInfo;


export enum ErrorTypesEnum {
  authError = "Authorization error",
  encunteredError = "Encountered error",
  cardIssuedError = "Card issued error",
  wrongAuthProviderError = "Wrong auth provider",
}


export interface IErrorPopUpType {
  error: boolean;
  popUpText: string;
}
