import React from "react";

import {
  StyledPtoCashAdvanceHeader,
  StyledPCAHeaderText,
  StyledPCABodyText,
  StyledPCABodyTitleText,
  StyledPCABody,
  StyledPtoImage,
  StyledCashAdvanceCustomContainer,
  StyledPtoAdvanceSorbetLogo,
} from "../Step1/styles";

import {
  StyledBTCSelectorWrapper,
  StyledBTCSelector,
  StyledBTCSSubmitButton,
  StyledHelperText,
  StyledSelectorItem,
  StyledSelectorLabel,
  StyledBodyText2,
  StyledApplyForm,
  StyledEmptyDiv,
} from "../styles";
import { useTranslation } from "react-i18next";
import sorbetLogo from "assets/logos/white-logo.svg";
import ptoImage from "assets/images/woman-phone.png";

import {
  LeadIndustryType,
  SeniorityType,
  TAnnualSalaryRange,
  TPtoPolicyDesc,
} from "services/api/types";
import { observer } from "mobx-react";
import FillInDetailsModal from "./FillInDetailsModal";

const sxSelect = {
  "& .MuiSelect-select .notranslate::after": "Choose an option"
    ? {
        content: `"Choose an option"`,
        opacity: 0.42,
      }
    : {},
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    boxShadow: "0 0 0 1px #7a56fc, 0 0 0 3px #e2daff",
    border: "none",
    borderRadius: "4px",
  },
  "& .MuiInputBase-input": {
    color: "#242323",
    fontSize: 15,
    fontFamily: "Poppins",
  },
};

const PtoCashAdvance = observer(() => {
  const { t } = useTranslation();
  const pageTranslationPrefix = "page_PtoCashAdvance";

  return (
    <StyledCashAdvanceCustomContainer>
      <StyledPtoCashAdvanceHeader>
        <StyledPtoAdvanceSorbetLogo src={sorbetLogo}></StyledPtoAdvanceSorbetLogo>
        <StyledPCAHeaderText>{t(`${pageTranslationPrefix}.timeIsMoney`)}</StyledPCAHeaderText>
        <StyledPCABodyText>{t(`${pageTranslationPrefix}.accessTheCashValue`)}</StyledPCABodyText>
        <StyledPtoImage src={ptoImage} />
      </StyledPtoCashAdvanceHeader>
      <StyledPCABody>
        <StyledPCABodyTitleText id="pto-cash-advance-title">
          {t(`${pageTranslationPrefix}.findOutWhatYourPto`)}
        </StyledPCABodyTitleText>
        <StyledBodyText2 id="pto-cash-advance-subtitle">
          {t(`${pageTranslationPrefix}.personalInformationKeptConfidential`)}
        </StyledBodyText2>
        <StyledApplyForm>
          {/* ######### Industry Selector ######### */}

          <StyledBTCSelectorWrapper>
            <StyledSelectorLabel required={true}>
              {t(`${pageTranslationPrefix}.industrySelector.description`)}
            </StyledSelectorLabel>

            <StyledBTCSelector
              defaultValue={""}
              SelectDisplayProps={{ style: { fontFamily: "Poppins", fontSize: 15 } }}
              MenuProps={{ PaperProps: { sx: { maxHeight: 400, top: 380 } } }}
              sx={sxSelect}>
              {[].map((item: LeadIndustryType) => (
                <StyledSelectorItem
                  key={item}
                  id={item}
                  value={item}
                  sx={{
                    "&:hover": {
                      background: "#E2DAFF",
                      fontFamily: "Poppins",
                      fontSize: 14,
                    },
                  }}>
                  {t(`${pageTranslationPrefix}.industrySelector.${item}`)}
                </StyledSelectorItem>
              ))}
            </StyledBTCSelector>
          </StyledBTCSelectorWrapper>

          {/* ######### Salary Selector ######### */}

          <StyledBTCSelectorWrapper>
            <StyledSelectorLabel required={true}>
              {t(`${pageTranslationPrefix}.salaryRangeSelector.description`)}
            </StyledSelectorLabel>

            <StyledBTCSelector
              defaultValue={""}
              SelectDisplayProps={{ style: { fontFamily: "Poppins", fontSize: 15 } }}
              MenuProps={{ PaperProps: { sx: { maxHeight: 400, top: 380 } } }}
              sx={sxSelect}>
              {[].map((item: TAnnualSalaryRange) => (
                <StyledSelectorItem
                  key={item}
                  id={item}
                  value={item}
                  sx={{
                    "&:hover": {
                      background: "#E2DAFF",
                      fontFamily: "Poppins",
                      fontSize: 14,
                    },
                  }}>
                  {t(`${pageTranslationPrefix}.salaryRangeSelector.${item}`)}
                </StyledSelectorItem>
              ))}
            </StyledBTCSelector>
          </StyledBTCSelectorWrapper>

          {/* ######### Seniority Selector ######### */}

          <StyledBTCSelectorWrapper>
            <StyledSelectorLabel required={true}>
              {t(`${pageTranslationPrefix}.senioritySelector.description`)}
            </StyledSelectorLabel>

            <StyledBTCSelector
              defaultValue={""}
              SelectDisplayProps={{ style: { fontFamily: "Poppins", fontSize: 15 } }}
              MenuProps={{ PaperProps: { sx: { maxHeight: 400, top: 380 } } }}
              sx={sxSelect}>
              {[].map((item: SeniorityType) => (
                <StyledSelectorItem
                  key={item}
                  id={item}
                  value={item}
                  sx={{
                    "&:hover": {
                      background: "#E2DAFF",
                      fontFamily: "Poppins",
                      fontSize: 14,
                    },
                  }}>
                  {t(`${pageTranslationPrefix}.senioritySelector.${item}`)}
                </StyledSelectorItem>
              ))}
            </StyledBTCSelector>
          </StyledBTCSelectorWrapper>

          {/* ######### PTO Policy Selector ######### */}

          <StyledBTCSelectorWrapper>
            <StyledSelectorLabel required={true}>
              {t(`${pageTranslationPrefix}.ptoPolicySelector.description`)}
            </StyledSelectorLabel>
            <StyledBTCSelector
              defaultValue={""}
              SelectDisplayProps={{ style: { fontFamily: "Poppins", fontSize: 15 } }}
              MenuProps={{ PaperProps: { sx: { maxHeight: 400, top: 380 } } }}
              sx={sxSelect}>
              {[].map((item: TPtoPolicyDesc, i) => (
                <StyledSelectorItem value={item} id={item} key={item}>
                  {t(`${pageTranslationPrefix}.ptoPolicySelector.${item}`)}
                </StyledSelectorItem>
              ))}
            </StyledBTCSelector>
          </StyledBTCSelectorWrapper>

          <StyledEmptyDiv />
          <StyledBTCSSubmitButton
            type="submit"
            style={{ marginTop: "24px" }}
            id="pto-cash-advance-submit">
            {t(`${pageTranslationPrefix}.submitButton`)}
          </StyledBTCSSubmitButton>
          <StyledHelperText>
            {t(`${pageTranslationPrefix}.checkingEligibilityWontAffect`)}
          </StyledHelperText>
        </StyledApplyForm>
      </StyledPCABody>

      <FillInDetailsModal open={true} />
    </StyledCashAdvanceCustomContainer>
  );
});

export default PtoCashAdvance;
