import {
  StyledDescription,
  StyledButtonsContainer,
  StyledCancelButton,
  StyledPayActionButton,
  StyledConsentContainer,
  StyledScroll,
  PaymentConsentContainer,
  PaymentConsentHeader,
  ExpandButton,
  PaymentConsentWrapper,
  ExpandButtonContainer,
  ZoomButtons,
  ZoomButton,
  StyledTitleNew,
  ProgressBarWrapper,
} from "./styles";
import { useTranslation } from "react-i18next";
import { UIEvent, useEffect, useRef, useState } from "react";
import { useStores } from "stores";
import { ContractTypeEnum, DebitAchAuthTypeEnum, PaymentTypeEnum } from "services/api/types";
import { pdfjs, Document, Page } from "react-pdf";
import { debounce } from "@mui/material";
import expandIcon from "../icons/expandIcon.svg";
import minimizeIcon from "../icons/minimizeIcon.svg";
import zoomInIcon from "../icons/zoomIn.svg";
import zoomOutIcon from "../icons/zoomOut.svg";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import ProgressBarLean from "pages/Btc/Components/ProgressBarLean";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
const translation = "achDebitAuth";

type Props = {
  handleClose: () => void;
  confirmButtonEnabled: boolean;
  rtpEnabled: boolean | undefined;
  type:
    | DebitAchAuthTypeEnum.AUTO_PAY
    | DebitAchAuthTypeEnum.ONE_TIME
    | DebitAchAuthTypeEnum.OFF_CYCLE;
  file?: File;
  fullScreen: boolean;
};

export const PaymentConsent = ({
  handleClose,
  rtpEnabled,
  confirmButtonEnabled,
  type,
  file,
  fullScreen,
}: Props) => {
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const pdfContainerRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const {
    debitConsentStore: { setConsentModal, getConsentPdf, confirmConsent },
  } = useStores();
  const [numPages, setNumPages] = useState<number>(0);
  const [viewerWidth, setViewerWidth] = useState(window.innerWidth - 40);
  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };
  const [documentScale, setDocumentScale] = useState(1);

  const onClose = () => {
    handleClose();
  };

  const onConfirm = () => {
    if (rtpEnabled) {
      setConsentModal({ showSuccess: true, fullScreen: false });
    } else {
      confirmConsent(PaymentTypeEnum.disbursement);
    }
  };

  const handleScroll = (e: UIEvent<HTMLElement>) => {
    const bottom =
      e.currentTarget.scrollHeight - e.currentTarget.scrollTop < e.currentTarget.clientHeight + 10;
    if (bottom) {
      setConsentModal({ confirmButtonEnabled: true });
    }
  };

  useEffect(() => {
    if (scrollContainerRef.current && pdfContainerRef.current) {
      if (scrollContainerRef.current?.clientHeight > pdfContainerRef.current?.scrollHeight) {
        setConsentModal({ confirmButtonEnabled: true });
      }
    }
  }, [viewerWidth]);

  useEffect(() => {
    if (scrollContainerRef.current?.offsetWidth) {
      setViewerWidth(scrollContainerRef.current?.offsetWidth - 64);
    }
  }, [fullScreen]);

  useEffect(() => {
    switch (type) {
      case DebitAchAuthTypeEnum.AUTO_PAY:
        getConsentPdf(ContractTypeEnum.auto_pay_agreement);
        break;
      case DebitAchAuthTypeEnum.ONE_TIME:
        getConsentPdf(ContractTypeEnum.one_time_agreement);
        break;
      case DebitAchAuthTypeEnum.OFF_CYCLE:
        getConsentPdf(ContractTypeEnum.one_time_off_cycle_agreement);
        break;

      default:
        break;
    }
    const handleResize = debounce(() => {
      if (window.innerWidth < 768) {
        setConsentModal({ fullScreen: false });
      } else {
        setDocumentScale(1);
      }
      if (scrollContainerRef.current) {
        setViewerWidth(scrollContainerRef.current?.offsetWidth - 64);
      }
    }, 500);

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const toggleExpand = () => {
    setConsentModal({ fullScreen: !fullScreen });
  };

  const zoomIn = () => {
    if (documentScale <= 2) {
      setDocumentScale(documentScale + 0.1);
    }
  };
  const zoomOut = () => {
    if (documentScale > 1) {
      setDocumentScale(documentScale - 0.1);
    }
  };

  return (
    <PaymentConsentWrapper _fullScreen={fullScreen}>
      {rtpEnabled ? (
        <ProgressBarWrapper>
          <ProgressBarLean progress={30} />
        </ProgressBarWrapper>
      ) : null}
      <PaymentConsentHeader>
        <StyledTitleNew>
          {type === DebitAchAuthTypeEnum.AUTO_PAY
            ? t(`${translation}.settingUpAutoPay`)
            : t(`${translation}.oneTimePay`)}
        </StyledTitleNew>
        <StyledDescription>
          {type === DebitAchAuthTypeEnum.AUTO_PAY
            ? t(`${translation}.pleaseReviewAutoPay`)
            : t(`${translation}.pleaseReviewOneTimePay`)}
        </StyledDescription>
      </PaymentConsentHeader>
      <PaymentConsentContainer _fullScreen={fullScreen}>
        <StyledConsentContainer _fullScreen={fullScreen} ref={scrollContainerRef}>
          <StyledScroll onScroll={handleScroll} id={"ach-debit-auth-consent"}>
            {file ? (
              <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
                <div ref={pdfContainerRef}>
                  {Array(numPages)
                    .fill("page")
                    .map((_, index) => (
                      <Page
                        scale={documentScale}
                        key={`page_number_${index}`}
                        pageNumber={index + 1}
                        width={Math.min(viewerWidth, 776)}
                        renderAnnotationLayer={true}
                        renderTextLayer={true}
                      />
                    ))}
                </div>
              </Document>
            ) : null}
          </StyledScroll>

          <ZoomButtons>
            <ZoomButton onClick={zoomIn}>
              <img src={zoomInIcon} alt={"zoom-in"} />
            </ZoomButton>
            <ZoomButton onClick={zoomOut}>
              <img src={zoomOutIcon} alt={"zoom-out"} />
            </ZoomButton>
          </ZoomButtons>
        </StyledConsentContainer>
      </PaymentConsentContainer>

      <StyledButtonsContainer>
        <StyledPayActionButton
          onClick={onConfirm}
          disabled={!confirmButtonEnabled || !file}
          id={`confirm-consent-${type}`}>
          <p>{t(`${translation}.confirm`)}</p>
        </StyledPayActionButton>
        <StyledCancelButton onClick={onClose} id={`cancel-consent-${type}`}>
          <p> {t(`${translation}.cancel`)}</p>
        </StyledCancelButton>
        {file ? (
          <ExpandButtonContainer>
            <ExpandButton
              onClick={toggleExpand}
              startIcon={
                <img
                  src={fullScreen ? minimizeIcon : expandIcon}
                  alt={fullScreen ? "Minimize" : "Expand"}
                />
              }>
              {t(`${translation}.${fullScreen ? "minimize" : "expand"}`)}
            </ExpandButton>
          </ExpandButtonContainer>
        ) : null}
      </StyledButtonsContainer>
    </PaymentConsentWrapper>
  );
};
