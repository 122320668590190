export function getCookie(cname: string) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export const getAnalyticHeader = () => {
  const utmUrl = window.location.href;

  const fbp = getCookie("_fbp") || undefined;
  let fbclid = undefined;
  let gclid = undefined;
  let irclickid = undefined;
  let ttclid = undefined;

  // get query params
  if (utmUrl) {
    const url = new URL(utmUrl);
    const searchParams = new URLSearchParams(url.search);
    const fbclidQP = searchParams.get("fbclid");
    if (fbclidQP) {
      fbclid = fbclidQP;
    }
    const gclidQP = searchParams.get("gclid");
    if (gclidQP) {
      gclid = gclidQP;
    }
    const irclickidQP = searchParams.get("irclickid");
    if (irclickidQP) {
      irclickid = irclickidQP;
    }
    const ttclidQP = searchParams.get("ttclid");
    if (ttclidQP) {
      ttclid = ttclidQP;
    }
  }

  fbclid = fbclid || getCookie("_fbc") || undefined;
  gclid = gclid || getCookie("_gcl_aw") || undefined;
  ttclid = ttclid || getCookie("ttclid") || undefined;

  return JSON.stringify({
    fbp,
    fbclid,
    gclid,
    ttclid,
    irclickid,
  });
};
