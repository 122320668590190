import { useEffect, useState } from "react";
import styled from "styled-components";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import { useStores } from "stores";
import OnCyclePaymentInfo from "pages/Home/OnCyclePaymentInfo";
import { Support } from "./components/Support";
import { CreditTransferMessage } from "./components/CreditTransferMessage";
import { Loader } from "../../components";
import LoanInfo from "./LoanInfo";
import { DisbursementError } from "./components/DisbursementError/DisbursementError";
import { DebitTransferError } from "./components/DebitTransferError/DebitTransferError";
import { SorbetContractDeclined } from "./components/SorbetContractDeclined";
import { UpdateNotification } from "./UpdateNotification/UpdateNotification";
import PendingOverdue from "./PendingOverdue";
import PromissoryNote from "./PromissoryNote";
import IssuesCenter from "./IssuesCenter";
import { currencyFormatter } from "utils/formatters";

import { snackbarUtils } from "components/Notification";
import { UserContractStatusEnum } from "services/api/types";
import { formattedDate } from "../../utils/date";
import { LoaderWrapper } from "../styles";
import { LOCAL_STORAGE_KEYS, LocalStorage } from "utils/localStorage";
import {errorLogger} from "../../services/logger";

const Home = observer(() => {
  const {
    contractsStore: { contractStatus },
    loanStore: { loanInfo, overdueTotalAmountCents, getAmortizeLoanDetails, isPromissoryNote },
    transfersInfoStore: {
      latestDebitTransfer,
      isReturnedExcludingNSF,
      isReturnedNSF,
      isCreationFailedNSF,
    },
    appStore: { setIsHeaderHidden },
    debitConsentStore: {
      isOffCycleConsentValid,
      isFirstConsentExist,
      disbursementAttemptFailed,
      shouldShowPendingOverdue,
    },
  } = useStores();

  const hasOverdue = overdueTotalAmountCents > 0;
  const { t } = useTranslation();
  const [contractRejected, setContractRejected] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const fromContract = location.state?.fromContract;

  useEffect(() => {
    setIsHeaderHidden(false);
    const redirectAfterLoginUrl = LocalStorage.getItem(LOCAL_STORAGE_KEYS.redirectAfterLoginUrl);
    if (redirectAfterLoginUrl) {
      errorLogger.captureMessage(`AfterLoginUrl: about to redirect to url: ${redirectAfterLoginUrl}`);
      LocalStorage.removeItem(LOCAL_STORAGE_KEYS.redirectAfterLoginUrl);
      navigate(redirectAfterLoginUrl, { state: { isRedirectAfterLogin: true } });
    }
  }, []);

  useEffect(() => {
    if (!isFirstConsentExist) {
      getAmortizeLoanDetails();
    }
  }, [isFirstConsentExist]);

  useEffect(() => {
    if (fromContract === "signed") {
      snackbarUtils.success(t(`mainScreen.contractSigned`));
    }
    if (fromContract === "rejected") {
      setContractRejected(true);
    }
  }, [fromContract]);

  useEffect(() => {
    if (isCreationFailedNSF && loanInfo) {
      snackbarUtils.error(
        t(`mainScreen.alertPaymentDue`, {
          amountDue: currencyFormatter(loanInfo.paymentDueAmountCents),
          dueDate: formattedDate(latestDebitTransfer?.createdAt),
        }),
      );
    }
  }, [isCreationFailedNSF, loanInfo]);

  useEffect(() => {
    if (isReturnedNSF && loanInfo) {
      snackbarUtils.error(t(`mainScreen.alertPaymentDueFailed`));
    }
  }, [isReturnedNSF, loanInfo]);

  if (isPromissoryNote) {
    return <PromissoryNote />;
  }

  if (contractRejected || contractStatus === UserContractStatusEnum.Declined) {
    return (
      <StyledContainer>
        <SorbetContractDeclined />
        <Support />
        {isReturnedExcludingNSF && <DebitTransferError />}
      </StyledContainer>
    );
  }

  return (
    <StyledContainer>
      <>
        {isFirstConsentExist && <IssuesCenter />}

        {
          // if user has overdue balance and valid consent, show 'pending' for 3 days
          hasOverdue && isOffCycleConsentValid && shouldShowPendingOverdue && <PendingOverdue />
        }

        <CreditTransferMessage />

        {!disbursementAttemptFailed && (
          <OnCyclePaymentInfo hasOverdue={hasOverdue && !isOffCycleConsentValid} />
        )}

        {disbursementAttemptFailed && <DisbursementError />}

        {isFirstConsentExist && !disbursementAttemptFailed && <LoanInfo />}
      </>
      <Support />
      {isReturnedExcludingNSF && <DebitTransferError />}
    </StyledContainer>
  );
});

const HomeScreenWrapper = () => {
  const {
    debitConsentStore: { consentModal },
    userStore: { loadingUserInfo },
    bankDetailsStore: { loading: bankDetailsLoading, relinkLoading },
  } = useStores();

  return (
    <StyledWrapper>
      <Home />

      <UpdateNotification />
      {(loadingUserInfo || bankDetailsLoading || relinkLoading) && !consentModal?.loading ? (
        <LoaderWrapper data-test-id={"loader"}>
          <Loader />
        </LoaderWrapper>
      ) : null}
    </StyledWrapper>
  );
};

export default observer(HomeScreenWrapper);

const StyledWrapper = styled.div`
  background: #ffffff;
  display: flex;
  justify-content: center;
  flex: 1;
  padding: 24px;
`;

const StyledContainer = styled.div`
  max-width: 702px;
  /* padding-top: 24px; */
`;
