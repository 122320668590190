
// 50 MB file limit

export const FILE_SIZE_LIMIT = 52428800;

//ssn number chars limit
export const SSN_LENGTH_LIMIT = 9;

//phone number chars limit
export const PHONE_LENGTH_LIMIT = 10;

//general chars limit, for firstname, lastname, etc...
export const GENERAL_TEXT_LIMIT = 50;

//general long chars limit, like company
export const GENERAL_LONG_TEXT_LIMIT = 100;

//max default amount of money to advance in cents
export const DEFAULT_MAX_ADVANCE_MONEY = 150000;

//Age limit - 18
export const AGE_LIMIT = 18;

export const STEPS_MAP: Record<string, string> = {
  '1': 'pto-cash-advance',
  '2': 'apply',
  '3': 'apply-advance',
  '4': 'apply-info',
  '5': 'credit-score',
  '6': 'security-social-number',
  '7': 'pay-stubs',
  '8': 'thank-you'
}

export const URLS_MAP: Record<string, number> = {
  'pto-cash-advance': 1,
  'apply' : 2,
  'apply-advance': 3,
  'apply-info' : 4,
  'credit-score' : 5,
  'security-social-number' : 6,
  'pay-stubs' : 7,
  'thank-you' : 8
}

export const URLS = [
  'apply-advance',
  'apply-info',
  'credit-score',
  'security-social-number',
  'pay-stubs',
  'thank-you',
]

export const BTC_ERRORS = {
  LEAD_EXISTS: "Lead with a given email already exists",
  USER_EXISTS: "User with a given email already exists",
  UUID_EXPECTED: "uuid is expected",
  MAILCHIMP_ERROR: "please enter a real email address"
}

export const ALLOWED_STATES = [
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
  "Australian Capital Territory",
  "New South Wales",
  "Northern Territory",
  "Queensland",
  "South Australia",
  "Tasmania",
  "Victoria",
  "Western Australia",
  "District of Columbia"
];
