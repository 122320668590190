import React from "react";

const Plus = () => {
  return (
    <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 0C8.27614 0 8.5 0.223858 8.5 0.5V7H15C15.2761 7 15.5 7.22386 15.5 7.5C15.5 7.77614 15.2761 8 15 8H8.5V14.5C8.5 14.7761 8.27614 15 8 15C7.72386 15 7.5 14.7761 7.5 14.5V8H1C0.723858 8 0.5 7.77614 0.5 7.5C0.5 7.22386 0.723858 7 1 7H7.5V0.5C7.5 0.223858 7.72386 0 8 0Z"
        fill="#1C184F"
      />
    </svg>
  );
};

export default Plus;
