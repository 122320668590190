import { Dialog, IconButton, styled as muiStyled } from "@mui/material";
import { device } from "config/devices";
import styled from "styled-components";

export const StyledDialog = muiStyled(Dialog)`
  z-index: 1301;
  .MuiDialog-container {
    align-items: flex-end;
  }
  .MuiPaper-root {
    max-width: 584px;
    margin: 0;
    padding: 40px 24px;
    margin: 0 8px;
  }
  @media ${device.tablet} {
    .MuiPaper-root {
      padding: 32px 40px;
    }
    .MuiDialog-container {
      align-items: center;
    }
  }
`;

export const CloseIcon = muiStyled(IconButton)`
  position: absolute;
  top: 2px;
  right: 2px;
`;

export const Title = styled.h1`
  color: var(--text-icon-dark, #242323);
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px; /* 142.857% */
  @media ${device.tablet} {
    font-size: 28px;
    line-height: 40px; /* 142.857% */
  }
`;

export const Description = styled.p`
  color: var(--text-icon-secondary, var(--text-icon-text-secondary, #585866));
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  font-size: 16px;
  @media ${device.tablet} {
    font-size: 14px;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  margin-top: 16px;
  @media ${device.tablet} {
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 56px;
  }
`;
