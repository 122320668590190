import { FC, lazy } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { BtcFooter } from "../components";
import { GTMDataLayer } from "components/GTMDataLayer";
import Protected from "components/Protected";
import { ROUTES } from "config/constants";
import Helmet from "react-helmet";
import { ManageLinkedAccounts } from "../pages/ManageLinkedAccounts";
import { PaymentHistory } from "../pages/PaymentHistory";
import { EmailLinkLogin } from "../pages/Login/EmailLinkLogin";
import { GeneralErrorPage } from "../pages/GeneralErrorPage";
import { Agreements } from "../pages/Agreements";
import ApplicationStatus from "./../pages/Btc/ApplicationStatus";
import Contract from "./../pages/SorbetContract";
import BtcRouter from "./btcRouter";
import Home from "../pages/Home";
import { observer } from "mobx-react";
import { BackupPaymentMethodAgreement } from "pages/Agreements/BackupPaymentMethod";
import IssueStepper from "../pages/Home/IssuesCenter/IssueStepper";
import {PTOCenter} from "pages/PtoCenter";
import {ConnectToPayroll} from "../pages/PtoCenter/components/ConnectToPayroll";
import Paystubs from "../pages/PtoCenter/components/paystubs";

const Login = lazy(() => import("./../pages/Login"));

const AppRoutes: FC = observer(() => {
  return (
    <Routes>
      <Route
        path={ROUTES.HOME}
        element={
          <Protected path={ROUTES.HOME} isPrivate>
            <GTMDataLayer pageTitle={"Home"}>
              <Helmet>
                <title>{"Home"}</title>
              </Helmet>
              <Home />
            </GTMDataLayer>
          </Protected>
        }
      />

      <Route
        path={ROUTES.MANAGE_LINKED_ACCOUNTS}
        element={
          <Protected path={ROUTES.MANAGE_LINKED_ACCOUNTS} isPrivate>
            <GTMDataLayer pageTitle={"Manage Accounts"}>
              <Helmet>
                <title>{"Manage Accounts"}</title>
              </Helmet>
              <ManageLinkedAccounts />
            </GTMDataLayer>
          </Protected>
        }
      />

      <Route
        path={ROUTES.ISSUES_CENTER}
        element={
          <Protected path={ROUTES.ISSUES_CENTER} isPrivate>
            <GTMDataLayer pageTitle={"Issues Center"}>
              <Helmet>
                <title>{"Issues Center"}</title>
              </Helmet>
              <IssueStepper />
            </GTMDataLayer>
          </Protected>
        }
      />

      <Route
        path={ROUTES.PAYMENT_HISTORY}
        element={
          <Protected path={ROUTES.PAYMENT_HISTORY} isPrivate>
            <GTMDataLayer pageTitle={"Payment History"}>
              <Helmet>
                <title>{"Payment History"}</title>
              </Helmet>
              <PaymentHistory />
            </GTMDataLayer>
          </Protected>
        }
      />
        <Route
            path={ROUTES.PAYROLL}
            element={
                <Protected path={ROUTES.PAYROLL} isPrivate>
                    <GTMDataLayer pageTitle={"PTO Center"}>
                        <Helmet>
                            <title>{"PTO Center"}</title>
                        </Helmet>
                        <ConnectToPayroll />
                    </GTMDataLayer>
                </Protected>
            }
        />
        <Route
            path={ROUTES.PAYROLL_FROM_CENTER}
            element={
                <Protected path={ROUTES.PAYROLL_FROM_CENTER} isPrivate>
                    <GTMDataLayer pageTitle={"PTO Center"}>
                        <Helmet>
                            <title>{"PTO Center"}</title>
                        </Helmet>
                        <ConnectToPayroll />
                    </GTMDataLayer>
                </Protected>
            }
        />
        <Route
            path={ROUTES.PAYSTUB_FROM_CENTER}
            element={
                <Protected path={ROUTES.PAYSTUB_FROM_CENTER} isPrivate>
                    <GTMDataLayer pageTitle={"PTO Center"}>
                        <Helmet>
                            <title>{"PTO Center"}</title>
                        </Helmet>
                        <Paystubs />
                    </GTMDataLayer>
                </Protected>
            }
        />

      <Route
        path={ROUTES.PTO_CENTER}
        element={
          <Protected path={ROUTES.PTO_CENTER} isPrivate>
            <GTMDataLayer pageTitle={"PTO Center"}>
              <Helmet>
                <title>{"PTO Center"}</title>
              </Helmet>
              <PTOCenter />
            </GTMDataLayer>
          </Protected>
        }
      />

      <Route
        path={ROUTES.GENERAL_ERROR}
        element={
          <Protected path={ROUTES.GENERAL_ERROR} isPrivate>
            <GTMDataLayer pageTitle={"Error"}>
              <Helmet>
                <title>{"Error"}</title>
              </Helmet>
              <GeneralErrorPage />
            </GTMDataLayer>
          </Protected>
        }
      />

      <Route
        path={ROUTES.EMAIL_LINK_LOGIN}
        element={
          <Protected path={ROUTES.EMAIL_LINK_LOGIN} isPrivate={false}>
            <GTMDataLayer pageTitle={"Email login"}>
              <Helmet>
                <title>{"Email Login"}</title>
              </Helmet>
              <EmailLinkLogin />
            </GTMDataLayer>
          </Protected>
        }
      />

      <Route
        path={ROUTES.LOGIN}
        element={
          <Protected path={ROUTES.LOGIN}>
            <GTMDataLayer pageTitle="Login">
              <Helmet>
                <title>{"Login"}</title>
              </Helmet>
              <Login />
            </GTMDataLayer>
          </Protected>
        }
      />
      <Route
        path={ROUTES.SIGN_CONTRACT}
        element={
          <Protected path={ROUTES.SIGN_CONTRACT} isPrivate>
            <GTMDataLayer pageTitle="Sign Contract">
              <Helmet>
                <title>{"Sign Contract"}</title>
              </Helmet>
              <Contract />
            </GTMDataLayer>
          </Protected>
        }
      />
      <Route
        path={ROUTES.BACKUP_PAYMENT_METHOD_AGREEMENT}
        element={
          <Protected path={ROUTES.BACKUP_PAYMENT_METHOD_AGREEMENT} isPrivate>
            <GTMDataLayer pageTitle="Backup payment method agreement">
              <Helmet>
                <title>{"Backup payment method agreement"}</title>
              </Helmet>
              <BackupPaymentMethodAgreement />
            </GTMDataLayer>
          </Protected>
        }
      />
      <Route
        path={ROUTES.APPLICATION_STATUS}
        element={
          <Protected path={ROUTES.APPLICATION_STATUS} isPrivate>
            <GTMDataLayer pageTitle="Application Status">
              <Helmet>
                <title>{"Application Status"}</title>
              </Helmet>
              <ApplicationStatus />
            </GTMDataLayer>
          </Protected>
        }
      />
      <Route
        path={ROUTES.BTC}
        element={
          <>
            <BtcRouter />
            <BtcFooter />
          </>
        }
      />
      <Route
        path={ROUTES.PRIVACY_POLICY}
        element={
          <GTMDataLayer pageTitle="Privacy Policy">
            <Agreements />
            <BtcFooter />
          </GTMDataLayer>
        }
      />
      <Route
        path={ROUTES.TERMS_OF_SERVICE}
        element={
          <GTMDataLayer pageTitle="Terms of service">
            <Agreements />
            <BtcFooter />
          </GTMDataLayer>
        }
      />
      <Route
        path={ROUTES.E_SIGN}
        element={
          <GTMDataLayer pageTitle="E-sign">
            <Agreements />
            <BtcFooter />
          </GTMDataLayer>
        }
      />
      <Route path="/" element={<Navigate to={ROUTES.HOME} />} />
      <Route path={ROUTES.NOT_FOUND} element={<Navigate to={ROUTES.LOGIN} />} />
      <Route path="*" element={<Navigate to={ROUTES.LOGIN} />} />
    </Routes>
  );
});

export default AppRoutes;
