import styled from "styled-components";
import { Box } from "@mui/material";
import { device } from "config/devices";

export const StyledModalBody = styled(Box)`
  padding: 16px;
  background-color: #fff;
  max-width: 528px;
  width: 100%;
`;

export const StyledCloseButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
`;

export const StyledModalTitle = styled.p`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #242323;
  margin-bottom: 8px;
`;

export const StyledModalSubtitle = styled.p`
  margin-bottom: 12px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #242323;
`;

export const StyledModalButtonsWrapper = styled(Box)`
  margin-top: 16px;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  @media ${device.tablet} {
    flex-direction: row;
    justify-content: flex-end;
    button {
      width: auto;
      padding: 0px 32px;
    }
  }
`;

export const StyledModalSubmitButton = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  max-width: 335px;
  width: 100%;
  height: 56px;
  @media ${device.laptop} {
    height: 40px;
  }
  color: #fff;
  background-color: #ff5277;
  border-radius: 8px;
  font-size: 15px;
  flex: none;
  order: 0;
  flex-grow: 0;
  z-index: 0;
  :hover {
    background-color: #000000;
  }
`;

export const StyledModalCancelButton = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  max-width: 335px;
  width: 100%;
  height: 56px;

  color: #ff5277;
  border-radius: 8px;

  font-size: 15px;

  flex: none;
  order: 0;
  flex-grow: 0;
  z-index: 0;
`;

export const StyledModalImage = styled.img`
  width: 100%;
`;
