import { device } from "config/devices";
import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 16px 24px;
`;
export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;
export const Logo = styled.img`
  width: 83px;
  height: 24px;
  margin-bottom: 24px;
`;
export const Title = styled.h1`
  color: #000;
  text-align: center;
  font-family: Poppins;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px; /* 142.857% */
  margin: 16px 0;
`;
export const Text = styled.p`
  color: #000;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
`;
export const ButtonContainer = styled.div`
  margin-bottom: 16px;
  margin-top: 40px;
  @media ${device.tablet} {
    margin-top: 80px;
  }
`;
export const HelpContainer = styled.div``;
export const HelpText = styled.p`
  color: var(--text-icon-secondary, #585866);
  text-align: center;
  /* Mobile Web/Body */
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
`;
export const ContactUsText = styled.a`
  color: #ff5277;
  text-decoration-line: underline;
`;

export const TitleEnterEmail = styled.h1`
  color: var(--text-icon-dark, #242323);
  /* Mobile Web/Page Title */
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px; /* 160% */
  margin-bottom: 24px;
`;
export const TextEnterEmail = styled.p`
  color: var(--text-icon-dark, #242323);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
`;

export const EmailInput = styled.input`
  border-radius: 4px;
  border: 1px solid var(--selection-colors-background-hover, #ffa8bb);
  background: var(--selection-colors-background-default, #fff);
  color: var(--text-icon-dark, #242323);
  /* Mobile Web/Body */
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  margin-bottom: 16px;
  margin-top: 4px;
  padding: 16px;
  font-size: 16px;
  @media ${device.tablet} {
      padding: 8px 16px;
    font-size: 14px;
  }
`;

export const EnterEmailContainer = styled.div`
  max-width: 335px;
  display: flex;
  flex-direction: column;
  margin-top: 16px;
`;
