import { useTranslation } from "react-i18next";
import {
  Description,
  ModalContainer,
  ModalContent,
  TipContainer,
  TipIcon,
  TipText,
  StyledModal,
  Title,
  ContainerCloseIcon,
  ButtonsContainer,
  CloseIcon,
  TipItem,
} from "./styles";
import { CancelButton, PrimaryButton } from "../styles";
import lightBulbIcon from "./lightBulbIcon.svg";
import closeIcon from "./closeIcon.svg";
import { IconButton } from "@mui/material";
import { UploadFileButton } from "./UploadFileButton";
import { useState } from "react";
import { useStores } from "stores";
import { LeadFileType } from "services/api/types";

type Props = { open: boolean; onClose: () => void };
const translationPrefix = "application_status";

export const UploadPayStubsModal = ({ open, onClose }: Props) => {
  const [selectedFile, setSelectedFile] = useState<File>();
  const {
    leadStore: { uploadLeadFile },
  } = useStores();
  const { t } = useTranslation();

  const submitClick = () => {
    if (selectedFile) {
      onClose();
      uploadLeadFile(LeadFileType.ptoBalanceDoc, selectedFile);
    }
  };

  return (
    <StyledModal open={open}>
      <ModalContainer>
        <ContainerCloseIcon onClick={onClose}>
          <IconButton>
            <CloseIcon src={closeIcon} />
          </IconButton>
        </ContainerCloseIcon>
        <ModalContent>
          <Title>{t(`${translationPrefix}.nmiUploads.uploadPayStub`)}</Title>
          <Description>{t(`${translationPrefix}.nmiUploads.screenshotLastPayStub`)}</Description>
          <TipContainer>
            <TipItem>
              <TipIcon src={lightBulbIcon} />
              <TipText>{t(`${translationPrefix}.nmiUploads.pleaseMakeSure`)}</TipText>
            </TipItem>
          </TipContainer>

          <UploadFileButton setSelectedFile={setSelectedFile} selectedFile={selectedFile} />
        </ModalContent>

        <ButtonsContainer>
          <CancelButton onClick={onClose}>
            {t(`${translationPrefix}.nmiUploads.cancel`)}
          </CancelButton>

          <PrimaryButton onClick={submitClick} disabled={!selectedFile}>
            {t(`${translationPrefix}.nmiUploads.submit`)}
          </PrimaryButton>
        </ButtonsContainer>
      </ModalContainer>
    </StyledModal>
  );
};
