import { Body, BodyContainer, LoaderContainer, PageContainer, Title } from "../styles";
import { pdfjs, Document, Page } from "react-pdf";
import { useEffect, useState } from "react";
import { useStores } from "stores";
import loaderImage from "assets/images/loader.svg";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import { observer } from "mobx-react";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

type Props = {
  file: File | undefined;
  title: string;
};

export const PdfComponent = observer(({ file, title }: Props) => {
  const [numPages, setNumPages] = useState<number>(0);
  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  const {
    appStore: { setIsHeaderHidden, windowDimensions },
  } = useStores();

  useEffect(() => {
    setIsHeaderHidden(false);
  }, []);

  return (
    <Body>
      <BodyContainer>
        <Title>{title}</Title>

        {file ? (
          <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
            {Array(numPages)
              .fill("page")
              .map((_, index) => (
                <PageContainer key={`page_number_${index}`}>
                  <Page
                    pageNumber={index + 1}
                    width={Math.min(windowDimensions.width - 42, 776)}
                    renderAnnotationLayer={true}
                    renderTextLayer={true}
                  />
                </PageContainer>
              ))}
          </Document>
        ) : (
          <div>
            <LoaderContainer>
              <img src={loaderImage} alt="loading" />
            </LoaderContainer>
          </div>
        )}
      </BodyContainer>
    </Body>
  );
});
