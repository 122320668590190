import { useTranslation } from "react-i18next";
import {
  Description,
  ModalContainer,
  ModalContent,
  TipContainer,
  TipItem,
  TipIconCircle,
  TipText,
  StyledModal,
  Title,
  ContainerCloseIcon,
  ButtonsContainer,
  CloseIcon,
  TipItemDevider,
  TipIcon,
} from "./styles";
import { CancelButton, PrimaryButton } from "../styles";
import checkWithCircle from "./checkWithCircle.svg";
import closeIcon from "./closeIcon.svg";
import { IconButton } from "@mui/material";
import { UploadFileButton } from "./UploadFileButton";
import { useState } from "react";
import { LeadFileType } from "services/api/types";
import { useStores } from "stores";
import lightBulbIcon from "./lightBulbIcon.svg";

type Props = { open: boolean; onClose: () => void };
const translationPrefix = "application_status";

export const UploadSelfieIdModal = ({ open, onClose }: Props) => {
  const {
    leadStore: { uploadLeadFile },
  } = useStores();
  const [selectedFile, setSelectedFile] = useState<File>();
  const { t } = useTranslation();

  const submitClick = () => {
    if (selectedFile) {
      onClose();
      uploadLeadFile(LeadFileType.idSelfie, selectedFile);
    }
  };

  return (
    <StyledModal open={open}>
      <ModalContainer>
        <ContainerCloseIcon onClick={onClose}>
          <IconButton>
            <CloseIcon src={closeIcon} />
          </IconButton>
        </ContainerCloseIcon>
        <ModalContent>
          <Title>{t(`${translationPrefix}.nmiUploads.takeSelfie`)}</Title>
          <Description>{t(`${translationPrefix}.nmiUploads.uploadPhotoYourself`)}</Description>
          <TipContainer>
            <TipItem>
              <TipIconCircle src={checkWithCircle} />
              <TipText>{t(`${translationPrefix}.nmiUploads.photoGoodLight`)}</TipText>
            </TipItem>

            <TipItemDevider />
            <TipItem>
              <TipIconCircle src={checkWithCircle} />
              <TipText>{t(`${translationPrefix}.nmiUploads.detailsFocus`)}</TipText>
            </TipItem>

            <TipItemDevider />
            <TipItem>
              <TipIconCircle src={checkWithCircle} />
              <TipText>{t(`${translationPrefix}.nmiUploads.noGlare`)}</TipText>
            </TipItem>
          </TipContainer>

          <TipContainer>
            <TipItem>
              <TipIcon src={lightBulbIcon} />
              <TipText>{t(`${translationPrefix}.nmiUploads.pleaseMakeSure`)}</TipText>
            </TipItem>
          </TipContainer>

          <UploadFileButton setSelectedFile={setSelectedFile} selectedFile={selectedFile} />
        </ModalContent>

        <ButtonsContainer>
          <CancelButton onClick={onClose}>
            {t(`${translationPrefix}.nmiUploads.cancel`)}
          </CancelButton>

          <PrimaryButton onClick={submitClick} disabled={!selectedFile}>
            {t(`${translationPrefix}.nmiUploads.submit`)}
          </PrimaryButton>
        </ButtonsContainer>
      </ModalContainer>
    </StyledModal>
  );
};
