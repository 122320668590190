import { FC, Suspense, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { observer } from "mobx-react";
import Helmet from "react-helmet";
import { Layout } from "antd";
import { SnackbarProvider } from "notistack";
import { useStores } from "stores";
import { Footer, Header } from "components";
import { PAGE_META_DATA } from "config/constants";
import SessionTimeout from "./components/SessionTimeout";
import ErrorModal from "components/ErrorModal";
import { errorLogger } from "./services/logger";
import { shouldHideFooter } from "utils/routeUtils";

import ReportComplete, { anchorOrigin, SnackbarUtilsConfigurator } from "components/Notification";
import AppRoutes from "routers/appRoutes";
import { PaymentConsentsModal } from "components/PaymentConsentsModal";

const App: FC = observer(() => {
  const { userStore, appStore } = useStores();
  const { checkUserAuthStateAndLoadData, isAuthenticated } = userStore;
  const { loadInitConfig, setInitState, errorModalVisible, setIsHeaderHidden } = appStore;
  const location = useLocation();

  useEffect(() => {
    Promise.all([loadInitConfig(), checkUserAuthStateAndLoadData()])
      .then(() => {
        setInitState("ready");
      })
      .catch((err) => {
        errorLogger.captureMessage(err);
        setInitState("error");
      });
  }, []);

  const listOfUrl = ["login", "cash-advance", "pto-center", "payroll"];
  useEffect(() => {
    errorLogger.captureMessage(window.location.href);
    const checkedUrl = listOfUrl.some((i) => window.location.href.includes(i));
    setIsHeaderHidden(!isAuthenticated || checkedUrl);
  }, [isAuthenticated, window.location.href]);

  return (
    <SnackbarProvider
      anchorOrigin={anchorOrigin}
      maxSnack={3}
      Components={{
        success: ReportComplete,
        error: ReportComplete,
        info: ReportComplete,
      }}
      autoHideDuration={6000}>
      <SnackbarUtilsConfigurator />
      <div id={"recaptcha-id"} />
      <Layout className="app">
        <Helmet
          titleTemplate={PAGE_META_DATA.titleTemplate}
          defaultTitle={PAGE_META_DATA.defaultTitle}>
          <meta property="og:site_name" content="Sorbet" />
          <meta property="og:type" content="website" />
          <meta name="description" content={PAGE_META_DATA.description} />
          <meta property="og:description" content={PAGE_META_DATA.description} />
        </Helmet>

        <Layout>
          <Header />
          <div className="mainContainer">
            <Layout className="innerContainer">
              <AppRoutes />
            </Layout>
          </div>
          {!shouldHideFooter(location.pathname, isAuthenticated) && <Footer />}
        </Layout>

        {/* service components */}
        <Suspense fallback="">{errorModalVisible && <ErrorModal />}</Suspense>
        <SessionTimeout />
        <PaymentConsentsModal />
      </Layout>
    </SnackbarProvider>
  );
});

export default App;
