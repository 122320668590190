import { IconButton, styled as muiStyled } from "@mui/material";
import styled from "styled-components";
import payrollImg from "./icons/payroll.png";
import payrollMobileImg from "./icons/payrollMobile.svg";
import { device } from "config/devices";

export const Container = styled.div`
  border-radius: 8px;
  background: rgba(255, 168, 187, 0.2);
  padding: 16px 20px 24px 20px;
  position: relative;
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  gap: 8px;
  background-repeat: no-repeat;
  background-position: top right; // position the background image
  background-size: 103px 180px; // initial size for mobile
    @media ${device.tablet} {
    background-size: 132px 126px; // size for tablet
    background-image: url(${payrollImg});
  }

  background-image: url(${payrollMobileImg});

`;
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  button {
    flex: 0;
  }
`;
export const Title = styled.p`
  color: var(--Surface-surface-base, #242323);
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px; /* 160% */
`;

export const Description = styled.p`
  color: var(--Surface-surface-base, #242323);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  margin-bottom: 16px;
`;

export const Image = styled.img`
  width: 103px;
  height: 180px;
  content: url(${payrollMobileImg});
  @media ${device.tablet} {
    width: 132px;
    height: 126px;
    content: url(${payrollImg});
  }
`;

export const CloseButton = muiStyled(IconButton)`
  position: absolute;
  top: 0px;
  right: 0px;
`;
