import moment from "moment";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import { EVENTS, useLeavePageAnalytics } from "services/analytics/events";
import {
  IBTCPatchData,
  LeadIndustryType,
  SeniorityType,
  TAnnualSalaryRange,
  TCurrentlyAccruedPtoDays,
  TPtoPolicyDesc,
} from "services/api/types";
import { gtmService } from "services/gtm";
import { useStores } from "stores";
import { btcErrorHandler } from "utils/btcErrorHandler";
import { LOCAL_STORAGE_KEYS, LocalStorage } from "utils/localStorage";

const ConvertRangeToSalary: Record<TAnnualSalaryRange, number> = {
  $20k_30k: 25000,
  $30k_40k: 35000,
  $40k_50k: 45000,
  $50k_75k: 62500,
  $75k_100k: 87500,
  $100k_150k: 125000,
  $150k_200k: 175000,
  more_than_$200k: 200000,
};

type TFormData = {
  ptoPolicy: TPtoPolicyDesc;
  currentlyAccruedPtoDays: TCurrentlyAccruedPtoDays;
  salary: TAnnualSalaryRange;
  industry: LeadIndustryType;
  seniority: SeniorityType;
};

export const useStep1 = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const {
    appStore: { leadsInitConfig, windowDimensions },
    btcStore: {
      btcCalculatePTO,
      hideErrorBox,
      createBtcApplication,
      btcLoading,
      updateBtcApplication,
    },
  } = useStores();
  const [firstTouchTimeStamp] = useState<string>(moment().toISOString());

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    getValues,
  } = useForm<TFormData>({ mode: "onBlur" });

  useLeavePageAnalytics((eventType) => {
    const data = getValues();
    EVENTS.btc.step1.leavePage({ action: eventType, ...data });
  });

  useEffect(() => {
    EVENTS.btc.step1.onload(searchParams);
    document.title = "On-demand PTO cash advance";
  }, []);

  const onSubmit = async (data: TFormData) => {
    try {
      const userUuid = uuidv4();
      const localUuid = LocalStorage.getItem(LOCAL_STORAGE_KEYS.uuid);

      const PageData: IBTCPatchData = {
        annualSalaryRange: data.salary,
        ptoPolicyDesc: data.ptoPolicy,
        industry: data.industry,
        seniority: data.seniority,
        uuid: localUuid ?? userUuid,
        lastSubmittedStep: 1,
        timestampCalculatorFt: firstTouchTimeStamp,
        timestampCalculator: moment().toISOString(),
        utm: window.location.href.includes("localhost")
          ? `https://cash.qa.sorbetapp.com${window.location.pathname}${window.location.search}`
          : window.location.href,
      };
      if (data.currentlyAccruedPtoDays) {
        PageData["currentlyAccruedPtoDays"] = data.currentlyAccruedPtoDays;
      }
      if (localUuid) {
        await updateBtcApplication(PageData);
      } else {
        await createBtcApplication(PageData, userUuid);
      }
      EVENTS.btc.step1.submit(data, true);
      hideErrorBox();
      LocalStorage.setItem(LOCAL_STORAGE_KEYS.lastSubmittedStep, "2");
      navigate(`/cash-advance/apply${location.search}`);
    } catch (error: any) {
      btcErrorHandler(error, "step1");
      EVENTS.btc.step1.submit({ ...data, failReason: error.message }, false);
    } finally {
      const salaryFromRange = ConvertRangeToSalary[data.salary];
      btcCalculatePTO();
      gtmService.customEvent({
        event: "step1Submission",
        pto_policy: data.ptoPolicy,
        uuid: LocalStorage.getItem(LOCAL_STORAGE_KEYS.uuid),
      });
      if (data.ptoPolicy === "carry_over") {
        gtmService.customEvent({
          event: "preferred-pto-policy",
          uuid: LocalStorage.getItem(LOCAL_STORAGE_KEYS.uuid),
        });
        if (salaryFromRange >= 62500) {
          gtmService.customEvent({
            event: "preferred-salary-and-policy",
            uuid: LocalStorage.getItem(LOCAL_STORAGE_KEYS.uuid),
          });
        }
      } else if (salaryFromRange >= 50000) {
        gtmService.customEvent({
          event: "preferred-avg-salary",
          uuid: LocalStorage.getItem(LOCAL_STORAGE_KEYS.uuid),
        });
      }
    }
  };

  const selectedPtoPolicy = watch("ptoPolicy");

  return {
    selectedPtoPolicy,
    onSubmit,
    isLoading: btcLoading,
    register,
    handleSubmit,
    errors,
    leadsInitConfig,
    windowDimensions,
    getValues,
  };
};
