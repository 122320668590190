import { FC, PropsWithChildren, useEffect } from "react";
import { useStores } from "stores";
import { observer } from "mobx-react";
import { gtmService } from "services/gtm";

interface IProps {
  pageTitle?: string;
  children: JSX.Element[] | JSX.Element;
}

export const GTMDataLayer: FC<IProps> = observer(
  ({ children, pageTitle }: PropsWithChildren<IProps>) => {
    const { userStore } = useStores();

    useEffect(() => {
      const { userInfo, isAuthenticated } = userStore;

      if (pageTitle) {
        gtmService.updateGeneralDataLayer({
          userId: userInfo?.id ? +userInfo.id : null,
          userState: isAuthenticated ? "login" : "no-login",
          pageTitle,
        });
      }
    }, []);

    return <>{children}</>;
  },
);
