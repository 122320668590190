import { IconButton, Modal, styled as muiStyled } from "@mui/material";
import { device } from "config/devices";
import styled from "styled-components";

export const StyledModal = styled(Modal)<{ _fullScreen?: boolean }>`
  ${(p) => {
    if (!p._fullScreen) {
      return `align-items: flex-end;
              display: flex;
              justify-content: center;
              @media ${device.tablet} {
                align-items: center;
              }`;
    }
    return "";
  }}
`;

export const StyledModalWrapper = styled.div<{ _fullScreen?: boolean }>`
  border-radius: 8px;
  position: relative;
  background-color: #fff;
  display: flex;
  flex: 1;
  max-width: ${(p) => (p._fullScreen ? "auto" : "600px")};
  flex-direction: column;
  ${(p) => {
    if (!p._fullScreen) {
      return `max-height: 100vh;
              overflow-y: auto;
              `;
    }
    return "";
  }}
`;

export const CloseIcon = muiStyled(IconButton)`
  position: absolute;
  right: 8px;
  top: 8px;
`;
