import { Dialog, Slide } from "@mui/material";
import { forwardRef } from "react";
import styled from "styled-components";
import CloseIcon from "../../../../assets/icons/close.svg";
import { useTranslation } from "react-i18next";
import Stepper from "./Stepper";
import { DialogTitle, StatusContainer, StyledCloseIcon, StyledTrackerContainer } from "../styles";
import { TransitionProps } from "@mui/material/transitions";
import { EB2cApplicationStatus } from "services/api/types";

const HalfScreenDialog = styled(Dialog)`
  .MuiDialog-paper {
    width: 100%;
    max-width: 600px;
    height: 50%;
    margin: 0;
    maxheight: 100%;
    borderradius: 0;
    position: fixed;
    bottom: 0;

    @media (min-width: 600px) {
      height: auto;
      bottom: unset;
      margin: auto;
      border-radius: 4px;
      position: relative;
    }
  }
`;

const pageTranslationPrefix = "application_status.modal";

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type Props = {
  handleClose: () => void;
  timestamp: Date | undefined;
  open: boolean;
  status: EB2cApplicationStatus;
};

export default function StatusTracker({ timestamp, open, handleClose, status }: Props) {
  const { t } = useTranslation();

  return (
    <StyledTrackerContainer>
      <HalfScreenDialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}>
        <StyledCloseIcon src={CloseIcon} onClick={handleClose} />
        {/* Your dialog content goes here */}
        <StatusContainer>
          <DialogTitle>{t(`${pageTranslationPrefix}.title`)}</DialogTitle>
          <Stepper status={status} timestamp={timestamp} />
        </StatusContainer>
      </HalfScreenDialog>
    </StyledTrackerContainer>
  );
}
