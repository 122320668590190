import { useTranslation } from "react-i18next";
import {
  CheckboxContainer,
  CheckboxFlowButtons,
  CheckboxFlowText,
  CheckboxFlowWrapper,
  CheckboxLabel,
  StyledCheckbox,
  StyledModalContainer,
} from "./styles";
import {
  PrimaryButton,
  SecondaryButton,
} from "pages/Btc/ApplicationStatus/components/NMIUploads/styles";
import {
  StyledModal,
  ContainerCloseIcon,
  CloseIcon,
  ModalContent,
  ButtonsContainer,
  Title,
  Description,
} from "pages/Btc/ApplicationStatus/components/NMIUploads/Modals/styles";
import { useState } from "react";
import { IconButton } from "@mui/material";
import closeIcon from "pages/Btc/ApplicationStatus/components/NMIUploads/Modals/closeIcon.svg";

type Props = {
  autoPayClick: () => void;
  oneTimePayClick: () => void;
};
export const CheckboxFlow = ({ autoPayClick, oneTimePayClick }: Props) => {
  const [openModal, setModalOpen] = useState(false);
  const { t } = useTranslation();
  const [checked, setChecked] = useState(false);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };
  const clickCheckboxLabel = () => {
    setChecked(!checked);
  };
  const continueClick = () => {
    if (checked) {
      autoPayClick();
    } else {
      setModalOpen(true);
    }
  };
  const closeModal = () => {
    setModalOpen(false);
  };
  return (
    <>
      <CheckboxFlowWrapper>
        <div>
          <CheckboxFlowText>{t("achDebitAuth.justOneMoreStep")}</CheckboxFlowText>
          <br />
          <CheckboxFlowText>
            {t("achDebitAuth.setUpAutoPayOrSingleMonthlyPayment")}
          </CheckboxFlowText>
        </div>

        <CheckboxFlowButtons>
          <CheckboxContainer>
            <StyledCheckbox id="consent-checkbox" checked={checked} type="checkbox" onChange={handleChange} />
            <CheckboxLabel onClick={clickCheckboxLabel}>
              {t("achDebitAuth.signUpForAutoPay")}
            </CheckboxLabel>
          </CheckboxContainer>
          <PrimaryButton id="continue-btn" onClick={continueClick}>{t("achDebitAuth.continue")}</PrimaryButton>
        </CheckboxFlowButtons>
      </CheckboxFlowWrapper>

      <StyledModal open={openModal}>
        <StyledModalContainer>
          <ContainerCloseIcon onClick={closeModal}>
            <IconButton>
              <CloseIcon src={closeIcon} />
            </IconButton>
          </ContainerCloseIcon>
          <ModalContent>
            <Title>{t("achDebitAuth.sureDonTWantAutoPay")}</Title>
            <Description>{t("achDebitAuth.ifYouDonTOptForAutoPay")}</Description>
          </ModalContent>
          <ButtonsContainer>
            <PrimaryButton
              id="setup-autopay-btn"
              onClick={() => {
                closeModal();
                autoPayClick();
              }}>
              {t("achDebitAuth.authorizeAutoPayment")}
            </PrimaryButton>
            <SecondaryButton
                id="no-thx-manual-single-payment"
                onClick={() => {
                  closeModal();
                  oneTimePayClick();
                }}>
              {t("achDebitAuth.continueToManualSinglePayment")}
            </SecondaryButton>
          </ButtonsContainer>
        </StyledModalContainer>
      </StyledModal>
    </>
  );
};
