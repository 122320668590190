import { useTranslation } from "react-i18next";
import {
  HelpContainer,
  HelpText,
  Logo,
  Text,
  Title,
  Wrapper,
  ContactUsText,
  ButtonContainer,
  Container,
  TitleEnterEmail,
  TextEnterEmail,
  EmailInput,
  EnterEmailContainer,
} from "./styles";
import { PrimaryButton } from "pages/Btc/ApplicationStatus/components/NMIUploads/styles";
import logo from "../logo.svg";
import { COMPANY_EMAILS } from "config/constants";
import { useStores } from "stores";
import { ChangeEvent, useEffect, useState } from "react";
import { AuthServices, AuthTypes } from "services/auth/types";
import { Loader } from "components";
import { gtmService } from "services/gtm";
import moment from "moment";
import { useSearchParams } from "react-router-dom";
import { LOCAL_STORAGE_KEYS, LocalStorage } from "utils/localStorage";

export const EmailLinkLogin = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);
  const [emailInput, setEmail] = useState("");
  const emailForSignIn = LocalStorage.getItem(LOCAL_STORAGE_KEYS.emailForSignIn);
  useEffect(() => {
    const pathToRedirect = searchParams.get("pathToRedirect");
    if (pathToRedirect) {
      LocalStorage.setItem(LOCAL_STORAGE_KEYS.redirectAfterLoginUrl, pathToRedirect);
    }
  }, []);
  const {
    userStore: { signInWith },
    appStore: { setInitState },
  } = useStores();

  const loginWith = (email: string) => async () => {
    setIsLoading(true);

    const success = await signInWith(
      AuthTypes.EMAIL_LINK_LOGIN,
      email,
      undefined,
      AuthServices.FIREBASE_RO,
    );
    if (success) {
      LocalStorage.setItem(LOCAL_STORAGE_KEYS.lastTime, moment().format());
      gtmService.customEvent({
        event: "signIn",
        emailDomain: AuthTypes.EMAIL_LINK_LOGIN,
        uuid: LocalStorage.getItem(LOCAL_STORAGE_KEYS.uuid),
      });

      setInitState(undefined);
      setInitState("ready");
    }
    setIsLoading(false);
  };

  const inputOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  return (
    <Wrapper>
      <Logo src={logo} />
      <Container>
        {emailForSignIn ? (
          <>
            <Title>{t("page_login.emailLinkLogin.useThisLink")}</Title>

            <Text>{t("page_login.emailLinkLogin.withThisButton")}</Text>
            <br />
            <Text>{t("page_login.emailLinkLogin.linkWillExpire")}</Text>

            <ButtonContainer onClick={loginWith(emailForSignIn)}>
              <PrimaryButton>{t("page_login.emailLinkLogin.signMeIn")}</PrimaryButton>
            </ButtonContainer>

            <HelpContainer>
              <HelpText>
                {t("page_login.emailLinkLogin.ifYouDidNTRequestThis.ifYouDidNTRequestThis")}
              </HelpText>
              <HelpText>
                {t("page_login.emailLinkLogin.ifYouDidNTRequestThis.please")}
                <ContactUsText
                  href={`mailto:${COMPANY_EMAILS.SUPPORT}?subject=Hey! I have a question`}
                  target="_blank"
                  rel="noreferrer">
                  {t("page_login.emailLinkLogin.ifYouDidNTRequestThis.contactUs")}
                </ContactUsText>
                {t("page_login.emailLinkLogin.ifYouDidNTRequestThis.reachUsAt")}
              </HelpText>
              <HelpText>
                <ContactUsText href="tel:4243835610">{COMPANY_EMAILS.PHONE}</ContactUsText>
                {t("page_login.emailLinkLogin.ifYouDidNTRequestThis.rightAway")}
              </HelpText>
            </HelpContainer>
          </>
        ) : (
          <EnterEmailContainer>
            <TitleEnterEmail>{t("page_login.emailLinkLogin.emailAuthentication")}</TitleEnterEmail>
            <TextEnterEmail>{t("page_login.emailLinkLogin.pleaseEnterTheEmail")}</TextEnterEmail>
            <EmailInput
              placeholder={t("page_login.emailLinkLogin.typeAnEmail")!}
              value={emailInput}
              onChange={inputOnChange}
            />
            <PrimaryButton onClick={loginWith(emailInput)}>
              {t("page_login.emailLinkLogin.signIn")}
            </PrimaryButton>
          </EnterEmailContainer>
        )}
      </Container>
      {isLoading ? <Loader /> : null}
    </Wrapper>
  );
};
