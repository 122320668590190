import "react-perfect-scrollbar/dist/css/styles.css";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { useStores } from "../../../stores";
import { currencyFormatter } from "../../../utils/formatters";
import { ImageContainer, StyledEmail, StyledImage, StyledSmallText, StyledText } from "./styles";
import { addYearsToDate, formattedDate } from "../../../utils/date";
import happyWoman from "assets/images/woman-happy.svg";
import { COMPANY_EMAILS } from "../../../config/constants";
import React from "react";

export const PromissoryNote = observer(() => {
  const { t } = useTranslation();
  const translation = "promissoryNote";
  const {
    userStore: { userInfo, userApprovedAmount },
    loanStore: { loanInfo },
  } = useStores();

  const advancedAmount: string = currencyFormatter(userApprovedAmount);
  const paymentAmount: string = currencyFormatter(loanInfo?.b2cPaybackAmountCents);
  const advancedDate: string = formattedDate(loanInfo?.b2cReceiveMoneyDate);
  const dueDate: string = addYearsToDate(loanInfo?.b2cReceiveMoneyDate, 3);

  return (
    <div>
      <StyledText>{t(`${translation}.helloName`, { name: userInfo?.firstName })}</StyledText>
      {advancedAmount && advancedDate ? (
        <StyledText>
          {t(`${translation}.beenAdvanced`, { advancedAmount, advancedDate })}
        </StyledText>
      ) : (
        <StyledText> {t(`${translation}.advanceBySorbet`)}</StyledText>
      )}
      {paymentAmount && dueDate ? (
        <StyledText>{t(`${translation}.yourPayment`, { paymentAmount, dueDate })}</StyledText>
      ) : (
        <StyledText>{t(`${translation}.repayItIn`)}</StyledText>
      )}
      <ImageContainer>
        <StyledImage src={happyWoman} />
      </ImageContainer>
      <StyledSmallText>{t(`${translation}.haveAny`)}</StyledSmallText>

      <StyledSmallText>
        {" "}
        {t(`${translation}.checkoutOur`)}
        <StyledEmail
          href={`mailto:${COMPANY_EMAILS.SUPPORT}?subject=Hey! I have a question about my Sorbet PTO Cash Advance application`}
          target="_blank">
          {COMPANY_EMAILS.SUPPORT}
        </StyledEmail>
      </StyledSmallText>
    </div>
  );
});

export default PromissoryNote;
