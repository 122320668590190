import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import ActiveStepIcon from "../../../../assets/icons/timeline-green.svg";
import InActiveStepIcon from "../../../../assets/icons/timeline-empty.svg";
import DeclinedStepIcon from "../../../../assets/icons/timeline-red.svg";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import { EB2cApplicationStatus } from "services/api/types";

const useStyles = makeStyles({
  stepLabel: {
    "& .MuiStepLabel-label": {
      // This is the nested selector that targets the label inside the StepLabel component
      color: "var(--text-icon-dark, #242323) !important",
      fontFamily: "Poppins !important",
      fontSize: "13px !important",
      fontStyle: "normal !important",
      fontWeight: "400 !important",
      lineHeight: "20px !important",
    },
  },
});
function StepIcon({ state }: { state: string }) {
  switch (state) {
    case "passed":
      return <img src={ActiveStepIcon} alt={"ActiveStepIcon"} />;
    case "declined":
      return <img src={DeclinedStepIcon} alt={"DeclinedStepIcon"} />;
    case "empty":
    default:
      return <img src={InActiveStepIcon} alt={"InActiveStepIcon"} />;
  }
}

const steps: Record<
  EB2cApplicationStatus,
  { value: string; translationKey: string; state: string }[]
> = {
  [EB2cApplicationStatus.Declined]: [],
  [EB2cApplicationStatus.Canceled]: [],
  [EB2cApplicationStatus.UnderReview]: [
    {
      value: "submitted",
      translationKey: "submitted",
      state: "passed",
    },
    {
      value: "under_review",
      translationKey: "under_review",
      state: "passed",
    },
    {
      value: "approval",
      translationKey: "approval",
      state: "empty",
    },
    {
      value: "disbursed",
      translationKey: "disbursed",
      state: "empty",
    },
  ],
  [EB2cApplicationStatus.Pending]: [
    {
      value: "submitted",
      translationKey: "submitted",
      state: "passed",
    },
    {
      value: "under_review",
      translationKey: "under_review",
      state: "passed",
    },
    {
      value: "approval",
      translationKey: "approval",
      state: "empty",
    },
    {
      value: "disbursed",
      translationKey: "disbursed",
      state: "empty",
    },
  ],
  [EB2cApplicationStatus.NeedMoreInfo]: [
    {
      value: "submitted",
      translationKey: "submitted",
      state: "passed",
    },
    {
      value: "under_review",
      translationKey: "under_review",
      state: "declined",
    },
    {
      value: "approval",
      translationKey: "approval",
      state: "empty",
    },
    {
      value: "disbursed",
      translationKey: "disbursed",
      state: "empty",
    },
  ],
  [EB2cApplicationStatus.Frozen]: [
    {
      value: "submitted",
      translationKey: "submitted",
      state: "passed",
    },
    {
      value: "under_review",
      translationKey: "under_review",
      state: "declined",
    },
    {
      value: "approval",
      translationKey: "approval",
      state: "empty",
    },
    {
      value: "disbursed",
      translationKey: "disbursed",
      state: "empty",
    },
  ],
  [EB2cApplicationStatus.Incomplete]: [
    {
      value: "submitted",
      translationKey: "submitted",
      state: "passed",
    },
    {
      value: "under_review",
      translationKey: "under_review",
      state: "declined",
    },
    {
      value: "approval",
      translationKey: "approval",
      state: "empty",
    },
    {
      value: "disbursed",
      translationKey: "disbursed",
      state: "empty",
    },
  ],
  [EB2cApplicationStatus.Approved]: [
    {
      value: "submitted",
      translationKey: "submitted",
      state: "passed",
    },
    {
      value: "under_review",
      translationKey: "under_review",
      state: "passed",
    },
    {
      value: "approval",
      translationKey: "approval",
      state: "passed",
    },
    {
      value: "disbursed",
      translationKey: "disbursed",
      state: "empty",
    },
  ],
};

type Props = {
  status: EB2cApplicationStatus;
  timestamp: Date | undefined;
};

export default function VerticalLinearStepper({ status, timestamp }: Props) {
  const [activeStep] = React.useState(1);
  const { t } = useTranslation();
  const pageTranslationPrefix = "application_status.modal";

  const classes = useStyles();

  // useEffect(() => {
  //   const currentStepIndex = steps.findIndex(step => step.value === status);
  //   setActiveStep(currentStepIndex >= 0 ? currentStepIndex : 0);
  // }, [status]);

  return (
    <Box sx={{marginLeft: '24px', marginTop: '16px', marginBottom:'32px'}}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps[status].map((step, index) => (
          <Step key={step.value}>
            <StepLabel
              className={classes.stepLabel}
              StepIconComponent={(props) => <StepIcon state={step.state} />} // Pass index here
              optional={
                index === 0 ? (
                  <Typography className={classes.stepLabel} variant="caption">
                    {moment(timestamp).format("MMMM D, YYYY h:mmA")}
                  </Typography>
                ) : null
              }>
              {t(`${pageTranslationPrefix}.${step.translationKey}`)}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}
