import { useTranslation } from "react-i18next";
import {
  EmptyButtonsContainer,
  EmptyContainer,
  EmptyContent,
  EmptyImg,
  EmptySubtitle,
  EmptyTitle,
} from "../styles";
import emptyIcon from "../emptyIcon.svg";
import {
  PrimaryButton,
  SecondaryButton,
} from "pages/Btc/ApplicationStatus/components/NMIUploads/styles";
import { observer } from "mobx-react";
import { DebitAchAuthTypeEnum } from "services/api/types";
import { useStores } from "stores";

export const PaymentHistoryEmpty = observer(() => {
  const { t } = useTranslation();
  const {
    debitConsentStore: { isFirstConsentExist, setConsentModal },
  } = useStores();

  return (
    <EmptyContainer>
      <EmptyContent>
        <EmptyImg src={emptyIcon} />
        <EmptyTitle>{t("paymentHistory.noPayments")}</EmptyTitle>
        <EmptySubtitle>
          {t(
            `paymentHistory.${
              isFirstConsentExist ? "paymentsYouMakeWillShowUpHere" : "setUpAutoPay"
            }`,
          )}
        </EmptySubtitle>
      </EmptyContent>
      {!isFirstConsentExist ? (
        <>
          <EmptyButtonsContainer>
            <PrimaryButton
              onClick={() => setConsentModal({ type: DebitAchAuthTypeEnum.AUTO_PAY, open: true })}>
              {t("achDebitAuth.authorizeAutoPayment")}
            </PrimaryButton>

            <SecondaryButton
              onClick={() => setConsentModal({ type: DebitAchAuthTypeEnum.ONE_TIME, open: true })}>
              {t("achDebitAuth.manualSinglePaymentAuthorization")}
            </SecondaryButton>
          </EmptyButtonsContainer>
        </>
      ) : null}
    </EmptyContainer>
  );
});
