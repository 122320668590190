import { ERRORS } from "config/constants";
import {
  ConfirmationResult,
  getAuth,
  isSignInWithEmailLink,
  RecaptchaVerifier,
  sendSignInLinkToEmail,
  signInWithEmailLink,
  signInWithPhoneNumber,
  UserCredential,
} from "firebase/auth";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";

import { AuthServices, AuthTypes, IAuthInstance, ILoginPayload } from "../types";
import { LOCAL_STORAGE_KEYS, LocalStorage } from "utils/localStorage";

const { GOOGLE } = AuthTypes;

export interface IFirebaseProviderData {
  providerId: string;
  scopes: string[];
}

export abstract class CommonFirebaseAuthInstance implements IAuthInstance {
  abstract readonly name: AuthServices;
  /**
   * Providers config
   */
  protected abstract readonly providers: {
    [GOOGLE]?: IFirebaseProviderData;
  };
  /**
   * Firebase instance
   */
  protected abstract fbInstance?: firebase.app.App;
  /**
   * Firebase auth service
   */
  protected get fbAuth() {
    return (this.fbInstance as firebase.app.App).auth();
  }

  protected recaptchaVerifier?: RecaptchaVerifier;
  protected confirmationResult?: ConfirmationResult;

  get isInitialized() {
    return !!this.fbInstance;
  }

  abstract init: () => void;

  public getAuthToken = async () => {
    const cypress_test_email = LocalStorage.getItem(LOCAL_STORAGE_KEYS.cypress_test_email);
    if (cypress_test_email) {
      return cypress_test_email;
    }
    const user = this.fbAuth.currentUser;
    if (user) {
      return await user.getIdToken();
    }
    return undefined;
  };

  public getPhotoUrl = async () => {
    return this.fbAuth.currentUser?.providerData[0]?.photoURL;
  };

  public loadAuthState = async () =>
    new Promise<string | null>((resolve) => {
      const unsubscribe = this.fbAuth.onAuthStateChanged((user) => {
        resolve(this.getFid(user));
        unsubscribe();
      });
    });

  public logInWith = async (
    type: AuthTypes,
    email?: string,
    password?: string,
    smsCode?: string,
  ) => {
    try {
      let id: string | null = null;
      const cypress_test_email = LocalStorage.getItem(LOCAL_STORAGE_KEYS.cypress_test_email);
      if (cypress_test_email) {
        return { id: cypress_test_email, success: true };
      }
      switch (type) {
        case AuthTypes.GOOGLE:
          id = await this.oAuthLogin(type);
          break;
        case AuthTypes.EMAIL_LINK_LOGIN:
          id = await this.signInWithEmailLink(email || "");
          break;
        case AuthTypes.EMAIL_LINK:
          await this.sendSignInLinkToEmail(email || "");
          return { success: true, ignore: true };
        case AuthTypes.PHONE_NUMBER:
          id = await this.verifyPhoneNumberCode(smsCode);
          break;
        default:
          id = await this.emailLogin(email as string, password as string);
          break;
      }
      return { id, success: true };
    } catch (e: any) {
      const result: ILoginPayload = { success: false };
      if (
        e.code === "auth/account-exists-with-different-credential" ||
        e.code === "custom/already-exists-with-another-provider"
      ) {
        const provider = e.provider || "different";
        result.error = `You have previously logged in with this email using a ${provider} provider. Please sign in using this provider instead.`;
      } else if (e.code === "auth/user-not-found") {
        result.error = ERRORS.USER_NOT_FOUND;
      } else if (e.code === "auth/invalid-verification-code") {
        result.error = ERRORS.WRONG_PHONE_NUMBER_AUTH_OTP;
      } else if (
        // if user closed or canceled auth popup we do nothing
        e.code !== "auth/popup-closed-by-user" &&
        e.code !== "auth/user-cancelled" &&
        !/User cancelled request/.test(e.message)
      ) {
        throw e;
      }
      return result;
    }
  };

  public logOut = async () => {
    await this.fbAuth.signOut();
    // this.providerAccessToken = undefined;
  };

  protected emailLogin = async (email: string, password: string) => {
    const loginMethods = await this.fbAuth.fetchSignInMethodsForEmail(email);
    if (!loginMethods.length || loginMethods.includes("password")) {
      const result = await this.fbAuth.signInWithEmailAndPassword(email, password);
      return this.getFid(result.user);
    }
    // eslint-disable-next-line no-throw-literal
    throw { code: "custom/already-exists-with-another-provider", provider: loginMethods[0] };
  };

  public initializeRecaptcha = () => {
    if (!this.recaptchaVerifier) {
      this.recaptchaVerifier = new RecaptchaVerifier(
        "recaptcha-id",
        {
          size: "invisible",
          callback: () => {
            // reCAPTCHA solved, allow signInWithPhoneNumber.
          },
        },
        getAuth(this.fbInstance),
      );
    }
  };
  public phoneNumberLogin = async (phoneNumber: string) => {
    this.initializeRecaptcha();
    if (this.recaptchaVerifier) {
      try {
        const confirmationResult = await signInWithPhoneNumber(
          getAuth(this.fbInstance),
          phoneNumber,
          this.recaptchaVerifier,
        );
        this.confirmationResult = confirmationResult;
      } catch (error: any) {
        if (error.code && error.code.startsWith("auth/")) {
          return { errorMessage: error.message };
        }
        return { errorMessage: "unhandled error" };
      }
    }
  };

  private signInWithEmailLink = async (email: string) => {
    if (isSignInWithEmailLink(this.fbAuth, window.location.href)) {
      const result = await signInWithEmailLink(this.fbAuth, email, window.location.href);
      LocalStorage.removeItem(LOCAL_STORAGE_KEYS.emailForSignIn);
      return this.getFid(result.user);
    }
    throw new Error("not correct link");
  };

  private sendSignInLinkToEmail = async (email: string) => {
    const redirectAfterLoginUrl = LocalStorage.getItem(LOCAL_STORAGE_KEYS.redirectAfterLoginUrl);
    const continueUrl = `${window.location.origin}/email-link-login${
      redirectAfterLoginUrl ? "?pathToRedirect=" + redirectAfterLoginUrl : ""
    }`;
    await sendSignInLinkToEmail(this.fbAuth, email, {
      url: continueUrl,
      handleCodeInApp: true,
    });
    // The link was successfully sent. Inform the user.
    // Save the email locally so you don't need to ask the user for it again
    // if they open the link on the same device.
    LocalStorage.setItem(LOCAL_STORAGE_KEYS.emailForSignIn, email);
    return null;
  };

  public verifyPhoneNumberCode = async (smsCode?: string) => {
    if (!smsCode) {
      throw new Error("sms code is required");
    }
    const result = await this.confirmationResult?.confirm(smsCode);
    // @ts-ignore
    this.providerAccessToken = result?.user.accessToken;
    return result?.user.uid || null;
  };

  protected oAuthLogin = async (type: AuthTypes) => {
    if (type === GOOGLE) {
      const providerData = this.providers[type];
      if (!providerData) {
        throw new Error(`${this.name} auth instance doesn't support "${type}" auth type`);
      }
      const { providerId, scopes } = providerData;
      const provider = new firebase.auth.OAuthProvider(providerId);
      provider.setCustomParameters({
        prompt: "select_account",
      });
      scopes.forEach((scope) => provider.addScope(scope));

      const result = await this.fbAuth.signInWithPopup(provider);
      return this.getFid(result.user);
    }
    return null;
  };

  protected getFid = (user: firebase.User | null | UserCredential["user"]) => user?.uid || null;
}
