import { useState } from "react";
import loggedOutAvatarImage from "assets/images/logged-out.svg";
import sorbetLogo from "assets/logos/sorbet-logo.svg";
import { IUserAvatarProps, UserAvatar } from "components/UserAvatar";
import { observer } from "mobx-react";
import { useStores } from "stores";
import {
  AvatarContainer,
  MenuItemText,
  StyledHeader,
  StyledIconButton,
  StyledLogo,
  StyledMenuItem,
} from "./styles";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "config/constants";
import { UserContractStatusEnum } from "services/api/types";
import { Menu } from "@mui/material";
import arrowDownSvg from "./arrowDown.svg";

interface IMenuButton {
  title: string;
  onClick: () => void;
  enabled: boolean;
  id: string;
}

const translationPrefix = "header";
const HeaderComponent = observer(() => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);
  const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const closeMenu = () => {
    setAnchorEl(null);
  };
  const {
    contractsStore: {contractStatus},
    userStore: { isAuthenticated, userInfo, logOut },
    appStore: {isHeaderHidden},
    bankDetailsStore: { bankDetails },
    debitConsentStore: { isFirstConsentExist },
  } = useStores();

  const avatarProps = (): IUserAvatarProps => {
    if (isAuthenticated) {
      return { user: userInfo };
    }
    return { src: loggedOutAvatarImage };
  };

  const menuButtons = (): IMenuButton[] => {
    return [
      {
        id: "paymentHistoryMenuItem",
        title: t(`${translationPrefix}.paymentHistory`),
        onClick: () => {
          closeMenu();
          navigate(ROUTES.PAYMENT_HISTORY);
        },
        enabled: !!bankDetails && contractStatus === UserContractStatusEnum.Executed,
      },
      {
        id: "pto-center",
        title: t(`${translationPrefix}.ptoCenter`),
        onClick: () => {
          closeMenu();
          navigate(ROUTES.PTO_CENTER, { state: { fromOrigin: "Menu" } });
        },
        enabled: isFirstConsentExist,
      },
      {
        id: "manageLinkedAccountsMenuItem",
        title: t(`${translationPrefix}.manageLinkedAccounts`),
        onClick: () => {
          closeMenu();
          navigate(ROUTES.MANAGE_LINKED_ACCOUNTS);
        },
        enabled:
          !!bankDetails &&
          contractStatus === UserContractStatusEnum.Executed &&
          isFirstConsentExist,
      },
      {
        id: "logout",
        title: t(`${translationPrefix}.logout`),
        onClick: () => {
          closeMenu();
          logOut();
        },
        enabled: true,
      },
    ];
  };

  const renderProfileMenu = () => (
    <Menu onClose={closeMenu} anchorEl={anchorEl} open={menuOpen}>
      {menuButtons()
        .filter((item) => item.enabled)
        .map(({ title, onClick, id }) => (
          <StyledMenuItem key={id} id={id} onClick={onClick}>
            <MenuItemText>{title}</MenuItemText>
          </StyledMenuItem>
        ))}
    </Menu>
  );

  return (
    <StyledHeader isHeaderHidden={isHeaderHidden} data-testid="header">
      <StyledLogo>
        <img src={sorbetLogo} alt="Sorbet" />
      </StyledLogo>
      {isAuthenticated && (
        <>
          <StyledIconButton onClick={openMenu} disabled={!isAuthenticated}>
            <AvatarContainer>
              <UserAvatar size={32} {...avatarProps()} />

              <img src={arrowDownSvg} alt={"arrow-down"} />
            </AvatarContainer>
          </StyledIconButton>
          {renderProfileMenu()}
        </>
      )}
    </StyledHeader>
  );
});

export default HeaderComponent;
