import { action, computed, observable } from "mobx";
import { FilesToUploadNMI, ILeadStore } from "./types";
import * as types from "services/api/types";
import { apiService } from "services/api";
import userStore from "stores/user";
import { analytics } from "services/analytics";
import { snackbarUtils } from "components/Notification";
import { identifyThirdPartyServices } from "services/identifyThirdPartyServices";

class LeadStore implements ILeadStore {
  @observable fileUploading: boolean = false;
  @observable leadInfo?: types.ILeadInfo;
  @observable leadApprovalInfo?: types.TLeadApprovalInfo;
  @observable employmentAccount?: types.TEmploymentAccount;
  @observable private _payrollConnectedSuccessfully: boolean = false;
  @observable leadUuid: string = "";

  @action setPayrollConnectedSuccessfully = (v: boolean) => {
    this._payrollConnectedSuccessfully = v;
  };

  @action public loadLeadInfo = async (uuid: string, force: boolean = false) => {
    if (this.leadInfo && !force) {
      return;
    }
    this.leadUuid = uuid;
    await identifyThirdPartyServices(uuid);
    const info = await apiService.getLeadInfo(uuid);
    if (info?.b2cApplicationStatus === types.EB2cApplicationStatus.Canceled) {
      userStore.logOut(true);
    } else {
      this.leadInfo = info;
    }
  };

  @action getLeadApprovalInfo = async (userId: string) => {
    const { userInfo, setUserApprovedAmount, logOut } = userStore;
    try {
      const approvalInfo = await apiService.getLeadApprovalInfo(userId);
      if (approvalInfo.employmentAccountId) {
        this.getEmploymentAccount();
      }
      this.leadUuid = approvalInfo.uuid;
      this.leadApprovalInfo = approvalInfo;
      await identifyThirdPartyServices(
        approvalInfo.uuid,
        `${userInfo?.firstName} ${userInfo?.lastName}`,
        userInfo?.email,
      );
      analytics.setSuperProps({ email: userInfo?.email, UUID: approvalInfo.uuid });
      setUserApprovedAmount(approvalInfo.b2cApprovedAmountCents);
    } catch (error) {
      await logOut(true);
      snackbarUtils.error(
        `Error while trying to log you in. Please contact our support for more information.`,
      );
      throw new Error(`can't get lead for userId=${userId}`);
    }
  };

  @action getEmploymentAccount = async () => {
    try {
      const employmentAccount = await apiService.getEmploymentAccount();
      this.employmentAccount = employmentAccount;
    } catch (error) {}
  };

  @computed get needToConnectPayroll() {
    if (
      !this.leadApprovalInfo?.employmentAccountId ||
      this.employmentAccount?.payrollConnectionStatus === "pending"
    ) {
      return !this._payrollConnectedSuccessfully;
    }
    return false;
  }

  @computed get filesToUploadNMI() {
    const needToUpload: FilesToUploadNMI[] = [];
    if (this.leadInfo?.b2cApplicationStatus === types.EB2cApplicationStatus.NeedMoreInfo) {
      if (Array.isArray(this.leadInfo.b2cNeedMoreInfoReasons)) {
        const filesStatus: {
          pto?: types.BTCReasonType;
          payStub?: types.BTCReasonType;
          idSelfie?: types.BTCReasonType;
        } = {};

        this.leadInfo.b2cNeedMoreInfoReasons.forEach((reason) => {
          if (reason.status === "active" || reason.status === "inactive") {
            switch (reason.subCategory) {
              case types.NeedMoreInfoSubCategoriesEnum.UNCLEAR_PTO_POLICY:
                if (filesStatus.pto !== "active") {
                  filesStatus.pto = reason.status;
                }
                break;

              case types.NeedMoreInfoSubCategoriesEnum.MISSING_PTO_BALANCE:
              case types.NeedMoreInfoSubCategoriesEnum.ILLEGIBLE_PARTIALLY_READABLE:
              case types.NeedMoreInfoSubCategoriesEnum.NEEDS_TO_CONTAIN_PAY_RATE:
              case types.NeedMoreInfoSubCategoriesEnum.MORE_RECENT_PAYSTUB:
                if (filesStatus.payStub !== "active") {
                  filesStatus.payStub = reason.status;
                }
                break;

              case types.NeedMoreInfoSubCategoriesEnum.NEED_A_SELFIE_WITH_ID_SCREENING:
              case types.NeedMoreInfoSubCategoriesEnum.NEED_A_SELFIE_WITH_ID_KYC:
              case types.NeedMoreInfoSubCategoriesEnum.NEED_TO_VERIFY_ADDRESS:
                if (filesStatus.idSelfie !== "active") {
                  filesStatus.idSelfie = reason.status;
                }
                break;

              default:
                break;
            }
          }
        });
        if (filesStatus["pto"]) {
          needToUpload.push({ type: "pto", status: filesStatus["pto"] });
        }
        if (filesStatus["payStub"]) {
          needToUpload.push({ type: "payStub", status: filesStatus["payStub"] });
        }
        if (filesStatus["idSelfie"]) {
          needToUpload.push({ type: "idSelfie", status: filesStatus["idSelfie"] });
        }
      }
    }

    return needToUpload;
  }

  @computed get filesToUploadNMIActive() {
    return this.filesToUploadNMI.filter((v) => v.status === "active");
  }

  @computed get isLeadBankSponsor() {
    return this.leadApprovalInfo?.sponsor === types.LeadSponsorEnum.lead_bank;
  }

  public uploadLeadFile = async (type: types.LeadFileType, file: File) => {
    try {
      this.fileUploading = true;
      await apiService.updateBtcApplicationFiles({ uuid: this.leadUuid, [type]: file });
      await this.loadLeadInfo(this.leadUuid, true);
    } catch (error) {
    } finally {
      this.fileUploading = false;
    }
  };

  @action clearStore = () => {
    this.leadInfo = undefined;
    this.leadApprovalInfo = undefined;
    this.leadUuid = "";
    this._payrollConnectedSuccessfully = false;
  };
}

const leadStore: ILeadStore = new LeadStore();

export default leadStore;
