import { action, computed, observable } from "mobx";
import { ILoanStore } from "./types";
import * as types from "services/api/types";
import { apiService } from "services/api";
import moment from "moment";
import { currencyFormatter } from "utils/formatters";
import { stores } from "stores";

class LoanStore implements ILoanStore {
  @observable public loanInfo?: types.ILoanDataInfo;
  @observable private amortizeLoan?: types.IAmortizeLoanInfo;

  @computed get isLoan() {
    return this.loanInfo?.applicationType === types.ApplicationTypeEnum.LOAN;
  }
  @computed get isPromissoryNote() {
    return this.loanInfo?.applicationType === types.ApplicationTypeEnum.PROMISSORY_NOTE;
  }

  @computed
  public get nextAmountDue() {
    return this.loanInfo && typeof this.loanInfo?.paymentDueAmountCents === "number"
      ? (this.loanInfo?.paymentDueAmountCents / 100).toFixed(2)
      : this.amortizeLoan?.schedule[0]?.amount
      ? (this.amortizeLoan?.schedule[0].amount).toFixed(2)
      : "";
  }

  @computed
  public get maturityDate() {
    const time = moment().utcOffset() < 0 ? "T12:00:00.000Z" : "T00:00:00.000Z";

    if (this.loanInfo) {
      return this.loanInfo?.maturityDate;
    }

    return new Date(
      `${
        this.amortizeLoan?.schedule[this.amortizeLoan?.schedule.length - 1].date.split("T")[0]
      }${time}`,
    );
  }

  @computed
  public get outstandingBalance() {
    if (this.loanInfo && this.loanInfo?.outstandingPrincipalBalanceCents) {
      return currencyFormatter(this.loanInfo?.outstandingPrincipalBalanceCents);
    }
    if (this.amortizeLoan) {
      return currencyFormatter(this.amortizeLoan?.amountFinanced);
    }
    return currencyFormatter(stores.userStore.userApprovedAmount);
  }

  @computed
  public get remainingToPay() {
    if (this.loanInfo) {
      return (
        this.loanInfo.totalPaybackAmountCents -
        this.loanInfo.balances?.paidBalances?.paidTotalAmountCents
      );
    }
    return 0;
  }

  @computed
  public get totalPaybackAmount() {
    if (this.loanInfo) {
      return this.loanInfo.totalPaybackAmountCents;
    }
    return 0;
  }

  @computed
  public get totalPaid() {
    if (this.loanInfo) {
      return this.loanInfo.balances?.paidBalances?.paidTotalAmountCents;
    }
    return 0;
  }

  @computed public get overdueTotalAmountCents() {
    if (this.loanInfo) {
      return this.loanInfo?.balances?.overdueBalances?.overdueTotalAmountCents;
    }
    return 0;
  }

  @computed
  public get nextPaymentDate() {
    const time = moment().utcOffset() < 0 ? "T12:00:00.000Z" : "T00:00:00.000Z";
    // We either present statement info OR amortize loan info
    if (this.loanInfo && this.loanInfo.paymentDueDate) {
      return new Date(`${this.loanInfo.paymentDueDate.split("T")[0]}${time}`);
    }
    return new Date(`${this.amortizeLoan?.schedule[0].date.split("T")[0]}${time}`);
  }

  @computed
  public get formattedNextPaymentDate() {
    return moment(this.nextPaymentDate).format("MMMM Do, YYYY");
  }

  @computed
  public get formattedDayOfMonth() {
    return moment(this.nextPaymentDate).format("Do");
  }

  @action public getAmortizeLoanDetails = async () => {
    try {
      const loanInfo = await apiService.amortizeLoan();
      this.amortizeLoan = loanInfo;
    } catch (error) {}
  };

  @action public getLoanDetails = async (userId: string) => {
    try {
      const loanInfo = await apiService.getLoanDetails(userId);
      this.loanInfo = loanInfo;
    } catch (error) {}
  };

  @action clearStore = () => {
    this.loanInfo = undefined;
    this.amortizeLoan = undefined;
  };
}

const loanStore: ILoanStore = new LoanStore();

export default loanStore;
