import { IconButton, Modal, styled as muiStyled } from "@mui/material";
import { device } from "config/devices";
import { Link } from "react-router-dom";
import styled from "styled-components";

export const StyledModal = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  @media ${device.tablet} {
    align-items: center;
  }
`;
export const ModalContainer = styled.div`
  max-width: 600px;
  border-radius: 8px;
  background-color: #fff;
  position: relative;
  overflow-y: auto;
  max-height: 100vh;
`;

export const CloseIcon = muiStyled(IconButton)`
  position: absolute;
  top: 2px;
  right: 2px;
`;

export const Header = styled.div`
  padding: 32px 24px 8px 24px;
  @media ${device.tablet} {
    padding: 32px 40px;
  }
`;

export const HeaderIcons = styled.div`
  display: flex;
  gap: 4px;
`;

export const HeaderTitle = styled.p`
  color: var(--text-icon-dark, #242323);
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  margin-top: 16px;

  font-size: 20px;
  line-height: 32px; /* 160% */
  @media ${device.tablet} {
    font-size: 28px;
    line-height: 40px; /* 160% */
  }
`;

export const HeaderDescription = styled.p`
  color: var(--text-icon-secondary, var(--text-icon-text-secondary, #585866));
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
`;

export const BankAccountContainer = styled.div`
  margin: 0 24px 24px 24px;
  @media ${device.tablet} {
    margin: 0 40px 24px 40px;
  }
`;

export const ConsentContainer = styled.div`
  border-radius: var(--Radius-small-radius, 4px);
  border: var(--Radius-zero-radius, 1px) solid var(--Surface-surface-action-disabled, #e4e2ed);
  background: var(--Surface-surface-secondary, #f8f8fc);
  display: flex;
  padding: var(--Radius-large-radius, 16px) 24px;

  margin: 0 24px 24px 24px;
  @media ${device.tablet} {
    margin: 0 40px 24px 40px;
  }
`;

export const ConsentText = styled.p`
  color: var(--text-icon-secondary, var(--text-icon-text-secondary, #585866));
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
`;

export const SorbetDebitAuthorizationLink = styled(Link)`
  color: #ff5277;
  text-decoration: none;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 0 24px 24px 24px;
  @media ${device.tablet} {
    margin: 0 40px 32px 40px;
  }
`;

export const Loader = styled.div`
  position: absolute;
  top: 40px;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;
