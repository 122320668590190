import { useTranslation } from "react-i18next";
import { ContainerForSmallText, StyledSmallText } from "../styles";
import { EB2cApplicationStatus, LeadSponsorEnum } from "services/api/types";

const pageTranslationPrefix = "application_status";

type Props = {
  status: EB2cApplicationStatus;
  sponsor?: LeadSponsorEnum;
};
export const RenderAdverseActionNotice = ({ status, sponsor }: Props) => {
  const { t } = useTranslation();
  if (status !== EB2cApplicationStatus.Declined) {
    return null;
  }
  return (
    <div>
      <ContainerForSmallText>
        <StyledSmallText>
          {sponsor === LeadSponsorEnum.lead_bank
            ? t(`${pageTranslationPrefix}.statuses.Declined.leadBank.federalNotice`)
            : t(`${pageTranslationPrefix}.statuses.Declined.sorbet.federalNotice`)}
        </StyledSmallText>
        <StyledSmallText>
          {sponsor === LeadSponsorEnum.lead_bank
            ? t(`${pageTranslationPrefix}.statuses.Declined.leadBank.federalTrade`)
            : t(`${pageTranslationPrefix}.statuses.Declined.sorbet.federalTrade`)}
        </StyledSmallText>
      </ContainerForSmallText>
    </div>
  );
};
