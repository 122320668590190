import { snackbarUtils } from "components/Notification";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { PlaidLinkOnSuccessMetadata, PlaidLinkOptions, usePlaidLink } from "react-plaid-link";
import { apiService } from "services/api";
import { BankAccountsStatusEnum, IBankAccount } from "services/api/types";
import { gtmService } from "services/gtm";
import { errorLogger } from "services/logger";
import { useStores } from "stores";
import { useIsMount } from "utils/hooks/useIsMount";
import { LOCAL_STORAGE_KEYS, LocalStorage } from "utils/localStorage";

type LinkProps = { flow: "link" | "update" | "link_new" };
type BackupProps = {
  flow: "backup" | "update_backup";
  onSuccessCallback: (account?: IBankAccount) => void;
};

type Props = LinkProps | BackupProps;

export const usePlaid = (props: Props) => {
  const { t } = useTranslation();
  const isMount = useIsMount();
  const [linkToken, setToken] = useState<string | null>(null);
  const {
    userStore: { userInfo, loadGeneralUserInfo },
    bankDetailsStore: {
      setBankAccountRelinkError,
      getBankAccountDetail,
      relinkLoading,
      setRelinkLoading,
    },
  } = useStores();
  const userId = userInfo?.id;

  const isOAuthRedirect = window.location.href.includes("?oauth_state_id=");

  // get link_token from your server when component mounts
  useEffect(() => {
    if (isOAuthRedirect) {
      setToken(LocalStorage.getItem(LOCAL_STORAGE_KEYS.link_token));
      return;
    }
  }, []);

  useEffect(() => {
    if (!isMount) {
      setToken(null);
    }
  }, [props.flow]);

  const createLinkToken = async () => {
    if (userId) {
      try {
        setRelinkLoading(true);
        switch (props.flow) {
          case "update": {
            if (userInfo && userInfo.bankIntegrationId) {
              try {
                const response = await apiService.getPlaidUpdateAccessToken(
                  userId,
                  userInfo.bankIntegrationId,
                );
                const { token } = response;
                setToken(token);
                LocalStorage.setItem(LOCAL_STORAGE_KEYS.link_token, token);
              } catch (error) {
                setBankAccountRelinkError(true);
              }
            }
            break;
          }

          case "update_backup": {
            if (userInfo && userInfo.bankIntegrationId) {
              try {
                const bankAccountDetailId = LocalStorage.getItem(
                  LOCAL_STORAGE_KEYS.relink_bankAccountDetailId,
                );
                if (bankAccountDetailId) {
                  const response = await apiService.requestUpdateAuthLinkTokenByBankAccountDetailId(
                    userId,
                    userInfo.bankIntegrationId,
                    +bankAccountDetailId,
                  );
                  const { token } = response;
                  setToken(token);
                  LocalStorage.setItem(LOCAL_STORAGE_KEYS.link_token, token);
                }
              } catch (error: any) {
                snackbarUtils.error(
                  `Error request update auth link token by bank accountDetail id ${
                    error.message ?? ""
                  }`,
                );
              }
            }
            break;
          }

          case "link":
          case "backup":
          case "link_new":
          default: {
            const response = await apiService.getUserPlaidAccessToken(userId);
            const { token } = response;
            setToken(token);
            LocalStorage.setItem(LOCAL_STORAGE_KEYS.link_token, token);
            break;
          }
        }
      } catch (error: any) {
        if (error.message) {
          snackbarUtils.error(error.message);
        }
      } finally {
        setRelinkLoading(false);
      }
    }
  };

  const loadUserData = async () => {
    await loadGeneralUserInfo(true);
  };

  const onSuccess = async (publicToken: string, _metadata: PlaidLinkOnSuccessMetadata) => {
    try {
      if (userId) {
        setRelinkLoading(true);
        switch (props.flow) {
          case "update":
            if (userInfo && userInfo.bankIntegrationId) {
              await apiService.setBankIntegrationTokenValid(userId, userInfo.bankIntegrationId);
            }
            snackbarUtils.success(t("page_bankAuth.successReLinkedBankAccount"));
            await loadUserData();
            break;
          case "update_backup":
            try {
              const bankAccountDetailId = LocalStorage.getItem(
                LOCAL_STORAGE_KEYS.relink_bankAccountDetailId,
              );

              if (userInfo && userInfo.bankIntegrationId && bankAccountDetailId) {
                const details = await apiService.updateBankDetailById(
                  userId,
                  userInfo.bankIntegrationId,
                  +bankAccountDetailId,
                  {
                    status: BankAccountsStatusEnum.valid,
                    //@ts-ignore account_id doesn't exist in types, but real response has the property
                    accountId: _metadata.account_id,
                  },
                );
                snackbarUtils.success(t("page_bankAuth.successReLinkedBankAccount"));
                props.onSuccessCallback(details);
              }
            } catch (error: any) {
              snackbarUtils.error(`Error update bank detail by id ${error.message ?? ""}`);
            }
            break;
          case "backup": {
            const { bankAccountDetailId } = await apiService.exchangeUserPublicToken(
              publicToken,
              userId,
            );

            const details = await getBankAccountDetail(bankAccountDetailId);
            props.onSuccessCallback(details);

            break;
          }
          case "link":
          case "link_new":
          default: {
            await apiService.exchangeUserPublicToken(publicToken, userId);
            snackbarUtils.success(t("page_bankAuth.successLinkedBankAccount"));
            await loadUserData();
            break;
          }
        }
      }
    } catch (error: any) {
      if (error.message) {
        snackbarUtils.error(error.message);
      }
    } finally {
      setRelinkLoading(false);
    }
  };
  const config: PlaidLinkOptions = {
    // token must be the same token used for the first initialization of Link
    token: linkToken,
    onSuccess,
    onEvent: (eventName, metadata) => {
      errorLogger.captureMessage("plaid on event: " + eventName, {
        plaid_metadata: metadata,
        userId,
      });
      gtmService.customEvent({ event: "linkbank_" + metadata.view_name, userId });
    },
    onExit: (eventName, metadata) => {
      errorLogger.captureMessage("plaid on exit: " + eventName, {
        plaid_metadata: metadata,
        userId,
      });
      gtmService.customEvent({ event: "linkbank_EXIT: " + eventName, userId });
      setToken(null);
    },
  };
  if (isOAuthRedirect) {
    // receivedRedirectUri must include the query params
    config.receivedRedirectUri = window.location.href;
  }

  const { open, ready } = usePlaidLink(config);

  useEffect(() => {
    // If OAuth redirect, instantly open link when it is ready instead of
    // making user click the button
    if (isOAuthRedirect && ready) {
      errorLogger.captureMessage("plaid OAuth redirect", {
        userId,
        link_token: LocalStorage.getItem(LOCAL_STORAGE_KEYS.link_token),
      });
      open();
    } else if (ready) {
      open();
    }
  }, [ready, open, isOAuthRedirect]);

  const trackStartEvent = () => {
    gtmService.customEvent({ event: "linkbankstart", userId });
  };

  const onClick = async () => {
    if (!linkToken) {
      await createLinkToken();
      return;
    }
    trackStartEvent();
    open();
  };

  return {
    loading: relinkLoading,
    onClick,
  };
};
