import CryptoJS from "crypto-js";
import { UserEntityTypeEnum } from "services/api/types";
import { LOCAL_STORAGE_KEYS, LocalStorage } from "./localStorage";
export enum LoginProviderEnum {
  GOOGLE = "google",
  MICROSOFT = "microsoft",
  SORBET = "sorbet",
  TEST_MODE = "test_mode",
}

function base64url(source: CryptoJS.lib.WordArray) {
  let encodedSource = CryptoJS.enc.Base64.stringify(source);
  encodedSource = encodedSource.replace(/=+$/, "");
  encodedSource = encodedSource.replace(/\+/g, "-");
  encodedSource = encodedSource.replace(/\//g, "_");
  return encodedSource;
}

export interface ISorbetLoginParams {
  provider: LoginProviderEnum.SORBET;
  email?: string;
  jwtSecret: string;
  fid?: string;
  phone?: string;
  countryCode?: string;
  authEntityType?: string;
}

const sign = (data: object, secret: string) => {
  const header = {
    alg: "HS256",
    typ: "JWT",
  };

  const stringifiedHeader = CryptoJS.enc.Utf8.parse(JSON.stringify(header));
  const encodedHeader = base64url(stringifiedHeader);
  const stringifiedData = CryptoJS.enc.Utf8.parse(JSON.stringify(data));
  const encodedData = base64url(stringifiedData);
  const token = `${encodedHeader}.${encodedData}`;
  const signature = base64url(CryptoJS.HmacSHA256(token, secret));
  const signedToken = `${token}.${signature}`;
  return signedToken;
};

export class LoginMethodSorbet {
  private tokenTtlSeconds = 3600;
  constructor(private params: ISorbetLoginParams) {}

  login(): Promise<{ sorbetAuthToken: string; entityId: string; entityType: UserEntityTypeEnum }> {
    const sorbetAuthToken = sign(
      {
        email: this.params.email,
        uid:
          this.params.fid || "e2e-tests-" + this.md5(this.params.email || this.params.phone || ""),
        provider: "e2e-tests",
        exp: this.createTokenExpirationTime(),
        phone: this.params.phone,
        countryCode: this.params.countryCode,
        authEntityType: this.params.authEntityType || UserEntityTypeEnum.user,
      },
      this.params.jwtSecret,
    );
    return Promise.resolve({
      sorbetAuthToken,
      entityId: "123",
      entityType: UserEntityTypeEnum.user,
    });
  }

  private md5(input: string): string {
    return "blabla-" + input;
  }

  private createTokenExpirationTime() {
    return Math.floor(Date.now() / 1000) + this.tokenTtlSeconds;
  }
}

export const cypressUserLogin = async (email: string) => {
  const loginSorbet = new LoginMethodSorbet({
    provider: LoginProviderEnum.SORBET,
    jwtSecret: LocalStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_JWT_SECRET) || "missed jwt",
    email,
    authEntityType: "user",
  });
  return await loginSorbet.login();
};
