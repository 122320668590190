import { device } from "config/devices";
import styled from "styled-components";

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  border: 1px solid #e4e2ed;
  max-width: 702px;
  padding: 20px;
  margin-bottom: 16px;
`;
export const StyledHeader = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
`;
export const StyledTitle = styled.div`
  color: #242323;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  line-height: 32px;
  @media ${device.tablet} {
    font-size: 18px;
    line-height: 24px;
  }
`;
export const StyledDescription = styled.div`
  display:flex;
  flex:1;
  color: #242323;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  @media ${device.tablet} {
    font-size: 14px;
  }
`;

export const StyledTopContainer = styled.div`
  width: 100%;
  direction: rtl;
  @media ${device.tablet} {
    width: 50%;
    
  }
`;

export const StyledBottomContainer = styled.div`
  display: flex;
  flex: 1;
  
  flex-direction: column;
  @media ${device.tablet} {
    flex-direction: row;
  }
  
  
`;

export const StyledSmallText = styled.div`
  color: #242323;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  @media ${device.tablet} {
    font-size: 12px;
  }
`;

export const StyledProgressBar = styled.div`
 margin-top: 14px;
  margin-bottom: 4px;
`;

export const StyledBoldDescription = styled.div`
  color: #242323;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  @media ${device.tablet} {
    font-size: 15px;
  }
`;

export const StyledToTheRight= styled.div`
  display: flex;
  flex:1;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;


export const StyledLine = styled.div`
  display: flex;
  flex:1;
  flex-direction: row;
`;

export const StyledAmountLine = styled.div`
  display: flex;
  flex:1;
  justify-content: space-between;
  flex-direction: row;
  @media ${device.tablet} {
    flex-direction: column;
    align-items: flex-start;
  }
`;


export const StyledDivider = styled.div`
  background: rgba(0, 6, 36, 0.1);
  width: 100%;
  height: 1px;
  margin-bottom: 14px;
  margin-top: 14px;
  @media ${device.tablet} {
    height: 1px;
  }
`;

export const StyledIcon = styled.img`
  width: 18px;
  height: 18px;
`;

export const StyledImage = styled.img`
  width: 134px;
  height: 120px;
`;
export const StyledBody = styled.div`
  display: flex;
  padding-top: 8px;
`;
