import { Colors } from "assets/styles/constants";
import { device } from "config/devices";
import styled from "styled-components";

export const StyledModalLoader = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  inset: 0;
  background-color: #fff;
  border-radius: 8px;
  position: fixed;
  z-index: 10000;
  @media ${device.tablet} {
    position: absolute;
  }
`;
export const LoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const LoaderIcon = styled.img`
  width: 82px;
  height: 106px;
  margin-bottom: 16px;
`;

export const LoaderTitleContainer = styled.div<{ numberOfLines: number }>`
  position: relative;
  height: ${(p) => p.numberOfLines * 32}px;
  width: 250px;
  margin-bottom: 64px;
  @media ${device.tablet} {
    margin-bottom: 48px;
  }
`;

export const LoaderTitle = styled.div<{
  duration: number;
  delay: number;
  animationKey: string;
  hideAfter?: boolean;
}>`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;

  opacity: 0;
  @keyframes textAnimation_${p => p.animationKey} {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 1;
    }
    90% {
      opacity: 1;
    }
    100% {
      opacity: ${(p) => (p.hideAfter ? 0 : 1)};
    }
  }
  animation-name: textAnimation_${p => p.animationKey};
  animation-duration: ${(p) => p.duration}s;
  animation-timing-function: linear;
  animation-delay: ${(p) => p.delay}s;
  animation-fill-mode: forwards;
  h4 {
    color: ${Colors.darkText};
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px; /* 200% */
    height: 32px;
  }
`;

export const ProgressWrapper = styled.div<{
  width: string;
  duration: number;
  color: string;
  backColor: string;
}>`
  height: 8px;
  width: ${(p) => p.width};
  border-radius: 16px;
  background: ${(p) => p.backColor};
  position: relative;
  overflow: hidden;
  div {
    @keyframes loadingAnimation {
      0% {
        transform: translate(-100%);
      }
      100% {
        transform: translate(0px);
      }
    }
    position: absolute;
    height: 8px;
    width: ${(p) => p.width};
    border-radius: var(--Radius-large-radius, 16px);
    background: ${(p) => p.color};
    animation-name: loadingAnimation;
    animation-duration: ${(p) => p.duration}s;
    animation-timing-function: linear;
  }
`;
