import { Box } from "@mui/material";
import { useStores } from "../../../stores";

import {
  IssueWrapper,
  IssuesCount,
  ResolveButton,
  IssuesTitleText,
  IssuesTitle,
  IssuesDescription,
} from "./styles";

import { ROUTES } from "../../../config/constants";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { getIssueComponent } from "./IssueStepper";
import { UpdatePayroll } from "./UpdatePayroll";

const NUMBERS_MAP: { [key: number]: string } = {
  1: "one",
  2: "two",
  3: "three",
  4: "four",
  5: "five",
  6: "six",
  7: "seven",
};

const IssuesCenter = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    issuesStore: { showPayrollIssue, unresolvedIssue },
    leadStore: { needToConnectPayroll },
    debitConsentStore: { isFirstConsentExist },
  } = useStores();

  const openIssueCenter = () => {
    navigate(ROUTES.ISSUES_CENTER);
  };
  const showPayroll = isFirstConsentExist && needToConnectPayroll && showPayrollIssue;
  return (
    <Box>
      {unresolvedIssue?.length > 0 ? (
        <IssueWrapper>
          <IssuesTitle>
            <IssuesTitleText>{t(unresolvedIssue[0].title)}</IssuesTitleText>
            <IssuesCount id={`issues count ${unresolvedIssue.length}`}>
              {unresolvedIssue.length}
            </IssuesCount>
          </IssuesTitle>
          {unresolvedIssue.length > 1 && (
            <IssuesDescription>
              {`You have ${NUMBERS_MAP[unresolvedIssue.length - 1]} more ${
                unresolvedIssue.length > 2 ? "issues" : "issue"
              }.`}
            </IssuesDescription>
          )}

          {unresolvedIssue.length > 1 ? (
            <ResolveButton onClick={openIssueCenter}>{t("issuesCenter.resolveNow")}</ResolveButton>
          ) : (
            getIssueComponent(unresolvedIssue[0], false)
          )}
        </IssueWrapper>
      ) : showPayroll ? (
        <UpdatePayroll />
      ) : null}
    </Box>
  );
};

export default observer(IssuesCenter);
