import * as types from "./types";
import { computed, action, observable } from "mobx";
import * as servicesApiTypes from "services/api/types";
import { apiService } from "services/api/index";
import { errorLogger } from "../../services/logger";

class AppStore implements types.IAppStore {
  constructor() {
    window.onresize = () => {
      this.windowDimensions = this.getWindowDimensions();
    };
  }
  private getWindowDimensions = () => {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  };
  @computed public get isHeaderHidden() {
    return this._isHeaderHiden;
  }

  @computed public get initState() {
    return this._initState;
  }

  @computed public get errorModalParams() {
    return this._errorModalParams;
  }

  @computed public get errorModalVisible() {
    return !!this._errorModalParams;
  }

  @computed public get initConfig() {
    return this._initConfig;
  }

  @computed public get leadsInitConfig() {
    return this._leadsInitConfig;
  }
  @computed public get leadsInitConfigLoading() {
    return this._leadsInitConfigLoading;
  }

  public loadInitConfig = async () => {
    try {
      this._leadsInitConfigLoading = true;
      const leadsInitConfig = await apiService.getLeadsInitConfig();
      this.setLeadsInitConfig(leadsInitConfig);
    } catch (error) {
      this.setLeadsInitConfig(undefined);
      errorLogger.captureMessage("Failed to fetch configurations", {
        error: error,
      });
    } finally {
      this._leadsInitConfigLoading = false;
    }
  };

  public notify = (error: Error, params?: types.INotifyParams) => {
    const { showModal = true, context, ...modalParams } = params || {};

    const apiError = error as unknown as servicesApiTypes.IApiError;

    if (apiError?.status !== 401) {
      if (showModal) {
        this.showErrorModal(modalParams);
      }
    }
  };

  @action public showErrorModal = (params?: types.IErrorModalParams) => {
    this._errorModalParams = params || {};
  };

  @action public closeErrorModal = () => {
    this._errorModalParams = undefined;
  };

  @action public setInitState = (value?: types.InitStateType) => {
    this._initState = value;
  };

  @action public setIsHeaderHidden = (value: boolean) => {
    this._isHeaderHiden = value;
  };

  @action private setLeadsInitConfig = (config: servicesApiTypes.ILeadsInitConfig | undefined) => {
    this._leadsInitConfig = config;
  };

  @observable private _errorModalParams?: types.IErrorModalParams;

  @observable private _initConfig?: servicesApiTypes.IAppInitConfig = undefined;
  @observable private _leadsInitConfig?: servicesApiTypes.ILeadsInitConfig = undefined;

  @observable private _initState?: types.InitStateType = undefined;

  @observable private _isHeaderHiden: boolean = true;

  @observable private _leadsInitConfigLoading: boolean = false;
  @observable windowDimensions = {
    width: window.innerWidth,
    height: window.innerHeight,
  };
}

const appStore: types.IAppStore = new AppStore();

export default appStore;
