import { device } from "config/devices";
import styled from "styled-components";

export const Container = styled.div`
  padding: 20px 24px 24px 24px;
  display: flex;
  gap: 16px;
  @media ${device.tablet} {
    border-radius: 6px;
    border: 1px solid var(--color-primary-blue-30-cloud, #e4e2ed);
    gap: 24px;
  }
`;

export const Title = styled.h2`
  color: var(--text-icon-dark, #242323);
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  line-height: 32px; /* 145.455% */
  font-size: 20px;
  @media ${device.tablet} {
    font-size: 22px;
  }
`;

export const Description = styled.p`
  color: var(--text-icon-secondary, var(--text-icon-text-secondary, #585866));
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  font-size: 16px;
  @media ${device.tablet} {
    font-size: 14px;
  }
`;
