import { ENV } from "config/env";
import { IEvent } from "services/analytics/types";

class GTMService {
  init() {
    const gtm_id = ENV.REACT_APP_GTM_ID;

    const func = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-${gtm_id}');`;

    const script = document.createElement("script");
    script.innerHTML = func;
    document.head.insertBefore(script, document.head.childNodes[0]);

    //noscript
    const iframe = `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-${gtm_id}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
    const noscript = document.createElement("noscript");
    noscript.innerHTML = iframe;
    document.body.insertBefore(noscript, document.body.childNodes[0]);
  }

  private updateDataLayer = (event: string, dataLayer: object) => {
    if (window.dataLayer) {
      window.dataLayer.push(event, dataLayer);
    }
  };

  public customEvent = (dataLayer: object) => {
    this.updateDataLayer("CustomEvent", dataLayer);
  };

  public updateGeneralDataLayer = (generalDataLayer: {
    userId: number | null;
    userState: string;
    pageTitle: string;
  }) => {
    this.updateDataLayer("DataLayerUpdated", generalDataLayer);
  };

  public trackProductEvent = ({ event, params }: IEvent) => {
    if (window.dataLayer) {
      window.dataLayer.push({ event, params, mixpanel: true });
    }
  };

  public identifyProductUser = (identifier: string) => {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: "mixpanel identify",
        params: { MiXPANEL_TYPE: "identify", identifier },
        mixpanel: true,
      });
    }
  };

  public setProductSuperProps = (superProperties: object) => {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: "mixpanel register",
        params: { MiXPANEL_TYPE: "register", superProperties },
        mixpanel: true,
      });
    }
  };
  public setProductUserProps = (userProperties: object) => {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: "mixpanel people.set",
        params: { MiXPANEL_TYPE: "people.set", userProperties },
        mixpanel: true,
      });
    }
  };
  public productIncrementProperty = (property: string) => {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: "mixpanel people.increment",
        params: { MiXPANEL_TYPE: "people.increment", property },
        mixpanel: true,
      });
    }
  };

  public resetProductUser = () => {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: "mixpanel reset",
        params: { MiXPANEL_TYPE: "reset" },
        mixpanel: true,
      });
    }
  };
}

export const gtmService = new GTMService();
