import styled from "styled-components";
import {Box} from "@mui/material";
import {device} from "../../../config/devices";

export const StyledApplyPhoto = styled.img`
  margin-top: -32px;
  width: 100%;
`;

export const StyledPicturesContainer = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;

export const StyledApplyBody = styled(Box)`
  background-color: #fff;
  align-self: center;
  @media ${device.laptop} {
    width: 528px;
   }
  @media ${device.mobileS} {
    max-width: 528px;
   }
`;

export const StyledApplyAdvanceHeader = styled(Box)`
  display: flex;
  padding: 18px 20px 22px;
  background: #fff;
  flex-direction: column;
`;

export const StyledPictureCard = styled(Box)`
    width: 200px;
    margin-top: 40px;
`;

export const StyledCardText = styled(Box)`
  max-width: 192px;
  text-align: center;
`;

export const StyledCardPicture = styled.img`
  
`;


export const Styled56Px = styled.div`
  margin-top: 56px;
`;

export const StyledCurvedTop = styled.img`
  width: 100%
`;

export const StyledCurvedBody = styled.div`
  background-color: #F8F8FC;
`;

export const StyledApplyAdvanceForPtoBody = styled.div`
  padding: 0px 20px;
  margin-bottom: 48px;
`;

export const StyledManPicture = styled.img`
  width: 100%;
`;

export const StyledApplyForm = styled.form`
`;

export const StyledTermsOfService = styled.div`
  margin-top: 24px;
  padding: 10px 16px;
  color: #32536A;
  font-size: 12px;
  background-color: #F8F8FC;
`;

export const StyledTermsOfServiceCheckboxWrapper = styled.div`
  padding: 12px 16px;
  color: #32536A;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
`;
