import { ENV } from "config/env";
import Logger, { ILogger } from "./factory";
let logger: ILogger | ILogger[] | undefined = undefined;

const errorLogger: ILogger = {
  captureError: (error: any, data?: object | undefined) => {
    if (logger) {
      if (Array.isArray(logger)) {
        logger.forEach((l) => l.captureError(error, data));
      } else {
        logger.captureError(error, data);
      }
    }
  },
  captureMessage: (message: string, data?: object | undefined) => {
    if (logger) {
      if (Array.isArray(logger)) {
        logger.forEach((l) => l.captureMessage(message, data));
      } else {
        logger.captureMessage(message, data);
      }
    }
  },
  addLoggerGlobalContext: (params) => {
    if (logger) {
      if (Array.isArray(logger)) {
        logger.forEach((l) => l.addLoggerGlobalContext(params));
      } else {
        logger.addLoggerGlobalContext(params);
      }
    }
  },
  removeLoggerGlobalContext: (key: string) => {
    if (logger) {
      if (Array.isArray(logger)) {
        logger.forEach((l) => l.removeLoggerGlobalContext(key));
      } else {
        logger.removeLoggerGlobalContext(key);
      }
    }
  },
};

export const initLogger = () => {
  logger = Logger.createLogger(ENV.REACT_APP_APM_PROVIDER);
};
export { errorLogger };
