import styled from "styled-components";
import { Box } from "@mui/material";

export const StyledModalBody = styled(Box)`
  background-color: #fff;
  max-width: 560px;
  width: 100%;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  margin:8px;
  padding:32px;
  align-content: center;
`;

export const StyledModalTitle = styled.p`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 24px;
  color: #242323;
  margin-bottom: 8px;
`;

export const StyledModalSubtitle = styled.span`
  margin-bottom: 8px;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  color: #242323;
  
  a {
    color: #242323;
  }
`;
