import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { StyledSorbetLogo, StyledApplyHeader, StyledHeaderAmount } from "../styles";

import {
  StyledApplyBody,
  StyledApplyContainer,
  StyledCSBody,
  StyledSweetContainer,
  StyledApplydHeaderTitle,
  StyledApplyInnerContainer,
  StyledSweetTextContainer,
  StyledApplyFormWrapper,
  StyledEmail,
  StyledInfoHeaderTitle,
  StyledInfoHeaderSubtitle,
  StyledInfoHeaderText,
  ApplyForPtoImg,
  Curve1,
  Curve2,
} from "./styles";

import sorbetLogo from "../../../assets/logos/color-logo.svg";

import womanApply from "../../../assets/images/woman-apply.svg";

import { Box } from "@mui/material";
import { BTCLoader } from "../Components/Loader";

import { useStores } from "stores";
import { observer } from "mobx-react";
import { currencyFormatter } from "../../../utils/formatters";

import { COMPANY_EMAILS } from "../../../config/constants";
import { EVENTS } from "services/analytics/events";
import { HowDoesItWork } from "./components/howDoesItWorks";
import { ApplyForPtoForm } from "./components/form";
import curve1Svg from "./curves/curve1.svg";
import curve2Svg from "./curves/curve2.svg";

const pageTranslationPrefix = "page_Apply";

const Apply = observer(() => {
  const [showHearAboutSorbetQ, setShowHearAboutSorbetQ] = useState(true);

  const {
    btcStore: {
      btcCalculatePTO,
      btcPTO,
      ptoPolicyDesc,
      btcCalculatePTOLoading,
      showCalculatorResult,
      btcLoading,
    },
  } = useStores();

  useEffect(() => {
    EVENTS.btc.step3.onload();
    const href = window.location.href;
    const utmWithSource = href.includes("utm_source") && !href.includes("utm_source=direct");

    setShowHearAboutSorbetQ(!utmWithSource);
    document.title = "On-demand PTO cash advance";
    if (!btcPTO) {
      btcCalculatePTO();
    }
  }, []);

  const { t } = useTranslation();

  const NoCalculatorResult = () => {
    return (
      <Box maxWidth={"528px"}>
        <ApplyForPtoImg src={womanApply} />
        <StyledInfoHeaderTitle id="no-calc-result-title">
          {ptoPolicyDesc === "not_sure"
            ? t(`${pageTranslationPrefix}.cannotCalculate`)
            : t(`${pageTranslationPrefix}.notOweYou`)}
        </StyledInfoHeaderTitle>
        <StyledInfoHeaderSubtitle>
          {t(`${pageTranslationPrefix}.notSureGotPTO`)}
        </StyledInfoHeaderSubtitle>
        <StyledInfoHeaderText>
          {t(`${pageTranslationPrefix}.reachToYourEmployer`)}
        </StyledInfoHeaderText>
        <StyledEmail
          href={`mailto:${COMPANY_EMAILS.SUPPORT}?subject=Hey! I want to update my Sorbet PTO Cash Advance application`}
          target="_blank">
          {COMPANY_EMAILS.SUPPORT}
        </StyledEmail>
      </Box>
    );
  };

  return (
    <StyledApplyContainer>
      <StyledCSBody>
        <StyledApplyInnerContainer>
          <StyledApplyHeader>
            <StyledSorbetLogo src={sorbetLogo} />
          </StyledApplyHeader>
          <StyledSweetContainer>
            <StyledSweetTextContainer>
              {showCalculatorResult ? (
                <Box maxWidth={"528px"} width={"100%"}>
                  <ApplyForPtoImg src={womanApply} hideOnSmall />
                  <StyledApplydHeaderTitle>
                    {t(`${pageTranslationPrefix}.sweetYourUnused`)}
                  </StyledApplydHeaderTitle>
                  <StyledHeaderAmount id="amount-worth">{`${currencyFormatter(btcPTO, {
                    noFraction: true,
                    rounding: "floor",
                  })}`}</StyledHeaderAmount>
                </Box>
              ) : (
                <NoCalculatorResult />
              )}
            </StyledSweetTextContainer>
            <Curve1 src={curve1Svg} />
            <StyledApplyFormWrapper>
              <ApplyForPtoForm showHearAboutSorbetQ={showHearAboutSorbetQ} />
            </StyledApplyFormWrapper>
          </StyledSweetContainer>
          <Curve2 src={curve2Svg} />
        </StyledApplyInnerContainer>
        <StyledApplyBody>
          <HowDoesItWork />
        </StyledApplyBody>
      </StyledCSBody>
      {btcLoading || btcCalculatePTOLoading ? <BTCLoader /> : null}
    </StyledApplyContainer>
  );
});

export default Apply;
