import * as types from "services/api/types";
import { action, computed, observable } from "mobx";

// services / stores
import { authService } from "services/auth";
import { apiService } from "services/api";
import appStore from "stores/app";
import webAppStore from "stores/webApp";

// types
import { AuthServices, AuthTypes } from "services/auth/types";
import { IUserStore } from "./types";

// other
import { errorLogger } from "services/logger";
import leadStore from "stores/lead";
import { snackbarUtils } from "components/Notification";
import { ERRORS } from "config/constants";
import transfersInfoStore from "stores/transfersInfo";
import bankDetailsStore from "stores/bankDetails";
import { gtmService } from "services/gtm";
import { stores } from "stores";
import debitConsentStore from "stores/debitConsent";
import { analytics } from "services/analytics";
import issuesStore from "stores/issues";
import loanStore from "stores/loan";
import routerStore from "stores/router";
import contractsStore from "stores/contracts";
import { LocalStorage } from "utils/localStorage";

class UserStore implements IUserStore {
  @observable private _userInfo?: types.IUserInfo;
  @observable private _loadingUserInfo?: boolean;
  @observable private _userFiles: types.TUserFile[] = [];
  // @observable private _generalUserInfo?: types.IGeneralUserInfo;

  @observable private _userApprovedAmount: number = 0;
  @observable private _entityType: types.UserEntityTypeEnum | undefined = undefined;
  @observable wrongPhoneNumberOtp: boolean = false;
  @observable showConfirmPhoneNumberModal: boolean = false;
  @observable userFilesLoading: boolean = false;

  @computed get entityType() {
    return this._entityType;
  }

  @computed
  public get isAuthenticated() {
    return !!this.userInfo || !!leadStore.leadInfo;
  }

  @computed
  public get loadingUserInfo() {
    return this._loadingUserInfo;
  }

  @computed
  public get userFiles() {
    return this._userFiles;
  }

  @computed
  public get userInfo() {
    return this._userInfo;
  }

  @computed
  public get userApprovedAmount() {
    return this._userApprovedAmount;
  }

  @action setWrongPhoneNumberOtp = (v: boolean) => {
    this.wrongPhoneNumberOtp = v;
  };

  @action setShowConfirmPhoneNumberModal = (v: boolean) => {
    this.showConfirmPhoneNumberModal = v;
  };

  public signInWith = async (
    type: AuthTypes,
    email?: string,
    password?: string,
    serviceName?: AuthServices,
    smsCode?: string,
  ) => {
    try {
      const { success, error, ignore } = await authService.logInWith(
        type,
        email,
        password,
        serviceName,
        smsCode,
      );

      if (!success) {
        if (error?.startsWith("You have previously logged in with this email")) {
          snackbarUtils.error(
            "The email address you entered is already in use. Try signing in with a different provider.",
          );
        } else if (error === ERRORS.WRONG_PHONE_NUMBER_AUTH_OTP) {
          this.wrongPhoneNumberOtp = true;
        } else if (error) {
          snackbarUtils.error(error);
        }
        this.logOut(true);
        return false;
      }
      if (success && ignore) {
        return false;
      }
      this.showConfirmPhoneNumberModal = false;
      const authData = await authService.getAuthToken();

      if (authData?.entityType === types.UserEntityTypeEnum.lead && authData.entityId) {
        await leadStore.loadLeadInfo(authData.entityId, true);
        routerStore.setRouterScope(types.UserEntityTypeEnum.lead);
        this.setEntityType(types.UserEntityTypeEnum.lead);
        gtmService.customEvent({ event: "leadSuccessfulLogin", userId: leadStore?.leadInfo?.uuid });
      } else if (authData?.entityType === types.UserEntityTypeEnum.user) {
        await this.loadGeneralUserInfo(true);
        gtmService.customEvent({
          event: "userSuccessfulLogin",
          userId: this.userInfo?.id,
        });
      }

      return true;
    } catch (e: any) {
      gtmService.customEvent({ event: "failedLogin", userId: leadStore?.leadInfo?.uuid });
      // if the user doesn't exist in our database

      if (
        (e as types.IApiError).code === types.API_ERROR_CODES.UnauthorizedSuspiciousError ||
        (e as types.IApiError).code === types.API_ERROR_CODES.UnauthorizedError ||
        (e as types.IApiError).status === types.API_ERROR_CODES.UnauthorizedStatus
      ) {
        snackbarUtils.error(
          type === AuthTypes.PHONE_NUMBER
            ? "The phone number you entered does not match our records. Please sign in with the same phone number you used to apply."
            : "The email address you entered does not match our records. Please sign in with the same email address you used to apply.",
        );
      }
      if (e.code === "auth/invalid-action-code" && AuthTypes.EMAIL_LINK_LOGIN) {
        snackbarUtils.error("Link is expired, or has already been used");
      }
      if (e.code === "auth/invalid-email" && AuthTypes.EMAIL_LINK_LOGIN) {
        snackbarUtils.error("The email cannot be recognized. Please retry.");
      }
      if (e.message === "User does not have a card!") {
        return true;
      }
      authService.logOut();
      return false;
    }
  };

  public checkUserAuthStateAndLoadData = async () => {
    const authUser = await authService.loadAuthState();
    if (authUser) {
      try {
        const authData = await authService.getAuthToken();

        if (authData?.entityType === types.UserEntityTypeEnum.lead && authData.entityId) {
          await leadStore.loadLeadInfo(authData.entityId, true);
          routerStore.setRouterScope(types.UserEntityTypeEnum.lead);
          this.setEntityType(types.UserEntityTypeEnum.lead);
        } else if (authData?.entityType === types.UserEntityTypeEnum.user) {
          await this.loadGeneralUserInfo(true);
        }
      } catch (e: any) {
        if ((e as types.IApiError).status === 401) {
          authService.logOut();
        } else {
          appStore.notify(e, {
            context: "Failed to complete checkUserAuthStateAndLoadData",
          });
          throw e;
        }
      }
    } else {
      routerStore.checkIfRedirectIsNeeded();
    }
  };

  private _isLoginOut = false;

  public logOut = async (force: boolean = false) => {
    if (!force && (!this.isAuthenticated || this._isLoginOut)) {
      return;
    }
    this._isLoginOut = true;
    try {
      appStore.setInitState();
      await authService.logOut();
      this.clearUserInfo();
      errorLogger.removeLoggerGlobalContext("user-id");
      appStore.setInitState("ready");
    } catch (error: any) {
      appStore.notify(error, { context: "Failed to log out", showModal: false });
    }
    this._isLoginOut = false;
  };

  @action public getUserFiles = async () => {
    try {
      this.userFilesLoading = true;
      const files = await apiService.getUserFiles();
      this._userFiles = files;
    } catch (error) {
    } finally {
      this.userFilesLoading = false;
    }
  };

  public loadGeneralUserInfo = async (force: boolean = false) => {
    this.setEntityType(types.UserEntityTypeEnum.user);
    if (this.userInfo && !force) {
      return;
    }

    try {
      this._loadingUserInfo = true;
      const info = await apiService.getUserInfo();
      info.avatarUrl = await authService.getPhotoUrl();

      this.setUserInfo(info);

      await leadStore.getLeadApprovalInfo(info.id);
      await Promise.allSettled([
        transfersInfoStore.getAchTransfersList(info.bankIntegrationId),
        transfersInfoStore.getPayments(),
        this.getUserFiles(),
        loanStore.getLoanDetails(info.id),
        bankDetailsStore.getBankDetails(info.bankIntegrationId),
        stores.debitConsentStore.getConsentInfoByType(
          info.bankIntegrationId,
          types.DebitAchAuthTypeEnum.AUTO_PAY,
        ),
        stores.debitConsentStore.getConsentInfoByType(
          info.bankIntegrationId,
          types.DebitAchAuthTypeEnum.ONE_TIME,
        ),
        stores.debitConsentStore.getConsentInfoByType(
          info.bankIntegrationId,
          types.DebitAchAuthTypeEnum.OFF_CYCLE,
        ),
      ]);
      routerStore.setRouterScope(types.UserEntityTypeEnum.user, {
        b2cContractStatus: leadStore.leadApprovalInfo?.b2cContractStatus,
      });
      contractsStore.setContractStatus(leadStore.leadApprovalInfo?.b2cContractStatus);
    } catch (error) {
      errorLogger.captureError(error);
    } finally {
      this._loadingUserInfo = false;
      issuesStore.prepareIssues();
    }
  };

  @action
  private setUserInfo = (newUserInfo: types.IUserInfo) => {
    this._userInfo = { ...this._userInfo, ...newUserInfo };
  };

  @action
  public setUserApprovedAmount = (approvedAmount: number) => {
    this._userApprovedAmount = approvedAmount;
  };

  @action
  public setEntityType(type: types.UserEntityTypeEnum) {
    this._entityType = type;
  }

  @action
  private clearUserInfo() {
    this.clearStore();
  }

  @action public clearStore = () => {
    analytics.reset();
    this.userFilesLoading = false;
    this._entityType = undefined;
    this._userInfo = undefined;
    this._userFiles = [];
    loanStore.clearStore();
    LocalStorage.clear();

    webAppStore.clearStore();
    debitConsentStore.clearStore();
    leadStore.clearStore();
    bankDetailsStore.clearStore();
    issuesStore.clearStore();
    routerStore.resetStore();
    contractsStore.clearStore();
  };
}

const userStore: IUserStore = new UserStore();

export default userStore;
