import {IAnalytics} from './types';
import AnalyticsMixPanelImpl from './mixpanel';
import AnalyticsDevelopment from './development';

export enum ANALYTICS_TYPE {
  DEVELOPMENT = 'development',
  MIXPANEL = 'mixpanel',
}

export default class AnalyticsFactory {
  static createAnalytics(
    type: ANALYTICS_TYPE,
  ): IAnalytics {
    switch (type) {
      case ANALYTICS_TYPE.MIXPANEL:
        return new AnalyticsMixPanelImpl();
    }
    switch (type) {
      case ANALYTICS_TYPE.DEVELOPMENT:
        return new AnalyticsDevelopment();
    }
  }
}
