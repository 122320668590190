import { useTranslation } from "react-i18next";
import { useEffect, useRef, useState } from "react";

import {
  LoaderContainer,
  LoaderIcon,
  LoaderTitle,
  LoaderTitleContainer,
  StyledModalLoader,
} from "./styles";
import loaderGif from "./loader.gif";
import { CustomProgress } from "./progress";

type Props = {
  loading: boolean;
  callback?: () => void;
  duration: number;
  steps: Array<Array<string>>;
  waitUntilEnd?: boolean;
  timeoutText?: string;
  interrupt?: boolean;
};

export const LongEventsLoader = ({
  loading,
  callback = () => {},
  duration,
  steps,
  waitUntilEnd = true,
  timeoutText,
  interrupt,
}: Props) => {
  const { t } = useTranslation();

  const [showLoader, setShowLoader] = useState(false);
  const totalSteps = steps.length;
  const numberOfLines = steps.sort((a, b) => b.length - a.length)[0].length;

  const timeout = useRef<NodeJS.Timeout>();
  const isFinished = useRef<boolean>(false);
  const externalLoading = useRef<boolean>(false);

  const reset = () => {
    setShowLoader(false);
    clearTimeout(timeout.current);
    isFinished.current = false;
  };

  useEffect(() => {
    if (showLoader) {
      timeout.current = setTimeout(() => {
        if (!externalLoading.current) {
          // callback only if external loading finished
          reset();
          callback();
        } else {
          isFinished.current = true;
        }
      }, duration * 1000);
    }
  }, [showLoader]);

  useEffect(() => {
    if (interrupt) {
      reset();
    }
  }, [interrupt]);

  useEffect(() => {
    externalLoading.current = loading;
    if (loading) {
      setShowLoader(true);
    } else {
      if (isFinished.current || !waitUntilEnd) {
        reset();
        callback();
      }
    }
  }, [loading]);

  return showLoader ? (
    <StyledModalLoader>
      <LoaderContainer>
        <LoaderIcon src={loaderGif} />
        <LoaderTitleContainer numberOfLines={numberOfLines}>
          {steps.map((step, i) => (
            <LoaderTitle
              key={`step_${i}`}
              delay={(i * duration) / totalSteps}
              duration={duration / totalSteps}
              animationKey={`step_${i}`}
              hideAfter={!!timeoutText || steps.length - 1 > i}>
              {step.map((translationKey) => (
                <h4 key={translationKey}>{t(translationKey)}</h4>
              ))}
            </LoaderTitle>
          ))}
          {timeoutText ? (
            <LoaderTitle
              delay={(steps.length * duration) / totalSteps}
              duration={duration / totalSteps}
              animationKey={`step_${steps.length}`}
              hideAfter={false}>
              <h4>{t(timeoutText)}</h4>
            </LoaderTitle>
          ) : null}
        </LoaderTitleContainer>

        <CustomProgress duration={duration} />
      </LoaderContainer>
    </StyledModalLoader>
  ) : null;
};
