import { useTranslation } from "react-i18next";
import { Header, Wrapper, StyledWrapper, TopHeader } from "../styles";
import backIcon from "../icons/backIcon.svg";
import plusIcon from "../icons/plusIcon.svg";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "config/constants";
import { useEffect, useState } from "react";
import { useStores } from "stores";
import {
  BankAccountPrimary,
  BankAccountBackup,
  Content,
  HeaderTitle,
  TextInfo,
  LinkNewBankButton,
  LinkNewBankButtonLoading,
} from "./styles";
import { BankAccountItem } from "./components/BankAccountItem";
import { IconButton } from "@mui/material";
import { observer } from "mobx-react";
import { NewBackupAccountModal } from "./components/NewBackupAccountModal";
import { NoConsentModal } from "./components/NoConsentModal";
import { usePlaid } from "components/PlaidButton/usePlaidButton";
import loaderImage from "assets/images/loader.svg";
import { BankAccountsStatusEnum, IBankAccount, OperationalStatusEnum } from "services/api/types";
import { BTCLoader } from "pages/Btc/Components/Loader";
import { PrimaryAccountConsent } from "./components/PrimaryAccountConsent";
import { LOCAL_STORAGE_KEYS, LocalStorage } from "utils/localStorage";

const translationKey = "manageLinkedAccounts";

export const AdditionalAccountFlow = observer(() => {
  const [openPrimaryAccountConsent, setOpenPrimaryAccountConsent] = useState(false);
  const {
    appStore: { setIsHeaderHidden },
    bankDetailsStore: {
      backupAccounts,
      setNewBackupAccount,
      bankDetails,
      backupAccountConsents,
      loading,
      unlinkAccount,
      getBankAccountsDetails,
    },
    debitConsentStore: { isPrimaryConsentExist },
  } = useStores();

  const navigate = useNavigate();
  const { t } = useTranslation();

  const plaidLinkSuccess = (account?: IBankAccount) => {
    if (account) {
      setNewBackupAccount({ isBackupAccount: false, ...account, newAccount: true });
    }
  };

  const plaidRelinkSuccess = (account?: IBankAccount) => {
    getBankAccountsDetails();
    if (
      account &&
      account.operationalStatus === OperationalStatusEnum.backup &&
      !backupAccountConsents[account.bankAccountDetailId]
    ) {
      setNewBackupAccount({ isBackupAccount: false, ...account });
    }
  };

  const { onClick: linkPlaid, loading: plaidLinkLoading } = usePlaid({
    flow: "backup",
    onSuccessCallback: plaidLinkSuccess,
  });

  const { onClick: relinkPlaid, loading: plaidRelinkLoading } = usePlaid({
    flow: "update_backup",
    onSuccessCallback: plaidRelinkSuccess,
  });

  const backClick = () => {
    navigate(ROUTES.HOME);
  };

  const onUnlinkAccount = (bankAccountDetailId: number) => () => {
    unlinkAccount(bankAccountDetailId);
  };

  const onSetUpAsBackup = (account: IBankAccount) => () => {
    setNewBackupAccount({ isBackupAccount: false, ...account });
  };

  const relinkClick = (bankAccountDetailId?: number) => () => {
    LocalStorage.setItem(LOCAL_STORAGE_KEYS.relink_bankAccountDetailId, `${bankAccountDetailId}`);
    relinkPlaid();
  };

  useEffect(() => {
    setIsHeaderHidden(true);
  }, []);

  const authorizePrimaryClick = () => {
    setOpenPrimaryAccountConsent(true);
  };
  const closePrimaryAccountConsent = () => {
    setOpenPrimaryAccountConsent(false);
  };

  return (
    <StyledWrapper>
      <TopHeader />
      <Wrapper>
        <Header>
          <IconButton onClick={backClick} id={"manage-linked-account-back"}>
            <img src={backIcon} alt={"back"} />
          </IconButton>
          <HeaderTitle>{t(`${translationKey}.title`)}</HeaderTitle>
        </Header>

        <Content>
          <BankAccountPrimary>{t(`${translationKey}.primary`)}</BankAccountPrimary>
          <BankAccountItem
            type="primary"
            institutionName={bankDetails?.institutionName || ""}
            bankAccountLast4={
              bankDetails?.bankAccountLast4 ? `#${bankDetails.bankAccountLast4}` : "****"
            }
            showRelink={bankDetails?.status === BankAccountsStatusEnum.invalid}
            relinkClick={relinkClick(bankDetails?.bankAccountDetailId)}
            showAuthorize={!isPrimaryConsentExist}
            authorizeClick={authorizePrimaryClick}
          />

          <BankAccountBackup>{t(`${translationKey}.backup`)}</BankAccountBackup>
          {backupAccounts.map((bankAccount) => (
            <BankAccountItem
              key={bankAccount.bankAccountDetailId}
              type="backup"
              institutionName={bankAccount?.institutionName || ""}
              bankAccountLast4={
                bankAccount?.bankAccountLast4 ? `#${bankAccount.bankAccountLast4}` : "****"
              }
              isBackupAccount={backupAccountConsents[bankAccount.bankAccountDetailId]}
              unLinkAccount={onUnlinkAccount(bankAccount.bankAccountDetailId)}
              setUpAsBackup={onSetUpAsBackup(bankAccount)}
              showRelink={bankAccount.status === BankAccountsStatusEnum.invalid}
              relinkClick={relinkClick(bankAccount.bankAccountDetailId)}
            />
          ))}

          {backupAccounts.length < 1 ? (
            <LinkNewBankButton onClick={linkPlaid} disabled={plaidLinkLoading}>
              <img src={plusIcon} alt={"link-new-bank"} />
              {t(`${translationKey}.linkNewBank`)}

              {plaidLinkLoading ? <LinkNewBankButtonLoading src={loaderImage} /> : null}
            </LinkNewBankButton>
          ) : null}
          <TextInfo>{t(`${translationKey}.upcomingPaymentsWillBeDeducted`)}</TextInfo>
        </Content>
      </Wrapper>

      <NewBackupAccountModal />
      <NoConsentModal />
      <PrimaryAccountConsent
        openPrimaryAccountConsent={openPrimaryAccountConsent}
        closePrimaryAccountConsent={closePrimaryAccountConsent}
        primaryAccount={bankDetails}
      />
      {loading || plaidRelinkLoading ? <BTCLoader /> : null}
    </StyledWrapper>
  );
});
