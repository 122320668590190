import { StyledProgressAmount, StyledProgressBarWrapper, BorderLinearProgress } from "./styles";

type Props = {
  progress: number;
  showPercent?: boolean;
  width?: string;
  height?: string;
};
const ProgressBarLean = ({
  progress,
  showPercent = true,
  width = "207px",
  height = "8px",
}: Props) => {
  return (
    <StyledProgressBarWrapper>
      <BorderLinearProgress
        variant="determinate"
        value={progress}
        width={width}
        style={{ height }}
      />
      {showPercent ? (
        <StyledProgressAmount id="btc-progress-value">{`${progress}%`}</StyledProgressAmount>
      ) : null}
    </StyledProgressBarWrapper>
  );
};

export default ProgressBarLean;
