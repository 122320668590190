import styled from "styled-components";
import { device } from "../../../config/devices";

export const StyledLoanContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  @media ${device.laptop} {
    flex-direction: row;
    margin-top: 24px;
  }
  border-radius: 8px;
  border: solid 1px #e4e2ed;
  background: #fff;
  padding: 20px;
  margin-bottom: 16px;
  @media ${device.laptop} {
    padding: 24px;
  }
`;
export const StyledLoanDataContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-bottom: 16px;
  @media ${device.laptop} {
    padding-bottom: 0px;
  }
`;
export const StyledDescription = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #242323;
  margin-bottom: 16px;
`;
export const StyledLargeText = styled.p`
  font-weight: 500;
  font-family: "Poppins";
  font-size: 20px;
  color: #242323;
  line-height: 32px;
  @media ${device.tablet} {
    font-size: 18px;
    line-height: 24px;
  }
`;
export const StyledText = styled.p`
  font-weight: 400;
  font-family: "Poppins";
  font-size: 16px;
  color: #585866;
  line-height: 24px;
  @media ${device.tablet} {
    font-size: 14px;
  }
`;
