import { Button, styled as muiStyled } from "@mui/material";
import { device } from "config/devices";
import styled from "styled-components";

export const HeaderTitle = styled.h3`
  color: #000;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;

  font-size: 16px;
  line-height: 24px; /* 150% */
  width: 100%;
  text-align: center;
  @media ${device.tablet} {
    text-align: left;
    font-size: 22px;
    line-height: 32px; /* 150% */
  }
`;

export const BankAccountPrimary = styled.p`
  color: var(--text-icon-secondary, var(--text-icon-text-secondary, #585866));
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  margin-bottom: 4px;
`;

export const BankAccountBackup = styled.p`
  color: var(--text-icon-secondary, var(--text-icon-text-secondary, #585866));
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  margin-bottom: 4px;
  padding-top: 16px;
`;

export const Content = styled.div`
  margin: 0 16px;
  display: flex;
  flex-direction: column;
`;

export const TextInfo = styled.p`
  color: var(--text-icon-secondary, var(--text-icon-text-secondary, #585866));
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 48px;
  line-height: 20px; /* 153.846% */
`;

// muiStyled
export const LinkNewBankButton = muiStyled(Button)`
  display: flex;
  flex: 1;
  padding: 16px;
  border-radius: var(--Radius-small-radius, 4px);
  border: 1px dashed var(--text-icon-text-primary, #242323);
  color: var(--text-icon-text-primary, #242323);
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-transform: none;
  margin: 8px 0;
`;

export const LinkNewBankButtonLoading = styled.img`
  position: absolute;
`;
