import { action, computed, observable } from "mobx";
import { ITransfersInfoStore } from "./types";
import type { IAchTransfers, IPayment } from "services/api/types";
import { apiService } from "services/api";
import moment from "moment";
import bankDetailsStore from "stores/bankDetails";

class TransfersInfoStore implements ITransfersInfoStore {
  @observable private _transfers?: IAchTransfers;
  @observable private _payments: IPayment[] = [];

  @computed
  public get transfers() {
    return this._transfers;
  }

  @computed
  public get payments() {
    return this._payments;
  }

  @computed
  public get latestCreditTransfer() {
    return this._transfers?.data.find((transaction) => transaction.type === "credit");
  }

  @computed
  public get settledTransfers() {
    return this._transfers?.data.filter((transfer) => transfer.status === "settled") || [];
  }

  @computed
  public get latestDebitTransfer() {
    return this._transfers?.data.find((transaction) => transaction.type === "debit");
  }

  @computed
  public get latestTransfer() {
    return this._transfers?.data[0];
  }

  @computed
  public get isCreationFailedNSF() {
    return (
      transfersInfoStore.latestDebitTransfer?.status === "creation_failed" &&
      transfersInfoStore.latestDebitTransfer?.internalErrorMessage.includes("insufficient funds")
    );
  }

  @computed
  public get isReturnedNSF() {
    return (
      transfersInfoStore.latestDebitTransfer?.status === "returned" &&
      (transfersInfoStore.latestDebitTransfer?.providerErrorCode === "R01" ||
        transfersInfoStore.latestDebitTransfer?.providerErrorCode === "R09")
    );
  }

  @computed
  public get isReturnedExcludingNSF() {
    return (
      transfersInfoStore.latestDebitTransfer?.status === "returned" &&
      transfersInfoStore.latestDebitTransfer?.providerErrorCode !== "R01" &&
      transfersInfoStore.latestDebitTransfer?.providerErrorCode !== "R09"
    );
  }

  @computed
  public get shouldLinkDifferentPlaidAccount() {
    return (
      (transfersInfoStore.latestCreditTransfer?.status === "creation_failed" ||
        transfersInfoStore.latestCreditTransfer?.status === "returned") &&
      moment(transfersInfoStore.latestTransfer?.updatedAt).isAfter(
        bankDetailsStore.bankDetails?.createdAt,
      )
    );
  }

  @action
  private setUserTransfersInfo = (transfers: IAchTransfers) => {
    this._transfers = transfers;
  };

  @action
  private setPayments = (payments: IPayment[]) => {
    this._payments = payments;
  };

  public getAchTransfersList = async (bankIntegrationId: number | null | undefined) => {
    if (bankIntegrationId) {
      try {
        const transfersInfo = await apiService.getAchTransfersList();
        this.setUserTransfersInfo(transfersInfo);
      } catch (error) {}
    }
  };

  public getPayments = async () => {
      try {
        const paymentList = await apiService.getPayments();
        this.setPayments(paymentList.data);
      } catch (error) {}
  };
}

const transfersInfoStore: ITransfersInfoStore = new TransfersInfoStore();

export default transfersInfoStore;
