import { useTranslation } from "react-i18next";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";

import {
  StyledBodyText2,
  StyledBodyTitleText,
  StyledBTCSBackButton,
  StyledBTCSelectError,
  StyledBTCTextField,
  StyledBTCTextFieldWrapper,
  StyledBTCSelector,
  StyledBTCSelectorWrapper,
  StyledSelectorItem,
  StyledSelectorLabel,
  StyledCheckbox,
} from "../../styles";
import {
  StyledCheckboxError,
  StyledMilitaryCheckBoxWrapper,
  StylesCheckboxMilitary,
} from "../../Step7/styles";
import { StyledButtonsWrapper, StyledCSBody, StyledSubmitButton } from "../styles";

import { TEmploymentStatus } from "services/api/types";
import { useStores } from "stores";
import { GENERAL_LONG_TEXT_LIMIT, GENERAL_TEXT_LIMIT } from "../../Constants";
import { gtmService } from "services/gtm";
import { btcCheckUuid, btcErrorHandler } from "utils/btcErrorHandler";

import BtcErrorBox from "../../Components/ErrorBox";
import { PayStubsComponent } from "../../Components/PayStubs";
import { observer } from "mobx-react";
import { BTCLoader } from "pages/Btc/Components/Loader";
import { useEffect } from "react";
import { EVENTS, useLeavePageAnalytics } from "services/analytics/events";
import { LOCAL_STORAGE_KEYS, LocalStorage } from "utils/localStorage";

const EMPLOYMENT_STATUS_KEYS: TEmploymentStatus[] = [
  "employed_w2",
  "employed_1099",
  "self_employed",
  "unemployed",
  "homemaker",
  "retired",
  "active_military_status",
  "unable_to_work",
];

const SHOW_EXTRA_FIELDS = ["employed_w2", "employed_1099", "self_employed"];
const MILITARY_CHECKBOX_MANDATORY = ["active_military_status"];

const pageTranslationPrefix = "page_CreditStore";

type FormValues = {
  employmentStatus: TEmploymentStatus;
  companyName: string;
  jobTitle: string;
  isActiveMilitary: boolean;
  payStub1: any;
  optionalPayStub: any;
};
type Props = {
  setPayrollAccountFlow: (v: boolean) => void;
};
export const MoreDetails = observer(({ setPayrollAccountFlow }: Props) => {
  const { t } = useTranslation();
  const location = useLocation();
  const {
    btcStore: {
      errorBox,
      changePayStubModalVisibility,
      employmentsLinkProviderError,
      payrollAccountFinished,
      btcLoading,
      updateBtcApplication,
      uploadBtcFiles,
    },
  } = useStores();

  useLeavePageAnalytics((eventType) => {
    const data = getValues();
    EVENTS.btc.step5.leavePage({ action: eventType, ...data });
  });

  useEffect(() => {
    EVENTS.btc.step5.onload();
  }, []);

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm<FormValues>({ mode: "onBlur" });

  const userUuid = LocalStorage.getItem(LOCAL_STORAGE_KEYS.uuid);

  const handleBackClick = () => {
    const data = getValues();
    EVENTS.btc.step5.leavePage({ action: "Back", ...data });
    if (!employmentsLinkProviderError && !payrollAccountFinished) {
      setPayrollAccountFlow(true);
    } else {
      navigate(`/cash-advance/apply-info${location.search}`);
    }
  };

  const employeeStatus = watch<any>("employmentStatus", false);
  const optionalPayStub = watch("optionalPayStub", false);
  const isActiveMilitary = watch("isActiveMilitary", false);

  const showExtraFields = SHOW_EXTRA_FIELDS.includes(employeeStatus);
  const militaryCheckbox = MILITARY_CHECKBOX_MANDATORY.includes(employeeStatus);

  let navigate = useNavigate();

  const companyNamePlaceholder = t(`${pageTranslationPrefix}.typeCompanyName`);
  const jobTitlePlaceholder = t(`${pageTranslationPrefix}.typeJobTitle`);

  const verify = () => {
    if (optionalPayStub.length === 0) {
      changePayStubModalVisibility(true);
    } else {
      onSubmit();
    }
  };

  const onSubmit = async () => {
    btcCheckUuid();
    const data = getValues();
    if (userUuid !== null) {
      try {
        await uploadBtcFiles({
          uuid: userUuid,
          payStubOne: data.payStub1[0],
          ptoBalanceDoc: data.optionalPayStub[0],
        });

        await updateBtcApplication({
          uuid: userUuid!,
          employmentStatus: data.employmentStatus,
          isActiveMilitary: data.isActiveMilitary ? "Yes" : "No",
          companyName: showExtraFields ? data.companyName?.trim() : undefined,
          jobTitle: showExtraFields ? data.jobTitle?.trim() : undefined,
          timestampStepFour: moment().toISOString(),
          lastSubmittedStep: 5,
        });
        EVENTS.btc.step5.submit(data, true);
        LocalStorage.setItem(LOCAL_STORAGE_KEYS.lastSubmittedStep, "6");
        navigate(`/cash-advance/security-social-number${location.search}`);
        gtmService.customEvent({
          event: "step5Submission",
          employment_status: data.employmentStatus,
          uuid: LocalStorage.getItem(LOCAL_STORAGE_KEYS.uuid),
        });
      } catch (error: any) {
        btcErrorHandler(error, "step5");
        EVENTS.btc.step5.submit({ ...data, failReason: error.message }, false);
      }
    }
  };

  return (
    <StyledCSBody>
      <StyledBodyTitleText>{t(`${pageTranslationPrefix}.someMoreDetails`)}</StyledBodyTitleText>
      <StyledBodyText2 sx={{ marginBottom: 0 }}>
        {t(`${pageTranslationPrefix}.theseDetails`)}
      </StyledBodyText2>
      {errorBox && <BtcErrorBox />}

      <form onSubmit={handleSubmit(verify)}>
        {/* ######### Employment status Selector ######### */}
        <StyledBTCSelectorWrapper>
          <StyledSelectorLabel required={true}>
            {t(`${pageTranslationPrefix}.employmentStatusSelector.description`)}
          </StyledSelectorLabel>
          <StyledBTCSelector
            SelectDisplayProps={{ style: { fontFamily: "Poppins", fontSize: 15 } }}
            error={!!errors["employmentStatus"]}
            MenuProps={{ PaperProps: { sx: { maxHeight: 400, top: 380 } } }}
            sx={{
              "& .MuiSelect-select .notranslate::after": "Choose an option"
                ? {
                    content: `"Choose an option"`,
                    opacity: 0.42,
                  }
                : {},
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                boxShadow: "0 0 0 1px #7a56fc, 0 0 0 3px #e2daff",
                border: "none",
                borderRadius: "4px",
              },
              "& .MuiInputBase-input": {
                color: "#242323",
                fontSize: 15,
                fontFamily: "Poppins",
              },
            }}
            {...register("employmentStatus", { required: true })}
            id="employment-status-selector">
            {EMPLOYMENT_STATUS_KEYS.map((item: string) => (
              <StyledSelectorItem value={item} key={item} id={item}>
                {t(`${pageTranslationPrefix}.employmentStatusSelector.${item}`)}
              </StyledSelectorItem>
            ))}
          </StyledBTCSelector>
          <StyledBTCSelectError error={!!errors["employmentStatus"]}>
            {t(`${pageTranslationPrefix}.errorSelect`)}
          </StyledBTCSelectError>
        </StyledBTCSelectorWrapper>

        {/* ######### Active military checkbox ######### */}
        <StyledMilitaryCheckBoxWrapper>
          <StylesCheckboxMilitary>
            <StyledCheckbox
              type="checkbox"
              id="military-checkbox"
              {...register("isActiveMilitary", { required: militaryCheckbox })}
              error={!!errors["isActiveMilitary"]}
            />
            {t(`${pageTranslationPrefix}.InActiveMilitary`)}
          </StylesCheckboxMilitary>
          {militaryCheckbox && !isActiveMilitary && (
            <StyledCheckboxError>{t(`${pageTranslationPrefix}.errorCheckbox`)}</StyledCheckboxError>
          )}
        </StyledMilitaryCheckBoxWrapper>

        {/* ######### Company name text field ######### */}
        {showExtraFields && (
          <StyledBTCTextFieldWrapper>
            <StyledSelectorLabel required>
              {t(`${pageTranslationPrefix}.yourCompanyName`)}
            </StyledSelectorLabel>
            <StyledBTCTextField
              id="company-name"
              error={!!errors["companyName"]}
              placeholder={companyNamePlaceholder}
              {...register("companyName", { required: true, maxLength: GENERAL_LONG_TEXT_LIMIT })}
              helperText={
                errors["companyName"] &&
                t(`${pageTranslationPrefix}.errorCompanyName.${errors["companyName"].type}`)
              }
            />
          </StyledBTCTextFieldWrapper>
        )}

        {/* ######### Job title text field ######### */}
        {showExtraFields && (
          <StyledBTCTextFieldWrapper>
            <StyledSelectorLabel required>
              {t(`${pageTranslationPrefix}.jobTitle`)}
            </StyledSelectorLabel>
            <StyledBTCTextField
              id="job-title"
              error={!!errors["jobTitle"]}
              placeholder={jobTitlePlaceholder}
              {...register("jobTitle", { required: true, maxLength: GENERAL_TEXT_LIMIT })}
              helperText={
                errors["jobTitle"] &&
                t(`${pageTranslationPrefix}.errorJobTitle.${errors["jobTitle"].type}`)
              }
            />
          </StyledBTCTextFieldWrapper>
        )}

        <PayStubsComponent register={register} errors={errors} onSubmit={onSubmit} />

        <StyledButtonsWrapper>
          <StyledSubmitButton type="submit" id={"credit-score-submit"}>
            {t(`${pageTranslationPrefix}.submitButton`)}
          </StyledSubmitButton>

          <StyledBTCSBackButton
            onClick={handleBackClick}
            startIcon={<ArrowBackIcon />}
            id="step-5-go-back">
            {t(`${pageTranslationPrefix}.back`)}
          </StyledBTCSBackButton>
        </StyledButtonsWrapper>
      </form>

      {btcLoading ? <BTCLoader /> : null}
    </StyledCSBody>
  );
});
