import { IContractsStore } from "./types";
import { action, observable } from "mobx";
import { apiService } from "services/api";
import * as types from "services/api/types";
import routerStore from "stores/router";
import userStore from "stores/user";
class ContractsStore implements IContractsStore {
  @observable public contractStatus: types.UserContractStatusEnum | undefined = undefined;
  @observable public unapprovedContracts: Record<types.ContractTypeEnum, boolean> = {
    [types.ContractTypeEnum.privacy_policy]: false,
    [types.ContractTypeEnum.sorbets_terms_of_service]: false,
    [types.ContractTypeEnum.auto_pay_agreement]: false,
    [types.ContractTypeEnum.one_time_agreement]: false,
    [types.ContractTypeEnum.one_time_off_cycle_agreement]: false,
    [types.ContractTypeEnum.e_sign_consent]: false,
    [types.ContractTypeEnum.loan_agreement]: false,
    [types.ContractTypeEnum.loan_amended_agreement]: false,
    [types.ContractTypeEnum.backup_payment_method_agreement]: false,
    [types.ContractTypeEnum.loan_agreement_lead_bank]: false,
  };

  @action
  private setUnapprovedContracts(unapprovedContracts: types.TContractTemplatesInfo) {
    unapprovedContracts.contractTemplateSignedVersionsInfo.forEach((contract) => {
      const contractType = Object.keys(contract)[0] as types.ContractTypeEnum;
      this.unapprovedContracts = {
        ...this.unapprovedContracts,
        [contractType]: contract[contractType].shouldSignNewVersion,
      };
    });
  }

  @action
  public setContractStatus(status?: types.UserContractStatusEnum) {
    if (status) {
      this.contractStatus = status;
    } else {
      userStore.logOut();
    }
  }

  public signContractAgreement = async (data: types.TSignContractBody, id: number) => {
    const res = await apiService.signContract(data, id);
    //if user signed a contract, update his routing scope since now he is a "user'
    if (res?.executionStatus === "signed" || res?.executionStatus === "rejected") {
      userStore.setEntityType(types.UserEntityTypeEnum.user);
      routerStore.setRouterScope(types.UserEntityTypeEnum.user, {
        b2cContractStatus: types.UserContractStatusEnum.Executed,
      });
    }
    return res;
  };

  public getUnapprovedContracts = async () => {
    try {
      const unapprovedContractsInfo = await apiService.checkUnsignedContract();
      this.setUnapprovedContracts(unapprovedContractsInfo);
    } catch (error) {}
  };

  public clearStore = () => {
    this.contractStatus = undefined;
  };
}
const contractsStore = new ContractsStore();
export default contractsStore;
