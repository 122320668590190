import { useState } from "react";
import UploadFileButton from "../UploadFileButton/UploadFileButton";
import {
  StylesOptionalUploadFileSubtitle,
  StylesOptionalUploadFileTitle,
} from "pages/Btc/Step7/styles";
import { useTranslation } from "react-i18next";
import { UseFormRegister } from "react-hook-form";
import PayStubsModal from "pages/Btc/Step7/Modal";
import { observer } from "mobx-react";
import { useStores } from "stores";
import {
  TipContainer,
  TipIcon,
  TipItem,
  TipText,
} from "pages/Btc/ApplicationStatus/components/NMIUploads/Modals/styles";
import lightBulbIcon from "../../ApplicationStatus/components/NMIUploads/Modals/lightBulbIcon.svg";
import { EVENTS } from "services/analytics/events";

const pageTranslationPrefix = "page_PayStubs";
type PayStubsProps = {
  register: UseFormRegister<any>;
  errors: {
    payStub1?: any;
    optionalPayStub?: any;
  };
  onSubmit: () => void;
};
export const PayStubsComponent = observer(({ register, errors, onSubmit }: PayStubsProps) => {
  const { t } = useTranslation();
  const [secondPayStubUploaded, setSecondPayStubUploaded] = useState<boolean>(false);
  const {
    btcStore: { changePayStubModalVisibility, showPayStubModal },
  } = useStores();

  const onOptionalPayStubUploadSuccess = () => {
    setSecondPayStubUploaded(true);
  };

  const submit = () => {
    changePayStubModalVisibility(false);
    onSubmit();
  };

  const payStubOnClick = () => {
    EVENTS.btc.step5.attemptUploadPaystab();
  };
  return (
    <div style={{ marginTop: "24px" }}>
      <UploadFileButton
        error={!!errors["payStub1"]}
        errorMessage={
          errors["payStub1"] &&
          t(`${pageTranslationPrefix}.payStubError.${errors["payStub1"].type}`)!
        }
        successMessage={t(`${pageTranslationPrefix}.uploadedSuccessfully`)}
        title={t(`${pageTranslationPrefix}.payStub1`)!}
        required
        register={register}
        buttonText={t(`${pageTranslationPrefix}.uploadFile`)}
        name="payStub1"
        id={"pay-stub-1"}
        onSuccess={EVENTS.btc.step5.payStubSuccess}
        onClick={payStubOnClick}
      />

      <TipContainer>
        <TipItem>
          <TipIcon src={lightBulbIcon} />
          <TipText>{t("application_status.nmiUploads.pleaseMakeSure")}</TipText>
        </TipItem>
      </TipContainer>

      {/* ######### Optional pay stub ######### */}
      <StylesOptionalUploadFileTitle>
        {t(`${pageTranslationPrefix}.PTONotOnYourpayStubs`)}
      </StylesOptionalUploadFileTitle>
      <StylesOptionalUploadFileSubtitle>
        {t(`${pageTranslationPrefix}.uploadSeparateScreenshot`)}
      </StylesOptionalUploadFileSubtitle>
      <UploadFileButton
        error={!!errors["optionalPayStub"]}
        errorMessage={
          errors["optionalPayStub"] &&
          t(`${pageTranslationPrefix}.payStubError.${errors["optionalPayStub"].type}`)!
        }
        successMessage={t(`${pageTranslationPrefix}.uploadedSuccessfully`)}
        register={register}
        buttonText={t(`${pageTranslationPrefix}.uploadFile`)}
        name="optionalPayStub"
        id={"pay-stub-optional"}
        onSuccess={onOptionalPayStubUploadSuccess}
      />

      <PayStubsModal
        open={!secondPayStubUploaded && showPayStubModal}
        onClose={() => changePayStubModalVisibility(false)}
        onSubmit={submit}
      />
    </div>
  );
});
