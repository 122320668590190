import { useTranslation } from "react-i18next";
import {
  StyledConsentErrorImg,
  StyledLargeText,
  StyledText,
  StyledConsentErrorContainer,
} from "../../OnCyclePaymentInfo/style";
import { COMPANY_EMAILS } from "config/constants";
import errorIcon from "./DisbursementErrorIcon.svg";

const translation = "achDebitAuth";

export const DisbursementError = () => {
  const { t } = useTranslation();

  return (
    <StyledConsentErrorContainer>
      <StyledConsentErrorImg src={errorIcon} />
      <StyledLargeText>{t(`${translation}.somethingWentWrong`)}</StyledLargeText>
      <StyledText>
        {t(`${translation}.thereWasAnIssue`, { supportEmail: COMPANY_EMAILS.SUPPORT })}
      </StyledText>
    </StyledConsentErrorContainer>
  );
};
