import { device } from "config/devices";
import styled from "styled-components";

export const StyledWrapper = styled.div`
    border-radius: var(--Radius-medium-radius, 8px);
    border: 1px solid var(--Surface-surface-action-disabled, #E4E2ED);
    background: var(--Surface-surface-primary, #FFF);
    display: flex;
    flex-direction: row;
    padding: 20px 24px 20px var(--Radius-large-radius, 16px);
    align-items: flex-start;
    gap: var(--Radius-medium-radius, 8px);
    align-self: stretch;
`;

export const OptionContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    gap: var(--Radius-medium-radius, 8px);
`;

export const Title = styled.div`
    color: var(--text-icon-dark, #242323);
    /* Mobile Web/Page Title */
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px; /* 160% */
`;

export const StyledLogo = styled.img`

`;

export const StyledText = styled.div`
    color: var(--text-icon-text-secondary, #585866);
    font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 153.846% */
`;
export const TopSkipHeader = styled.div`
    display: flex;
    justify-content: flex-end;
    display: flex;
    height: 56px;
    padding: 0px 16px 0px 24px;
    justify-content: flex-end;
    align-items: center;
    flex-shrink: 0;
`;

export const StyledCloseIcon = styled.img`
    width: 24px;
    height: 24px;
`;

export const StyledPayStubWrapper = styled.div`
    padding-right: 24px;
    padding-left: 24px;
    flex: 1;
    display: flex;
    flex-direction: column;
`;

export const Description = styled.div`
    color: var(--text-icon-text-secondary, #585866);
    
    /* Mobile Web/Body */
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
      :last-child {
    margin-bottom: 20px;
  }
`;



export const Label = styled.div`
    background-color: #DBF9E8;
    padding: 4px 8px;
    border-radius: 4px;
    color: var(--text-icon-text-primary, #242323);
    
    margin-bottom: 24px;
    
    /* Text Tiny/Regular */
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
`;

export const StyledTimeStampText = styled.div`
color: var(--text-icon-text-primary, #242323);

/* Mobile Web/Body */
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 400;
margin-left: 24px;
line-height: 24px; /* 150% */
`;

export const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
     @media ${device.tablet} {
     justify-content: flex-start;
  }
  
`;

export const StyledSmallNotification = styled.div`
    color: var(--text-icon-text-primary, #242323);
    
    /* Mobile Web/Body */
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
`;

export const StyledSmallRedNotification = styled.div`
    color: var(--text-icon-text-link, #FF5277);
    font-family: Poppins;
    font-size: 15px;
    cursor: pointer;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 160% */
    text-decoration-line: underline;
`;

export const NoticeWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: flex-start;
  flex-direction: column;
  gap: 8px;
  justify-content: flex-end;
   @media ${device.tablet} {
     justify-content: flex-start;
  }
`;

export const StyledNoticeInnerWrapper = styled.div`
    width: 100%;
    display: flex;
   @media ${device.tablet} {
    margin-top: auto;
    display: flex;
    flex-direction: row;
    gap: 8px;
  }
      flex-direction: column;
      align-items: center;
`;
