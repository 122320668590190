import styled from "styled-components";
import {device} from "../../../../config/devices";

export const StyledProgressBarWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 20px 0;
  @media ${device.laptop} {
    padding: 0 20px 42px;
  }
`;

export const StyledProgressAmount = styled.div`
  font-weight: 600;
  font-size: 12px;
  color: #000624;
`;
