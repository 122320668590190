import { btcEvents } from "./btc";
import {mainEvents} from "./main";
import { ptoCenterEvents } from "./ptoCenter";

export * from "./utils";
export const EVENTS = {
  btc: btcEvents,
  main: mainEvents,
  ptoCenter: ptoCenterEvents,
};
