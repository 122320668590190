import { action, computed, observable } from "mobx";
import { IRouterStore } from "./types";
import { ROUTES } from "config/constants";
import { UserContractStatusEnum, UserEntityTypeEnum } from "services/api/types";
import { LOCAL_STORAGE_KEYS, LocalStorage } from "utils/localStorage";
import {errorLogger} from "../../services/logger";

class RouterStore implements IRouterStore {
  @observable private _routeScope?: ROUTES[] = [ROUTES.LOGIN, ROUTES.EMAIL_LINK_LOGIN];
  @observable redirectAfterLogin?: ROUTES;

  @computed public get routeScope() {
    return this._routeScope;
  }

  @action setRouterScope = (
    entityType: UserEntityTypeEnum,
    data?: { b2cContractStatus?: UserContractStatusEnum },
  ) => {
    if (entityType === UserEntityTypeEnum.lead) {
      this._routeScope = [
        ROUTES.APPLICATION_STATUS,
        ROUTES.LOGIN,
        ROUTES.EMAIL_LINK_LOGIN,
        ROUTES.PAYROLL,
      ];
      return;
    }
    if (entityType === UserEntityTypeEnum.user) {
      switch (data?.b2cContractStatus) {
        case UserContractStatusEnum.Pending:
        case UserContractStatusEnum.NotIssued:
          this._routeScope = [
            ROUTES.SIGN_CONTRACT,
            ROUTES.GENERAL_ERROR,
            ROUTES.LOGIN,
            ROUTES.EMAIL_LINK_LOGIN,
          ];
          return;

        case UserContractStatusEnum.Executed:
        case UserContractStatusEnum.Declined:
          this._routeScope = [
            ROUTES.HOME,
            ROUTES.PAYMENT_HISTORY,
            ROUTES.PTO_CENTER,
            ROUTES.PAYROLL_FROM_CENTER,
            ROUTES.PAYSTUB_FROM_CENTER,
            ROUTES.PAYROLL,
            ROUTES.UPLOAD_PAYSTUB,
            ROUTES.MANAGE_LINKED_ACCOUNTS,
            ROUTES.ISSUES_CENTER,
            ROUTES.PRIVACY_POLICY,
            ROUTES.TERMS_OF_SERVICE,
            ROUTES.E_SIGN,
            ROUTES.GENERAL_ERROR,
            ROUTES.LOGIN,
            ROUTES.EMAIL_LINK_LOGIN,
            ROUTES.BACKUP_PAYMENT_METHOD_AGREEMENT,
          ];
          return;
        default:
          break;
      }
    }
    this._routeScope = [ROUTES.LOGIN, ROUTES.EMAIL_LINK_LOGIN];
  };

  @action checkIfRedirectIsNeeded = () => {
    const pathname = window.location.pathname;
    [
      ROUTES.MANAGE_LINKED_ACCOUNTS,
      ROUTES.PAYMENT_HISTORY,
      ROUTES.PTO_CENTER,
      ROUTES.PAYROLL,
      ROUTES.UPLOAD_PAYSTUB,
      ROUTES.PAYSTUB_FROM_CENTER,
      ROUTES.PAYROLL_FROM_CENTER,
    ].forEach((route) => {
      if (pathname === route) {
        errorLogger.captureMessage(`Redirecting to url after login: ${route}`);
        LocalStorage.setItem(LOCAL_STORAGE_KEYS.redirectAfterLoginUrl, route);
        this.setRedirectAfterLogin(route);
      }
    });
  };

  @action setRedirectAfterLogin = (path?: ROUTES) => {
    this.redirectAfterLogin = path;
  };

  @action resetStore = () => {
    this._routeScope = [ROUTES.LOGIN, ROUTES.EMAIL_LINK_LOGIN];
    this.redirectAfterLogin = undefined;
  };
}

const routerStore = new RouterStore();

export default routerStore;
