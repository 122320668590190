import { observable, action, computed, autorun } from "mobx";
import { IIssueStore, Issue, IssueIdEnum } from "./types";
import debitConsentStore from "../debitConsent";
import bankDetailsStore from "../bankDetails";
import loanStore from "stores/loan";

const PRIORITY_MAP = {
  offCyclePaymentAuthorization: 1,
  primaryBankAccountUnAuthorized: 2,
  //unlinkedBankAccount: 3,
  backupBankAccountUnAuthorized: 3,
  //backupBankAccountUnlinked: 5,
  //setUpAutoPay: 6,
};
class IssuesStore implements IIssueStore {
  private issuesIsReady = false;
  @observable issues: Issue[] = [];
  @observable showPayrollIssue: boolean = true;
  @action resolveIssue = (issueId: IssueIdEnum) => {
    const index = this.issues.findIndex((issue) => issue.id === issueId);
    if (index !== -1) {
      this.issues[index].status = "resolved";
    }
  };

  observeIssues = () => {
    autorun(() => {
      if (debitConsentStore.isFirstConsentExist && debitConsentStore.isPrimaryConsentExist) {
        this.resolveIssue(IssueIdEnum.primaryBankAccountUnAuthorized);
      }
    });
    autorun(() => {
      if (bankDetailsStore.backupAccountsWithoutConsent.length <= 0) {
        this.resolveIssue(IssueIdEnum.backupBankAccountUnAuthorized);
      }
    });
    // autorun(() => {
    //   if (bankDetailsStore.bankDetails?.status !== BankAccountsStatusEnum.invalid) {
    //     this.resolveIssue(IssueIdEnum.unlinkedBankAccount);
    //   }
    // });
    autorun(() => {
      const { overdueTotalAmountCents } = loanStore;
      const hasOverdue = overdueTotalAmountCents > 0;
      if (!(hasOverdue && !debitConsentStore.isOffCycleConsentValid)) {
        this.resolveIssue(IssueIdEnum.offCyclePaymentAuthorization);
      }
    });
  };

  @action hidePayrollIssue = () => {
    this.showPayrollIssue = false;
  };

  @computed get unresolvedIssue() {
    return this.issues.filter((issue) => issue.status !== "resolved");
  }

  @computed get activeStep() {
    // Calculate the index of the first unresolved issue
    return this.issues.findIndex((issue) => issue.status !== "resolved");
  }

  // Fetch and populate the issues list from an API or other source
  @action prepareIssues = () => {
    if (!this.issuesIsReady) {
      const { overdueTotalAmountCents } = loanStore;
      const { isOffCycleConsentValid, isFirstConsentExist, isPrimaryConsentExist } =
        debitConsentStore;
      const { backupAccountsWithoutConsent } = bankDetailsStore;
      const issues = [];

      const hasOverdue = overdueTotalAmountCents > 0;

      // Check conditions and push new issues with priority from PRIORITY_MAP
      // if (bankDetails?.status === BankAccountsStatusEnum.invalid) {
      //   const issue = createIssueById(IssueIdEnum.unlinkedBankAccount, {
      //     invalidBankAccounts: invalidBankAccounts[0],
      //   });
      //   if (issue) {
      //     issues.push(issue);
      //   }
      // }

      if (isFirstConsentExist && !isPrimaryConsentExist) {
        const issue = createIssueById(IssueIdEnum.primaryBankAccountUnAuthorized);
        if (issue) {
          issues.push(issue);
        }
      }

      if (backupAccountsWithoutConsent.length > 0) {
        const issue = createIssueById(IssueIdEnum.backupBankAccountUnAuthorized);
        if (issue) {
          issues.push(issue);
        }
      }

      if (hasOverdue && !isOffCycleConsentValid) {
        const issue = createIssueById(IssueIdEnum.offCyclePaymentAuthorization);
        if (issue) {
          issues.push(issue);
        }
      }

      // Sort and update the observable
      this.issues = issues.sort((a, b) => a.priority - b.priority);
      this.observeIssues();
      this.issuesIsReady = true;
    }
  };
  @action clearStore = () => {
    this.issues = [];
    this.issuesIsReady = false;
    this.showPayrollIssue = true;
  };
}

const createIssueById = (id: IssueIdEnum, details?: object): Issue | undefined => {
  switch (id) {
    // case IssueIdEnum.unlinkedBankAccount:
    //   return {
    //     id: IssueIdEnum.unlinkedBankAccount,
    //     label: "issuesCenter.labels.relinkAccount",
    //     description: "issuesCenter.descriptions.relinkAccount",
    //     priority: PRIORITY_MAP.unlinkedBankAccount,
    //     status: "open",
    //     title: "issuesCenter.titles.relinkAccount",
    //     cta: "issuesCenter.cta.relinkAccount",
    //     details,
    //     ctaNow: "issuesCenter.ctaNow.relinkAccount",
    //   };
    case IssueIdEnum.primaryBankAccountUnAuthorized:
      return {
        id: IssueIdEnum.primaryBankAccountUnAuthorized,
        label: "issuesCenter.labels.authorizePrimaryAccount",
        description: "issuesCenter.descriptions.authorizePrimaryAccount",
        priority: PRIORITY_MAP.primaryBankAccountUnAuthorized,
        status: "open",
        title: "issuesCenter.titles.authorizePrimaryAccount",
        cta: "issuesCenter.cta.authorizePrimaryAccount",
        ctaNow: "issuesCenter.ctaNow.authorizePrimaryAccount",
      };
    case IssueIdEnum.backupBankAccountUnAuthorized:
      return {
        id: IssueIdEnum.backupBankAccountUnAuthorized,
        label: "issuesCenter.labels.authorizeBackupAccount",
        description: "issuesCenter.descriptions.authorizeBackupAccount",
        priority: PRIORITY_MAP.backupBankAccountUnAuthorized,
        status: "open",
        title: "issuesCenter.titles.authorizeBackupAccount",
        cta: "issuesCenter.cta.authorizeBackupAccount",
        ctaNow: "issuesCenter.ctaNow.authorizeBackupAccount",
      };
    case IssueIdEnum.offCyclePaymentAuthorization:
      return {
        id: IssueIdEnum.offCyclePaymentAuthorization,
        label: "issuesCenter.labels.payOverdue",
        description: "issuesCenter.descriptions.payOverdue",
        priority: PRIORITY_MAP.offCyclePaymentAuthorization,
        status: "open",
        title: "issuesCenter.titles.payOverdue",
        cta: "issuesCenter.cta.payOverdue",
        ctaNow: "issuesCenter.ctaNow.payOverdue",
      };

    default:
      break;
  }
};

const issuesStore = new IssuesStore();
export default issuesStore;
