import "react-perfect-scrollbar/dist/css/styles.css";
import { useTranslation } from "react-i18next";
import { useStores } from "../../../stores";
import { observer } from "mobx-react";
import {
  StyledDescText,
  StyledLargeText,
  StyledLoanContainer,
  StyledLoanDataContainer,
} from "./styles";
import { currencyFormatter } from "utils/formatters";

export const PendingOverdue = observer(() => {
  const { t } = useTranslation();
  const translation = "pendingOverdue";
  const {
    loanStore: { overdueTotalAmountCents, loanInfo },
    bankDetailsStore: { bankDetails },
  } = useStores();

  const OverdueTotalAmount = currencyFormatter(
    (overdueTotalAmountCents + (loanInfo?.feesAmountCents || 0)) / 100,
  );
  return (
    <StyledLoanContainer>
      <StyledLoanDataContainer>
        <StyledLargeText>
          {t(`${translation}.title`, { amount: OverdueTotalAmount })}
        </StyledLargeText>
        <StyledDescText>
          {t(`${translation}.description`, { bankAccountNumber: bankDetails?.bankAccountLast4 })}
        </StyledDescText>
      </StyledLoanDataContainer>
    </StyledLoanContainer>
  );
});

export default PendingOverdue;
