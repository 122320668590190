import { IconButton, Modal, styled as muiStyled } from "@mui/material";
import { device } from "config/devices";
import styled from "styled-components";

export const StyledModal = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  @media ${device.tablet} {
    align-items: center;
  }
`;

export const ModalContainer = styled.div`
  width: 100%;
  max-width: 600px;
  border-radius: 8px;
  background: #fff;
  position: relative;
  max-height: 100vh;
  overflow: auto;
`;

export const ModalContainerAutoPayWarning = styled.div`
  max-width: 764px;
  border-radius: 8px;
  background: #fff;
  position: relative;
  max-height: 100vh;
  overflow: auto;
`;

export const ModalContent = styled.div`
  padding: 40px 24px 32px 24px;
  @media ${device.tablet} {
    padding: 32px 40px;
  }
`;

export const Title = styled.h1`
  color: var(--text-icon-dark, #242323);
  /* H1 - Page Title */
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px; /* 142.857% */
  @media ${device.tablet} {
    font-size: 28px;
    line-height: 40px; /* 142.857% */
  }
`;
export const Description = styled.p`
  color: var(--text-icon-secondary, #585866);
  /* Text Medium/Regular */
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px; /* 171.429% */
  @media ${device.tablet} {
    font-size: 14px;
  }
`;

export const CloseIcon = muiStyled(IconButton)`
  position: absolute;
  top: 16px;
  right: 16px;
`;

export const ButtonsContainer = styled.div<{ separator?: boolean }>`
  padding: 0px 24px 24px 24px;
  display: flex;
  flex-direction: column-reverse;
  @media ${device.tablet} {
    flex-direction: row;
    align-items: center;
    padding: 24px 40px 32px 40px;
    box-shadow: 0px 1px 0px 0px #f0eef5 inset;
    justify-content: flex-end;
  }
`;

export const CheckboxContainer = styled.div`
  border-radius: var(--Radius-small-radius, 4px);
  border: 1px solid var(--Surface-surface-action-disabled, #e4e2ed);
  background: var(--Surface-surface-secondary, #f8f8fc);
  padding: 12px 16px;
  display: flex;
  align-items: center;
  margin-top: 32px;
`;

export const CheckBoxLabel = styled.label`
  color: var(--text-icon-secondary, var(--text-icon-text-secondary, #585866));
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
`;
