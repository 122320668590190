import { useTranslation } from "react-i18next";
import { Title, ButtonContainer } from "./styles";

import { PrimaryButton } from "pages/Btc/ApplicationStatus/components/NMIUploads/styles";
import { useStores } from "stores";
import { observer } from "mobx-react";
import PinwheelModal from "@pinwheel/react-modal";
import { useEffect, useRef, useState } from "react";
import { Loader } from "components";
import { apiService } from "../../../../services/api";
import moment from "moment/moment";
import { Item, ItemDescription, ItemTitle } from "../../../Btc/Step5/PayrollAccount/styles";
import secureSvg from "../../../Btc/Step5/PayrollAccount/icons/secure.svg";
import lockSvg from "../../../Btc/Step5/PayrollAccount/icons/lock.svg";
import {
  BackIcon,
  Header,
  HeaderTitle,
  PaddingWrapper,
  StyledWrapper,
  TopHeader,
  Wrapper,
} from "../../styles";
import { IconButton } from "@mui/material";
import backIcon from "../../icons/backIcon.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { EVENTS, useLeavePageAnalytics } from "services/analytics/events";
import { PayrollConnectionStatusEnum, UserEntityTypeEnum } from "services/api/types";
import { ENV, Environments } from "config/env";

export const ConnectToPayroll = observer(() => {
  const selectedPlatformName = useRef<string>("");
  const { t } = useTranslation();
  const location = useLocation();
  const [openWidget, setOpenWidget] = useState(false);
  const previousScreenName = useRef<string>();
  const previousScreenNameWithoutExit = useRef<string>();

  const {
    userStore: { entityType },
    leadStore: { setPayrollConnectedSuccessfully, leadInfo, leadUuid },
    btcStore: {
      getEmploymentsLinkProvider,
      employmentsLinkProvider,
      employmentsLinkProviderLoading,
    },
    appStore: { setIsHeaderHidden },
  } = useStores();
  useEffect(() => {
    const isRedirectAfterLogin = location.state?.isRedirectAfterLogin;
    const cameFrom = location.state?.cameFrom;
    const applicationStatus =
      entityType === UserEntityTypeEnum.user ? "Borrower" : leadInfo?.b2cApplicationStatus || "";
    EVENTS.ptoCenter.payroll.onload(
      isRedirectAfterLogin ? "Email Campaign" : cameFrom,
      applicationStatus,
    );
  }, []);

  useLeavePageAnalytics((eventType) => {
    EVENTS.ptoCenter.payroll.leavePage(eventType);
  });

  const verifyClick = () => {
    setOpenWidget(true);
    getEmploymentsLinkProvider(leadUuid);
  };

  const onSuccess = async () => {
    setPayrollConnectedSuccessfully(true);
    if (employmentsLinkProvider && employmentsLinkProvider.employment) {
      await apiService.updateEmploymentAccountInfo(
        ENV.REACT_APP_ENV === Environments.PRODUCTION
          ? { feConnectedTimestamp: moment().toISOString() }
          : {
              payrollConnectionStatus: PayrollConnectionStatusEnum.fe_completed,
              feConnectedTimestamp: moment().toISOString(),
            },
      );
    }
    EVENTS.ptoCenter.payroll.completePayroll(selectedPlatformName.current);
  };

  const onError = () => {};
  const onEvent = (name: string, payload: any) => {
    switch (name) {
      case "open":
        EVENTS.btc.payroll.onOpenWidget();
        break;
      case "select_employer":
        EVENTS.btc.payroll.selectPlatform(payload.selectedEmployerName, "employer");
        break;
      case "select_platform":
        EVENTS.btc.payroll.selectPlatform(payload.selectedPlatformName, "payroll");
        break;
      case "screen_transition":
        if (payload.screenName === "LOGIN") {
          EVENTS.btc.payroll.userEntersCredentials();
        }
        if (payload.screenName === "PROVIDER_CONFIRMATION") {
          EVENTS.btc.payroll.Provider_Confirmation();
        }
        if (payload.screenName === "SEARCH_RESULTS") {
          EVENTS.btc.payroll.searchingForPayroll();
        }
        if (payload.screenName === "SEARCH_DEFAULT") {
          EVENTS.btc.payroll.viewSelectPayrollProvider();
          if (!previousScreenName.current) {
            EVENTS.btc.payroll.continueToConnectionToPayroll("PTO Center payroll");
          }
        }
        previousScreenName.current = payload.screenName;
        if (payload.screenName !== "EXIT_CONFIRMATION") {
          previousScreenNameWithoutExit.current = payload.screenName;
        }
        break;
      default:
        break;
    }
  };
  const onExit = () => {
    if (previousScreenName.current === "EXIT_CONFIRMATION") {
      EVENTS.btc.payroll.userClosesPayrollWidget(previousScreenNameWithoutExit.current);
    }
    setOpenWidget(false);
    navigate(-1);
  };

  const navigate = useNavigate();
  const backClick = () => {
    navigate(-1); // Navigate back to the previous page
  };

  useEffect(() => {
    setIsHeaderHidden(true);
  }, []);

  const translationKey = "payroll";

  return (
    <StyledWrapper>
      <TopHeader />
      <Wrapper>
        <Header>
          <IconButton onClick={backClick}>
            <BackIcon src={backIcon} />
          </IconButton>
          <HeaderTitle>{t(`${translationKey}.title`)}</HeaderTitle>
        </Header>
        <PaddingWrapper>
          <Title>{t(`page_CreditStore.getYourEmploymentDetails`)}</Title>
          <StyledWrapper>
            <Item>
              <img src={secureSvg} alt={"secure"} />
              <div>
                <ItemTitle>{t(`page_CreditStore.secure`)}</ItemTitle>
                <ItemDescription>{t(`page_CreditStore.securelyConnectingPto`)}</ItemDescription>
              </div>
            </Item>

            <Item>
              <img src={lockSvg} alt={"lock"} />
              <div>
                <ItemTitle>{t(`page_CreditStore.fastEasy`)}</ItemTitle>
                <ItemDescription>{t(`page_CreditStore.connectingYourPTOData`)}</ItemDescription>
              </div>
            </Item>
          </StyledWrapper>
        </PaddingWrapper>
        <ButtonContainer>
          <PrimaryButton onClick={verifyClick}>
            {t("page_CreditStore.continueButton")}
          </PrimaryButton>
        </ButtonContainer>

        {employmentsLinkProvider?.publicToken ? (
          <PinwheelModal
            open={openWidget}
            linkToken={employmentsLinkProvider?.publicToken}
            onSuccess={onSuccess}
            onError={onError}
            onEvent={onEvent}
            onExit={onExit}
          />
        ) : null}

        {employmentsLinkProviderLoading ? <Loader /> : null}
      </Wrapper>
    </StyledWrapper>
  );
});
