import { AuthServices, AuthTypes, IAuthInstance, IAuthService, IAuthTokenData } from "./types";
import { authInstanceFactory } from "./instancesFactory";
import { ENV } from "config/env";
import { apiService } from "services/api";
import { errorLogger } from "../logger";
import { cypressUserLogin } from "utils/generateSorbetTokenSanity";
import { LOCAL_STORAGE_KEYS, LocalStorage } from "utils/localStorage";

class AuthService implements IAuthService {
  private get currentServiceName() {
    return LocalStorage.getItem(LOCAL_STORAGE_KEYS.authServiceName) as AuthServices;
  }

  private setCurrentServiceName = (name: AuthServices) => {
    LocalStorage.setItem(LOCAL_STORAGE_KEYS.authServiceName, name);
  };

  private get instanceAuthToken(): IAuthTokenData | null {
    const data = LocalStorage.getItem(LOCAL_STORAGE_KEYS.instanceAuthToken);
    if (data) {
      return JSON.parse(data) as IAuthTokenData;
    }
    return null;
  }

  private setInstanceAuthToken = (data: IAuthTokenData) => {
    LocalStorage.setItem(LOCAL_STORAGE_KEYS.instanceAuthToken, JSON.stringify(data));
  };

  private get authInstance() {
    const serviceName = this.currentServiceName;
    if (!serviceName) {
      return null;
    }
    return authInstanceFactory.getInstance(serviceName);
  }

  getAuthToken = async () => {
    const instanceAuthToken = this.instanceAuthToken;
    if (instanceAuthToken) {
      return instanceAuthToken;
    }

    if (!this.authInstance || !this.currentServiceName) {
      return null;
    }
    let authToken = await this.authInstance.getAuthToken();

    if (!authToken) {
      return null;
    }

    const isJwtAuthFlow = ENV.REACT_APP_USE_SORBET_JWT === "yes";

    let fetchedEntityId, fetchedEntityType;
    if (isJwtAuthFlow) {
      const cypress_test_email = LocalStorage.getItem(LOCAL_STORAGE_KEYS.cypress_test_email);

      const { sorbetAuthToken, entityId, entityType } = cypress_test_email
        ? await cypressUserLogin(cypress_test_email)
        : await apiService.signIn({
            authToken: authToken,
            authStrategy: this.currentServiceName,
          });
      authToken = sorbetAuthToken;
      fetchedEntityType = entityType;
      fetchedEntityId = entityId;
    }

    const serviceName = isJwtAuthFlow ? AuthServices.sorbet : this.currentServiceName;
    const tokenData = {
      authToken,
      serviceName,
      entityId: fetchedEntityId,
      entityType: fetchedEntityType,
    };
    this.setInstanceAuthToken(tokenData);
    return tokenData;
  };

  loadAuthState = async () => {
    if (!this.authInstance) {
      return null;
    }
    return this.authInstance.loadAuthState();
  };

  phoneNumberLogin = async (v: string) => {
    this.setCurrentServiceName(AuthServices.FIREBASE_RO);
    return (this.authInstance as IAuthInstance).phoneNumberLogin?.(v);
  };

  logInWith = (
    type: AuthTypes,
    email?: string,
    password?: string,
    serviceName: AuthServices = AuthServices.FIREBASE_RW,
    smsCode?: string,
  ) => {
    this.setCurrentServiceName(serviceName);
    return (this.authInstance as IAuthInstance).logInWith(type, email, password, smsCode);
  };

  logOut = async () => {
    const authInstance = this.authInstance;
    if (!authInstance) {
      return;
    }
    await authInstance.logOut();
    LocalStorage.clear();
    errorLogger.removeLoggerGlobalContext("user-id");
  };

  getPhotoUrl = async () => {
    return await this.authInstance?.getPhotoUrl();
  };
}

export const authService: IAuthService = new AuthService();
