import React, { Suspense } from "react";
import { Navigate } from "react-router-dom";
import { Loader } from "components/Loader";
import { ROUTES } from "config/constants";
import { useStores } from "stores";
import { observer } from "mobx-react";
import { errorLogger } from "../../services/logger";
import { UserContractStatusEnum, UserEntityTypeEnum } from "services/api/types";

function determineRedirectPath(
  path: string,
  userStatus: {
    isAuthenticated: boolean;
    contractStatus: UserContractStatusEnum | undefined;
    entityType: UserEntityTypeEnum | undefined;
  },
): string {
  // Redirect logic for leads
  if (
    userStatus.entityType === UserEntityTypeEnum.lead &&
    path !== ROUTES.APPLICATION_STATUS &&
    userStatus.isAuthenticated
  ) {
    switch (path) {
      case ROUTES.PAYROLL:
        return ROUTES.PAYROLL;
      default:
        return ROUTES.APPLICATION_STATUS;
    }
  }

  // Redirect logic for users
  if (path === ROUTES.LOGIN || path === ROUTES.EMAIL_LINK_LOGIN) {
    if (userStatus.isAuthenticated && userStatus.entityType === UserEntityTypeEnum.user) {
      switch (userStatus.contractStatus) {
        case UserContractStatusEnum.Pending:
        case UserContractStatusEnum.NotIssued:
          return ROUTES.SIGN_CONTRACT;

        case UserContractStatusEnum.Executed:
        case UserContractStatusEnum.Declined:
          return ROUTES.HOME;
        default:
          break;
      }
      //user has null or undefined userStatus, which is indicates a bug
      if (!userStatus?.contractStatus) {
        errorLogger.captureMessage(
          `user trying to login with corrupted b2cContractStatus: ${userStatus?.contractStatus}`,
        );
      }
    }
  }
  // Add other specific logic as needed
  return path; // Default case to stay on the current path
}

const Protected = observer(
  ({ children, path }: { children: React.ReactNode; path: string; isPrivate?: boolean }) => {
    const { userStore, appStore, routerStore, contractsStore } = useStores();
    const { routeScope } = routerStore;
    const { initState } = appStore;

    if (!initState) {
      return <Loader />;
    }

    if (initState === "error") {
      return <Navigate to={ROUTES.GENERAL_ERROR} />;
    }

    // Centralized redirection logic
    const redirectPath = determineRedirectPath(path, {
      isAuthenticated: userStore.isAuthenticated,
      contractStatus: contractsStore.contractStatus,
      entityType: userStore.entityType, // assuming this is how you determine the user type
    });

    if (redirectPath !== path) {
      return <Navigate to={redirectPath} replace />;
    }

    //Check if the current path is in the user's route scope
    if (!routeScope?.includes(path as ROUTES)) {
        return <Navigate to={ROUTES.LOGIN} replace />;
    }

    return <Suspense fallback={<Loader />}>{children}</Suspense>;
  },
);

export default Protected;
