import styled from "styled-components";
import { Box } from "@mui/material";

export const StyledPtoTip = styled(Box)`
  background-color: #f8f8fc;
  padding: 10px 16px;
  border-radius: 6px;
  max-width: 496px;
`;

export const StyledPtoTipTextBold = styled.span`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #242323;
`;

export const StyledPtoTipText = styled.span`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #242323;
`;

export const sxSelect = {
    "& .MuiSelect-select .notranslate::after": "Choose an option"
        ? {
            content: `"Choose an option"`,
            opacity: 0.42,
        }
        : {},
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        boxShadow: "0 0 0 1px #7a56fc, 0 0 0 3px #e2daff",
        border: "none",
        borderRadius: "4px",
    },
    "& .MuiInputBase-input": {
        color: "#242323",
        fontSize: 15,
        fontFamily: "Poppins",
    },
};
