import { Button, Menu, MenuItem, styled as muiStyled } from "@mui/material";
import styled from "styled-components";

export const Container = styled.div`
  border-radius: var(--Radius-small-radius, 4px);
  border: var(--Radius-zero-radius, 1px) solid var(--Surface-surface-action-disabled, #e4e2ed);
  background: var(--Surface-surface-secondary, #f8f8fc);
  display: flex;
  padding: var(--Radius-large-radius, 16px) 24px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

export const BankInfoContainer = styled.div`
  display: flex;
  align-items: center;
`;
export const BankInfo = styled.div`
  flex: 1;
`;
export const BankName = styled.p`
  color: var(--text-icon-dark, #242323);
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 153.846% */
`;
export const BankLastFour = styled.p`
  color: var(--text-icon-placeholder, #9486a5);
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 153.846% */
`;

export const SetUpAsBackup = styled.p`
  color: var(--text-icon-secondary, #5d5881);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
`;

export const RelinkButton = muiStyled(Button)`
  border-radius: 8px;
  background-color: #ff5277;
  color: #fff;
  text-transform: none;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 153.846% */
  padding: 6px 16px;

  :hover {
    background-color: #000;
  }
`;

export const StyledMenu = muiStyled(Menu)`
  & .MuiMenu-list {
    padding: 0;
  }
  & .MuiPaper-root {
    border-radius: 8px;
  }
`;
export const StyledMenuItem = muiStyled(MenuItem)`
  color: var(--text-icon-dark, #242323);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  padding: 8px 24px;
  :hover {
    background-color: #FFA8BB;
  }
`;

export const StyledMenuItemUnlink = muiStyled(MenuItem)`
  color: var(--text-icon-error, var(--Surface-surface-destructive, #e43));
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  padding: 8px 24px;
`;

export const ActionItemIcon = styled.img`
  margin-right: 8px;
`;

export const BankLogoContainer = styled.div`
  position: relative;
  margin-right: 16px;
`;

export const BankLogo = styled.img`
  width: 50px;
  height: 50px;
  padding: 4px;
`;

export const BankDefaultLogo = styled.img`
  width: 45px;
  height: 45px;
  padding: 8px;
`;

export const AlertIcon = styled.img`
  position: absolute;
  bottom: 0;
  right: 0;
`;
