import { Button, IconButton, styled as muiStyled } from "@mui/material";
import { ButtonBase } from "components/Buttons";
import { device } from "config/devices";
import styled from "styled-components";

export const StyledPrintButton = styled.button`
  margin-top: 24px;
  margin-bottom: 24px;
  width: fit-content;
  height: 56px;
  border-color: #ff5277;
  border-width: 1px;
  border-style: solid;
  color: #ff5277;
  border-radius: 8px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  padding-left: 16px;
  padding-right: 16px;
  justify-content: space-between;
  :hover {
    color: #000000;
    border-color: #000000;
  }
  @media ${device.tablet} {
    height: 40px;
    width: fit-content;
  }
`;

export const StyledSuccessTitle = styled.div`
  font-weight: 400;
  font-family: "Poppins";
  font-size: 24px;
  color: #242323;
  font-style: normal;
`;
export const StyledSuccessSubtitle = styled.div`
  margin-top: 16px;
  font-weight: 400;
  font-family: "Poppins";
  font-size: 14px;
  color: #585866;
  font-style: normal;
`;
export const StyledSubUnit = styled.div`
  padding: 32px 16px 16px 16px;
  @media ${device.tablet} {
    padding: 24px 40px 16px 40px;
  }
`;

export const PaymentConsentWrapper = styled.div<{ _fullScreen: boolean }>`
  display: flex;
  flex-direction: column;
  height: ${(p) => (p._fullScreen ? "100vh" : "auto")};
  padding-top: 24px;
`;

export const PaymentConsentContainer = styled.div<{ _fullScreen: boolean }>`
  ${(p) => (p._fullScreen ? `overflow: hidden; display: flex; flex: 1;` : "")}
`;
export const PaymentConsentHeader = styled.div`
  padding: 0 16px;
  @media ${device.tablet} {
    padding: 0px 40px 16px 40px;
  }
`;
export const StyledSuccessText = styled.div`
  font-weight: 400;
  font-family: "Poppins";
  font-size: 14px;
  color: #242323;
  font-style: normal;
`;

export const SuccessModalIcon = styled.img`
  width: 32px;
  height: 32px;
  @media ${device.tablet} {
    width: 112px;
    height: 112px;
  }
`;

export const StyledButtonsContainer = styled.div`
  padding: 16px;
  @media ${device.tablet} {
    padding: 24px 40px;
    display: flex;
    flex-flow: row-reverse;
    box-shadow: 0px 1px 0px 0px #f0eef5 inset;
  }
`;

export const StyledPayActionButton = styled(ButtonBase)<{ disabled?: boolean }>`
  border-radius: 8px;
  background: ${(props) => (props.disabled ? "#CFCCDD" : "#ff5277")};
  width: 100%;
  height: 56px;

  padding: 16px;
  margin-top: 16px;
  @media ${device.tablet} {
    margin-top: 0px;
    padding: 8px 32px;
    margin-right: 16px;
    width: fit-content;
    height: 40px;
  }

  p {
    text-align: center;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    color: #fff;
  }
`;

export const StyledDescription = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #242323;
  margin-bottom: 16px;
`;
export const StyledCancelButton = styled.div`
  border-radius: 8px;
  cursor: pointer;

  border: 1px #ff5277 solid;
  padding: 16px;
  margin-top: 16px;

  @media ${device.tablet} {
    border: 0px #ff5277 solid;
    margin-top: 0px;
    padding: 8px 32px;
    margin-right: 16px;
  }

  p {
    text-align: center;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    color: #ff5277;
  }
`;

export const StyledTitleNew = styled.h1`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #242323;
  @media ${device.tablet} {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 40px;
  }
`;
export const StyledTitle = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #242323;
  margin-top: 30px;

  @media ${device.tablet} {
    margin-top: 0px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 40px;
  }
`;

export const StyledConsentContainer = styled.div<{ _fullScreen: boolean }>`
  position: relative;
  display: flex;
  flex: 1;
  height: ${(p) => (p._fullScreen ? "auto" : "350px")};
  flex-direction: column;
  padding: 0 8px;
`;

export const StyledScroll = styled.div`
  display: flex;
  flex: 1;
  padding: 20px;
  background: #e4e2ed;
  overflow: auto;

  .react-pdf__Document {
    margin: 0 auto;
  }

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;
export const ExpandButtonContainer = styled.div`
  display: none;
  @media ${device.tablet} {
    flex: 1;
    display: flex;
  }
`;

export const ExpandButton = muiStyled(Button)`
  color: var(--text-icon-secondary, var(--text-icon-text-secondary, #585866));
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  border-radius: var(--Radius-medium-radius, 8px);
  border: 1px solid var(--text-icon-secondary, #585866);
  padding: 8px 24px;
  width: fit-content;
  height: 40px;
  text-transform: none;
`;

export const ZoomButtons = styled.div`
  position: absolute;
  bottom: 0;
  left: 12px;
  @media ${device.tablet} {
    display: none;
  }
`;

export const ZoomButton = muiStyled(IconButton)`
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  margin-bottom: 4px;

  border-radius: var(--Radius-medium-radius, 8px);
  border: 1px solid var(--text-icon-text-secondary, #585866);
  background: var(--Surface-surface-secondary, #f8f8fc);
`;

export const ProgressBarWrapper = styled.div`
  padding-left: 40px;
  padding-bottom: 24px;
`;
