import { css } from "styled-components";

export enum Breakpoints {
  XS = 480,
  SM = 576,
  MD = 768,
  LG = 992,
  XL = 1200,
  XXL = 1600,
}

export enum Colors {
  textColor = "#1c1259",
  greyText = "#b4b1c7",
  errorText = "#ff4d4f",
  textColorFaded = "rgba(28, 18, 89, 0.5)",
  textColorWhite = "#FFFFFF",
  lightBlue = "#F8F9FC",
  newGreyBorder = "#8884A3",
  lineBar = "#cfccdd",
  bottomBorderLine = "#e4e2ed",
  backgraund = "#f2f2f2",
  lineProgress = "#ff6485",
  progressBarText = "#8884A3",
  strangeProgressBarText = "#8884A2",
  darkIndigo = "#201472",
  inputFocusBorder = "#3ffbc0",
  darkBlue = "#1c184f",
  silver = "#f8f8fa",
  orange = "#ffae00",
  orange_rgb = "255, 174, 0",
  sand = "#f6a65a",
  rose = "#f04394",
  strangeRed = "#f43737",
  tangerine = "#ff9600",
  yellow = "#fcd307",
  red = "#ff6666",
  dangerRed = "#e02020",
  errorRed = "#FF0000",
  pink = "#FF6485",
  pink_rgb = "255, 82, 119",
  purple = "#ff63af",
  blue = "#332A6A",
  lightText = "#8580A5",
  darkText = " #242323",
  green = "#99e128",
  lightGreen = "#bee040",
  silverLine = "rgba(28, 18, 89, 0.1)",
  paleGrey = "#F4F3F7",
  paleBlue = "#EEF0F7",
  paleGreyTwo = "#eaecf5",
  blueGrey = "#9893ba",
  lightGrey = "#f8f8fc",
  lightBlueGray = "#d5daeb",
  anotherGrey = "#f0eef5",
  yellowBackground = "#ffbc00",
  textColorSecondary = "#77719b",
  textColorSecondary_rgb = "119, 113, 155",
  titleStripe = "rgba(255, 174, 0, 0.6)",
  textBoxFocused = "#ffbc00",
  weirdBlack = "#1C184F",
  black = "#000000",
}

export const GRADIENT_COLORS = [Colors.yellow, Colors.tangerine] as [Colors, Colors];

export const BUTTON_BASE_GRADIENT = ["#ff63af", "#ff6666"];

/**
 * Maximum allowed width of the content
 */

export const SCROLL_BAR_WIDTH = 4;

export const buttonActionStyle = css`
  cursor: pointer;
  transition: opacity 0.2s;

  &:hover {
    opacity: 0.7;
  }

  &:active {
    opacity: 0.5;
  }
`;

export const scrollBarStyle = css`
  &::-webkit-scrollbar {
    border: none;
    width: ${SCROLL_BAR_WIDTH}px;
    height: ${SCROLL_BAR_WIDTH}px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: ${Colors.textColor};
  }
`;
