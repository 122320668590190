import size, { device } from "config/devices";
import styled from "styled-components";

export const HeaderTitle = styled.h1`
  color: #242323;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;

  font-size: 16px;
  line-height: 24px;
  @media ${device.tablet} {
    font-size: 28px;
    line-height: 40px;
  }
`;
export const Header = styled.div`
  padding: 40px 0 32px 0;
  display: flex;
  align-items: center;
  flex: 1;
  margin: 0 24px;

  @media (max-width: ${size.tablet}) {
    margin: 0 0;
    margin-bottom: 40px;
    padding: 16px 24px;
    box-shadow: 0px -1px 0px 0px #f0eef5 inset;
  }
`;

export const Wrapper = styled.div`
  max-width: 702px;
  width: 100%;
  max-width: 702px;
`;

export const Content = styled.div`
  margin: 0 16px;

  @media ${device.tablet} {
    padding: 20px 24px 24px 24px;
    border-radius: 8px;
    border: 1px solid #e4e2ed;
  }
`;

export const BaseLabel = styled.p`
  color: #242323;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
`;

export const Text = styled.p`
  color: #585866;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;
export const StyledWrapper = styled.div`
  background: #ffffff;
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: column;
  padding-bottom: 24px;
`;

export const TopHeader = styled.div`
  @media ${device.tablet} {
    height: 56px;
    width: 100%;
    box-shadow: 0px -1px 0px 0px #f0eef5 inset;
  }
`;
