import { Box, IconButton, Paper, Step, StepContent, StepLabel, Stepper } from "@mui/material";
import { Header } from "../../../ManageLinkedAccounts/styles";
import backIcon from "../../../ManageLinkedAccounts/icons/backIcon.svg";
import { HeaderTitle } from "../../../ManageLinkedAccounts/additionalAccountFlow/styles";
import { Content, ContentHeader, StyledWrapper, Wrapper } from "./styles";
import { ROUTES } from "../../../../config/constants";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useStores } from "../../../../stores";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { ConsentActionStep } from "./StepActions/ConsentAction";
import { observer } from "mobx-react";

import OverDueBalanceStep from "./StepActions/OverdueAction";
import { Issue, IssueIdEnum } from "stores/issues/types";
import { Loader } from "components";

const theme = createTheme({
  components: {
    MuiStepIcon: {
      styleOverrides: {
        root: {
          color: "#C9C5DA",
          "&.Mui-active": {
            color: "#FF5277",
            marginBottom: "12px",
          },
          "&.Mui-completed": {
            color: "#8EC27B", // Completed step color
          },
        },
      },
    },
    MuiStepContent: {
      // Customizing StepContent
      styleOverrides: {
        root: {},
      },
    },
  },
});

const issueComponentMap: Record<
  IssueIdEnum,
  ({ issue, fromStepper }: { issue: Issue; fromStepper: boolean }) => JSX.Element | null
> = {
  //unlinkedBankAccount: RelinkActionStep,
  primaryBankAccountUnAuthorized: ConsentActionStep,
  backupBankAccountUnAuthorized: ConsentActionStep,
  offCyclePaymentAuthorization: OverDueBalanceStep,
  //backupBankAccountUnlinked: () => null,
  //setUpAutoPay: () => null,
  // Add more mappings as needed
};
const translationKey = "issuesCenter";

export const getIssueComponent = (step: Issue, fromStepper = true) => {
  const Component = issueComponentMap[step.id];
  return Component ? <Component issue={step} fromStepper={fromStepper} /> : null;
};

const IssueStepper = () => {
  const {
    appStore: { setIsHeaderHidden },
    loanStore: { loanInfo, overdueTotalAmountCents },
    issuesStore: { issues, activeStep },
    bankDetailsStore: { bankDetails, backupAccountsWithoutConsent, loading },
  } = useStores();

  const { t } = useTranslation();
  const navigate = useNavigate();
  const [localActiveStep, setLocalActiveStep] = useState(activeStep);

  useEffect(() => {
    setIsHeaderHidden(true);
  }, []);

  useEffect(() => {
    if (activeStep === -1) {
      navigate(ROUTES.HOME);
    }
  }, [activeStep]);

  const OverdueTotalAmount = (
    (overdueTotalAmountCents + (loanInfo?.feesAmountCents || 0)) /
    100
  ).toFixed(2);

  useEffect(() => {
    setLocalActiveStep(activeStep); // Sync with store on mount or when activeStep changes
  }, [activeStep]);

  const backClick = () => {
    navigate(ROUTES.HOME);
  };

  return (
    <StyledWrapper>
      <Wrapper>
        <Header>
          <IconButton onClick={backClick} id={"manage-linked-account-back"}>
            <img src={backIcon} alt={"back"} />
          </IconButton>
          <HeaderTitle>{t(`${translationKey}.title`)}</HeaderTitle>
          <Box sx={{ width: "40px" }} />
        </Header>
        <Content>
          <ContentHeader>{t(`${translationKey}.contentTitle`)}</ContentHeader>
          <ThemeProvider theme={theme}>
            <Stepper activeStep={localActiveStep} orientation="vertical">
              {issues.map((step) => (
                <Step key={step.id} completed={step.status === "resolved"}>
                  <StepLabel>
                    <Box
                      sx={{
                        marginLeft: "24px",
                        color: "#242323",
                        fontWeight: 400,
                        fontFamily: "Poppins",
                        fontSize: "16px",
                      }}>
                      {t(`${step.label}`)}
                    </Box>
                  </StepLabel>
                  <StepContent>
                    <Box
                      sx={{
                        marginLeft: "24px",
                        color: "#585866",
                        fontWeight: 400,
                        fontFamily: "Poppins",
                        fontSize: "16px",
                        marginBottom: "8px",
                      }}>
                      {t(`${step.description}`, {
                        bankNumber: bankDetails?.bankAccountLast4,
                        backupAccountNumber: backupAccountsWithoutConsent[0]?.bankAccountLast4,
                        overdueAmount: OverdueTotalAmount,
                      })}
                    </Box>
                    <Box sx={{ mb: 3, marginLeft: "24px" }}>
                      <div>{getIssueComponent(step, true)}</div>
                    </Box>
                  </StepContent>
                </Step>
              ))}
            </Stepper>
          </ThemeProvider>
          {localActiveStep === issues.length && (
            <Paper square elevation={0} sx={{ p: 3 }}>
              All steps completed - you&apos;re finished
            </Paper>
          )}
        </Content>
      </Wrapper>
      {loading ? <Loader /> : null}
    </StyledWrapper>
  );
};

export default observer(IssueStepper);
