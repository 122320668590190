import { errorLogger } from "../services/logger";
import { BTC_ERRORS } from "../pages/Btc/Constants";
import { stores } from "../stores";
import btcStore from "../stores/btc";
import { API_ERROR_CODES } from "../services/api/types";
import { LOCAL_STORAGE_KEYS, LocalStorage } from "./localStorage";

const boxErrors = [
  API_ERROR_CODES.LeadAlreadyExists,
  API_ERROR_CODES.UserAlreadyExists,
  API_ERROR_CODES.invalidEmail,
];

const pathsToIgnoreUUIDError = ["pto-cash-advance", "thank-you"];

export const btcCheckUuid = () => {
  btcStore.hideErrorBox();
  btcStore.hideErrorModal();
  const userUuid = LocalStorage.getItem(LOCAL_STORAGE_KEYS.uuid);
  if (
    (userUuid === null || userUuid === "") &&
    !pathsToIgnoreUUIDError.includes(window.location.href.toLowerCase())
  ) {
    btcStore.setErrorText(BTC_ERRORS.UUID_EXPECTED);
    btcStore.showErrorBox();
  } else {
    btcStore.hideErrorBox();
  }
};
export const btcErrorHandler = (error: any, step: string) => {
  console.error("btcErrorHandler: ", error);
  errorLogger.captureError(`B2C error in step ${step}: ${error}`, {
    lead_uuid: LocalStorage.getItem(LOCAL_STORAGE_KEYS.uuid),
    b2c_step: step,
    error_code: error.code,
    error_status: error.status,
  });

  if (boxErrors.includes(error.code)) {
    stores.btcStore.setErrorText(error.message);
    stores.btcStore.showErrorBox();
  } else {
    stores.btcStore.hideErrorBox();
    stores.btcStore.setErrorText(error.message);
    stores.btcStore.showErrorModal();
  }
};
