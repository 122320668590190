import { Card, CardActions, IconButton, Typography, styled } from "@mui/material";
import { SnackbarContent } from "notistack";
import { BaseVariant } from "notistack";

export const StyledSnackbarContent = styled(SnackbarContent)`
  @media (min-width: 600px) {
    min-width: 344px !important;
  }
`;
const getBackgroundColor = (variant: BaseVariant): string => {
  switch (variant) {
    case "error":
      return "#ee4433";
    case "info":
      return "#9486A5";

    case "success":
    default:
      return "#8ec27b";
  }
};
export const StyledCard = styled(Card)<{ notificationVariant: BaseVariant }>`
  width: 100%;
  background-color: ${(p) => getBackgroundColor(p.notificationVariant)};
`;

export const StyledTypography = styled(Typography)`
  color: #fff;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;

export const IconsContainer = styled("div")`
  margin-left: auto;
`;

export const StyledCardActions = styled(CardActions)`
  padding: 10px 8px 10px 24px;
  justify-content: space-between;
`;

export const StyledIconButton = styled(IconButton)`
  padding: 8px 8px;
  transform: rotate(0deg);
  color: #fff;
  transition: all 0.2s;
`;
