import { Modal } from "@mui/material";
import { device } from "config/devices";
import styled from "styled-components";

export const StyledModal = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  @media ${device.tablet} {
    align-items: center;
  }
`;

export const ModalContainer = styled.div`
  max-width: 600px;
  border-radius: 8px;
  background: #fff;
  position: relative;
  max-height: 100vh;
  overflow: auto;
`;

export const ModalContent = styled.div`
  padding: 40px 24px 32px 24px;
  @media ${device.tablet} {
    padding: 32px 40px;
  }
`;

export const Title = styled.h1`
  color: var(--text-icon-dark, #242323);
  /* H1 - Page Title */
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px; /* 142.857% */
  @media ${device.tablet} {
    font-size: 28px;
    line-height: 40px; /* 142.857% */
  }
`;
export const Description = styled.p`
  color: var(--text-icon-secondary, #585866);
  /* Text Medium/Regular */
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px; /* 171.429% */
  @media ${device.tablet} {
    font-size: 14px;
  }
`;

export const ExamplePayStubImg = styled.img`
  margin-top: 16px;
  width: 100%;
`;

export const TipContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  background: var(--Subtitle-Background, #f8f8fc);
  padding: 10px 16px;
  display: flex;
  margin-top: 16px;
  @media ${device.tablet} {
    margin-top: 0px;
  }
`;
export const TipItem = styled.div`
  display: flex;
  align-items: center;
`;

export const TipItemDevider = styled.div`
  height: 32px;
  @media ${device.tablet} {
    height: 16px;
  }
`;
export const TipIconCircle = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 16px;
`;
export const TipIcon = styled.img`
  width: 12px;
  height: 18px;
  margin-right: 14px;
`;
export const TipText = styled.p`
  color: var(--text-icon-dark, #242323);
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 153.846% */
`;

export const ContainerCloseIcon = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
`;
export const CloseIcon = styled.img`
  width: 24px;
  height: 24px;
`;

export const UploadFileButtonContainer = styled.div<{ _error: boolean }>`
  border-radius: 4px;
  border: 1px dashed ${(p) => (p._error ? "#E43" : "#242323")};
  opacity: 0.88;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  margin-top: 32px;
  padding: 16px;
  @media ${device.tablet} {
    margin-top: 24px;
    padding: 8px 16px;
  }
`;

export const UploadFileButtonIcon = styled.img`
  width: 24px;
  height: 24px;
  @media ${device.tablet} {
    width: 16px;
    height: 16px;
  }
  margin-right: 8px;
`;

export const UploadFileButtonText = styled.p`
  color: var(--text-icon-dark, #242323);
  /* Text Small/Regular */
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px; /* 153.846% */
  @media ${device.tablet} {
    font-size: 13px;
    line-height: 20px; /* 153.846% */
  }
`;

export const UploadFileButtonSuccess = styled.p`
  margin-top: 8px;
  color: var(--text-icon-secondary, #585866);
  /* Text Tiny/Regular */
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
`;

export const UploadFileButtonError = styled.p`
  margin-top: 8px;
  color: var(--text-icon-error, #e43);
  /* Text Tiny/Regular */
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
`;

export const ButtonsContainer = styled.div`
  padding: 0px 24px 24px 24px;
  display: flex;
  flex-direction: column;
  @media ${device.tablet} {
    flex-direction: row;
    align-items: center;
    padding: 24px 40px 32px 40px;
    box-shadow: 0px 1px 0px 0px #f0eef5 inset;
    justify-content: flex-end;
  }
`;
