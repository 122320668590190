import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";

import {
  StyledButtonsWrapper,
  StyledCSBody,
  StyledCSContainer,
  StyledSubmitButton,
} from "../Step5/styles";

import {
  StyledBodyTitleText,
  StyledBTCSBackButton,
  StyledSorbetLogo,
  StyledSelectorLabel,
  StyledCenteredBody,
  StyledBTCSelectorWrapper,
  StyledBTCSelector,
  StyledSelectorItem,
  StyledBTCSelectError,
  StyledBTCUploadFileError,
} from "../styles";
import ProgressBar from "../Components/ProgressBar";
import { StyledApplyAdvanceHeader } from "../Step3/styles";
import sorbetLogo from "../../../assets/logos/color-logo.svg";
import { BTCLoader } from "../Components/Loader";
import {
  TPayoutsAtTermination,
  TPtoDueTimes,
  TPtoRolloverLimits,
  TPtoRolloverLimitValues,
  TYearlyPtoDays,
} from "../../../services/api/types";
import { useStores } from "../../../stores";
import { sxSelect } from "./styles";
import { btcCheckUuid, btcErrorHandler } from "../../../utils/btcErrorHandler";
import BtcErrorBox from "../Components/ErrorBox";
import { observer } from "mobx-react";
import { gtmService } from "services/gtm";
import { EVENTS, useLeavePageAnalytics } from "services/analytics/events";
import { LOCAL_STORAGE_KEYS, LocalStorage } from "utils/localStorage";

type FormValues = {
  yearlyPtoDays: TYearlyPtoDays;
  payoutAtTermination: TPayoutsAtTermination;
  ptoDueTime: TPtoDueTimes;
  ptoRolloverLimit: TPtoRolloverLimits;
  ptoRolloverLimitValue: TPtoRolloverLimitValues;
};

const SecuritySocialNumber = () => {
  const { t } = useTranslation();
  const {
    appStore: { leadsInitConfig, windowDimensions },
    btcStore: { errorBox, updateBtcApplication, btcLoading },
  } = useStores();

  const navigate = useNavigate();
  const location = useLocation();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    getValues,
  } = useForm<FormValues>({ mode: "onBlur" });
  const selectedPtoRolloverLimit = watch("ptoRolloverLimit");
  const userUuid = LocalStorage.getItem(LOCAL_STORAGE_KEYS.uuid);

  useLeavePageAnalytics((eventType) => {
    const data = getValues();
    EVENTS.btc.step6.leavePage({ action: eventType, ...data });
  });

  useEffect(() => {
    EVENTS.btc.step6.onload();
  }, []);

  const onSubmit = async () => {
    btcCheckUuid();
    if (userUuid !== null) {
      const data = getValues();
      try {
        await updateBtcApplication({
          uuid: userUuid!,
          yearlyPtoDays: data.yearlyPtoDays,
          payoutAtTermination: data.payoutAtTermination,
          ptoDueTime: data.ptoDueTime,
          ptoRolloverLimit: data.ptoRolloverLimit,
          ptoRolloverLimitValue:
            data.ptoRolloverLimit === "yes" ? data.ptoRolloverLimitValue : undefined,
          lastSubmittedStep: 6,
          timestampStepFive: moment().toISOString(),
        });
        EVENTS.btc.step6.submit(data, true);
        LocalStorage.setItem(LOCAL_STORAGE_KEYS.lastSubmittedStep, "7");
        gtmService.customEvent({
          event: "enteredStep7",
          uuid: LocalStorage.getItem(LOCAL_STORAGE_KEYS.uuid),
        });
        navigate(`/cash-advance/pay-stubs${location.search}`);
        gtmService.customEvent({
          event: "step6Submission",
          payout_termination: data.payoutAtTermination,
          uuid: LocalStorage.getItem(LOCAL_STORAGE_KEYS.uuid),
        });
      } catch (error: any) {
        btcErrorHandler(error, "step6");
        EVENTS.btc.step6.submit({ ...data, failReason: error.message }, false);
      }
    }
  };

  const handleBackClick = () => {
    const data = getValues();
    EVENTS.btc.step6.leavePage({ action: "Back", ...data });
    navigate(`/cash-advance/credit-score${location.search}`);
  };

  const pageTranslationPrefix = "step6";

  return (
    <StyledCSContainer>
      <StyledApplyAdvanceHeader>
        <StyledSorbetLogo src={sorbetLogo} />
      </StyledApplyAdvanceHeader>
      <StyledCenteredBody>
        <ProgressBar progress={65} />
        <StyledCSBody>
          <StyledBodyTitleText>
            {t(`${pageTranslationPrefix}.moreAboutPtoPolicy`)}
          </StyledBodyTitleText>

          {errorBox && <BtcErrorBox />}

          {/* ######### How many PTO days you get per year ######### */}
          <StyledBTCSelectorWrapper>
            <StyledSelectorLabel required={true}>
              {t(`${pageTranslationPrefix}.howManyPtoDays`)}
            </StyledSelectorLabel>
            <StyledBTCSelector
              style={{
                maxWidth: windowDimensions.width - 40,
              }}
              SelectDisplayProps={{ style: { fontFamily: "Poppins", fontSize: 15 } }}
              error={!!errors["yearlyPtoDays"]}
              MenuProps={{ PaperProps: { sx: { maxHeight: 400, top: 380 } } }}
              sx={sxSelect}
              {...register("yearlyPtoDays", { required: true })}
              id="yearly-pto-days-selector">
              <StyledSelectorItem value={"dont_know"} id="-1">
                {t(`${pageTranslationPrefix}.yearlyPtoDaysSelector.dont_know`)}
              </StyledSelectorItem>
              <StyledSelectorItem value={"none"} id="0">
                {t(`${pageTranslationPrefix}.yearlyPtoDaysSelector.none`)}
              </StyledSelectorItem>
              <StyledSelectorItem value={"1"} id="1">
                {t(`${pageTranslationPrefix}.yearlyPtoDaysSelector.numericDay_one`)}
              </StyledSelectorItem>
              {Array.from({ length: 29 }, (_, index) => index + 2).map((item: number, i) => (
                <StyledSelectorItem value={item.toString()} id={`${item}`} key={item}>
                  {t(`${pageTranslationPrefix}.yearlyPtoDaysSelector.numericDay_other`, {
                    count: i + 2,
                  })}
                </StyledSelectorItem>
              ))}
              <StyledSelectorItem value={"more_than_30"} id={"more_than_30"}>
                {t(`${pageTranslationPrefix}.yearlyPtoDaysSelector.more_than_30`)}
              </StyledSelectorItem>
            </StyledBTCSelector>
            <StyledBTCSelectError error={!!errors["yearlyPtoDays"]}>
              <StyledBTCUploadFileError>
                {t(`${pageTranslationPrefix}.errorSelect`)}
              </StyledBTCUploadFileError>
            </StyledBTCSelectError>
          </StyledBTCSelectorWrapper>

          {/* ######### Does your employer pay out at termination ######### */}
          <StyledBTCSelectorWrapper>
            <StyledSelectorLabel required={true}>
              {t(`${pageTranslationPrefix}.doesEmployerPayoutAtTermination`)}
            </StyledSelectorLabel>
            <StyledBTCSelector
              style={{
                maxWidth: windowDimensions.width - 40,
              }}
              SelectDisplayProps={{ style: { fontFamily: "Poppins", fontSize: 15 } }}
              error={!!errors["payoutAtTermination"]}
              MenuProps={{ PaperProps: { sx: { maxHeight: 400, top: 380 } } }}
              sx={sxSelect}
              {...register("payoutAtTermination", { required: true })}
              id="pays-at-termination-selector">
              {leadsInitConfig?.payoutsAtTermination.map((item: TPayoutsAtTermination, i) => (
                <StyledSelectorItem value={item} id={item} key={item}>
                  {t(`${pageTranslationPrefix}.payoutAtTerminationSelector.${item}`)}
                </StyledSelectorItem>
              ))}
            </StyledBTCSelector>
            <StyledBTCSelectError error={!!errors["payoutAtTermination"]}>
              <StyledBTCUploadFileError>
                {t(`${pageTranslationPrefix}.errorSelect`)}
              </StyledBTCUploadFileError>
            </StyledBTCSelectError>
          </StyledBTCSelectorWrapper>

          {/* ######### Do you receive all your PTO at the beginning of the year ######### */}
          <StyledBTCSelectorWrapper>
            <StyledSelectorLabel required={true}>
              {t(`${pageTranslationPrefix}.receiveAllPto`)}
            </StyledSelectorLabel>
            <StyledBTCSelector
              style={{
                maxWidth: windowDimensions.width - 40,
              }}
              SelectDisplayProps={{ style: { fontFamily: "Poppins", fontSize: 15 } }}
              error={!!errors["ptoDueTime"]}
              MenuProps={{ PaperProps: { sx: { maxHeight: 400, top: 380 } } }}
              sx={sxSelect}
              {...register("ptoDueTime", { required: true })}
              id="receive-pto-selector">
              {leadsInitConfig?.ptoDueTimes.map((item: TPtoDueTimes, i) => (
                <StyledSelectorItem value={item} id={item} key={item}>
                  {t(`${pageTranslationPrefix}.ptoDueTimeSelector.${item}`)}
                </StyledSelectorItem>
              ))}
            </StyledBTCSelector>
            <StyledBTCSelectError error={!!errors["ptoDueTime"]}>
              <StyledBTCUploadFileError>
                {t(`${pageTranslationPrefix}.errorSelect`)}
              </StyledBTCUploadFileError>
            </StyledBTCSelectError>
          </StyledBTCSelectorWrapper>

          {/* ######### Is there a limit or a cap on number of PTO days ######### */}
          <StyledBTCSelectorWrapper>
            <StyledSelectorLabel required={true}>
              {t(`${pageTranslationPrefix}.limitOrCap`)}
            </StyledSelectorLabel>
            <StyledBTCSelector
              style={{
                maxWidth: windowDimensions.width - 40,
              }}
              SelectDisplayProps={{ style: { fontFamily: "Poppins", fontSize: 15 } }}
              error={!!errors["ptoRolloverLimit"]}
              MenuProps={{ PaperProps: { sx: { maxHeight: 400, top: 380 } } }}
              sx={sxSelect}
              {...register("ptoRolloverLimit", { required: true })}
              id="limit-or-cap-selector">
              {leadsInitConfig?.ptoRolloverLimits.map((item: TPtoRolloverLimits, i) => (
                <StyledSelectorItem value={item} id={item} key={item}>
                  {t(`${pageTranslationPrefix}.ptoRolloverLimitSelector.${item}`)}
                </StyledSelectorItem>
              ))}
            </StyledBTCSelector>
            <StyledBTCSelectError error={!!errors["ptoRolloverLimit"]}>
              <StyledBTCUploadFileError>
                {t(`${pageTranslationPrefix}.errorSelect`)}
              </StyledBTCUploadFileError>
            </StyledBTCSelectError>
          </StyledBTCSelectorWrapper>

          {/* ######### What is the limit or a cap on number of PTO days ######### */}
          {selectedPtoRolloverLimit === "yes" && (
            <StyledBTCSelectorWrapper>
              <StyledSelectorLabel required={true}>
                {t(`${pageTranslationPrefix}.whatIsTheMaxRollover`)}
              </StyledSelectorLabel>
              <StyledBTCSelector
                style={{
                  maxWidth: windowDimensions.width - 40,
                }}
                SelectDisplayProps={{ style: { fontFamily: "Poppins", fontSize: 15 } }}
                error={!!errors["ptoRolloverLimitValue"]}
                MenuProps={{ PaperProps: { sx: { maxHeight: 400, top: 380 } } }}
                sx={sxSelect}
                {...register("ptoRolloverLimitValue", { required: true })}
                id="limit-value-selector">
                <StyledSelectorItem value={"dont_know"} id="-1">
                  {t(`${pageTranslationPrefix}.ptoRolloverLimitValueSelector.dont_know`)}
                </StyledSelectorItem>
                <StyledSelectorItem value={"1"} id="1">
                  {t(`${pageTranslationPrefix}.ptoRolloverLimitValueSelector.numericDay_one`)}
                </StyledSelectorItem>
                {Array.from({ length: 29 }, (_, index) => index + 2).map((item: number, i) => (
                  <StyledSelectorItem value={item.toString()} id={`${item}`} key={item}>
                    {t(`${pageTranslationPrefix}.ptoRolloverLimitValueSelector.numericDay_other`, {
                      count: i + 2,
                    })}
                  </StyledSelectorItem>
                ))}
                <StyledSelectorItem value={"more_than_30"}>
                  {t(`${pageTranslationPrefix}.ptoRolloverLimitValueSelector.more_than_30`)}
                </StyledSelectorItem>
              </StyledBTCSelector>
              <StyledBTCSelectError error={!!errors["ptoRolloverLimitValue"]}>
                <StyledBTCUploadFileError>
                  {t(`${pageTranslationPrefix}.errorSelect`)}
                </StyledBTCUploadFileError>
              </StyledBTCSelectError>
            </StyledBTCSelectorWrapper>
          )}

          <form onSubmit={handleSubmit(onSubmit)}>
            <StyledButtonsWrapper>
              <StyledSubmitButton type="submit" id={"step-6-submit"}>
                {t(`${pageTranslationPrefix}.submitButton`)}
              </StyledSubmitButton>

              <StyledBTCSBackButton
                onClick={handleBackClick}
                startIcon={<ArrowBackIcon />}
                id="step-6-go-back">
                {t(`${pageTranslationPrefix}.back`)}
              </StyledBTCSBackButton>
            </StyledButtonsWrapper>
          </form>
        </StyledCSBody>
      </StyledCenteredBody>
      {btcLoading ? <BTCLoader /> : null}
    </StyledCSContainer>
  );
};

export default observer(SecuritySocialNumber);
