// Core
import { FC } from "react";
import { observer } from "mobx-react";
import { useStores } from "stores";

// assets
import meltedImage from "assets/images/melted.svg";

// services
import { mailService } from "services/mail";

// types / consts
import { COMPANY_EMAILS } from "config/constants";

// styles
import { StyledModal, CloseButton, ConfirmButton, MeltedIceCream, ModalContainer } from "./styles";

const ErrorModal: FC = observer(() => {
  const supportLink = mailService.generateMailUrl({ to: COMPANY_EMAILS.SUPPORT });
  const { appStore } = useStores();

  const { errorModalParams, closeErrorModal: close } = appStore;

  const title = errorModalParams?.title || "Oops!";
  const message = errorModalParams?.message || (
    <>
      Hmm... something went wrong, please try again.
      <br />
      If you receive another error, send us an email at <br />
      <a href={supportLink}>{COMPANY_EMAILS.SUPPORT}</a>
      <br />
      and we’ll give you a hand. Thanks!
    </>
  );

  return (
    <StyledModal open onClose={close}>
      <ModalContainer>
        <CloseButton onClick={close} data-testid="close_auth_modal" />
        <MeltedIceCream src={meltedImage} data-testid="melted_ice_cream" />
        <h2 data-testid="error_modal_title">{title}</h2>
        <p data-testid="error_modal_message">{message}</p>
        <ConfirmButton data-testid="confirm_auth_modal" onClick={close}>
          OK
        </ConfirmButton>
      </ModalContainer>
    </StyledModal>
  );
});

export default ErrorModal;
