import size, { device } from "config/devices";
import styled, { keyframes } from 'styled-components';

export const StyledWrapper = styled.div`
  background: #ffffff;
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: column;
  padding-bottom: 24px;
`;

export const PaddingWrapper = styled.div`
     padding: 0px 24px;

    @media ${device.tablet} {
     border-radius: 6px;
     padding: 20px 24px;
     border: 1px solid var(--color-primary-blue-30-cloud, #E4E2ED);
  }
`;

export const TopHeader = styled.div`
  @media ${device.tablet} {
    height: 56px;
    width: 100%;
    box-shadow: 0px -1px 0px 0px #f0eef5 inset;
  }
`;


export const StyledOptionText = styled.div<{disabled?: boolean}>`
    color: var(--text-icon-dark, #242323);
      color: ${({ disabled }) => (disabled  ? "#c0bdbd" : "#242323")};

    /* Mobile Web/Body */
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */

`;
export const StyledUploadedOptionText = styled.div`
    color: var(--text-icon-text-secondary, #585866);
    /* Mobile Web/Body */
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
`;

export const StyledUploadedOptions = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
    align-self: stretch;
`;

export const StyledInnerUploadedOption = styled.div`
    display: flex;
    padding: 16px 0px;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    flex: 1 0 0;
`;
export const StyledUploadedDate = styled.div`
    color: var(--text-icon-text-secondary, #585866);
    
    /* Text Tiny/Regular */
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
`;

export const StyledOptionIcon = styled.img`

`;

export const StyledOptionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    height: 60px;
    justify-content: center;
`;
const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

export const StyledSelectOption = styled.div<{ disabled?: boolean }>`
  display: flex;
  padding: var(--Radius-large-radius, 16px) 24px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  pointer-events: ${({ disabled }) => (disabled  ? "none" : "unset")};
  border-radius: 6px;
  border: 1px solid var(--Surface-surface-action-disabled, #E4E2ED);
  background: var(--Selection-Colors-Background-Default, #FFF);
  cursor: pointer;
  transition: all 0.3s ease;

  &.fade-out {
    animation: ${fadeOut} 0.5s forwards;
  }
`;

export const PTOCenterContainer = styled.div`
    padding: 0 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--Radius-large-radius, 16px);
    flex: 1 0 0;
    align-self: stretch;
`;


export const Label = styled.div<{type?: string}>`
    background-color: ${({ type }) => (type === "notification"  ? "#d0d0d0" : "#FBE363")};
    border-radius: 4px;
    display: flex;
    padding: 4px var(--Radius-medium-radius, 16px);
    justify-content: center;
    width: fit-content;
    align-items: center;
    gap: 6px;
`;

export const StyledPTOCenterDescription = styled.div`
    color: var(--text-icon-text-primary, #242323);
    /* Mobile Web/Body */
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
`;

export const Wrapper = styled.div`
  max-width: 702px;
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
`;

export const Header = styled.div`
  padding: 40px 0 32px 0;
  display: flex;
  align-items: center;
  flex: 0;
  margin: 0 24px;

  @media (max-width: ${size.tablet}) {
    margin: 0 0;
    margin-bottom: 16px;
    padding: 16px 24px;
    box-shadow: 0px -1px 0px 0px #f0eef5 inset;
  }
`;

export const BackIcon = styled.img`
  width: 24px;
  height: 24px;
`;

export const HeaderTitle = styled.h1`
  color: #242323;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;

  font-size: 16px;
  line-height: 24px;
  @media ${device.tablet} {
    font-size: 22px;
    line-height: 32px;
  }
`;

export const EmptyContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 16px;
`;
export const EmptyImg = styled.img`
  width: 150px;
  height: 160px;
`;
export const EmptyTitle = styled.p`
  color: #000;
  text-align: center;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  font-size: 16px;
  @media ${device.tablet} {
    font-size: 14px;
  }
`;
export const EmptySubtitle = styled.p`
  color: var(--text-icon-secondary, #585866);
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  @media ${device.tablet} {
    line-height: 20px; /* 150% */
    font-size: 13px;
  }
`;

export const EmptyButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media ${device.tablet} {
    align-items: center;
    margin-top: 24px;
  }
`;

export const EmptyContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  padding: 64px 0;
  @media ${device.tablet} {
    flex: 0;
    padding: 0;
  }
`;

export const ConnectButton = styled.div<{selected?: boolean}>`
  border-radius: 8px;
  background: ${({ selected }) => (selected  ? "#ff5277" : "#FFF")};
  color: ${({ selected }) => (selected  ? "#fff" : "#FF5277")};
  border: ${({ selected }) => (selected  ? "none" : "1px solid #FF5277")};
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  white-space: nowrap;
  transition: background-color 500ms;
  :hover {
    background-color: #000000;
    color: #fff;
    border: none;
  }
  :disabled {
    color: #fff;
    background: var(--selection-colors-border-disabled, #cfccdd);
    pointer-events: none;
  }

  font-size: 16px;
  padding: 12px 36px;
  flex: 1;
  @media ${device.tablet} {
    flex: 0;
    padding: 8px 36px;
    font-size: 14px;
  }
`;
