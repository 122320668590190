import { ProgressWrapper } from "./styles";

type Props = {
  width?: string;
  color?: string;
  backColor?: string;
  duration: number;
};
export const CustomProgress = ({
  width = "250px",
  color = "#ff5277",
  duration,
  backColor = "#F0EEF5",
}: Props) => {
  return (
    <ProgressWrapper width={width} color={color} backColor={backColor} duration={duration}>
      <div />
    </ProgressWrapper>
  );
};
