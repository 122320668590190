import { StyledBTCTextField } from "pages/Btc/styles";
import React, { useState } from "react";
import { Control, useController } from "react-hook-form";
import { SSN_LENGTH_LIMIT } from "../../pages/Btc/Constants";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { FormValuesStep4 } from "pages/Btc/Step4/useStep4";

type Props = {
  placeholder: string;
  id: string;
  control: Control<FormValuesStep4, any>;
};
const SSNField = ({ id, placeholder, control }: Props) => {
  const [rawSSN, setRawSSN] = useState("");
  const [showSSN, setShowSSN] = useState(false); // new state to manage visibility of SSN
  const { t } = useTranslation();
  const { field, formState } = useController({
    name: "ssn",
    control,
    rules: {
      required: t("page_ApplyInfo.ssnError.required")!,
      validate: (value) => {
        if (value.length < 1) {
          return t("page_ApplyInfo.ssnError.required")!;
        }
        return value.length === SSN_LENGTH_LIMIT || t("page_ApplyInfo.ssnError.maxMinLength")!;
      },
    },
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    const valueWithoutDashes = value.replace(/-/g, "");
    if (valueWithoutDashes.length <= 9) {
      if (valueWithoutDashes.length < rawSSN.length) {
        const newValue = rawSSN.slice(
          0,
          rawSSN.length - (rawSSN.length - valueWithoutDashes.length),
        );
        setRawSSN(newValue);
        field.onChange(newValue);
      } else {
        const newValue = rawSSN + valueWithoutDashes[valueWithoutDashes.length - 1];
        setRawSSN(newValue);
        field.onChange(newValue);
      }
    }
  };

  const format = (v = "") => {
    const valueWithoutDashes = v.replace(/-/g, "");
    let formatedValue = valueWithoutDashes.substring(0, 3);
    if (valueWithoutDashes.length > 3) {
      formatedValue += `-${valueWithoutDashes.substring(3, 5)}`;
    }
    if (valueWithoutDashes.length > 5) {
      formatedValue += `-${valueWithoutDashes.substring(5, 9)}`;
    }
    return formatedValue;
  };

  const mask = (v = "") => {
    const masked = v.replace(/[0-9]/g, "*");
    return masked;
  };

  return (
    <StyledBTCTextField
      {...field}
      type="text"
      id={id}
      name="ssn"
      placeholder={placeholder}
      value={showSSN ? format(field.value) : mask(format(field.value))} // use value based on showSSN state
      onChange={handleChange}
      autoComplete="off"
      error={!!formState.errors["ssn"]}
      helperText={formState.errors["ssn"]?.message}
      // add InputProps to show the visibility icon
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => setShowSSN(!showSSN)}
              edge="end">
              {showSSN ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default SSNField;
