import styled from "styled-components";
import {device} from "../../config/devices";

export const PlaidWrapper = styled.div`
  margin-bottom: 32px;
  margin-top: 32px;
  position: relative;
`;
export const StyledPlaidButton = styled.div<{ error: boolean }>`
  border: 1px dashed ${(props) => (props.error ? "#ee4433" : "#242323")};
  border-radius: 8px;
  text-align: center;
  margin-top: 4px;
  padding: 16px 0px;
  margin-bottom: 8px;
  cursor: pointer;
  height: 56px;
  @media ${device.laptop} {
    height: 42px;
    padding: 8px 0px;
  }
`;

export const StyledInnerButton = styled.div`
  text-align: center;
  color: #242323;
  font-weight: 400;
  font-size: 15px;
  font-family: Poppins;
  > img {
    margin-right: 9px;
  }
`;

export const BankAccountDetailsContainer = styled.div`
  padding: 16px 36px 16px 24px;
  margin-top: 16px;
  flex-direction: row;
  display: flex;
  border: 1px solid #e4e2ed;
  border-radius: 8px;
`;

export const BankAccountLogo = styled.img`
  width: 40px;
  height: 40px;
  border: 1px solid #e5e5e5;
  border-radius: 20px;
`;

export const BankAccountWithoutLogo = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-color: #9486a5;
  border: 1px solid #e5e5e5;
`;

export const BankAccountDetails = styled.div`
  flex: 1;
  padding: 0 16px;
`;
export const BankAccountName = styled.p`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #242323;
`;
export const BankAccountNumber = styled.p`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #9486a5;
`;

export const StyledLoadingWrapper = styled.div<{ position?: "relative" | "absolute" }>`
  padding: 32px;
  display: flex;
  justify-content: center;

  position: ${({ position }) => (position === "absolute" ? "absolute" : "relative")};
  top: 0;
  left: 0;
  right: 0;
`;

export const StyledDescription = styled.p`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #585866;
  margin-top: 8px;
`;

export const StyledDeleteBank = styled.img`
  cursor: pointer;
`;
