import styled from "styled-components";

// MUI
import closeIcon from "assets/icons/close-small.svg";

// Constants and types
import { Colors } from "assets/styles/constants";
import { device } from "config/devices";
import { PAYMENT_SERVICE } from "services/api/types";

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1300;
  background: rgba(119, 113, 155, 0.5);

  &.popover {
    .top {
      background: ${Colors.textColorWhite};

      svg {
        color: ${Colors.blue};
      }
    }

    .popover {
      max-width: 440px;
    }
  }
`;

export const Background = styled.div`
  width: 100%;
  height: 100%;
  z-index: 9;

  &::before {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    @media ${device.mobileS} {
      background: rgba(51, 42, 106, 0.6);
      backdrop-filter: blur(4px);
    }
    @media ${device.tablet} {
      background: ${Colors.textColorSecondary_rgb};
      backdrop-filter: none;
    }
  }
`;

export const ModalContainer = styled.div<{ width: number; height: number }>`
  width: 100%;
  max-width: 600px;
  display: flex;
  position: absolute;
  z-index: 10;
  flex-direction: column;
  background: ${Colors.textColorWhite};
  border-radius: 4px;
  width: ${({ width }) => (width ? `${width}px` : null)};
  height: ${({ height }) => (height ? `${height}px` : null)};
  &.popover {
    max-width: 400px;
  }
`;

export const ModalCloseButton = styled.button`
  cursor: pointer;
  background-image: url(${closeIcon});
  color: ${Colors.darkBlue};
  margin: 0 10px;
  background-size: 20px;
  background-position: center;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
`;

export const Body = styled.div<{ paymentService?: PAYMENT_SERVICE; isHasCard?: boolean }>`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: ${({ paymentService }) => (!paymentService ? "space-between" : "none")};

  h1 {
    text-align: center;
    margin-bottom: 8px;
    line-height: 48px;
  }

  h2 {
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 8px;
    line-height: 36px;
    font-size: 24px;
  }

  p {
    text-align: center;
  }

  &.popover {
    padding-top: 0;

    h1 {
      text-align: left;
      margin-bottom: 32px;
      font-size: 22px;
      line-height: 22px;

      p {
        text-align: left;
        font-size: 12px;
      }

      img {
        margin-right: 10px;
        position: relative;
        top: 2px;
      }
    }
  }

  @media ${device.mobileS} {
    padding: ${({ paymentService }) => (paymentService ? "30px 20px" : "10px 25px 30px 25px")};
  }

  @media ${device.tablet} {
    padding: ${({ paymentService, isHasCard }) =>
      paymentService === PAYMENT_SERVICE.STRIPE && !isHasCard
        ? "43px 78px 64px 77px"
        : "10px 25px 30px 25px"};
  }
`;

export const ModalTop = styled.div<{ paymentService?: PAYMENT_SERVICE }>`
  background: ${Colors.textColorWhite};
  border-radius: 4px 4px 0 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  box-shadow: 0 0 0 rgba(63, 63, 68, 0.05), 0 1px 2px rgba(63, 63, 68, 0.15);
  @media ${device.mobileS} {
    height: ${({ paymentService }) => (paymentService ? "20px" : "40px")};
    box-shadow: ${({ paymentService }) =>
      paymentService
        ? "none"
        : "0px 0px 0px rgba(63, 63, 68, 0.05), 0px 1px 2px rgba(63, 63, 68, 0.15)"};
    padding-top: ${({ paymentService }) => (paymentService ? "20px" : "0")};
  }
  @media ${device.tablet} {
    height: 40px;
    box-shadow: 0 0 0 rgba(63, 63, 68, 0.05), 0 1px 2px rgba(63, 63, 68, 0.15);
    padding-top: 0;
  }
`;
