export enum STORES {
  USER = "userStore",
  ONBOARDING = "onboardingStore",
  APP = "appStore",
  WEBAPP = "webAppStore",
}

export enum VERIFICATION_TYPES {
  CASH_OUT = "cashOut",
  MOBILE_CASH_OUT = "mobileCashOut",
  REVEAL_CARD = "revealCard",
  MOBILE_REVEAL_CARD = "mobileRevealCard",
}

export const SOCIAL_LINKS = {
  LINKEDIN: "https://www.linkedin.com/company/67915732/",
  FACEBOOK: "https://www.facebook.com/getsorbet",
  TWITTER: "https://twitter.com/sorbetco",
  INSTAGRAM: "https://www.instagram.com/getsorbet/",
  YOUTUBE: "https://www.youtube.com/channel/UC5lvhwQgVEhHxoQPW1Shhfw",
};

export const FAQ_LINK = "https://www.getsorbet.com/faq";
export const FAQ_PTO_LINK = "https://sorbethelp.zendesk.com/hc/en-us";
export const SORBET_CARD_TERMS_LINK = "https://www.getsorbet.com/sorbet-card-terms";
export const E_SIGN_AGREEMENT_LINK = "/e-sign";
export const PRIVACY_LINK = "/privacy-policy";
export const TERMS_CONDITIONS_LINK = "/terms-of-service";
export const SORBET_DEBIT_AUTHORIZATION_LINK = "/terms-of-service";
export const PRE_PAID_TERMS_LINK = "https://www.getsorbet.com/sorbet-prepaid-card-terms";
export const SORBET_LINK = "https://www.getsorbet.com";
export const PLAID_LINK = "https://plaid.com/";
export const LEAD_PRIVACY = "https://www.lead.bank/privacy-and-terms";

export enum ROUTES {
  HOME = "/home",
  LOGIN = "/login",
  MY_DETAILS = "/my-details",
  APPLICATION_STATUS = "/application-status",
  SIGN_CONTRACT = "/contract",
  ACCEPT_TERMS = "/accept_terms",
  NOT_FOUND = "/not-found",
  BTC = "/cash-advance/*",
  MANAGE_LINKED_ACCOUNTS = "/manage-linked-accounts",
  ISSUES_CENTER = "/issues-center",
  PAYMENT_HISTORY = "/payment-history",
  PTO_CENTER = "/pto-center",
  PAYROLL_FROM_CENTER = "/pto-center/payroll",
  PAYSTUB_FROM_CENTER = "/pto-center/paystub",
  PAYROLL = "/payroll",
  UPLOAD_PAYSTUB = "/upload-paystub",
  EMAIL_LINK_LOGIN = "/email-link-login",
  GENERAL_ERROR = "/contact-support",
  PRIVACY_POLICY = "/privacy-policy",
  TERMS_OF_SERVICE = "/terms-of-service",
  E_SIGN = "/e-sign",
  BACKUP_PAYMENT_METHOD_AGREEMENT = "/backup-payment-method-agreement"
}

export const PAGE_META_DATA = {
  titleTemplate: "Sorbet Cash Advances - %s",
  defaultTitle: "Sorbet Cash Advances",
  description: "Don’t let your Paid Time Off melt away",
};

export enum ERRORS {
  REQUIRED = "Is required",
  USER_NOT_FOUND = "Wrong email or password, or user not found",
  WRONG_PHONE_NUMBER_AUTH_OTP = "wrong phone number auth OTP",
}

/**
 * Company support emails list
 */
export enum COMPANY_EMAILS {
  SUPPORT = "support@getsorbet.com",
  PHONE = "(424)-383-5610",
}

export enum EMAIL_SUBJECTS {
  NOT_YOUR_NUMBER = "Hi Sorbet, Please change my number",
  HELP_WITH_MY_APP = "Help with my application",
  EMPTY = " ",
}

export enum EMAIL_BODY_TEMPLATES {
  HELP_WITH_MY_APP = "Hello Sorbet, I am experiencing the following issue while completing the online application:",
}

export interface ISidebarSizeInfo {
  width: number;
  breakpoint: number;
  ratio: number;
  collapsedWidth: number;
}
export const HEADER_HEIGHT = 56;
export const FOOTER_HEIGHT = 53;
export const FOOTER_HEIGHT_MOBILE = 68;

export type SidebarSizeBreakpoint = "MIN" | "MID" | "MAX";

const SIDEBAR_DEFAULT_WIDTH = 480;
const SIDEBAR_DEFAULT_HORIZONTAL_MARGIN = 45;

const SIDEBAR_MIN_BREAKPOINT = 1440;
const SIDEBAR_MID_BREAKPOINT = 1920;
const SIDEBAR_MAX_BREAKPOINT = 2560;

const SIDEBAR_MIN_RATIO = SIDEBAR_MIN_BREAKPOINT / SIDEBAR_MID_BREAKPOINT;
const SIDEBAR_MAX_RATIO = SIDEBAR_MAX_BREAKPOINT / SIDEBAR_MID_BREAKPOINT;

/**
 * Sidebar sizes for different screen sizes
 */
export const SIDEBAR_SIZES: { [key in SidebarSizeBreakpoint]: ISidebarSizeInfo } = {
  MIN: {
    breakpoint: SIDEBAR_MIN_BREAKPOINT,
    width: SIDEBAR_DEFAULT_WIDTH * SIDEBAR_MIN_RATIO,
    ratio: SIDEBAR_MIN_RATIO,
    collapsedWidth: SIDEBAR_DEFAULT_HORIZONTAL_MARGIN * SIDEBAR_MIN_RATIO,
  },
  MID: {
    breakpoint: SIDEBAR_MID_BREAKPOINT,
    width: SIDEBAR_DEFAULT_WIDTH,
    ratio: 1,
    collapsedWidth: SIDEBAR_DEFAULT_HORIZONTAL_MARGIN,
  },
  MAX: {
    breakpoint: SIDEBAR_MAX_BREAKPOINT,
    width: SIDEBAR_DEFAULT_WIDTH * SIDEBAR_MAX_RATIO,
    ratio: SIDEBAR_MAX_RATIO,
    collapsedWidth: SIDEBAR_DEFAULT_HORIZONTAL_MARGIN * SIDEBAR_MAX_RATIO,
  },
};

export const COMMON_EMAIL_TYPOS = [
  ".con",
  ".comm",
  ".vom",
  ".comn",
  ".cim",
  ".coml",
  "@gnail.com",
  "@gmil.com",
  "@gmail.co",
  "@gmail.cm",
];
