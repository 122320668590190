import { action, computed, observable } from "mobx";
import { IBtcStore } from "./types";
import * as types from "services/api/types";
import { apiService } from "services/api";
import { identifyThirdPartyServices } from "services/identifyThirdPartyServices";
import { LOCAL_STORAGE_KEYS, LocalStorage } from "utils/localStorage";
import { btcErrorHandler } from "utils/btcErrorHandler";

class BtcStore implements IBtcStore {
  @observable errorModal = false;
  @observable errorBox = false;
  @observable payrollAccountFinished = false;
  @observable errorText = "";
  @observable showPayStubModal: boolean = false;
  @observable employmentsLinkProviderLoading: boolean = false;
  @observable employmentsLinkProviderError: boolean = false;
  @observable employmentsLinkProvider: types.EmploymentsLinkProviderResponse | undefined;
  @observable public btcCalculatePTOLoading: boolean = false;
  @observable public btcLoading: boolean = false;
  @observable private _btcPTO?: number = undefined;
  @observable private _ptoPolicyDesc: types.TPtoPolicyDesc = "";

  @computed public get ptoPolicyDesc() {
    return this._ptoPolicyDesc;
  }

  @computed public get showCalculatorResult() {
    return this._ptoPolicyDesc === "carry_over";
  }

  @computed public get btcPTO() {
    if (this._btcPTO) {
      return this._btcPTO;
    }
    return 0;
  }

  @action showErrorModal = () => {
    this.errorModal = true;
  };
  @action hideErrorModal = () => {
    this.errorModal = false;
    this.errorText = "";
  };

  @action showErrorBox = () => {
    this.errorBox = true;
  };
  @action hideErrorBox = () => {
    this.errorBox = false;
    this.errorText = "";
  };
  @action setErrorText = (v: string) => {
    this.errorText = v;
  };
  @action changePayStubModalVisibility = (v: boolean) => {
    this.showPayStubModal = v;
  };

  @action
  public getEmploymentsLinkProvider = async (uuid: string) => {
    try {
      this.employmentsLinkProvider = undefined;
      this.employmentsLinkProviderError = false;
      this.employmentsLinkProviderLoading = true;
      const employmentsLinkProvider = await apiService.getEmploymentsLinkProvider(uuid);
      this.employmentsLinkProvider = employmentsLinkProvider;
    } catch (error) {
      this.employmentsLinkProviderError = true;
    } finally {
      this.employmentsLinkProviderLoading = false;
    }
  };

  @action
  public finishPayrollAccount = () => {
    this.payrollAccountFinished = true;
    this.employmentsLinkProvider = undefined;
    this.employmentsLinkProviderError = false;
    this.employmentsLinkProviderLoading = false;
  };

  @action public btcCalculatePTO = async () => {
    try {
      this.btcCalculatePTOLoading = true;
      const uuid = LocalStorage.getItem(LOCAL_STORAGE_KEYS.uuid);
      if (uuid) {
        const { calculatedPtoCashCents, ptoPolicyDesc } = await apiService.btcCalculatePto(uuid);
        this._btcPTO = calculatedPtoCashCents;
        this._ptoPolicyDesc = ptoPolicyDesc;
      }
    } catch (error) {
    } finally {
      this.btcCalculatePTOLoading = false;
    }
  };

  @action uploadBtcFiles = async (data: types.IBTCUpdateFiles) => {
    try {
      this.btcLoading = true;
      await apiService.updateBtcApplicationFiles(data);
    } catch (error) {
      throw error;
    } finally {
      this.btcLoading = false;
    }
  };

  @action public createBtcApplication = async (data: types.IBTCPatchData, uuid: string) => {
    try {
      this.btcLoading = true;
      await apiService.createBtcApplication({ ...data, applicationType: "loan" });
      LocalStorage.setItem(LOCAL_STORAGE_KEYS.uuid, uuid);
      await identifyThirdPartyServices(uuid);
    } catch (error) {
      throw error;
    } finally {
      this.btcLoading = false;
    }
  };
  @action public updateBtcApplication = async (data: types.IBTCPatchData) => {
    try {
      this.btcLoading = true;
      const returnedEmailUrl = LocalStorage.getItem(LOCAL_STORAGE_KEYS.returnedEmailUrl);
      if (returnedEmailUrl) {
        LocalStorage.removeItem(LOCAL_STORAGE_KEYS.returnedEmailUrl);
        await apiService.updateBtcApplication({
          ...data,
          isReturnedFromEmail: true,
          returnedEmailUrl,
        });
      } else {
        await apiService.updateBtcApplication(data);
      }
    } catch (error) {
      throw error;
    } finally {
      this.btcLoading = false;
    }
  };

  @action btcInit = async () => {
    const uuid = LocalStorage.getItem(LOCAL_STORAGE_KEYS.uuid);
    const lastSubmittedStep = LocalStorage.getItem(LOCAL_STORAGE_KEYS.lastSubmittedStep);
    if (uuid) {
      await identifyThirdPartyServices(uuid);
      if (lastSubmittedStep !== "8") {
        try {
          await apiService.emptyRequestBtcApplication(uuid);
        } catch (error) {
          btcErrorHandler(error, "Lead already passed B2C flow");
        }
      }
    }
  };
}

const btcStore = new BtcStore();
export default btcStore;
