import styled from "styled-components";

import {
  FOOTER_HEIGHT_MOBILE,
  HEADER_HEIGHT,
} from "config/constants";

export const Container = styled.div`
  padding: 42px;
  min-height: calc(100vh - ${HEADER_HEIGHT}px - ${FOOTER_HEIGHT_MOBILE}px);
`;

export const LoaderWrapper = styled.div`
  position: relative;
  height: 200px;
`;
