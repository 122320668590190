import { ENV } from "config/env";
import { datadogLogs } from "@datadog/browser-logs";
import { ILogger } from "./factory";
import { datadogRum, RumInitConfiguration } from "@datadog/browser-rum";

export class DatadogLogger implements ILogger {
  constructor() {
    this.initLogs();
    this.initRum();
  }

  initLogs() {
    if (ENV.REACT_APP_DATADOG_LOGS_TOKEN) {
      datadogLogs.init({
        clientToken: ENV.REACT_APP_DATADOG_LOGS_TOKEN,
        site: ENV.REACT_APP_DATADOG_SITE || "datadoghq.com",
        forwardErrorsToLogs: true,
        env: ENV.REACT_APP_ENV,
        service: ENV.REACT_APP_DATADOG_LOGS_SERVICE,
        sampleRate: 100,
      });
    }
  }

  initRum() {
    if (ENV.REACT_APP_DATADOG_RUM_ID && ENV.REACT_APP_DATADOG_RUM_TOKEN) {
      const datadogRumConfig: RumInitConfiguration = {
        applicationId: ENV.REACT_APP_DATADOG_RUM_ID,
        clientToken: ENV.REACT_APP_DATADOG_RUM_TOKEN,
        site: ENV.REACT_APP_DATADOG_SITE || "datadoghq.com",
        service: ENV.REACT_APP_DATADOG_RUM_SERVICE,
        env: ENV.REACT_APP_ENV,
        sampleRate: 100,
        trackInteractions: true,
        defaultPrivacyLevel: "mask-user-input",
      };

      if (ENV.REACT_APP_DATADOG_ALLOWED_TRACING_ORIGINS)
        datadogRumConfig.allowedTracingOrigins =
          ENV.REACT_APP_DATADOG_ALLOWED_TRACING_ORIGINS.split(",");

      datadogRum.init(datadogRumConfig);

      datadogRum.startSessionReplayRecording();
    }
  }

  captureError(error: Error | string, data?: object): void {
    if (error instanceof Error) {
      datadogLogs.logger.error(error.message, data);
    }
    if (typeof error === "string") {
      datadogLogs.logger.error(error, data);
    }
    datadogRum.addError(error);
  }

  captureMessage(message: string, data?: object): void {
    datadogLogs.logger.log(message, data);
  }

  addLoggerGlobalContext({
    id,
    username,
    email,
  }: {
    id: string;
    username?: string;
    email?: string;
  }) {
    datadogLogs.addLoggerGlobalContext('user-id', id);
    datadogRum.addRumGlobalContext('user-id', id);
  }

  removeLoggerGlobalContext(key: string) {
    datadogLogs.removeLoggerGlobalContext(key);
    datadogRum.removeRumGlobalContext(key);
  }
}
