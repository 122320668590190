import { Button } from "@mui/material";
import styled from "styled-components";

export const IssueWrapper = styled.div`
  border-radius: var(--Radius-medium-radius, 8px);
  border: 1px solid var(--Surface-surface-destructive, #e43);
  background: #fdf0f2;
  display: flex;
  padding: 16px;
  align-items: flex-start;
  gap: 8px;
  flex-direction: column;
  margin-bottom: 16px;
  flex: 1 0 0;
`;
export const IssuesCount = styled.div`
  display: flex;
  width: 24px;
  min-width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
  background: #ee4433;
  border-radius: 100px;
  color: var(--Surface-surface-primary, #fff);
  text-align: center;
  /* Text Tiny/Bold */
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 133.333% */
`;

export const ResolveButton = styled(Button)`
  display: flex;
  height: 32px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: var(--Radius-medium-radius, 8px) !important;
  border: 1px solid var(--Surface-surface-base, #242323) !important;
  background: var(--Surface-surface-primary, #fff) !important;
  color: var(--text-icon-text-primary, #242323) !important;
  padding: 16px !important;
  /* Text Small/Regular */
  font-family: Poppins !important;
  font-size: 13px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 20px; /* 153.846% */
  text-transform: math-auto !important;
`;

export const IssuesTitleText = styled.div`
  color: var(--text-icon-text-primary, #242323);
  /* Mobile Web/Body Medium */
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
`;

export const IssuesTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  justify-content: space-between;
`;

export const IssuesDescription = styled.div`
  color: var(--text-icon-text-primary, #242323);
  /* Mobile Web/Body */
  font-family: Poppins;
  font-size: 16px;
  margin-bottom: 8px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
`;
