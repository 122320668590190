// Core
import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { observer } from "mobx-react";
import size from "config/devices";
import { useWindowSize } from "utils/hooks/useWindowSize";
import { Size } from "utils/hooks/useWindowSizeTypes";

//Styles
import {
  ButtonsGroup,
  StyledModal,
  PrimaryButton,
  SecondButton,
  StyledHeadsUp,
  StyledWarningText,
  StyledContextMessage,
  StyledHeadsUpBigScreen,
} from "./styles";

// Utils
import { convertMilisecondsTo, getMiliseconds, TimeType } from "utils/date";

// Types and Constants
import { ENV } from "config/env";
import { useStores } from "stores";
import moment, { Moment } from "moment";
import { LOCAL_STORAGE_KEYS, LocalStorage } from "utils/localStorage";

const IdleWarningTime = getMiliseconds(30, TimeType.second); // 30 seconds
const CheckingWarningInterval = 100; // mlseconds
const activityEvents = ["click", "load", "scroll", "keypress"];
const idle_time = getMiliseconds(ENV.REACT_APP_IDLE_TIME, TimeType.minute);

interface IProps {
  isTesting?: boolean;
}

const SessionTimeout: FC<IProps> = observer(({ isTesting = false }: IProps) => {
  const { userStore } = useStores();
  const { logOut } = userStore;
  const [events] = useState(activityEvents);
  const [second, setSecond] = useState(0);
  const [openModal, setOpenModal] = useState(isTesting);
  const sizes: Size = useWindowSize();
  const tabletWidth = +size.tablet.slice(0, -2);

  let warningInactiveInterval = useRef<NodeJS.Timeout>();
  let startTimerInterval = useRef<NodeJS.Timeout>();

  /**
   * Checking for our stored timeStamp.
   */
  const timeChecker = () => {
    startTimerInterval.current = setTimeout(() => {
      if (userStore.isAuthenticated) {
        const now = moment();
        warningInactive(now);
      }
    }, idle_time);
  };

  /**
   * Save current time to local storage
   */
  const saveTime = () => {
    const now = moment();
    LocalStorage.setItem(LOCAL_STORAGE_KEYS.lastTime, now.format());
  };

  /**
   *  Remove current time from local storage
   */
  const removeTime = () => {
    LocalStorage.removeItem(LOCAL_STORAGE_KEYS.lastTime);
  };

  /**
   * Take time from local storage
   */
  const getLastTime = (): Moment | undefined => {
    const lastTime = LocalStorage.getItem(LOCAL_STORAGE_KEYS.lastTime);
    return lastTime ? moment(lastTime) : undefined;
  };

  useEffect(() => {
    const fromTime = getLastTime();
    if (!fromTime) {
      resetTimer();
      return;
    }

    const toTime = moment();
    const msDuration = Math.abs(fromTime.diff(toTime, "millisecond"));
    if (msDuration > idle_time) {
      (async function () {
        logOut();
      })();
    }
    resetTimer();
  }, []);

  /**
   * Reset interval timer.
   * if the user is authenticated, we set the timestamp in localStorage
   * and if not authenticated will be removed
   * and restart the timer
   */
  let resetTimer = useCallback(() => {
    clearTimeout(startTimerInterval.current!);
    clearInterval(warningInactiveInterval.current!);
    if (userStore.isAuthenticated) {
      saveTime();
      timeChecker();
    } else {
      clearInterval(warningInactiveInterval.current!);
      removeTime();
    }
  }, [userStore.isAuthenticated]);

  /**
   * Adding event listeners for all events
   * and start timer
   */
  useEffect(() => {
    events.forEach((event) => {
      window.addEventListener(event, resetTimer);
    });
    timeChecker();

    return () => {
      clearTimeout(startTimerInterval.current!);
    };
  }, [events, timeChecker]);

  /**
   * Warning message before auto log out
   * @param startWaitingTime
   */
  const warningInactive = (startWaitingTime: Moment) => {
    clearTimeout(startTimerInterval.current!);
    setOpenModal(true);
    warningInactiveInterval.current = setInterval(async () => {
      const fromTime = startWaitingTime;
      const toTime = moment();

      const diff = fromTime.diff(toTime, TimeType.second);
      const leftSecond = convertMilisecondsTo(TimeType.second, IdleWarningTime) + diff;

      setSecond(leftSecond);

      if (leftSecond < 1) {
        await doLogOut();
      }
    }, CheckingWarningInterval);
  };

  const stayOnline = () => {
    setOpenModal(false);
    saveTime();
    setSecond(0);
  };

  const doLogOut = async () => {
    clearInterval(warningInactiveInterval.current!);
    removeTime();
    setOpenModal(false);
    await logOut();
  };

  return ReactDOM.createPortal(
    <StyledModal
      open={openModal}
      close={() => setOpenModal(false)}
      width={sizes.width && sizes.width >= tabletWidth ? 600 : 328}
      className="mainWrapper">
      <div>
        {sizes.width && sizes.width >= tabletWidth ? (
          <>
            <StyledHeadsUpBigScreen>HEADS-UP!</StyledHeadsUpBigScreen>
            <StyledWarningText>You're about to be logged out</StyledWarningText>
            <StyledContextMessage>
              If no action is made in the next {second} seconds,
              <br />
              you will be automatically logged out due to inactivity.
            </StyledContextMessage>
          </>
        ) : (
          <>
            <StyledHeadsUp>Heads-Up!</StyledHeadsUp>
            <StyledWarningText>You're about to be logged out</StyledWarningText>
            <StyledContextMessage>
              If no action is made in the
              <br />
              next {second} seconds,
              <br />
              you will be automatically
              <br />
              logged out due to inactivity.
            </StyledContextMessage>
          </>
        )}
      </div>
      {sizes.width && sizes.width >= tabletWidth ? (
        <ButtonsGroup isMobileView={sizes.width && sizes.width >= tabletWidth ? false : true}>
          <SecondButton
            name={"log-Off"}
            onClick={doLogOut}
            isMobileView={sizes.width && sizes.width >= tabletWidth ? false : true}>
            LOG OFF
          </SecondButton>
          <PrimaryButton
            onClick={stayOnline}
            name={"auto-Logout-stay-Online"}
            isMobileView={sizes.width && sizes.width >= tabletWidth ? false : true}>
            STAY ONLINE
          </PrimaryButton>
        </ButtonsGroup>
      ) : (
        <ButtonsGroup isMobileView={sizes.width && sizes.width >= tabletWidth ? false : true}>
          <PrimaryButton
            onClick={stayOnline}
            name={"auto-Logout-stay-Online"}
            isMobileView={sizes.width && sizes.width >= tabletWidth ? false : true}>
            STAY ONLINE
          </PrimaryButton>
          <SecondButton
            name={"log-Off"}
            onClick={doLogOut}
            isMobileView={sizes.width && sizes.width >= tabletWidth ? false : true}>
            LOG OFF
          </SecondButton>
        </ButtonsGroup>
      )}
    </StyledModal>,
    document.body,
  );
});

export default SessionTimeout;
