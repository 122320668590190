import { analytics } from "../index";
import { TLeavePageEventReason } from "./utils";

export const ptoCenterEvents = {
  ptoCenter: {
    onView(origin: string) {
      analytics.trackEvent({
        event: "View PTO Center Page",
        params: {
          Origin: origin,
        },
      });
    },
    leavePage(reason: string | TLeavePageEventReason) {
      if (reason === "Focus") {
        return;
      }
      analytics.trackEvent({
        event: "Leave PTO Center Page",
        params: { Reason: reason },
      });
    },
  },
  payrollBanner: {
    onView() {
      analytics.trackEvent({ event: "View Connect Your Payroll Banner" });
    },
    onLeave(reason: string) {
      analytics.trackEvent({
        event: "Leave Connect Your Payroll Banner",
        params: { Reason: reason },
      });
    },
  },
  paystub: {
    onView({
      origin,
      userId,
      payStubUploadedThisMonth,
    }: {
      origin: string;
      userId: string;
      payStubUploadedThisMonth: boolean;
    }) {
      analytics.trackEvent({
        event: payStubUploadedThisMonth
          ? "View Paystub Uploaded Successfully Page"
          : "View Upload Paystub Page",
        params: { Origin: origin, "User ID": userId },
      });
    },

    skip() {
      analytics.trackEvent({ event: "Clicked Skip Paystub Upload" });
      analytics.incrementProperty("Paystub page Skip clicks");
    },
    onLeave({
      reason,
      documentType,
      payStubUploadedThisMonth,
      isDocumentUploaded,
    }: {
      reason: string | TLeavePageEventReason;
      documentType: string;
      payStubUploadedThisMonth: boolean;
      isDocumentUploaded: boolean;
    }) {
      if (reason === "Focus") {
        return;
      }
      analytics.trackEvent({
        event: payStubUploadedThisMonth
          ? "Leave Paystub Uploaded Successfully Page"
          : "Leave Upload Paystub Page",
        params: {
          Reason: reason,
          ...(!payStubUploadedThisMonth && {
            "Uploaded Document": isDocumentUploaded,
            "Document Type": documentType,
          }),
        },
      });
    },
    attemptUploadPaystub() {
      analytics.trackEvent({
        event: "Attempt Upload the N Paystub",
        params: { Origin: "PTO Center Page" },
      });
      analytics.incrementProperty("Paystub Upload Attempts");
    },
    paystubUploadedSuccessfully() {
      analytics.trackEvent({ event: "The N Pay Stub Uploaded Successfully" });
      analytics.setUserProps({ "Uploaded the N Pay Stub?": true });
    },
  },
  payroll: {
    applicationStatus: "",
    onload(origin: string, applicationStatus: string) {
      this.applicationStatus = applicationStatus;
      analytics.trackEvent({
        event: "View Regulation Page",
        params: { Origin: origin, "User's Application Status": applicationStatus },
      });
    },
    leavePage(reason: string) {
      if (reason === "Focus") {
        this.onload("Regain Focus", this.applicationStatus);
        return;
      }
      analytics.trackEvent({
        event: "Leave Regulation Screen",
        params: { Reason: reason },
      });
    },
    completePayroll(selectedPlatformName: string) {
      analytics.trackEvent({
        event: "Completed Connection to Payroll",
        params: { "Chose Payroll Provider": selectedPlatformName },
      });
    },
  },
};
