import { ENV } from "config/env";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { usePlacesWidget } from "react-google-autocomplete";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { useStores } from "stores";
import { btcCheckUuid, btcErrorHandler } from "utils/btcErrorHandler";
import { AGE_LIMIT, ALLOWED_STATES } from "../Constants";
import { API_ERROR_CODES } from "../../../services/api/types";
import { gtmService } from "services/gtm";
import { EVENTS, useLeavePageAnalytics } from "services/analytics/events";
import { LOCAL_STORAGE_KEYS, LocalStorage } from "utils/localStorage";

export type FormValuesStep4 = {
  phone: string;
  city: string;
  countryCode: number;
  state: string;
  addressLine: string;
  zipCode: string;
  country: string;
  birthDate: string;
  timestampStepThree: string;
  ssn: string;
  address: string;
};

type AddressType = {
  state?: string | null;
  addressLine?: string | null;
  zipCode?: string | null;
  city?: string | null;
  country?: string | null;
};

const parseAddressComponent = (details: google.maps.places.PlaceResult) => {
  let aptNumber: string | null = null;
  let streetNumber: string | null = null;
  let streetName: string | null = null;
  let city: string | null = null;
  let state: string | null = null;
  let country: string | null = null;
  let zipCode: string | null = null;
  let addressLine: string | null = null;

  const addressComponents = details.address_components;

  addressComponents?.forEach((addressComponent: { types: any[]; long_name: any }) => {
    const addressType = addressComponent.types[0];
    switch (addressType) {
      case "subpremise":
        aptNumber = addressComponent.long_name;
        break;
      case "street_number":
        streetNumber = addressComponent.long_name;
        break;
      case "route":
        streetName = addressComponent.long_name;
        break;
      case "sublocality_level_1":
        city = addressComponent.long_name;
        break;
      case "locality":
        city = addressComponent.long_name;
        break;
      case "administrative_area_level_1":
        state = addressComponent.long_name;
        break;
      case "postal_code":
        zipCode = addressComponent.long_name;
        break;
      case "country":
        country = "USA";
        break;
      default:
        break;
    }
    addressLine = aptNumber
      ? aptNumber + " " + streetNumber + " " + streetName
      : (streetNumber || "") + " " + streetName;
  });
  return { city, zipCode, country, state, addressLine };
};

export const useStep4 = () => {
  const doesUserIgnoreAddressSelect = useRef<boolean | undefined>(undefined);
  const [enterAddressManually, setEnterAddressManually] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [googleAddress, setGoogleAddress] = useState<AddressType>();
  const userUuid = LocalStorage.getItem(LOCAL_STORAGE_KEYS.uuid);

  const {
    btcStore: { errorBox, updateBtcApplication, btcLoading },
  } = useStores();

  let { ref: googleRef } = usePlacesWidget<HTMLInputElement>({
    apiKey: ENV.REACT_APP_GOOGLE_MAPS_API_KEY,
    onPlaceSelected: (place) => {
      doesUserIgnoreAddressSelect.current = false;
      setGoogleAddress(parseAddressComponent(place));
      setEnterAddressManually(false);
    },
    options: {
      types: ["address"],
      componentRestrictions: { country: "us" },
    },
  });

  useEffect(() => {
    EVENTS.btc.step4.onload();
    document.title = "On-demand PTO cash advance";
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setError,
    getValues,
  } = useForm<FormValuesStep4>({ mode: "onBlur" });

  useLeavePageAnalytics((eventType) => {
    const data = getValues();
    EVENTS.btc.step4.leavePage({
      action: eventType,
      phone: data.phone,
      city: googleAddress?.city,
      state: googleAddress?.state,
      country: googleAddress?.country,
      birthDate: data.birthDate,
      ssn: data.ssn,
    });
  });

  const onSubmit = async (data: FormValuesStep4) => {
    btcCheckUuid();
    if (userUuid !== null) {
      try {
        if (enterAddressManually && !ALLOWED_STATES.includes(data.state.trim())) {
          setError("state", { type: "incorrectState" });
          return;
        }
        const { city, zipCode, country, state, addressLine } = googleAddress ?? {};
        const PageData = {
          phone: data.phone,
          birthDate: data.birthDate,
          countryCode: 1,
          state: enterAddressManually ? data.state.trim() : state?.trim() || data.state.trim(),
          city: enterAddressManually ? data.city.trim() : city?.trim() || data.city.trim(),
          addressLine: enterAddressManually
            ? data.address.trim()
            : addressLine?.trim() || data.address.trim(),
          zipCode: enterAddressManually
            ? data.zipCode.trim()
            : zipCode?.trim() || data.zipCode.trim(),
          country: country?.trim() || "USA",
          uuid: userUuid,
          timestampStepThree: moment().toISOString(),
          lastSubmittedStep: 4,
          ssnNumber: data.ssn,
        };
        await updateBtcApplication(PageData);
        EVENTS.btc.step4.submit(
          {
            phone: data.phone,
            state: enterAddressManually ? data.state : googleAddress?.state || data.state,
            city: enterAddressManually ? data.city : googleAddress?.city || data.city,
            country: googleAddress?.country || "USA",
            birthDate: data.birthDate,
            ssn: data.ssn,
          },
          true,
        );
        LocalStorage.setItem(LOCAL_STORAGE_KEYS.lastSubmittedStep, "5");
        navigate(`/cash-advance/credit-score${location.search}`);
        gtmService.customEvent({
          event: "step4Submission",
          state: enterAddressManually ? data.state : state || data.state,
          uuid: LocalStorage.getItem(LOCAL_STORAGE_KEYS.uuid),
        });
      } catch (error: any) {
        EVENTS.btc.step4.submit(
          {
            phone: data.phone,
            state: enterAddressManually ? data.state : googleAddress?.state || data.state,
            city: enterAddressManually ? data.city : googleAddress?.city || data.city,
            country: googleAddress?.country || "USA",
            birthDate: data.birthDate,
            ssn: data.ssn,
            failReason: error.message,
          },
          false,
        );
        if (error.code === API_ERROR_CODES.invalidAddress) {
          setEnterAddressManually(true);
        } else {
          btcErrorHandler(error, "step4");
        }
      }
    }
  };

  const handleBackClick = () => {
    const data = getValues();
    EVENTS.btc.step4.leavePage({
      action: "Back",
      phone: data.phone,
      state: enterAddressManually ? data.state : googleAddress?.state || data.state,
      city: enterAddressManually ? data.city : googleAddress?.city || data.city,
      country: googleAddress?.country || "USA",
      birthDate: data.birthDate,
      ssn: data.ssn,
    });
    navigate(`/cash-advance/apply-advance${location.search}`);
  };

  const validateDateOfBirth = (date: string | undefined) => {
    const years = moment().diff(date, "years");
    return years >= AGE_LIMIT;
  };

  const validatePhone = (phone: string | undefined) => {
    return phone && phone[0] !== "0" && phone[0] !== "1";
  };

  const onAddressInputChange = () => {
    doesUserIgnoreAddressSelect.current = true;
  };

  const onAddressInputBlur = (e: React.FocusEvent<HTMLInputElement, Element>) => {
    if (doesUserIgnoreAddressSelect.current) {
      setEnterAddressManually(true);
    }
  };

  return {
    errors,
    register,
    validateDateOfBirth,
    handleBackClick,
    validatePhone,
    handleSubmit,
    control,
    onSubmit,
    isLoading: btcLoading,
    errorBox,
    googleRef,
    googleAddress,
    onAddressInputChange,
    onAddressInputBlur,
    enterAddressManually,
  };
};
