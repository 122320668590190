import { device } from "config/devices";
import styled from "styled-components";

export const Title = styled.p`
  color: var(--text-icon-dark, #242323);
  /* Text Medium/Regular */
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  font-size: 16px;
  @media ${device.tablet} {
    font-size: 14px;
  }
  margin-bottom: 8px;
`;

export const UploadsContainer = styled.div`
  border: 1px solid #f0eef5;
  border-radius: 8px;
  margin-bottom: 32px;
`;
export const UploadItem = styled.div<{ devider?: boolean; active: boolean }>`
  padding: 16px 24px;
  display: flex;
  align-items: center;
  background: #fff;
  transition: background 1.5s;
  ${(p) => (p.active ? "" : "background: #FFF1F4;")}
  ${(p) => {
    if (p.devider) {
      return `border-bottom: 1px solid #f0eef5;`;
    }
  }}
`;
export const UploadIcon = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 16px;
`;

export const AddIconContainer = styled.div<{ active: boolean }>`
  transition: opacity 1.5s;
  opacity: ${(p) => (p.active ? "1" : "0")};
`;
export const AddIcon = styled.img`
  width: 18px;
  height: 18px;
`;

export const UploadTextContainer = styled.div`
  flex: 1;
  display: flex;
`;
export const UploadText = styled.p<{ active: boolean }>`
  color: var(--text-icon-dark, #242323);
  /* Text Small/Medium1 */
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  position: relative;
  &:before {
    content: "";
    transition: width 1.5s;
    width: ${(p) => (p.active ? "0" : "100%")};
    height: 0.1em;
    background: #242323;
    position: absolute;
    top: 50%;
    left: 0;
  }

  font-size: 16px;
  line-height: 24px; /* 153.846% */
  @media ${device.tablet} {
    font-size: 13px;
    line-height: 20px; /* 153.846% */
  }
`;

export const PrimaryButton = styled.button`
  border-radius: 8px;
  background: var(--non-interactive-colors-pink, #ff5277);
  color: #fff;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  white-space: nowrap;
  transition: background-color 500ms;
  :hover {
    background-color: #000000;
  }
  :disabled {
    color: #fff;
    background: var(--selection-colors-border-disabled, #cfccdd);
    pointer-events: none;
  }

  font-size: 16px;
  padding: 16px 36px;
  flex: 1;
  @media ${device.tablet} {
    flex: 0;
    padding: 8px 36px;
    font-size: 14px;
  }
`;

export const CompactPrimaryButton = styled.button`
  border-radius: 8px;
  background: var(--non-interactive-colors-pink, #ff5277);
  color: #fff;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  white-space: nowrap;
  transition: background-color 500ms;
  :hover {
    background-color: #000000;
  }
  :disabled {
    color: #fff;
    background: var(--selection-colors-border-disabled, #cfccdd);
    pointer-events: none;
  }

  font-size: 14px;
  padding: 4px 16px;
  @media ${device.tablet} {
    flex: 0;
    padding: 4px 16px;
    font-size: 14px;
  }
`;


export const SecondaryButton = styled.button`
  border-radius: 8px;
  white-space: nowrap;
  color: #ff5277;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  transition: color 500ms;
  line-height: 24px; /* 171.429% */
  text-wrap: wrap;
  :hover {
    color: #000000;
  }
  :disabled {
    color: #d4d1e0;
  }

  font-size: 16px;
  padding: 16px 36px;
  @media ${device.tablet} {
    padding: 8px 36px;
    font-size: 14px;
  }
`;

export const SecondaryButtonCompact = styled.button`
  border-radius: 8px;
  white-space: nowrap;
  color: #ff5277;
  font-family: Poppins;
  flex: 1 !important;
  font-style: normal;
  font-weight: 400;
  transition: color 500ms;
  line-height: 24px; /* 171.429% */
  text-wrap: wrap;
  :hover {
    color: #000000;
  }
  :disabled {
    color: #d4d1e0;
  }

  font-size: 15px;
  @media ${device.tablet} {
    font-size: 14px;
  }
`;
export const CancelButton = styled(SecondaryButton)`
  display: none;
  @media ${device.tablet} {
    margin-right: 16px;
    display: flex;
  }
`;
