import styled from "styled-components";
import { Box, Dialog, StepLabel } from "@mui/material";
import { device } from "config/devices";
import { PrimaryButton } from "./components/NMIUploads/styles";

export const StyledThankYouWrapper = styled(Box)`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  background: #fff;
`;

export const StyledBodyTitleText = styled(Box)`
  color: var(--text-icon-dark, #242323);
  white-space: pre-line;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  @media ${device.tablet} {
    font-size: 18px;
    line-height: 24px;
  }
`;

export const StyledBodySubTitleText = styled(Box)`
  color: var(--text-icon-secondary, #585866);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  @media ${device.tablet} {
    font-size: 14px;
    line-height: 24px;
  }
`;

export const StyledStatusMessageContainer = styled(Box)`
  border-radius: 8px;
  white-space: pre-line;
  padding: 16px;
  margin-top: 24px;
  margin-bottom: 24px;
  color: var(--text-icon-dark, #242323);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  background: var(--subtitle-background, #f8f8fc);
`;
export const StyledQuestionsAndContactUs = styled(Box)`
  color: #585866;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-top: 24px;
  a {
    margin-left: 4px;
    margin-right: 4px;
    color: #ff5277;
    text-decoration: none;
  }
  :last-child {
    margin-top: 0px;
    margin-left: 0px;
    margin-top: 0px;
  }
`;

export const ContainerForSmallText = styled.div`
  white-space: pre-line;
  margin-bottom: 24px;
`;

export const StyledSmallText = styled(Box)`
  color: #585866;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin-top: 24px;
  white-space: pre-line;
`;

export const StyledTrackAppStatusContainer = styled(Box)`
  border-radius: 8px;
  border: 1px solid var(--text-icon-link, #ff5277);
  color: var(--text-icon-link, #ff5277);
  font-family: Poppins;
  margin-bottom: 24px;
  font-size: 16px;
  font-style: normal;
  padding: 14px 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  line-height: 24px;
  cursor: pointer;

  &.track-status-large-screen {
    @media (max-width: 768px) {
      display: none;
    }
    @media (min-width: 769px) {
      font-size: 12px; // Change the font size for larger screens
      padding: 0px 8px;
      width: fit-content;
      margin-top: 8px;
    }
  }
  &.track-status-small-screen {
    @media (min-width: 769px) {
      display: none;
    }
  }
`;

export const HalfScreenDialog = styled(Dialog)`
  .MuiDialog-paper {
    height: 50%;
    margin-top: auto;
    border-radius: 8px;
    background: #fff;
    position: relative;
  }
`;

export const StyledTrackerContainer = styled(Box)``;

export const StyledThankYouContainer = styled(Box)`
  flex: 1;
  background-color: #fff;
  align-self: center;
  max-width: 528px;
`;

export const StyledCloseIcon = styled.img`
  width: 24px;
  height: 24px;
  position: absolute;
  top: 10px;
  right: 10px;
`;
export const DialogTitle = styled.div`
  color: var(--text-icon-dark, #242323);
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  padding-bottom: 32px;
  line-height: normal;
  padding-left: 40px;
  margin-top: 32px;
  border-bottom: 1px solid #f0eef5;
`;

export const StatusContainer = styled.div``;

export const StyledStepLabel = styled(StepLabel)`
  color: var(--text-icon-dark, #242323) !important;
  font-family: Poppins !important;
  font-size: 13px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 20px !important;
`;

export const SubmittingLoaderWrapper = styled.div`
  position: absolute;
  top: 0px;
  right: 0;
  left: 0;
  bottom: 0;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #ff5277;
`;

export const SubmittingLoaderText = styled.p`
  color: var(--text-icon-secondary, #585866);
  /* Mobile Web/Body */
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  margin-top: 8px;
`;

export const PayrollContainer = styled.div`
  border-radius: 8px;
  background: rgba(255, 168, 187, 0.2);
  display: flex;
`;
export const PayrollInfo = styled.div`
  padding: 16px 0px 24px 20px;
  flex: 1;
`;
export const PayrollTitle = styled.p`
  color: var(--Surface-surface-base, #242323);
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px; /* 160% */
`;
export const PayrollDescription = styled.p`
  color: var(--Surface-surface-base, #242323);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
`;

export const PayrollImg = styled.img`
  height: 144px;
`;

export const PayrollTryNow = styled(PrimaryButton)`
  padding: 8px 36px;
  font-size: 14px;
  margin-top: 16px;
  @media ${device.tablet} {
    padding: 4px 36px;
  }
`;
