import { observer } from "mobx-react";
import { useStores } from "stores";
import {
  Container,
  TransferItem,
  TypeText,
  DateText,
  AmountText,
  Wrapper,
  StatusText,
  StyledPagination,
  TransferItemLeft,
  TransferItemRight,
  PaginationWrapper,
} from "./styles";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useEffect, useState } from "react";
import { IAchTransfer } from "services/api/types";

const USDollar = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});
const NumberOfItemsPerPage = 7;
const getNumberOfPages = (itemsCount: number) => {
  if (NumberOfItemsPerPage >= itemsCount) {
    return 0;
  }
  return Math.ceil(itemsCount / NumberOfItemsPerPage);
};
const getTransfersToShow = (transfers: IAchTransfer[], currentPage: number) => {
  if (transfers) {
    return transfers.slice(
      (currentPage - 1) * NumberOfItemsPerPage,
      currentPage * NumberOfItemsPerPage,
    );
  }
  return [];
};
export const Payments = observer(() => {
  const [currentPage, setCurrentPage] = useState(1);
  const {
    transfersInfoStore: { settledTransfers },
  } = useStores();
  const [transfersToShow, setTransfersToShow] = useState<IAchTransfer[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    setTransfersToShow(getTransfersToShow(settledTransfers, currentPage));
  }, [settledTransfers]);

  const paginationOnChange = (_: any, pageNumber: number) => {
    setCurrentPage(pageNumber);
    setTransfersToShow(getTransfersToShow(settledTransfers, pageNumber));
  };
  const paginationCount = getNumberOfPages(settledTransfers.length);

  return (
    <Wrapper>
      <Container>
        {transfersToShow.map((transfer) => {
          const isCredit = transfer.type === "credit";
          return (
            <TransferItem key={transfer.id}>
              <TransferItemLeft>
                <div>
                  <TypeText>
                    {t(`paymentHistory.${isCredit ? "loanTransfer" : "payment"}`)}
                  </TypeText>
                  {isCredit ? (
                    <DateText>{`To account #${transfer.bankAccountLast4}`}</DateText>
                  ) : null}
                </div>
                <DateText>{moment(transfer.createdAt).format("MMM Do YYYY • hh:mmA")}</DateText>
              </TransferItemLeft>

              <TransferItemRight>
                <StatusText>{t("paymentHistory.completed")}</StatusText>
                <AmountText>
                  {USDollar.format((transfer.amount / 100) * (isCredit ? 1 : -1))}
                </AmountText>
              </TransferItemRight>
            </TransferItem>
          );
        })}
      </Container>

      <PaginationWrapper>
        {paginationCount > 0 ? (
          <StyledPagination
            onChange={paginationOnChange}
            count={paginationCount}
            shape="rounded"
            page={currentPage}
          />
        ) : null}
      </PaginationWrapper>
    </Wrapper>
  );
});
