import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useStores } from "stores";
import { PdfComponent } from "./Components/PdfComponent";
import { apiService } from "services/api";
import { ContractTypeEnum } from "services/api/types";
import { snackbarUtils } from "components/Notification";
import { COMPANY_EMAILS, ROUTES } from "config/constants";

const mapPathNameToContractType: Record<string, ContractTypeEnum> = {
  [ROUTES.PRIVACY_POLICY]: ContractTypeEnum.privacy_policy,
  [ROUTES.E_SIGN]: ContractTypeEnum.e_sign_consent,
  [ROUTES.TERMS_OF_SERVICE]: ContractTypeEnum.sorbets_terms_of_service,
};
export const Agreements = () => {
  const { t } = useTranslation();
  const [pdfFile, setPdfFile] = useState<File>();

  const {
    appStore: { setIsHeaderHidden },
  } = useStores();

  const contractType = mapPathNameToContractType[window.location.pathname];

  useEffect(() => {
    setIsHeaderHidden(false);
    const getContractTemplate = async () => {
      try {
        const file = await apiService.getContractTemplate(contractType);
        setPdfFile(file);
      } catch (error) {
        snackbarUtils.error(`${t("btc_errors.generalError")}${COMPANY_EMAILS.SUPPORT}`);
      }
    };
    getContractTemplate();
  }, []);

  return <PdfComponent title={t(`contractsInApplication.${contractType}`)} file={pdfFile} />;
};
