import { observable, action, computed } from "mobx";
import { IBankDetailsStore, INewBackupAccount } from "./types";
import { apiService } from "services/api";
import * as types from "services/api/types";
import { snackbarUtils } from "components/Notification";
import i18n from "i18n";
import userStore from "stores/user";
import { AccountAddedWithoutConsent } from "pages/ManageLinkedAccounts/additionalAccountFlow/components/SuccessMessage/AccountAddedWithoutConsent";
import debitConsentStore from "stores/debitConsent";
import { errorLogger } from "services/logger";

const getPrimaryAndBackupsAccounts = (
  accounts: types.IBankAccount[],
): { primary: types.IBankAccount; backups: types.IBankAccount[] } => {
  const primary =
    accounts.filter(
      (account) => account.operationalStatus === types.OperationalStatusEnum.primary,
    )[0] || accounts[0];

  const backups = accounts.filter(
    (account) => account.bankAccountDetailId !== primary.bankAccountDetailId,
  );

  return {
    primary,
    backups,
  };
};

class BankDetailsStore implements IBankDetailsStore {
  @observable loading: boolean = false;
  @observable relinkLoading: boolean = false;
  @observable newBackupPaymentMethod: INewBackupAccount | undefined = undefined;
  @observable backupAccounts: types.IBankAccount[] = [];
  @observable openNoConsentModal = false;
  @observable public bankDetails?: types.IBankAccount;
  @observable public bankDetailsErrorCode?: number;
  @observable public bankAccountRelinkError: boolean = false;
  @observable public backupAccountConsents: Record<number, boolean> = {};
  @observable public generateBackupContractLoading: boolean = false;

  @action public getBankDetails = async (bankIntegrationId: number | null | undefined) => {
    if (bankIntegrationId) {
      try {
        await this.getBankAccountsDetails();
      } catch (error: any) {
        if (error?.code === types.API_ERROR_CODES.BANK_INTEGRATION_TOKEN_IS_NOT_VALID) {
          this.bankDetailsErrorCode = types.API_ERROR_CODES.BANK_INTEGRATION_TOKEN_IS_NOT_VALID;
        }
      }
    }
  };

  @action setRelinkLoading = (loading: boolean) => {
    this.relinkLoading = loading;
  };

  private getBackupAccountConsents = async (accounts: types.IBankAccount[]) => {
    const { userInfo } = userStore;
    if (userInfo?.bankIntegrationId) {
      for (let i = 0; i < accounts.length; i++) {
        const account = accounts[i];
        try {
          const response = await apiService.getBackupConsent(
            userInfo.bankIntegrationId,
            account.bankAccountDetailId,
          );
          const consent = response.data[0];
          this.backupAccountConsents[account.bankAccountDetailId] =
            consent?.status === types.ConsentStatusEnum.APPROVED;
        } catch (error) {
          errorLogger.captureError("can't get backup account consent", {
            bankIntegrationId: userInfo.bankIntegrationId,
            bankAccountDetailId: account.bankAccountDetailId,
            error,
          });
        }
      }
    }
  };

  @action public getBankAccountsDetails = async () => {
    try {
      this.loading = true;
      const { userInfo } = userStore;
      if (userInfo?.id && userInfo?.bankIntegrationId) {
        const accounts = await apiService.getBankAccounts({
          userId: userInfo.id,
          bankIntegrationId: userInfo.bankIntegrationId,
        });

        const { primary, backups } = getPrimaryAndBackupsAccounts(accounts.data);
        await this.getBackupAccountConsents(backups);
        this.bankDetails = primary;
        this.backupAccounts = backups;
      }
    } catch (error: any) {
      snackbarUtils.error(`Error to fetch accounts, ${error.message ?? ""}`);
    } finally {
      this.loading = false;
    }
  };

  @action setBankAccountRelinkError = (v: boolean) => {
    this.bankAccountRelinkError = v;
  };

  @computed
  public get backupAccountsLength() {
    return this.backupAccounts?.length;
  }

  @action setNewBackupAccount = (bankAccount: INewBackupAccount) => {
    this.newBackupPaymentMethod = bankAccount;
  };

  @action setBackupAccountConsent = async (
    bankAccountDetailId: number,
    bankAccountLast4: string,
  ) => {
    const { userInfo } = userStore;
    try {
      this.loading = true;
      if (userInfo?.bankIntegrationId) {
        await apiService.setBackupAccountConsent({
          bankAccountDetailId,
          bankIntegrationId: userInfo.bankIntegrationId,
          type: types.DebitAchAuthTypeEnum.BACKUP_BA,
          status: types.ConsentStatusEnum.APPROVED,
          version: types.DEBIT_CONSENT_VERSIONS.BACKUP,
        });
        snackbarUtils.success(
          i18n.t("manageLinkedAccounts.bankAccountSuccessfullyAuthorized", {
            bankAccountLast4,
          }),
        );
      }
    } catch (error) {
    } finally {
      this.loading = false;
    }
    this.getBankAccountsDetails();
  };

  @action closeNewBackupAccountModal = () => {
    if (!this.newBackupPaymentMethod?.isBackupAccount && this.newBackupPaymentMethod?.newAccount) {
      snackbarUtils.info(<AccountAddedWithoutConsent bankAccount={this.newBackupPaymentMethod} />);
      this.getBankAccountsDetails();
    }

    this.newBackupPaymentMethod = undefined;
    this.generateBackupContractLoading = false;
  };

  @action unlinkAccount = (bankAccountDetailId: number) => {
    // TODO
  };

  @action setNoConsentModal = (open: boolean) => {
    this.openNoConsentModal = open;
  };

  getStoredBankAccount = (bankAccountDetailId: number) => {
    if (this.bankDetails?.bankAccountDetailId === bankAccountDetailId) {
      return this.bankDetails;
    }
    return this.getStoredBackupAccount(bankAccountDetailId);
  };

  getStoredBackupAccount = (bankAccountDetailId: number) => {
    return this.backupAccounts.find(
      (bankAccount) => bankAccount.bankAccountDetailId === bankAccountDetailId,
    );
  };

  getBankAccountDetail = async (
    bankAccountDetailId: number,
  ): Promise<types.IBankAccount | undefined> => {
    try {
      const { userInfo } = userStore;
      if (userInfo?.bankIntegrationId) {
        const account = await apiService.getBankAccountByAccountId({
          userId: userInfo.id,
          bankIntegrationId: userInfo.bankIntegrationId,
          accountDetailId: bankAccountDetailId,
        });
        return account;
      }
    } catch (error) {}
  };

  @computed
  get atLeastOneConsentForBackupAccounts() {
    return Object.values(this.backupAccountConsents).some((v) => v);
  }

  @computed
  get backupAccountsWithoutConsent() {
    const backupAccounts = this.backupAccounts.filter((account) => {
      return !this.backupAccountConsents[account.bankAccountDetailId];
    });
    const { isPrimaryConsentExist, isFirstConsentExist } = debitConsentStore;
    if (
      isFirstConsentExist &&
      !isPrimaryConsentExist &&
      this.bankDetails &&
      this.bankDetails?.status !== types.BankAccountsStatusEnum.invalid &&
      this.bankDetails?.operationalStatus === "backup"
    ) {
      return [this.bankDetails, ...backupAccounts];
    }

    return backupAccounts;
  }

  @computed
  get invalidBankAccounts() {
    if (this.bankDetails) {
      return [this.bankDetails, ...this.backupAccounts].filter(
        (account) => account.status === types.BankAccountsStatusEnum.invalid,
      );
    } else {
      return this.backupAccounts.filter(
        (account) => account.status === types.BankAccountsStatusEnum.invalid,
      );
    }
  }

  @computed
  get isBankIntegrationValid() {
    if (this.bankDetails?.status !== types.BankAccountsStatusEnum.invalid) {
      return true;
    }
    const notGoodBanks = this.backupAccounts.filter((account) => {
      if (account.status === types.BankAccountsStatusEnum.invalid) {
        return true;
      }
      return !this.backupAccountConsents[account.bankAccountDetailId];
    });

    if (notGoodBanks.length < this.backupAccounts.length) {
      return true;
    }
    // if primary account is not valid && all backup banks (invalid || valid but without consent)
    // return bank integration is not valid
    return false;
  }

  //-------------------- Back up Payment Method contract --------------------//
  generateBackupContract = async () => {
    try {
      this.generateBackupContractLoading = true;
      const contract = await apiService.generateContract({
        type: types.ContractTypeEnum.backup_payment_method_agreement,
      });
      return contract;
    } catch (error: any) {
    } finally {
      this.generateBackupContractLoading = false;
    }
  };

  signBackupContract = async (contractId: number) => {
    try {
      await apiService.signContract(
        {
          executionStatus: types.ContractExecutionStatus.signed,
          executedAtTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          executedAt: new Date().toISOString(),
        },
        contractId,
      );
    } catch (error) {}
  };

  //-------------------- clear bank details store --------------------//

  @action clearStore = () => {
    this.newBackupPaymentMethod = undefined;
    this.backupAccounts = [];
    this.bankDetailsErrorCode = undefined;
    this.bankAccountRelinkError = false;
    this.bankDetails = undefined;
    this.backupAccountConsents = {};
    this.loading = false;
    this.relinkLoading = false;
    this.openNoConsentModal = false;
    this.generateBackupContractLoading = false;
  };
}
const bankDetailsStore = new BankDetailsStore();
export default bankDetailsStore;
