import styled from "styled-components";
import { Box } from "@mui/material";
import { device } from "config/devices";

export const StylesOptionalUploadFileWrapper = styled(Box)`
  background: #f8f8fc;
  border-radius: 6px;
  padding: 10px 16px 16px 16px;
  margin-top: 24px;
`;
export const StylesOptionalUploadFileTitle = styled.p`
  font-family: "Poppins";
  font-style: normal;
  color: #242323;
  margin-top: 24px;

  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  @media ${device.tablet} {
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
  }
`;

export const StylesCheckboxWrapperTerms = styled.div`
  padding: 0px 16px;
  padding-bottom: 6px;
  margin-bottom: 4px;
  color: #585866;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  a {
    color: #ff5277;
  }
  @media ${device.tablet} {
    padding-right: 56px;
  }
`;

export const StylesCheckboxWrapperMarketing = styled.div`
  padding: 30px 16px 16px 16px;
  color: #585866;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  a {
    color: #ff5277;
  }
  @media ${device.tablet} {
    padding-right: 64px;
  }
`;

export const StyledErrorTextCheckbox = styled.p`
  display: flex;
  align-items: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #585866;
  margin-left: 4px;
  color: #ee4433;
  margin-left: 45px;
  margin-bottom: 4px;
`;

export const StyledMilitaryCheckBoxWrapper = styled.div`
  margin-bottom: 24px;
`;
export const StylesCheckboxMilitary = styled.div`
  color: #585866;
  font-family: "Poppins";
  font-style: normal;
  line-height: 24px;
  font-weight: 400;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #f8f8fc;
  border-radius: 6px;
  padding: 16px 12px;
  margin-top: 16px;
  margin-bottom: 4px;
`;

export const StyledCheckboxError = styled.div`
  color: #ee4433;
  font-family: "Poppins";
  font-style: normal;
  line-height: 16px;
  font-weight: 400;
  font-size: 12px;
  display: flex;
`;
export const StylesOptionalUploadFileSubtitle = styled.p`
  font-family: "Poppins";
  color: #585866;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
`;
