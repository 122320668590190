import { CurrencyCodeType, IUserInfo } from "services/api/types";

/**
 * Uppercase first letter in the given text
 */
export const uppercaseFirst = (text: string) => {
  return `${text[0].toUpperCase()}${text.substr(1)}`;
};

/**
 * Add "s" to text if not single
 */
export const pluralize = (text: string, count: number): string => (count === 1 ? text : text + "s");

/**
 * Add currency symbols to digits
 */
export const addCurrency = (
  text: string | number,
  currencyCode: CurrencyCodeType = "USD",
): string => {
  let pattern = `$1 ${currencyCode}`;
  if (["USD", "AUD"].includes(currencyCode.toUpperCase())) {
    pattern = "$$$1";
  }
  return text.toString().replace(/([\d.]+)/g, pattern);
};

/**
 * Getting ceil part of amount
 * @param amount
 */
export const getCeilPartOfAmount = (amount: number): string => {
  return Math.floor(amount / 100).toString();
};

/**
 * Getting decimal part of amount
 * @param amount
 */
export const getDecimalPartOfAmount = (amount: number): string => {
  return (amount / 100 + "").split(".")[1] || "00";
};

/**
 * Get currency symbols
 * @param currencyCode
 */
export const getCurrency = (currencyCode: CurrencyCodeType = "USD"): string => {
  return ["USD", "AUD"].includes(currencyCode.toUpperCase()) ? "$" : "";
};

export const requestBodyTrim = (requestBody: Object) => {
  for (const [key, value] of Object.entries(requestBody)) {
    try {
      if (typeof value === "string" || value instanceof String) {
        //@ts-ignore
        requestBody[key] = value.trim();
      }
      if (typeof value === "object" || value instanceof Object) {
        requestBodyTrim(value);
      }
    } catch (error) {}
  }
  return requestBody;
};

export const fullUserName = (userInfo: IUserInfo) => {
  const { firstName, lastName, middleName = "" }: any = userInfo;
  const neededLength = 30;
  const calculateLastNameLength = () =>
    lastName.substring(0, neededLength - processedFirstName.length - 1);

  let processedFirstName = "";
  let processedLastName = "";

  firstName.length <= neededLength
    ? (processedFirstName = firstName)
    : (processedFirstName = firstName.substring(0, neededLength));

  processedFirstName.length + lastName.length <= neededLength
    ? (processedLastName = lastName)
    : (processedLastName = calculateLastNameLength());

  const fullName =
    middleName!! && `${lastName} ${firstName} ${middleName}`.length <= neededLength
      ? `${firstName} ${middleName} ${lastName}`
      : `${processedFirstName} ${processedLastName}`;
  return fullName;
};

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

const formatterNoFraction = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 0,
});

export const currencyFormatter = (
  amount: number | undefined,
  props?: { noFraction: boolean; rounding: "round" | "floor" | "ceil" },
) => {
  try {
    if (amount) {
      if (props?.noFraction) {
        return formatterNoFraction.format(Math[props.rounding](amount / 100));
      }
      return formatter.format(amount / 100);
    }
    return "0.00";
  } catch (error) {
    return "0.00";
  }
};
