import { DebitAchAuthTypeEnum } from "services/api/types";
import { PriorConsentImg } from "../style";
import { StyledPriorConsentWrapper, StyledLargeTextPriorConsent } from "./styles";
import { useTranslation } from "react-i18next";
import TransMsg from "../../components/CreditTransferMessage/TransMsg.svg";
import { CheckboxFlow } from "./PaymentAuth/CheckboxFlow";
import { useStores } from "stores";

const translation = "achDebitAuth";

type Props = { approvedAmount: string };
export const PriorConsent = ({ approvedAmount }: Props) => {
  const { t } = useTranslation();
  const {
    debitConsentStore: { setConsentModal },
  } = useStores();
  const autoPayClick = () => {
    setConsentModal({ type: DebitAchAuthTypeEnum.AUTO_PAY, open: true });
  };
  const oneTimePayClick = () => {
    setConsentModal({ type: DebitAchAuthTypeEnum.ONE_TIME, open: true });
  };

  return (
    <StyledPriorConsentWrapper>
      <PriorConsentImg src={TransMsg} />
      <StyledLargeTextPriorConsent>
        {t(`${translation}.areWaitingForYou`, { amount: approvedAmount })}
      </StyledLargeTextPriorConsent>

      <CheckboxFlow autoPayClick={autoPayClick} oneTimePayClick={oneTimePayClick} />
    </StyledPriorConsentWrapper>
  );
};
