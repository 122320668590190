import { useTranslation } from "react-i18next";
import {
  StyledConsentErrorImg,
  StyledLargeText,
  StyledText,
  StyledConsentErrorContainer,
} from "../../OnCyclePaymentInfo/style";
import errorIcon from "../DisbursementError/DisbursementErrorIcon.svg";
import { useStores } from "../../../../stores";
import { currencyFormatter } from "utils/formatters";

const translation = "achDebitAuth";

export const DebitTransferError = () => {
  const { t } = useTranslation();
  const {
    loanStore: { loanInfo },
  } = useStores();
  return (
    <StyledConsentErrorContainer>
      <StyledConsentErrorImg src={errorIcon} />
      <StyledLargeText>{t(`${translation}.somethingWentWrong`)}</StyledLargeText>
      {loanInfo && (
        <StyledText>
          {t(`${translation}.thereWasAProblemProcessing`, {
            amountDue: currencyFormatter(loanInfo?.paymentDueAmountCents),
          })}
        </StyledText>
      )}
      <StyledText>{t(`${translation}.pleaseMakeSure`)}</StyledText>
    </StyledConsentErrorContainer>
  );
};
