import { setStatsigUserId } from "./abTesting";
import { analytics } from "./analytics";
import { errorLogger } from "./logger";

export const identifyThirdPartyServices = async (
  uuid: string,
  username?: string,
  email?: string,
) => {
  try {
    await setStatsigUserId(uuid); // identify user for statsig
    analytics.identifyUser(uuid); // identify user for mixpanel
    errorLogger.addLoggerGlobalContext({ id: uuid, email, username }); // identify user for coralogix or datadog
    // @ts-ignore
    window.clarity("set", "sorbet_uuid", `${uuid}`);
    // @ts-ignore
    window.clarity("identify", `${uuid}`);
  } catch (error) {
    errorLogger.captureError(error);
  }
};
