import styled from "styled-components";
import { Box } from "@mui/material";
import { device } from "../../config/devices";

export const StyledContractPage = styled(Box)`
  display: flex;
  width: 100%;
  height: 100%;
  min-height: 1000px;
  flex-direction: column;
  background: #fff;
`;
export const StyledLoadWrapper = styled(Box)`
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-width: 500px;
  text-align: center;
  padding: 0 20px;
`;
export const StyledContractBody = styled(Box)`
  display: flex;
`;
export const StyledSubHeader = styled(Box)`
  display: flex;
  padding: 24px 20px 16px 0px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  width: 550px;
  margin: auto;
  @media (max-width: 550px) {
    padding: 24px 20px 16px 20px;
    width: 100%;
  }
`;
export const StyledCongrats = styled(Box)`
  color: var(--text-icon-dark, #242323);
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px; /* 160% */
`;
export const StyledCongratsDesc = styled(Box)`
  color: var(--text-icon-secondary, #585866);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
`;
export const StyledContractHeader = styled(Box)`
  display: flex;
  width: 100%;
  flex-direction: row;
  background: #fff;
  justify-content: space-between;
  padding-bottom: 16px;
  padding-left: 20px;
  padding-top: 16px;
  padding-right: 16px;
  box-shadow: 0px -1px 0px 0px #f0eef5 inset;
`;
export const StyledContractSignDesktop = styled(Box)`
  display: flex;
  padding: 0px 16px;
  gap: 8px;
  color: var(--text-icon-light, #fff);
  /* Text Small/Regular */
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  background-color: #ff5277;
  font-weight: 400;
  line-height: 20px; /* 153.846% */
  flex-shrink: 0;
  height: 32px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
`;
export const StyledContractTitle = styled(Box)`
  color: var(--text-icon-secondary, #585866);
  text-align: right;
  /* Mobile Web/Body */
  font-family: Poppins;
  text-align: left;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
`;

export const ContractPrimaryButton = styled.button`
  border-radius: 8px;
  background: var(--non-interactive-colors-pink, #ff5277);
  color: #fff;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  white-space: nowrap;
  transition: background-color 500ms;
  :hover {
    background-color: #000000;
  }
  :disabled {
    color: #fff;
    background: var(--selection-colors-border-disabled, #cfccdd);
    pointer-events: none;
  }

  font-size: 13px;
  padding: 8px 36px;
  flex: 0 1 0%;
  @media ${device.tablet} {
    flex: 0;
    padding: 8px 36px;
    font-size: 14px;
  }
`;
export const StyledPDFViewer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1100px; // Set the max-width for larger screens
  margin: auto;
  overflow-y: auto;
  box-shadow: 0px 2px 16px 1px rgba(36, 35, 35, 0.16);

  @media (max-width: 550px) {
    box-shadow: none;
    max-width: 100%; // For smaller screens, use the full width
  }
`;
export const StyledContractFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 36px;
`;
export const StyledNotificationBox = styled.div`
  && {
    display: flex;
    padding: 16px;
    margin-top: 16px;
    align-items: flex-start;
    gap: 8px;
    flex: 1 0 0;
    border-radius: 8px;
    background: var(--Subtitle-Background, #f8f8fc);
    color: var(--text-icon-secondary, #585866);
    /* Mobile Web/Body */
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    max-width: 550px;
    width: calc(100% - 40px);
  }
`;
export const StyledButtons = styled.div`
  && {
    display: flex;
    align-items: center;
    margin-top: 24px;
    margin-bottom: 40px;
    gap: 16px;
    margin-left: 20px;
    max-width: 528px;
    width: 100%;
    justify-content: end;
    flex-flow: row-reverse;
    margin-right: 20px;
    @media (max-width: 550px) {
      width: calc(100% - 40px);
      flex-direction: column;
      justify-content: center;
    }
  }
`;
export const DeclineModalTitle = styled.p`
  color: var(--text-icon-dark, #242323);
  /* H1 - Page Title */
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px; /* 142.857% */
  @media ${device.tablet} {
    font-size: 28px;
    line-height: 40px; /* 142.857% */
  }
`;
export const ButtonsContainer = styled.div`
  padding-top: 32px;
  display: flex;
  flex-direction: column;
  @media ${device.tablet} {
    flex-direction: row-reverse;
  }
`;
