import styled from "styled-components";
import { Colors } from "assets/styles/constants";

import { Box } from "@mui/material";
import { device } from "../../../config/devices";

export const sxSelect = {
  "& .MuiSelect-select .notranslate::after": "Choose an option"
    ? {
        content: `"Choose an option"`,
        opacity: 0.42,
      }
    : {},
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    boxShadow: "0 0 0 1px #7a56fc, 0 0 0 3px #e2daff",
    border: "none",
    borderRadius: "4px",
  },
  "& .MuiInputBase-input": {
    color: "#242323",
    fontSize: 15,
    fontFamily: "Poppins",
  },
};
export const StyledCashAdvanceCustomContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  @media ${device.laptop} {
    width: 100%;
    display: flex;
    flex-direction: row;
  }
`;

export const StyledPtoAdvanceSorbetLogo = styled.img`
  width: 90px;
  height: 20px;
  
  @media ${device.laptop} {
    margin-top: 112px;
  }
`;

export const StyledPtoCashAdvanceHeader = styled(Box)`
  display: flex;
  background: linear-gradient(324.93deg, #ff5277 63.16%, #fce464 114.69%);
  flex-direction: column;
  padding: 32px 20px 56px 20px;
  @media ${device.laptop} {
    display: flex;
    padding: 32px 20px 0px 20px;
    flex: 1;
    background: linear-gradient(324.93deg, #ff5277 63.16%, #fce464 114.69%);
    flex-direction: column;
    align-items: center;
  }
`;
export const StyledPCABodyWrapper = styled.div`
  max-width: 528px;
  width: 100%;
`;
export const StyledPCABody = styled(Box)`
  display: flex;
  flex: 1;
  background: #fff;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px 20px 50px 20px;
  @media ${device.laptop} {
    padding: 0;
  }
`;

export const StyledPCAHeaderText = styled(Box)`
  font-weight: 600;
  color: #fff;
  font-size: 35px;
  line-height: 40px;
  margin-bottom: 20px;
  margin-top: 20px;
  white-space: pre-line;
  @media ${device.laptop} {
    font-size: 63px;
    line-height: 56px;
  }
`;

export const StyledPCABodyTitleText = styled(Box)`
  font-weight: 500;
  color: #242323;
  font-size: 24px;
  line-height: 32px;
  margin-right: 24px;
  @media ${device.laptop} {
    margin-right: 72px;
  }
`;

export const StyledPCABodyText600 = styled(Box)`
  font-weight: 600;
  color: #fff;
  font-size: 15px;
  max-width: 482px;
`;

export const StyledPCABodyText = styled(Box)`
  font-weight: 400;
  color: #fff;
  font-size: 15px;
  max-width: 482px;
  line-height: 24px;
`;

export const StyledBoldText = styled(Box)`
  font-weight: 600;
  color: #fff;
  font-size: 15px;
  max-width: 482px;
  line-height: 24px;
`;

export const StyledPtoCashAdvanceImageWrapper = styled.div`
  width: 320px;
  height: 272px;
  object-fit: cover;
  object-position: 50% 50%;
  background-image: linear-gradient(135deg, ${Colors.sand} 25%, ${Colors.rose} 74.95%);
`;

export const StyledPtoImage = styled.img`
  @media ${device.laptop} {
    display: block;
    width: 310px;
    align-self: center;
  }
  display: none;
`;
