import "react-perfect-scrollbar/dist/css/styles.css";
import { StyledLoanContainer } from "./style";
import { observer } from "mobx-react";

import { OnCyclePaymentContent } from "./Content";

type Props = {
  hasOverdue: boolean;
};

export const OnCyclePaymentInfo = observer(({ hasOverdue }: Props) => {
  return (
    <StyledLoanContainer>
      <OnCyclePaymentContent hasOverdue={hasOverdue} />
    </StyledLoanContainer>
  );
});

export default OnCyclePaymentInfo;
