import { ENV } from "config/env";
import { ILogger } from "./factory";
import { CoralogixRum } from "@coralogix/browser";
import { CoralogixLogSeverity } from "@coralogix/browser/src/types-external";

const pjson = require("../../../package.json");

export class CoralogixLogger implements ILogger {
  constructor() {
    this.initLogs();
    this.initRum();
  }

  initLogs() {
    if (ENV.REACT_APP_CORALOGIX_PUBLIC_KEY) {
      CoralogixRum.init({
        public_key: ENV.REACT_APP_CORALOGIX_PUBLIC_KEY,
        application: "cash.sorbetapp.com",
        version: pjson.version,
        coralogixDomain: "EU2",
        environment: ENV.REACT_APP_ENV,
        instrumentations: {
          fetch: false,
          xhr: false,
          resources: false,
          web_vitals: false,
          interactions: false,
        },
        sessionRecordingConfig: {
          enable: true, // Must declare.
          /**
           * If autoStartSessionRecording is false, you can manually start & stop your session recording.
           * Refer to Recording Manually Section.
           **/
          autoStartSessionRecording: true, // Automatically records your session when SDK is up.
          recordConsoleEvents: true, // Will record all console events from dev tools. Levels: log, debug, warn, error, info, table etc..
          sessionRecordingSampleRate: 100, // Percentage of overall sessions recording being tracked, defaults to 100% and applied after the overall sessionSampleRate.
        },
      });
    }
  }

  initRum() {}

  captureError(error: Error | string, data?: object): void {
    if (CoralogixRum.isInited) {
      if (error instanceof Error) {
        CoralogixRum.log(CoralogixLogSeverity.Error, error.message, data);
      }
      if (typeof error === "string") {
        CoralogixRum.log(CoralogixLogSeverity.Error, error, data);
      }
      CoralogixRum.log(CoralogixLogSeverity.Error, "error", error);
    }
  }

  captureMessage(message: string, data?: object): void {
    if (CoralogixRum.isInited) {
      CoralogixRum.log(CoralogixLogSeverity.Info, message, data);
    }
  }

  addLoggerGlobalContext({
    id,
    username,
    email,
  }: {
    id: string;
    username?: string;
    email?: string;
  }) {
    if (CoralogixRum.isInited) {
      CoralogixRum.setUserContext({
        user_id: id,
        user_name: username ?? "",
        user_email: email ?? "",
      });
    }
  }

  removeLoggerGlobalContext(key: string) {
    if (CoralogixRum.isInited) {
      CoralogixRum.setUserContext({ user_id: "", user_name: "" });
    }
  }
}
