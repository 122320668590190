import React from "react";
import Modal from "@mui/material/Modal";
import { observer } from "mobx-react";
import { StyledModalBody, StyledModalSubtitle, StyledModalTitle } from "./styles";
import { useStores } from "stores";
import { useTranslation } from "react-i18next";
import { COMPANY_EMAILS } from "config/constants";
import ApplicationStatus from "../../ApplicationStatus";

const style = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  alignSelf: "center",
  margin: 2,
  alignContent: "center",
};

const BtcErrorModal = observer(() => {
  const {
    btcStore: { errorModal, errorText },
  } = useStores();

  const { t } = useTranslation();
  const translationPrefix = "btc_errors";

  return (
    <Modal
      sx={style}
      open={errorModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <StyledModalBody>
        {errorText.includes("application status is Canceled") ? (
          <ApplicationStatus />
        ) : (
          <div>
            <StyledModalTitle>{t(`${translationPrefix}.oopsSomethingWrong`)}</StyledModalTitle>
            <StyledModalSubtitle>
              {t(`${translationPrefix}.generalError`)}
              <a
                href={`mailto:${COMPANY_EMAILS.SUPPORT}?subject=Hey! I have a question`}
                target="_blank"
                rel="noreferrer">
                {COMPANY_EMAILS.SUPPORT}
              </a>
            </StyledModalSubtitle>
          </div>
        )}
      </StyledModalBody>
    </Modal>
  );
});

export default BtcErrorModal;
