import moment from "moment";

export enum TimeType {
  minute = "minute",
  second = "second",
}

const secPerMin = 60;

/**
 * Convert to miliseconds from seconds, minutes and etc
 * @param time
 * @param timeType
 */
export const getMiliseconds = (time: number, timeType: TimeType): number => {
  switch (timeType) {
    case TimeType.minute:
      return time * secPerMin * 1000;
    case TimeType.second:
      return time * 1000;
    default:
      return time;
  }
};

/**
 * Convert Miliseconds to seconds, to minutes and etc
 * @param timeType
 * @param time
 */
export const convertMilisecondsTo = (timeType: TimeType, time: number): number => {
  switch (timeType) {
    case TimeType.minute:
      return time / secPerMin / 1000;
    case TimeType.second:
      return time / 1000;
    default:
      return time;
  }
};

export const formattedDate = (date: string | undefined) => {
  return date? moment(date).format("MMMM Do, YYYY") : '';
}

export const addYearsToDate = (date: string | undefined, years: number) => {
  return date? moment(date).add(years,'y').format("MMMM Do, YYYY") : '';

}
