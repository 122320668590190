import { useEffect, useState } from "react";
import { pdfjs, Document, Page } from "react-pdf";
import { Body, BodyContainer, LoaderContainer, PageContainer, Title } from "../styles";
import loaderImage from "assets/images/loader.svg";
import { apiService } from "services/api";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import { observer } from "mobx-react";
import { useStores } from "stores";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

export const BackupPaymentMethodAgreement = observer(() => {
  const {
    appStore: { windowDimensions },
  } = useStores();

  const { t } = useTranslation();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const fileId = query.get("fileId");

  const [file, setFile] = useState<File>();
  const [numPages, setNumPages] = useState<number>(0);
  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  const getFile = async () => {
    try {
      if (fileId && typeof +fileId === "number") {
        const contract = await apiService.getFileContent(+fileId);
        setFile(contract);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getFile();
  }, []);

  return (
    <Body>
      <BodyContainer>
        <Title>{t("contractsInApplication.backup_payment_method_agreement")}</Title>

        {file ? (
          <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
            {Array(numPages)
              .fill("page")
              .map((_, index) => (
                <PageContainer key={`page_number_${index}`}>
                  <Page
                    pageNumber={index + 1}
                    width={Math.min(windowDimensions.width - 42, 776)}
                    renderAnnotationLayer={true}
                    renderTextLayer={true}
                  />
                </PageContainer>
              ))}
          </Document>
        ) : (
          <div>
            <LoaderContainer>
              <img src={loaderImage} alt="loading" />
            </LoaderContainer>
          </div>
        )}
      </BodyContainer>
    </Body>
  );
});
