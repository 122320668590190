import { Pagination } from "@mui/material";
import { device } from "config/devices";
import styled from "styled-components";

export const Wrapper = styled.div``;
export const Container = styled.div`
  height: 50vh;
  overflow-y: scroll;
  padding: 0 16px;

  > div:first-child {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-top-width: 1px;
  }
  > div:last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  > div {
    border: 1px solid var(--Divider, #e4e2ed);
    border-top-width: 0px;
  }
`;
export const TransferItem = styled.div`
  padding: 16px 24px;
  display: flex;
  align-items: center;
`;
export const TransferItemLeft = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  @media ${device.tablet} {
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;
export const TransferItemRight = styled.div`
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-end;
  @media ${device.tablet} {
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
  }
`;
export const TypeText = styled.p`
  color: var(--text-icon-dark, #242323);
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */

  font-size: 16px;
  @media ${device.tablet} {
    font-size: 14px;
  }
`;

export const DateText = styled.p`
  color: var(--text-icon-secondary, #585866);
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;

  font-size: 12px;
  line-height: 16px; /* 133.333% */
  @media ${device.tablet} {
    font-size: 13px;
    line-height: 20px; /* 133.333% */
  }
`;

export const AmountText = styled.p`
  color: #000;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  @media ${device.tablet} {
    font-size: 14px;
  }
`;

export const StatusText = styled.p`
  color: var(--text-icon-secondary, #585866);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
  @media ${device.tablet} {
    font-size: 13px;
    line-height: 20px; /* 133.333% */
    width: 100%;
    text-align: center;
  }
`;

export const StyledPagination = styled(Pagination)`
  button.Mui-selected {
    background-color: rgba(255, 82, 119, 1);
    color: #fff;
  }
  button.Mui-selected:hover {
    background-color: #ffa8bb;
  }
  button:hover {
    color: #fff;
    background-color: #ffa8bb;
  }
  button {
    color: var(--text-icon-dark, #000624);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
`;

export const PaginationWrapper = styled.div`
  display: flex;
  padding: 16px;
  justify-content: center;
  @media ${device.tablet} {
    justify-content: flex-end;
  }
`;
