import { useState } from "react";
import { observer } from "mobx-react";

import { StyledApplyAdvanceHeader } from "../Step3/styles";
import { StyledSorbetLogo } from "../styles";
import { Body, StyledCSContainer } from "./styles";

import { useStores } from "../../../stores";

import sorbetLogo from "../../../assets/logos/color-logo.svg";
import ProgressBar from "../Components/ProgressBar";
import { PayrollAccount } from "./PayrollAccount";
import { MoreDetails } from "./MoreDetails";

const CreditScore = () => {
  const [payrollAccountFlow, setPayrollAccountFlow] = useState(true);

  const {
    btcStore: { employmentsLinkProviderError },
  } = useStores();

  const switchToDetailsFlow = () => {
    setPayrollAccountFlow(false);
  };
  const showPayrollAccountFlow = payrollAccountFlow && !employmentsLinkProviderError;
  return (
    <StyledCSContainer>
      <StyledApplyAdvanceHeader>
        <StyledSorbetLogo src={sorbetLogo} />
      </StyledApplyAdvanceHeader>

      <Body>
        <ProgressBar progress={showPayrollAccountFlow ? 25 : 50} />

        {showPayrollAccountFlow ? (
          <PayrollAccount switchToDetailsFlow={switchToDetailsFlow} />
        ) : (
          <MoreDetails setPayrollAccountFlow={setPayrollAccountFlow} />
        )}
      </Body>
    </StyledCSContainer>
  );
};

export default observer(CreditScore);
