import React from "react";
import loaderImage from "assets/images/loader.svg";
import { Wrapper } from "./styles";

interface ILoaderProps {
  bottom?: boolean;
  button?: boolean;
}

export const Loader = ({ bottom = false, button = false }: ILoaderProps) => {
  return (
    <Wrapper bottom={bottom} button={button}>
      <img src={loaderImage} alt="loading" />
    </Wrapper>
  );
};
