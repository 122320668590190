import { useEffect, useRef } from "react";
import Modal from "@mui/material/Modal";
import { useTranslation } from "react-i18next";

import {
  StyledCloseButton,
  StyledModal,
  StyledModalBodyWithPadding,
  StyledModalButtonsWrapper,
  StyledModalCancelButton,
  StyledModalSubmitButton,
  StyledModalSubtitle,
  StyledModalTitle,
  StyledModalWrapper,
} from "./styles";
import { useForm } from "react-hook-form";
import {
  StyledBTCTextField,
  StyledBTCTextFieldWrapper,
  StyledCheckbox,
  StyledHyperlinkTextPurple,
  StyledSelectorLabel,
} from "pages/Btc/styles";
import { GENERAL_TEXT_LIMIT } from "pages/Btc/Constants";
import { nameRegex } from "utils/regExps";
import { VERIFY_ERRORS } from "../../../types";
import { StyledErrorTextCheckbox, StylesCheckboxWrapperTerms } from "pages/Btc/Step7/styles";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import {
  COMMON_EMAIL_TYPOS,
  E_SIGN_AGREEMENT_LINK,
  LEAD_PRIVACY,
  PRIVACY_LINK,
  TERMS_CONDITIONS_LINK,
} from "config/constants";
import { useStores } from "../../../../stores";
import { btcCheckUuid, btcErrorHandler } from "../../../../utils/btcErrorHandler";
import BtcErrorBox from "../../Components/ErrorBox";
import btcStore from "../../../../stores/btc";
import { gtmService } from "services/gtm";
import { EVENTS, useLeavePageAnalytics } from "services/analytics/events";
import { analytics } from "services/analytics";
import { LongEventsLoader } from "components/LongEventsLoader";
import { LOCAL_STORAGE_KEYS, LocalStorage } from "utils/localStorage";

type FormValues = {
  firstName?: string;
  lastName?: string;
  email?: string;
  acceptTermsOfServices?: boolean;
  acceptMarketing?: boolean;
  acceptTCPA?: boolean;
};

type PayStubsModalProps = {
  open: boolean;
};

const loaderText = [
  ["page_PtoCashAdvance.calculationStepLoader.step1line1"],
  [
    "page_PtoCashAdvance.calculationStepLoader.step2line1",
    "page_PtoCashAdvance.calculationStepLoader.step2line2",
  ],
  [
    "page_PtoCashAdvance.calculationStepLoader.step3line1",
    "page_PtoCashAdvance.calculationStepLoader.step3line2",
  ],
];

const isValidEmail = (email: string | undefined) => {
  if (email) {
    return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      email,
    );
  }
  return false;
};

const pageTranslationPrefix = "page_PtoCashAdvance.fillInDetailsModal";
const FillInDetailsModal = ({ open }: PayStubsModalProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const isSubmittedSuccessfully = useRef(false);
  const userUuid = LocalStorage.getItem(LOCAL_STORAGE_KEYS.uuid);
  const {
    btcStore: { errorBox, updateBtcApplication, btcLoading },
  } = useStores();
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm<FormValues>({ mode: "onBlur" });

  useLeavePageAnalytics((eventType) => {
    const data = getValues();
    EVENTS.btc.step2.leavePage({ action: eventType, ...data });
  });

  const handleEmailValidation = (email: string | undefined) => {
    const isValid = isValidEmail(email);
    const noCommonTypo = COMMON_EMAIL_TYPOS.filter((typo) => email?.endsWith(typo)).length === 0;
    return isValid && noCommonTypo;
  };

  useEffect(() => {
    EVENTS.btc.step2.onload();
  }, []);

  const onSubmitForm = async (data: FormValues) => {
    btcCheckUuid();
    if (userUuid !== null) {
      try {
        const modalData = {
          firstName: data.firstName?.trim(),
          lastName: data.lastName?.trim(),
          email: data.email?.toLowerCase(),
          emailDomain: data.email?.split("@")[1].toLowerCase(),
          acceptedB2cTransactionalTimestamp: data.acceptMarketing
            ? moment().toISOString()
            : undefined,
          acceptedB2cNewsletteringTimestamp: data.acceptTCPA ? moment().toISOString() : undefined,
          uuid: userUuid!,
          timestampStepOne: moment().toISOString(),
          lastSubmittedStep: 2,
          agreementsAcceptedAt: moment().toISOString(),
          agreementsAcceptedTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          agreementsAccepted: ["privacy_policy", "sorbets_terms_of_service", "e_sign_consent"],
          acceptedB2cTncTimestamp: moment().toISOString(),
        };
        await updateBtcApplication(modalData);
        EVENTS.btc.step2.submit(data, true);
        LocalStorage.setItem(LOCAL_STORAGE_KEYS.lastSubmittedStep, "3");
        isSubmittedSuccessfully.current = true;
        gtmService.customEvent({
          event: "step2Submission",
          email: data.email,
          uuid: LocalStorage.getItem(LOCAL_STORAGE_KEYS.uuid),
        });
        analytics.setSuperProps({ email: data.email });
      } catch (error: any) {
        btcErrorHandler(error, "step2");
        isSubmittedSuccessfully.current = false;
        EVENTS.btc.step2.submit({ ...data, failReason: error.message }, false);
      }
    }
  };

  const onClose = () => {
    btcStore.hideErrorBox();
    btcStore.hideErrorModal();
    LocalStorage.removeItem(LOCAL_STORAGE_KEYS.lastSubmittedStep);
    navigate(`/cash-advance/pto-cash-advance${location.search}`);
  };

  const loadingCallback = () => {
    if (isSubmittedSuccessfully.current) {
      navigate(`/cash-advance/apply-advance${location.search}`);
    }
  };

  return (
    <>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        id={"fill-in-details-modal"}
        style={{ overflow: "scroll", display: "flex" }}>
        <>
          <StyledModalWrapper>
            <StyledModal>
              <StyledModalBodyWithPadding>
                <StyledCloseButton type="button" onClick={onClose} id="modal-pay-stubs-close">
                  <svg width="14" height="14" viewBox="0 0 14 14" fill="none">
                    <path
                      d="M0.5 0.5L13.5 13.5M13.5 0.5L0.5 13.5"
                      stroke="#242323"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </StyledCloseButton>
                <StyledModalTitle>{t(`${pageTranslationPrefix}.title`)}</StyledModalTitle>
                <StyledModalSubtitle>
                  {t(`${pageTranslationPrefix}.description`)}
                </StyledModalSubtitle>
                {errorBox && <BtcErrorBox />}
              </StyledModalBodyWithPadding>

              <form onSubmit={handleSubmit(onSubmitForm)}>
                <StyledModalBodyWithPadding>
                  {/* Firstname Textfield */}
                  <StyledBTCTextFieldWrapper>
                    <StyledSelectorLabel required>
                      {t(`${pageTranslationPrefix}.firstname`)}
                    </StyledSelectorLabel>
                    <StyledBTCTextField
                      id="first-name"
                      error={!!errors["firstName"]}
                      defaultValue={""}
                      placeholder={`${t(`${pageTranslationPrefix}.first_name_placeholder`)}`}
                      {...register("firstName", {
                        required: true,
                        maxLength: GENERAL_TEXT_LIMIT,
                        pattern: {
                          value: nameRegex,
                          message: VERIFY_ERRORS.CORRECT_VALUE,
                        },
                      })}
                      helperText={
                        errors["firstName"] &&
                        t(`${pageTranslationPrefix}.errorFirstname.${errors["firstName"].type}`)
                      }
                    />
                  </StyledBTCTextFieldWrapper>

                  {/* Lastname Textfield */}
                  <StyledBTCTextFieldWrapper>
                    <StyledSelectorLabel required>
                      {t(`${pageTranslationPrefix}.lastname`)}
                    </StyledSelectorLabel>
                    <StyledBTCTextField
                      id="last-name"
                      error={!!errors["lastName"]}
                      defaultValue={""}
                      placeholder={`${t(`${pageTranslationPrefix}.last_name_placeholder`)}`}
                      {...register("lastName", {
                        required: true,
                        maxLength: GENERAL_TEXT_LIMIT,
                        pattern: {
                          value: nameRegex,
                          message: VERIFY_ERRORS.CORRECT_VALUE,
                        },
                      })}
                      helperText={
                        errors["lastName"] &&
                        t(`${pageTranslationPrefix}.errorLastname.${errors["lastName"].type}`)
                      }
                    />
                  </StyledBTCTextFieldWrapper>

                  {/* Email Textfield */}
                  <StyledBTCTextFieldWrapper>
                    <StyledSelectorLabel required>
                      {t(`${pageTranslationPrefix}.email`)}
                    </StyledSelectorLabel>
                    <StyledBTCTextField
                      id="email"
                      error={!!errors["email"]}
                      defaultValue={""}
                      placeholder={`${t(`${pageTranslationPrefix}.email_placeholder`)}`}
                      {...register("email", { required: true, validate: handleEmailValidation })}
                      helperText={
                        errors["email"] &&
                        t(`${pageTranslationPrefix}.errorEmail.${errors["email"].type}`)
                      }
                    />
                  </StyledBTCTextFieldWrapper>

                  <StylesCheckboxWrapperTerms>
                    <StyledCheckbox
                      type="checkbox"
                      id="accept-terms-of-services-checkbox"
                      {...register("acceptTermsOfServices", { required: true })}
                      error={!!errors["acceptTermsOfServices"]}
                    />
                    <span>
                      {t(`${pageTranslationPrefix}.iAcceptAndAgree`)}
                      <StyledHyperlinkTextPurple href={TERMS_CONDITIONS_LINK} target={"_blank"}>
                        {t(`${pageTranslationPrefix}.termsOfService`)}
                      </StyledHyperlinkTextPurple>{" "}
                      <StyledHyperlinkTextPurple href={PRIVACY_LINK} target={"_blank"}>
                        {t(`${pageTranslationPrefix}.privacyPolicy`)}
                      </StyledHyperlinkTextPurple>{" "}
                      <StyledHyperlinkTextPurple href={LEAD_PRIVACY} target={"_blank"}>
                        {t(`${pageTranslationPrefix}.leadPolicy`)}
                      </StyledHyperlinkTextPurple>
                      {t(`${pageTranslationPrefix}.and`)}
                      <StyledHyperlinkTextPurple href={E_SIGN_AGREEMENT_LINK} target={"_blank"}>
                        {t(`${pageTranslationPrefix}.eSignConsent`)}
                      </StyledHyperlinkTextPurple>
                    </span>
                  </StylesCheckboxWrapperTerms>

                  {!!errors["acceptTermsOfServices"] && (
                    <StyledErrorTextCheckbox>
                      {t(`${pageTranslationPrefix}.youMustCheckCheckbox`)}
                    </StyledErrorTextCheckbox>
                  )}

                  <StylesCheckboxWrapperTerms>
                    <StyledCheckbox
                      id={"accept-tcpa-checkbox"}
                      {...register("acceptTCPA", { required: true })}
                      type="checkbox"
                      error={!!errors["acceptTCPA"]}
                    />
                    {t(`${pageTranslationPrefix}.receiveTCPA`)}
                  </StylesCheckboxWrapperTerms>

                  {!!errors["acceptTCPA"] && (
                    <StyledErrorTextCheckbox>
                      {t(`${pageTranslationPrefix}.youMustCheckCheckbox`)}
                    </StyledErrorTextCheckbox>
                  )}

                  <StylesCheckboxWrapperTerms id={"accept-marketing-checkbox"}>
                    <StyledCheckbox
                      {...register("acceptMarketing", { required: false })}
                      defaultChecked
                      type="checkbox"
                    />
                    {t(`${pageTranslationPrefix}.receiveMarketingCommunications`)}
                  </StylesCheckboxWrapperTerms>
                </StyledModalBodyWithPadding>

                {/* <StyledSeparator /> */}

                <StyledModalButtonsWrapper>
                  <StyledModalSubmitButton type="submit" id="fill-in-details-modal-submit">
                    {t(`${pageTranslationPrefix}.submit`)}
                  </StyledModalSubmitButton>
                  <StyledModalCancelButton
                    type="button"
                    onClick={onClose}
                    id="fill-in-details-modal-cancel">
                    {t(`${pageTranslationPrefix}.cancel`)}
                  </StyledModalCancelButton>
                </StyledModalButtonsWrapper>
              </form>
            </StyledModal>
            <LongEventsLoader
              loading={btcLoading}
              callback={loadingCallback}
              duration={6}
              interrupt={errorBox}
              steps={loaderText}
            />
          </StyledModalWrapper>
        </>
      </Modal>
    </>
  );
};

export default FillInDetailsModal;
