import {
  CompactPrimaryButton,
  SecondaryButtonCompact,
} from "pages/Btc/ApplicationStatus/components/NMIUploads/styles";
import { Container, Content, Title, Description, CloseButton } from "./styles";
import { useTranslation } from "react-i18next";
import { useStores } from "stores";
import closeIcon from "./icons/closeIcon.svg";
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import { ROUTES } from "config/constants";
import { useEffect } from "react";
import { EVENTS } from "services/analytics/events";

const translationKey = "issuesCenter.payroll";
export const UpdatePayroll = observer(() => {
  const { t } = useTranslation();
  const {
    btcStore: { employmentsLinkProviderLoading },
    issuesStore: { hidePayrollIssue },
  } = useStores();
  const navigate = useNavigate();

  useEffect(() => {
    EVENTS.ptoCenter.payrollBanner.onView();
  }, []);

  const connectNowClick = () => {
    navigate(ROUTES.PAYROLL_FROM_CENTER);
    EVENTS.ptoCenter.payrollBanner.onLeave("Connect now");
  };
  const exploreOptionsClick = () => {
    navigate(ROUTES.PTO_CENTER, { state: { fromOrigin: "Explore Options" } });
    EVENTS.ptoCenter.payrollBanner.onLeave("Explore options");
  };

  const closeBanner = () => {
    hidePayrollIssue();
    EVENTS.ptoCenter.payrollBanner.onLeave("Close");
  };

  return (
    <>
      <Container>
        <Content>
          <div>
            <Title>{t(`${translationKey}.updateYourPayroll`)}</Title>
            <Description>{t(`${translationKey}.fastSecureEasy`)}</Description>
          </div>
          <Box sx={{ display: "flex", gap: "24px" }}>
            <CompactPrimaryButton
              disabled={employmentsLinkProviderLoading}
              onClick={connectNowClick}>
              {t(`${translationKey}.connectNow`)}
            </CompactPrimaryButton>
            <SecondaryButtonCompact
              disabled={employmentsLinkProviderLoading}
              onClick={exploreOptionsClick}>
              {t(`${translationKey}.exploreOptions`)}
            </SecondaryButtonCompact>
          </Box>
        </Content>

        <CloseButton onClick={closeBanner}>
          <img src={closeIcon} alt={"close"} />
        </CloseButton>
      </Container>
    </>
  );
});
