import { BrowserRouter } from "react-router-dom";

import { stores, StoreContext } from "stores";
import { ErrorBoundary, ScrollToTop } from "components";

import { GlobalStyle } from "assets/styles/globalStyle";
import { FontStyles } from "assets/fonts/fontStyles";
import App from "./App";
import { gtmService } from "services/gtm";

import "./i18n";

import "index.css";

// After
import { createRoot } from "react-dom/client";
import { initLogger } from "services/logger";
import { StatsigProvider } from "statsig-react";
import { ENV } from "config/env";
const container = document.getElementById("root");
const root = createRoot(container!); // createRoot(container!) if you use TypeScript

gtmService.init();

root.render(
  <StoreContext.Provider value={stores}>
    <ErrorBoundary>
      <BrowserRouter>
        <FontStyles />
        <GlobalStyle />
        <ScrollToTop />
        <StatsigProvider
          user={{}}
          sdkKey={ENV.REACT_APP_STATSIG_SDK_KEY}
          waitForInitialization={true}
          options={{
            environment: { tier: ENV.REACT_APP_ENV },
            initCompletionCallback: () => {
              initLogger();
            },
          }}>
          <App />
        </StatsigProvider>
      </BrowserRouter>
    </ErrorBoundary>
  </StoreContext.Provider>,
);
