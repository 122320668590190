import { Statsig } from "statsig-react";
import { CoralogixLogger } from "./coralogix";
import { DatadogLogger } from "./datadog";
import { MockLogger } from "./mockLogger";

export enum LOGGER_TYPE {
  DATADOG = "datadog",
  MOCK_LOGGER = "mockLogger",
  CORALOGIX = "coralogix",
}
export interface ILogger {
  captureError: (error: any, data?: object) => void;
  captureMessage: (message: string, data?: object) => void;
  flush?: () => void;
  addLoggerGlobalContext: ({
    id,
    username,
    email,
  }: {
    id: string;
    username?: string;
    email?: string;
  }) => void;
  removeLoggerGlobalContext: (key: string) => void;
}
export default class Logger {
  static createLogger(type: LOGGER_TYPE = LOGGER_TYPE.DATADOG): ILogger | ILogger[] {
    switch (type) {
      case LOGGER_TYPE.CORALOGIX: {
        if (Statsig.checkGate("fe_use_datadog")) {
          return [new CoralogixLogger(), new DatadogLogger()];
        } else {
          return new CoralogixLogger();
        }
      }
      case LOGGER_TYPE.MOCK_LOGGER:
        return new MockLogger();
      case LOGGER_TYPE.DATADOG:
      default:
        return new DatadogLogger();
    }
  }
}
