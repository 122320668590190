import styled from "styled-components";

export const StyledGreyContainer = styled.div`
  flex-direction: column;
  display: flex;
  background-color: #F8F8FC;
  margin-top: 24px;
  padding: 16px;
`;

export const StyledButtonContainer= styled.button`
  flex-direction: column;
  width: 240px;
  border-width: 1px;
  border-style: solid;
  border-color: #FF5277;
  border-radius: 8px;
  padding: 8px;
  margin-top: 24px;
  align-content: center;
  align-items: center;
  :hover {
    opacity: 0.8;
  }
`;

export const StyledButtonText = styled.text`
  font-weight: 400;
  font-family: "Poppins";
  font-size: 14px;
  color: #FF5277;
`;
