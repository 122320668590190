import { useTranslation } from "react-i18next";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react";
import PinwheelModal, { ErrorEventPayload } from "@pinwheel/react-modal";

import { Loader, StyledCSBody, StyledSubmitButton } from "../styles";
import { StyledBTCSBackButton } from "../../styles";

import payStubGeneral from "./icons/payStubGeneral.svg";
import secureSvg from "./icons/secure.svg";
import lockSvg from "./icons/lock.svg";
import loaderImage from "assets/images/loader.svg";

import BtcErrorBox from "../../Components/ErrorBox";
import { useStores } from "stores";
import { EVENTS, useLeavePageAnalytics } from "services/analytics/events";
import {
  Description,
  Title,
  PayrollAccountActionButtons,
  PayrollAccountButtons,
  PayrollAccountDoItManually,
  Item,
  ItemTitle,
  ItemDescription,
} from "./styles";
import { snackbarUtils } from "components/Notification";
import { LOCAL_STORAGE_KEYS, LocalStorage } from "utils/localStorage";

type Props = { switchToDetailsFlow: () => void };

export const PayrollAccount = observer(({ switchToDetailsFlow }: Props) => {
  const [openWidget, setOpenWidget] = useState(false);
  const { t } = useTranslation();
  let navigate = useNavigate();
  const location = useLocation();
  const {
    btcStore: {
      errorBox,
      getEmploymentsLinkProvider,
      employmentsLinkProviderLoading,
      employmentsLinkProvider,
      finishPayrollAccount,
    },
  } = useStores();
  const previousScreenName = useRef<string>();
  const previousScreenNameWithoutExit = useRef<string>();

  useLeavePageAnalytics((eventType) => {
    if (openWidget) {
      EVENTS.btc.payroll.leaveConnectingPayrollPage(eventType);
    } else {
      EVENTS.btc.payroll.leavePage(eventType);
    }
  });

  useEffect(() => {
    EVENTS.btc.payroll.onload();
    const userUuid = LocalStorage.getItem(LOCAL_STORAGE_KEYS.uuid);
    if (userUuid) {
      getEmploymentsLinkProvider(userUuid);
    }
  }, []);

  const nextClick = () => {
    setOpenWidget(true);
    EVENTS.btc.payroll.acceptConnectingPayroll();
  };

  const handleBackClick = () => {
    navigate(`/cash-advance/apply-info${location.search}`);
    EVENTS.btc.payroll.leavePage("Back");
  };

  const doItManuallyClick = () => {
    EVENTS.btc.payroll.leavePage("I'd rather do it manually");
    switchToDetailsFlow();
  };
  const onError = (error: ErrorEventPayload) => {
    if (error.code === "expiredLinkToken") {
      EVENTS.btc.payroll.expiredToken();
    }
  };
  const onEvent = (name: string, payload: any) => {
    switch (name) {
      case "open":
        EVENTS.btc.payroll.onOpenWidget();
        break;
      case "select_employer":
        EVENTS.btc.payroll.selectPlatform(payload?.selectedEmployerName, "employer");
        break;
      case "select_platform":
        EVENTS.btc.payroll.selectPlatform(payload?.selectedPlatformName, "payroll");
        break;
      case "login_attempt":
        EVENTS.btc.payroll.selectedPlatformId(payload?.platformId);
        break;
      case "screen_transition":
        if (payload?.screenName === "LOGIN") {
          EVENTS.btc.payroll.userEntersCredentials();
        }
        if (payload?.screenName === "PROVIDER_CONFIRMATION") {
          EVENTS.btc.payroll.Provider_Confirmation();
        }
        if (payload?.screenName === "SEARCH_RESULTS") {
          EVENTS.btc.payroll.searchingForPayroll();
        }
        if (payload?.screenName === "SEARCH_DEFAULT") {
          EVENTS.btc.payroll.viewSelectPayrollProvider();
          if (!previousScreenName.current) {
            EVENTS.btc.payroll.continueToConnectionToPayroll("Payroll Connection Fork Page");
          }
        }
        previousScreenName.current = payload?.screenName;
        if (payload?.screenName !== "EXIT_CONFIRMATION") {
          previousScreenNameWithoutExit.current = payload?.screenName;
        }
        break;
      default:
        break;
    }
  };
  const onSuccess = () => {
    finishPayrollAccount();
    EVENTS.btc.payroll.completeConnectToPinwheel();
    setOpenWidget(false);
    switchToDetailsFlow();
  };
  const onExit = (payload?: ErrorEventPayload) => {
    if (previousScreenName.current === "JOB_SUCCESS") {
      snackbarUtils.success(t("page_CreditStore.successfullyConnectedPayroll"));
    }
    if (previousScreenName.current === "EXIT_CONFIRMATION") {
      EVENTS.btc.payroll.leaveConnectingPayrollPage("X");
      EVENTS.btc.payroll.userClosesPayrollWidget(previousScreenNameWithoutExit.current);
    }
    setOpenWidget(false);
    switchToDetailsFlow();
    previousScreenName.current = undefined;
  };

  return (
    <>
      {!employmentsLinkProviderLoading ? (
        <StyledCSBody>
          {errorBox && <BtcErrorBox />}
          <Title>{t(`page_CreditStore.getYourEmploymentDetails`)}</Title>
          <Description>{t(`page_CreditStore.youCanLoginToPayroll`)}</Description>

          <Item>
            <img src={secureSvg} alt={"secure"} />
            <div>
              <ItemTitle>{t(`page_CreditStore.secure`)}</ItemTitle>
              <ItemDescription>{t(`page_CreditStore.securelyConnectingPto`)}</ItemDescription>
            </div>
          </Item>

          <Item>
            <img src={lockSvg} alt={"lock"} />
            <div>
              <ItemTitle>{t(`page_CreditStore.fastEasy`)}</ItemTitle>
              <ItemDescription>{t(`page_CreditStore.connectingYourPTOData`)}</ItemDescription>
            </div>
          </Item>

          <PayrollAccountButtons>
            <StyledBTCSBackButton
              onClick={handleBackClick}
              startIcon={<ArrowBackIcon />}
              id="payroll-account-back">
              {t(`page_CreditStore.back`)}
            </StyledBTCSBackButton>

            <PayrollAccountActionButtons>
              <PayrollAccountDoItManually
                startIcon={<img src={payStubGeneral} alt={"do-it-manually"} />}
                onClick={doItManuallyClick}
                id={"payroll-account-do-it-manually"}>
                {t(`page_CreditStore.doItManually`)}
              </PayrollAccountDoItManually>

              <StyledSubmitButton onClick={nextClick} id={"payroll-account-next"}>
                {t(`page_CreditStore.submitButton`)}
              </StyledSubmitButton>
            </PayrollAccountActionButtons>
          </PayrollAccountButtons>
          <PinwheelModal
            open={openWidget}
            linkToken={employmentsLinkProvider?.publicToken || ""}
            onSuccess={onSuccess}
            onError={onError}
            onEvent={onEvent}
            onExit={onExit}
          />
        </StyledCSBody>
      ) : (
        <Loader>
          <img src={loaderImage} alt="loading" />
        </Loader>
      )}
    </>
  );
});
