import { useTranslation } from "react-i18next";
import {
  StyledModal,
  StyledModalContainer,
  Description,
  ButtonsContainer,
  PrimaryButton,
  SecondaryButton,
  IconsContainer,
  CloseIcon,
} from "./styles";
import {
  E_SIGN_AGREEMENT_LINK,
  PRIVACY_LINK,
  TERMS_CONDITIONS_LINK,
} from "../../../config/constants";
import bulbIcon from "../../../assets/icons/bulb.svg";
import closeIcon from "../../../assets/icons/close-white.svg";
import { observer } from "mobx-react";
import { useStores } from "stores";
import { useEffect, useState } from "react";
import { ContractExecutionStatus, ContractTypeEnum } from "services/api/types";
import { apiService } from "services/api";
import { snackbarUtils } from "components/Notification";

const translationPrefix = "updateNotifications";
export const UpdateNotification = observer(() => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const {
    contractsStore: { unapprovedContracts, getUnapprovedContracts },
  } = useStores();
  useEffect(() => {
    getUnapprovedContracts();
  }, []);
  useEffect(() => {
    setOpen(
      unapprovedContracts.privacy_policy ||
        unapprovedContracts.e_sign_consent ||
        unapprovedContracts.sorbets_terms_of_service,
    );
  }, [unapprovedContracts]);

  const multiple =
    +unapprovedContracts.privacy_policy +
    +unapprovedContracts.e_sign_consent +
    +unapprovedContracts.sorbets_terms_of_service;

  const PrivacyPolicyLink = () => {
    return (
      <a href={PRIVACY_LINK} target="_blank" rel="noopener noreferrer">
        {t(`${translationPrefix}.privacyPolicy`)}
      </a>
    );
  };

  const TermsLink = () => {
    return (
      <a href={TERMS_CONDITIONS_LINK} target="_blank" rel="noopener noreferrer">
        {t(`${translationPrefix}.termsOfService`)}
      </a>
    );
  };

  const EsignLink = () => {
    return (
      <a href={E_SIGN_AGREEMENT_LINK} target="_blank" rel="noopener noreferrer">
        {t(`${translationPrefix}.eSign`)}
      </a>
    );
  };

  const agreeClick = async () => {
    setOpen(false);
    try {
      const signContractData = {
        signStatus: ContractExecutionStatus.signed,
        signAtTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        signedAt: new Date().toISOString(),
      };
      if (unapprovedContracts.privacy_policy) {
        await apiService.generateContract({
          type: ContractTypeEnum.privacy_policy,
          ...signContractData,
        });
      }
      if (unapprovedContracts.e_sign_consent) {
        await apiService.generateContract({
          type: ContractTypeEnum.e_sign_consent,
          ...signContractData,
        });
      }
      if (unapprovedContracts.sorbets_terms_of_service) {
        await apiService.generateContract({
          type: ContractTypeEnum.sorbets_terms_of_service,
          ...signContractData,
        });
      }
      await getUnapprovedContracts();
    } catch (error: any) {
      snackbarUtils.error(`${t(`${translationPrefix}.somethingWentWrong`)}: ${error.message}`);
    }
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <StyledModal open={open}>
      <StyledModalContainer>
        <IconsContainer>
          <img src={bulbIcon} style={{ height: 24, width: 20 }} alt={"bulb-icon"} />

          <CloseIcon onClick={onClose}>
            <img src={closeIcon} alt={"close"} />
          </CloseIcon>
        </IconsContainer>
        <Description>
          <span>
            {t(`${translationPrefix}.our`)}
            {unapprovedContracts.privacy_policy && <PrivacyPolicyLink />}
            {unapprovedContracts.privacy_policy && multiple > 2
              ? ", "
              : unapprovedContracts.privacy_policy && multiple > 1
              ? t(`${translationPrefix}.and`)
              : ""}
            {unapprovedContracts.sorbets_terms_of_service && <TermsLink />}
            {unapprovedContracts.sorbets_terms_of_service && multiple > 1
              ? t(`${translationPrefix}.and`)
              : ""}
            {unapprovedContracts.e_sign_consent && <EsignLink />}
            {multiple > 1
              ? t(`${translationPrefix}.manyBeenUpdated`)
              : t(`${translationPrefix}.oneBeenUpdated`)}
          </span>
        </Description>
        <ButtonsContainer>
          <SecondaryButton onClick={onClose}>{t(`${translationPrefix}.notNow`)}</SecondaryButton>
          <PrimaryButton onClick={agreeClick}>{t(`${translationPrefix}.agree`)}</PrimaryButton>
        </ButtonsContainer>
      </StyledModalContainer>
    </StyledModal>
  );
});
