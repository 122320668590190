import { createContext, useContext } from "react";
import userStore from "./user";
import appStore from "./app";
import webAppStore from "./webApp";
import btcStore from "./btc";
import leadStore from "./lead";
import transfersInfoStore from "./transfersInfo";
import { IUserStore } from "./user/types";
import { IAppStore } from "./app/types";
import { IWebAppStore } from "./webApp/types";
import { IBtcStore } from "./btc/types";
import debitConsentStore from "./debitConsent";
import { IDebitConsentStore } from "./debitConsent/types";
import { ILeadStore } from "./lead/types";
import { ITransfersInfoStore } from "./transfersInfo/types";
import bankDetailsStore from "./bankDetails";
import { IBankDetailsStore } from "./bankDetails/types";
import issuesStore from "./issues";
import { IIssueStore } from "./issues/types";
import loanStore from "./loan";
import { ILoanStore } from "./loan/types";
import routerStore from "./router";
import contractsStore from "./contracts";
import { IRouterStore } from "./router/types";
import { IContractsStore } from "./contracts/types";

export interface IStore {
  userStore: IUserStore;
  appStore: IAppStore;
  webAppStore: IWebAppStore;
  btcStore: IBtcStore;
  debitConsentStore: IDebitConsentStore;
  leadStore: ILeadStore;
  transfersInfoStore: ITransfersInfoStore;
  bankDetailsStore: IBankDetailsStore;
  issuesStore: IIssueStore;
  loanStore: ILoanStore;
  routerStore: IRouterStore;
  contractsStore: IContractsStore;
}

export const stores: IStore = {
  userStore,
  appStore,
  webAppStore,
  btcStore,
  debitConsentStore,
  leadStore,
  transfersInfoStore,
  bankDetailsStore,
  issuesStore,
  loanStore,
  routerStore,
  contractsStore,
};

export const StoreContext = createContext(stores);

export const useStores = () => {
  return useContext(StoreContext);
};
