import { LOGGER_TYPE } from "services/logger/factory";

interface IAppConfig {
  REACT_APP_API_URL: string;
  REACT_APP_API_URL_QA?: string;
  REACT_APP_MOBILE_API_ALIASING_URL: string;
  REACT_APP_MOBILE_API_ALIASING_URL_QA?: string;
  REACT_APP_ENV: Environments;
  REACT_APP_USE_SORBET_JWT: "yes" | "no";
  REACT_APP_FIREBASE_API_KEY: string;
  REACT_APP_FIREBASE_PROJECT_ID: string;
  REACT_APP_FIREBASE_SENDER_ID: string;
  REACT_APP_FIREBASE_APP_ID: string;
  REACT_APP_FIREBASE_RO_API_KEY: string;
  REACT_APP_FIREBASE_RO_PROJECT_ID: string;
  REACT_APP_FIREBASE_RO_SENDER_ID: string;
  REACT_APP_FIREBASE_RO_APP_ID: string;
  REACT_APP_GTM_ID: string;
  REACT_APP_VERSION_NUMBER?: string;
  REACT_APP_STATSIG_SDK_KEY: string;

  REACT_APP_IDLE_TIME: number;

  REACT_APP_MIXPANEL_TOKEN: string;
  REACT_APP_USE_ANALYTICS: "yes" | "no";
  REACT_APP_VGS_VAULT: string;
  REACT_APP_VGS_CNAME: string;
  REACT_APP_VGS_CNAME_QA?: string;
  REACT_APP_VGS_ENV: string;
  REACT_APP_ZENDESK_KEY: string;
  REACT_APP_AUTH_MODE: string;

  REACT_APP_APM_PROVIDER: LOGGER_TYPE;
  REACT_APP_DATADOG_SITE: string;
  REACT_APP_DATADOG_LOGS_TOKEN: string;
  REACT_APP_CORALOGIX_PUBLIC_KEY: string | undefined;
  REACT_APP_DATADOG_LOGS_SERVICE: string;
  REACT_APP_DATADOG_RUM_TOKEN: string;
  REACT_APP_DATADOG_RUM_ID: string;
  REACT_APP_DATADOG_RUM_SERVICE: string;
  REACT_APP_DATADOG_ALLOWED_TRACING_ORIGINS: string;
  REACT_APP_GOOGLE_MAPS_API_KEY: string;
  REACT_APP_SORBET_QAT_TOKEN_SIGN: string;
  REACT_APP_DATADOG_DISABLE: "yes" | "no" | undefined;
}

export const ENV: IAppConfig = {
  ...(window["_env_" as any] as unknown as IAppConfig),
  ...process.env,
};

export enum Environments {
  PRODUCTION = "production",
  QA = "qa",
  QAT = "qat",
  DEMO = "demo",
}
