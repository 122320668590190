export const AccessedChars = /^[`!@#$%^&*()_+=[\]{};':"|<>\\/?~ a-z0-9 -]+$/i;

export const OnlyLettersChars = /^[`!@#$%^&*()_+=[\]{};':"|<>\\/?~ a-z -]+$/i;

export const Phone = /(\+?\d{8,10})/;

export const ZipCode = /^[0-9]*$/g;

export const nameRegex = /^[a-z -']+$/i;

export const addressRegex = /^[a-zA-Z0-9\s,'-]+$/i;

export const stateRegex = /^[a-zA-Z\s,'-]+$/i;
