type SorbetDate = string;
export enum API_ERROR_CODES {
  CommonError = 30000,
  InvalidRegion = 30001,
  UserNotFound = 30002,
  InvalidParam = 60200,
  InvalidPhone = 30009,
  CardLimitExceeded = 30011,
  InvalidOtp = 30014,
  UnauthorizedError = 30016,
  invalidAddress = 30018,
  UnauthorizedSuspiciousError = 30017,
  UnauthorizedStatus = 401,
  LeadAlreadyExists = 30026,
  UserAlreadyExists = 30007,
  invalidEmail = 30036,
  BANK_INTEGRATION_TOKEN_IS_NOT_VALID = 30044,
  MAX_BANKS_EXCEEDED = 30054,
  BANK_ACCOUNT_ALREADY_EXIST = 30061,
}

export enum PAYMENT_SERVICE {
  STRIPE = "stripe",
}

export interface IOrganization {
  adminId: string;
  fid: string;
  id: string;
  name: string;
  startPeriod: string;
  endPeriod: string;
  hoursPerDay?: number;
  workingHoursPerDayMinutes?: number;
  balanceLimit?: number;
  cardTermsUrl?: string;
  paymentServiceName?: PAYMENT_SERVICE;
}

export enum AddressType {
  PERSONAL = "personal",
  SHIPPING = "shipping",
}

export interface IAddressData {
  apartment?: string;
  city?: string;
  country?: string;
  state?: string;
  addressLine?: string;
  zipCode?: string;
  countryCode?: string | number;
  street?: string;
}

export interface IAddress extends IAddressData {
  id: string;
  userId: string;
  type: AddressType;
}

export enum PtoPolicy {
  perPayroll = "standard",
  inAdvance = "pto bank",
}

export interface IUserInfoResponse {
  id: string;
  fid: string;
  email: string;
  firstName: string;
  lastName: string;
  familyStatus?: string;
  addresses?: IAddress[];
  avatarUrl: string | null | undefined;
  bankIntegrationId: number | null | undefined;
  ptoPolicy?: PtoPolicy | string;
  jobTitle: string;
  organization: IOrganization;
  birthDate?: string;
  createDate: string;
  lastReportedPTOBalance?: number | null;
  marketingAllowed: boolean;
  orgUnitId: number;
  organizationId: number;
  peakHour: string | null;
  phone?: string;
  updateDate: string;
  userStatus?: string;
  hourlyRate?: number;
  countryCode?: string | number;
  numberOfChildren?: string;
  cardCreatedAt?: string;
  hourlyRateCurrency?: string;
  uniqueUserId: string;
  couponRequested?: boolean;
  agreementAcceptedTime?: string;
  acceptedSorbetTermsTime?: string;
  agreementAccepted?: boolean;
  acceptedSorbetTerms?: boolean;
}

export interface IUserInfo extends Omit<IUserInfoResponse, "addresses"> {
  personalAddress: IAddressData;
  middleName?: string;
}

export interface ICardInfo {
  token: string;
  state: string;
  cardNumber: string;
  expiration: string;
  balance: number;
  totalFunds: number;
  usedFunds: number;
  currencyCode: string;
  activatedDate: string;
  totalAuthorizationsAmount: string;
}

export enum EB2cApplicationStatus {
  Pending = "Pending",
  Declined = "Declined",
  Approved = "Approved",
  UnderReview = "Under Review",
  NeedMoreInfo = "Need More Info",
  Frozen = "Frozen",
  Canceled = "Canceled",
  Incomplete = "Incomplete",
}

export enum ENeedMoreInfoReasonsCategory {
  screening = "screening",
  kyc = "kyc",
  emailAddress = "emailAddress",
  paystub = "paystub",
  pto = "pto",
}

export enum NeedMoreInfoSubCategoriesEnum {
  UNCLEAR_PTO_POLICY = "Unclear PTO Policy",
  MISSING_PTO_BALANCE = "Missing PTO Balance",
  ILLEGIBLE_PARTIALLY_READABLE = "Illegible / Partially Readable",
  MORE_RECENT_PAYSTUB = "More Recent paystub",
  NEEDS_TO_CONTAIN_PAY_RATE = "Needs to contain pay rate",
  NEED_AN_ELIGIBLE_EMAIL_ADDRESS = "Need an eligible Email Address",
  NEED_A_SELFIE_WITH_ID = "Need a selfie with ID",
  NEED_A_SELFIE_WITH_ID_SCREENING = "Need a selfie with ID for screening",
  NEED_A_SELFIE_WITH_ID_KYC = "Need a selfie with ID for KYC",
  NEED_TO_VERIFY_ADDRESS = "Need to verify address (State)",
}

export type BTCReasonType = "active" | "inactive";
export interface ILeadInfo {
  uuid: string;
  firstName: string;
  lastName: string;
  addressLine: string;
  city: string;
  state: string;
  zipCode: string;
  b2cApplicationStatus: EB2cApplicationStatus;
  sponsor: LeadSponsorEnum;
  missingRequiredFields: string[];
  b2cContractStatus: UserContractStatusEnum;
  b2cDeclinedTimestamp: Date;
  timestampStepSix: Date;
  b2cPaybackAmountCents: number;
  b2cApprovedAmountCents: number;
  b2cDeclinedReasons: {
    msg: string;
    subCategory: string;
    category: string;
    priority: number;
    status: BTCReasonType;
  }[];
  b2cNeedMoreInfoReasons: {
    msg: string;
    subCategory: NeedMoreInfoSubCategoriesEnum;
    category: ENeedMoreInfoReasonsCategory;
    priority: number;
    status: BTCReasonType;
  }[];
}

export interface IAchTransfer {
  id: number;
  type: AchTransferType;
  status: AchTransferStatus;
  amount: number;
  bankIntegrationId: number;
  consentId: number;
  providerTransferId: string;
  uniqueTransferId: string;
  providerErrorMessage: string;
  providerErrorCode: string;
  internalErrorMessage: string;
  description: string;
  createdAt: string;
  updatedAt: string;
  bankAccountLast4: string;
}

export interface IAchTransfers {
  data: IAchTransfer[];
}

export interface ICreateConsent {
  type: DebitAchAuthTypeEnum;
  status: ConsentStatusEnum;
  paymentType?: PaymentTypeEnum;
  version: string;
  bankIntegrationId: number;
  bankAccountDetailId?: number;
  paymentDueDate: string | Date;
  enforceCancellation?: boolean;
}

export interface IConsents {
  offCycle?: IConsentResponse;
  oneTime?: IConsentResponse;
  recurring?: IConsentResponse;
}

export interface IConsentResponse {
  type: DebitAchAuthTypeEnum;
  status: ConsentStatusEnum;
  version: string;
  bankIntegrationId: number;
  id: number;
  createdAt: string;
  updatedAt: string;
  paymentDueDate: string;
}

export interface ICardLoad {
  amount: number;
  timeUnit: UNIT_TYPES.MONEY;
  code: string;
  termsAndConditions?: object;
}

export interface ICardPanInfo {
  state: string;
  cardNumber: string;
  expMonth: string;
  expYear: string;
  cvc: string;
}

export enum UNIT_TYPES {
  DAYS = "days",
  HOURS = "hours",
  MINUTES = "minutes",
  MONEY = "money",
}

export enum DEBIT_CONSENT_VERSIONS {
  AUTOPAY = "auto_pay_v2",
  ONE_TIME = "one_time_pay_v2",
  OFF_CYCLE = "off_cycle_v1",
  BACKUP = "backup_v0",
}

export interface IApiError {
  message?: string;
  code?: number;
  name?: string;
  status?: number;
  details?: [];
}

export interface IResponseErrorDto {
  message: string;
  name?: string;
  code?: number;
}

export interface IPeakHoursItem {
  id: number;
  key: string;
  title: string;
}

export interface IGoalItem {
  key: string;
  title: string;
}

export type FilterValue = string | number | boolean;

export interface IFilterOption<T extends FilterValue = string> {
  title?: string;
  value: T;
}

export interface IAppInitConfig {
  goals: IGoalItem[];
  peakHours: IPeakHoursItem[];
  countries: string[];
  states: string[];
  statesByCountry: { [key: string]: string[] };
}

export type CurrencyCodeType = "USD" | string; // ISO 4217

export type Status = "failed" | "success" | "done";

export type Direction = "debit" | "credit";

export type TransactionSource = "stripe" | "sorbet";

export interface ITransaction {
  id: number;
  name: string;
  date: string;
  category: string | null;
  amount: number;
  currencyCode: CurrencyCodeType;
  status: Status;
  direction: Direction;
  transactionSource: TransactionSource;
}

export interface ITransactions {
  nextToken: string;
  transactions: ITransaction[];
}

export type ICommunityMember = Pick<IUserInfo, "id" | "firstName" | "lastName" | "avatarUrl">;

export enum ConsentStatusEnum {
  APPROVED = "approved",
  CANCELLED = "cancelled",
  NONE = "none",
}

export enum DebitAchAuthTypeEnum {
  ONE_TIME = "oneTime",
  AUTO_PAY = "recurring",
  NONE = "none",
  OFF_CYCLE = "offCycle",
  BACKUP_BA = "backupBA",
}

export interface IOrgPtoPolicy {
  id: number;
  orgId: number;
  accrualHours: number;
  xAnnualAllocation: number;
  liquidationPolicy: LiquidationPolicyEnum;
  liquidationUpTo: number;
  liquidationPolicyValue: number;
  ptoPolicyType: PtoPolicy;
}

export enum LiquidationPolicyEnum {
  UP_TO_PERCENTAGE = "upToPercentage",
  NO_POLICY = "noPolicy",
  MIN_TO_LIQUIDATE = "minToLiquidate",
}

export interface IGeneralUserInfo {
  hoursPerDay: number;
  workingHoursPerDayMinutes?: number;
  availableForLiquidation: number;
  extraMoney: number;
  extraTime: number;
  activationFee: number;
  loadingFee: ILoadingFee;
  availableForPlanning: number;
  card: ICardInfo;
  user: IUserInfo;
  orgPtoPolicy: IOrgPtoPolicy;
}

export enum ApplicationTypeEnum {
  LOAN = "loan",
  PROMISSORY_NOTE = "promissory_note",
}

export type LoanStatusType =
  | "pending"
  | "originated"
  | "declined"
  | "active"
  | "frozen"
  | "accelerated"
  | "chargedOff"
  | "paidoff"
  | "canceled";

export enum LoanSourceEnum {
  LEAD = "lead",
  STATEMENT = "statement",
  EXPECTED_PAYMENTS = "expected_payments",
}

export interface IAmortizeLoanSchedule {
  date: string;
  amount: number;
}

export interface IAmortizeLoanInfo {
  amountFinanced: number;
  schedule: IAmortizeLoanSchedule[];
}

type TLoanBalances = {
  paidBalances: { paidTotalAmountCents: number };
  outstandingBalances: {
    outstandingFeesAmountCents: number;
    outstandingInterestAmountCents: number;
    outstandingPrincipalAmountCents: number;
    outstandingTotalAmountCents: number;
  };
  overdueBalances: {
    overdueFeesAmountCents: number;
    overdueInterestAmountCents: number;
    overduePrincipalAmountCents: number;
    overdueTotalAmountCents: number;
  };
  overdueNumberDays: number;
};

type TDisbursement = {
  netAmount: number;
  grossAmount: number;
  feeRatePercentage: number;
  paymentType: PaymentTypeEnum;
};

export interface ILoanDataInfo {
  externalId: string;
  monthlyPaymentDay: number;
  paymentDueDate: string;
  b2cReceiveMoneyDate: string;
  paymentDueAmountCents: number;
  totalPaybackAmountCents: number;
  outstandingPrincipalBalanceCents: number;
  applicationType: ApplicationTypeEnum;
  loanStatus: string;
  monthlyPaymentAmountCents: number;
  b2cPaybackAmountCents: number;
  feesAmountCents: number;
  balances: TLoanBalances;
  maturityDate: any;
  source: LoanSourceEnum;
  disbursement?: TDisbursement;
  createdAt?: string;
}

export interface ILoadingFee {
  type: string;
  valueCents: number;
  valuePercentage: number;
}

////////////////////////////////////////////////////// BTC //////////////////////////////////////////////////////
export type TAnnualSalaryRange =
  | "$20k_30k"
  | "$30k_40k"
  | "$40k_50k"
  | "$50k_75k"
  | "$75k_100k"
  | "$100k_150k"
  | "$150k_200k"
  | "more_than_$200k";

export type TPtoPolicyDesc = "carry_over" | "forfeit" | "unlimited" | "no_pto" | "not_sure" | "";

export type THowToUseFunds =
  | "credit_card_payoff_repayment"
  | "debt_consolidation"
  | "home_improvement"
  | "indulge_myself"
  | "travel_wellness"
  | "moving_relocation"
  | "medical_expenses"
  | "special_occasion"
  | "wedding"
  | "car_financing"
  | "everyday_expenses"
  | "other";

export type TLivingArrangement = "own_home" | "renting" | "living_with_family_friends";
export type TEmploymentStatus =
  | "employed_w2"
  | "employed_1099"
  | "self_employed"
  | "unemployed"
  | "homemaker"
  | "retired"
  | "active_military_status"
  | "unable_to_work";

export type THearAboutUsFrom =
  | "Search_Engine"
  | "Facebook"
  | "Instagram"
  | "Tiktok"
  | "LinkedIn"
  | "Blog"
  | "Friend_Colleague"
  | "Employer"
  | "Partner_website";

export interface IBTCPatchData {
  uuid: string;
  applicationType?: string;
  firstName?: string;
  lastName?: string;
  returnedEmailUrl?: string;
  countryCode?: number;
  email?: string;
  annualSalaryCents?: number;
  annualSalaryRange?: TAnnualSalaryRange;
  calculatedPtoCashCents?: number;
  ptoBalanceMinutes?: number;
  ptoPolicyDesc?: TPtoPolicyDesc;
  utm?: string | null | undefined;
  timestampStepOne?: string;
  centsToAdvance?: number;
  howToUseFunds?: THowToUseFunds;
  timestampStepTwo?: string;
  phone?: string;
  state?: string;
  lastSubmittedStep?: number;
  city?: string;
  addressLine?: string;
  zipCode?: string;
  birthDate?: string;
  bankIntegrationId?: number | null | undefined;
  timestampStepThree?: string;
  livingArrangement?: TLivingArrangement;
  employmentStatus?: TEmploymentStatus;
  companyName?: string;
  isActiveMilitary?: string;
  jobTitle?: string;
  timestampStepFour?: string;
  ssnNumber?: string;
  yearlyPtoDays?: string;
  currentlyAccruedPtoDays?: string;
  payoutAtTermination?: string;
  ptoDueTime?: string;
  ptoRolloverLimit?: string;
  ptoRolloverLimitValue?: string;
  timestampStepFive?: string;
  timestampStepSix?: string;
  isReturnedFromEmail?: boolean;
  acceptedB2cTransactionalTimestamp?: string;
  acceptedB2cTncTimestamp?: string;
  acceptedB2cNewsletteringTimestamp?: string;
  hearAboutUsFrom?: THearAboutUsFrom;
  industry?: LeadIndustryType;
  seniority?: SeniorityType;
  timestampCalculatorFt?: string;
  timestampCalculator?: string;
}

export type AchTransferType = "debit" | "credit";

export type AchTransferStatus =
  | "pending"
  | "posted"
  | "settled"
  | "canceled"
  | "returned"
  | "creation_failed";

export type LeadIndustryType =
  | "natural_resources_mining"
  | "construction"
  | "manufacturing"
  | "retail"
  | "transportation_warehousing"
  | "utilities"
  | "information"
  | "software"
  | "finance"
  | "insurance"
  | "real_estate"
  | "health_care"
  | "education"
  | "law_firms_legal_services"
  | "professional_business_services"
  | "leisure_hospitality"
  | "other_services"
  | "government"
  | "other";

export type SeniorityType =
  | "less_than_1_year"
  | "1_2_years"
  | "2_3_years"
  | "3_4_years"
  | "4_5_years"
  | "5_7_years"
  | "7_10_years"
  | "10_15_years"
  | "15_20_years"
  | "more_than_20_years";

export enum LeadFileType {
  payStubOne = "payStubOne",
  ptoBalanceDoc = "ptoBalanceDoc",
  idSelfie = "idSelfie",
  ptoPolicyProof = "ptoPolicyProof",
  idStatementWithAddress = "idStatementWithAddress",
}

export interface IBTCUpdateFiles {
  uuid: string;
  [LeadFileType.payStubOne]?: File;
  [LeadFileType.ptoBalanceDoc]?: File;
  [LeadFileType.idSelfie]?: File;
  [LeadFileType.ptoPolicyProof]?: File;
  [LeadFileType.idStatementWithAddress]?: File;
}

export type TPayoutsAtTermination = "yes" | "no" | "dont_know";
export type TPtoRolloverLimits = "yes" | "no" | "dont_know";
export type TPtoDueTimes = "yearly" | "monthly";
export type TPtoRolloverLimitValues =
  | "dont_know"
  | "1"
  | "2"
  | "3"
  | "4"
  | "5"
  | "6"
  | "7"
  | "8"
  | "9"
  | "10"
  | "11"
  | "12"
  | "13"
  | "14"
  | "15"
  | "16"
  | "17"
  | "18"
  | "19"
  | "20"
  | "21"
  | "22"
  | "23"
  | "24"
  | "25"
  | "26"
  | "27"
  | "28"
  | "29"
  | "30"
  | "more_than_30";
export type TYearlyPtoDays =
  | "dont_know"
  | "none"
  | "1"
  | "2"
  | "3"
  | "4"
  | "5"
  | "6"
  | "7"
  | "8"
  | "9"
  | "10"
  | "11"
  | "12"
  | "13"
  | "14"
  | "15"
  | "16"
  | "17"
  | "18"
  | "19"
  | "20"
  | "21"
  | "22"
  | "23"
  | "24"
  | "25"
  | "26"
  | "27"
  | "28"
  | "29"
  | "30"
  | "more_than_30";

export type TCurrentlyAccruedPtoDays =
  | "dont_know"
  | "none"
  | "1"
  | "2"
  | "3"
  | "4"
  | "5"
  | "6"
  | "7"
  | "8"
  | "9"
  | "10"
  | "11"
  | "12"
  | "13"
  | "14"
  | "15"
  | "16"
  | "17"
  | "18"
  | "19"
  | "20"
  | "21"
  | "22"
  | "23"
  | "24"
  | "25"
  | "26"
  | "27"
  | "28"
  | "29"
  | "30"
  | "more_than_30";

export interface ILeadsInitConfig {
  annualSalaries: TAnnualSalaryRange[];
  hearAboutUsFrom: THearAboutUsFrom[];
  employmentStatuses: TEmploymentStatus[];
  fundUsagePlans: THowToUseFunds[];
  industries: LeadIndustryType[];
  livingArrangements: TLivingArrangement[];
  payoutsAtTermination: TPayoutsAtTermination[];
  ptoDueTimes: TPtoDueTimes[];
  ptoPolicies: TPtoPolicyDesc[];
  ptoRolloverLimitValues: TPtoRolloverLimitValues[];
  ptoRolloverLimits: TPtoRolloverLimits[];
  seniorities: SeniorityType[];
  yearlyPtoDays: TYearlyPtoDays[];
  currentlyAccruedPtoDays: TCurrentlyAccruedPtoDays[];
}

export enum ContractTypeEnum {
  privacy_policy = "privacy_policy",
  sorbets_terms_of_service = "sorbets_terms_of_service",
  auto_pay_agreement = "auto_pay_agreement",
  one_time_agreement = "one_time_agreement",
  one_time_off_cycle_agreement = "one_time_off_cycle_agreement",
  e_sign_consent = "e_sign_consent",
  loan_agreement = "loan_agreement",
  loan_amended_agreement = "loan_amended_agreement",
  backup_payment_method_agreement = "backup_payment_method_agreement",
  loan_agreement_lead_bank = "loan_agreement_lead_bank",
}
export enum ContractExecutionStatus {
  pending = "pending",
  signed = "signed",
  rejected = "rejected",
}
export type TContractTemplatesInfo = {
  contractTemplateSignedVersionsInfo: Record<ContractTypeEnum, TContractShouldSign>[];
};

export type TContractShouldSign = {
  shouldSignNewVersion: boolean;
};

export type TGeneratedContract = {
  id: number;
  type: ContractTypeEnum;
  fileId: number;
  executionStatus: ContractExecutionStatus;
  createdAt: string;
  executedAt: string;
  executedAtTimeZone: string;
  updatedAt: string;
};

export type TSignContractBody = {
  executionStatus: ContractExecutionStatus;
  executedAtTimeZone: string;
  executedAt: string;
};
export enum BankAccountsStatusEnum {
  valid = "valid",
  invalid = "invalid",
}
export enum OperationalStatusEnum {
  backup = "backup",
  primary = "primary",
}
export interface IBankAccount {
  bankAccountLast4: string;
  bankRoutingNumberLast4: string;
  bankWireRoutingNumberLast4: string;
  institutionName: string;
  bankAccountDetailId: number;
  isActive: boolean;
  status: BankAccountsStatusEnum;
  createdAt: string;
  updatedAt: string;
  operationalStatus: OperationalStatusEnum;
  rtpCreditEnabled?: boolean;
}
export interface IBankAccountsResponse {
  data: IBankAccount[];
}

export interface IBackupAccountConsentsResponse {
  data: IBackupAccountConsent[];
}
export interface IBackupAccountConsent {
  type: DebitAchAuthTypeEnum;
  status: ConsentStatusEnum;
  version: string;
  bankIntegrationId: number;
  bankAccountDetailId: number;
  enforceCancellation?: boolean;
  paymentDueDate?: string;
}

export enum UserContractStatusEnum {
  NotIssued = "Not Issued",
  Pending = "Pending",
  Executed = "Executed",
  Declined = "Declined",
}

export enum UserEntityTypeEnum {
  user = "user",
  lead = "lead",
}

enum PayrollProviderStatus {
  active = "active",
  inactive = "inactive",
  deleted = "deleted",
}
enum ProviderImplEnum {
  pinwheel = "pinwheel",
}
export type EmploymentsLinkProviderResponse = {
  employment: {
    id: number;
    employmentAccountId: number;
    externalId: string;
    internalUuid: string;
    compensationAmountCents: number;
    compensationUnit: string;
    employerName: string;
    employmentStatus: string;
    startDate: SorbetDate;
    terminationDate: SorbetDate;
    jobTitle: string;
    providerResponse: {};
    providerErrorResponse: {};
    providerImpl: ProviderImplEnum;
    payrollProvider: string;
    status: PayrollProviderStatus;
  };
  publicToken: string;
};

export enum PaymentTypeEnum {
  disbursement = "disbursement",
  express_disbursement = "express_disbursement",
  debit = "debit",
}
export enum PaymentStatusEnum {
  pending = "pending",
  succeeded = "succeeded",
  failed = "failed",
}

export interface IPayment {
  id: number;
  consentId: number;
  loanId: number;
  paymentType: PaymentTypeEnum;
  grossAmount: number;
  netAmount: number;
  feeRatePercentage: number;
  feeAmountCents: number;
  maxAttempts: number;
  status: PaymentStatusEnum;
  createdAt: SorbetDate;
  updatedAt: SorbetDate;
  deletedAt: SorbetDate;
}
export interface IPaymentsResponse {
  data: IPayment[];
}

export interface CalculatePaymentResponse {
  netAmount: number;
  paymentType: PaymentTypeEnum;
  grossAmount: number;
  feeRatePercentage: number;
  feeAmountCents: number;
}

export enum LeadSponsorEnum {
  lead_bank = "lead_bank",
  sorbet = "sorbet",
}

export type TLeadApprovalInfo = {
  b2cApprovedAmountCents: number;
  b2cContractStatus: UserContractStatusEnum;
  uuid: string;
  sponsor: LeadSponsorEnum;
  employmentAccountId: number | null;
};
export enum PayrollConnectionStatusEnum {
  pending = "pending",
  active = "active",
  fe_completed = "fe_completed",
  disabled = "disabled",
  user_action_required = "user_action_required",
}
export enum EmploymentAccountStatusEnum {
  active = "active",
  inactive = "inactive",
  deleted = "deleted",
}
export type TEmploymentAccount = {
  id: number;
  status: EmploymentAccountStatusEnum;
  payrollConnectionStatus: PayrollConnectionStatusEnum;
  payrollProviderConnectedDate: SorbetDate;
  createdAt: SorbetDate;
  updatedAt: SorbetDate;
};

export type TEmploymentConnection = {
  payrollConnectionStatus?: PayrollConnectionStatusEnum;
  feConnectedTimestamp: string;
};

export enum UserFileTypeEnum {
  undefined = "undefined",
  loadusers = "loadusers",
  idSelfie = "idSelfie",
  payStubOne = "payStubOne",
  payStubTwo = "payStubTwo",
  ptoBalanceDoc = "ptoBalanceDoc",
  ptoPolicyProof = "ptoPolicyProof",
  idStatementWithAddress = "idStatementWithAddress",
  asset = "asset",
  contractTemplate = "contractTemplate",
  contract = "contract",
}

export type TUserFile = {
  id: number;
  type: UserFileTypeEnum;
  createdAt: SorbetDate;
  updatedAt: SorbetDate;
};
