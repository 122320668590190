import moment from "moment";
import { useCallback, useEffect } from "react";

export type TLeavePageEventReason = "Lost focus" | "Navigate Button" | "Focus";
export const useLeavePageAnalytics = (fireEvent: (eventType: TLeavePageEventReason) => void) => {
  const handleBeforeUnload = () => {
    fireEvent("Navigate Button");
  };
  const handleVisibilityChange = useCallback(() => {
    if (document.visibilityState !== "visible") {
      fireEvent("Lost focus");
    } else {
      fireEvent("Focus");
    }
  }, []);

  useEffect(() => {
    document.addEventListener("visibilitychange", handleVisibilityChange);
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  return [];
};

export const replaceSensitiveString = (value: any) => {
  if (typeof value === "string") {
    return value.replace(/./g, "*");
  }
  return "****";
};

export class Timer {
  private startTime = moment();
  private totalDuration = 0;
  private status: "pause" | "running" = "running";
  init() {
    this.startTime = moment();
    this.totalDuration = 0;
  }
  get duration() {
    if (this.status === "running") {
      const duration = moment().diff(this.startTime, "seconds") + this.totalDuration;
      return duration;
    } else {
      return this.totalDuration;
    }
  }
  update(reason?: string | TLeavePageEventReason) {
    if (reason === "Focus") {
      this.status = "running";
      this.startTime = moment();
    } else {
      this.status = "pause";
      const duration = moment().diff(this.startTime, "seconds") + this.totalDuration;
      this.totalDuration = duration;
    }
  }
}
