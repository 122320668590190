import { useEffect, useState } from "react";
import {
  BankDefaultLogo,
  BankLogo,
  BankLogoContainer,
} from "../../ManageLinkedAccounts/additionalAccountFlow/components/BankAccountItem/styles";
import bankIcon from "../../ManageLinkedAccounts/icons/bankIcon.svg";
import { bankLogos } from "../../ManageLinkedAccounts/additionalAccountFlow/components/BankAccountItem";
import { useStores } from "../../../stores";
import { currencyFormatter } from "../../../utils/formatters";
import ClipLoader from "react-spinners/ClipLoader";
import { PaymentTypeEnum } from "services/api/types";
import {
  StyledBankContainer,
  StyledBankSection,
  StyledBody,
  StyledButton,
  StyledContainer,
  StyledLoanAmount,
  StyledPercentageRateAmount,
  StyledPercentageRateText,
  StyledRadioWrapper,
  StyledRateAmountContainer,
  StyledSkeleton,
  StyledTitle,
} from "./style";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { EVENTS, useLeavePageAnalytics } from "services/analytics/events";
import ProgressBarLean from "pages/Btc/Components/ProgressBarLean";
import { CustomRadioButton } from "./RadioButton";
import { LongEventsLoader } from "components/LongEventsLoader";

const translationKey = "expressPayment";

const loaderText = {
  [PaymentTypeEnum.express_disbursement]: [
    ["expressPayment.loader.express.step1line1", "expressPayment.loader.express.step1line2"],
    ["expressPayment.loader.express.step2line1", "expressPayment.loader.express.step2line2"],
    ["expressPayment.loader.express.step3line1", "expressPayment.loader.express.step3line2"],
  ],
  [PaymentTypeEnum.disbursement]: [
    ["expressPayment.loader.standard.step1line1", "expressPayment.loader.standard.step1line2"],
    ["expressPayment.loader.standard.step2line1", "expressPayment.loader.standard.step2line2"],
  ],
  [PaymentTypeEnum.debit]: [],
};

const ExpressPayment = ({ loading }: { loading: boolean }) => {
  const { t } = useTranslation();
  useLeavePageAnalytics((eventType) => {
    EVENTS.main.expressPayment.leavePage(eventType);
  });
  const {
    debitConsentStore: { calculatePayment, calculatedPayment, confirmConsent },
  } = useStores();

  const [selectedOption, setSelectedOption] = useState<PaymentTypeEnum>(
    PaymentTypeEnum.express_disbursement,
  );

  const {
    bankDetailsStore: { bankDetails },
    leadStore: { isLeadBankSponsor },
  } = useStores();

  useEffect(() => {
    calculatePayment(PaymentTypeEnum.express_disbursement, true);
  }, []);
  const handleRadioChange = (value: PaymentTypeEnum) => async () => {
    setSelectedOption(value);
    await calculatePayment(value);
    EVENTS.main.expressPayment.selectTransferMethod(
      value,
      currencyFormatter(selectedCalculatePayment?.netAmount),
    );
  };

  const handleSubmit = () => {
    // handle submission logic
    if (!loading && !!calculatedPayment) {
      confirmConsent(selectedOption);
    }
  };

  const selectedCalculatePayment = calculatedPayment[selectedOption];
  return (
    <StyledContainer>
      <ProgressBarLean progress={90} />
      <StyledTitle>{t(`${translationKey}.sorbetPTOAdvanceAmount`)}</StyledTitle>
      {!selectedCalculatePayment?.grossAmount ? (
        <StyledSkeleton
          sx={{ bgcolor: "rgb(248, 248, 252)" }}
          animation="wave"
          variant="rounded"
          width={388}
          height={72.5}
        />
      ) : (
        <StyledLoanAmount>{`${currencyFormatter(
          selectedCalculatePayment?.grossAmount,
        )}`}</StyledLoanAmount>
      )}

      <StyledBody>
        <StyledRadioWrapper>
          <CustomRadioButton
            selected={selectedOption === PaymentTypeEnum.express_disbursement}
            onClick={handleRadioChange(PaymentTypeEnum.express_disbursement)}
            title={t(`${translationKey}.express`)}
            description={t(
              `${translationKey}.${
                isLeadBankSponsor
                  ? "typicallyWithinMinutesLeadBank"
                  : "typicallyWithinMinutesSorbet"
              }`,
              {
                fee: calculatedPayment[PaymentTypeEnum.express_disbursement]?.feeRatePercentage,
              },
            )}
            type={PaymentTypeEnum.express_disbursement}
          />

          <CustomRadioButton
            selected={selectedOption === PaymentTypeEnum.disbursement}
            onClick={handleRadioChange(PaymentTypeEnum.disbursement)}
            title={t(`${translationKey}.standard`)}
            description={t(`${translationKey}.daysNoFee`)}
            type={PaymentTypeEnum.disbursement}
          />
        </StyledRadioWrapper>
        {selectedOption === PaymentTypeEnum.express_disbursement && (
          <StyledRateAmountContainer>
            <StyledPercentageRateText>
              {t(`${translationKey}.expressTransferFee`, {
                fee: selectedCalculatePayment?.feeRatePercentage,
              })}
            </StyledPercentageRateText>
            {!!selectedCalculatePayment?.feeAmountCents && (
              <StyledPercentageRateAmount>
                {`-${currencyFormatter(selectedCalculatePayment?.feeAmountCents)}`}
              </StyledPercentageRateAmount>
            )}
          </StyledRateAmountContainer>
        )}

        <StyledBankSection>
          <BankLogoContainer>
            {bankDetails && bankLogos[bankDetails?.institutionName] ? (
              <BankLogo src={bankLogos[bankDetails?.institutionName]} />
            ) : (
              <BankDefaultLogo src={bankIcon} />
            )}
          </BankLogoContainer>
          <StyledBankContainer defaultValue="#111" margin="normal">
            {t(`${translationKey}.transferTo`, {
              institutionName: bankDetails?.institutionName,
              bankAccountLast4: bankDetails?.bankAccountLast4,
            })}
          </StyledBankContainer>
        </StyledBankSection>
        <StyledButton
          onClick={handleSubmit}
          id={"confirm-express-payment"}
          startIcon={
            <ClipLoader color="#ffffff" loading={loading} size={24} data-test-id="loader" />
          }>
          {t(`${translationKey}.transfer`)}
          <span className="amount">{`${currencyFormatter(
            selectedCalculatePayment?.netAmount,
          )}`}</span>
        </StyledButton>
      </StyledBody>

      <LongEventsLoader
        loading={loading}
        duration={10}
        steps={loaderText[selectedOption]}
        waitUntilEnd={false}
        timeoutText={`${translationKey}.loader.justFewSec`}
      />
    </StyledContainer>
  );
};

export default observer(ExpressPayment);
