import moment, { Moment } from "moment";
import { TUserFile, UserFileTypeEnum } from "services/api/types";

export const determineUserStatus = (
    userFiles: TUserFile[],
    userInfo: any
): {
  userStatus: string;
  timestamps: Moment[];
  payStubUploadedThisMonth: boolean;
  hasPtoBalanceDoc: boolean;
} => {
  let userStatus = "state1_paystub"; // default state
  const paystubFiles = userFiles.filter((file) => file.type === UserFileTypeEnum.payStubOne);
  const ptoFiles = userFiles.filter((file) => file.type === UserFileTypeEnum.ptoBalanceDoc);
  const timestamps: Moment[] = [];

  const previousMonth = moment().subtract(1, "month").month();
  const previousMonthYear = moment().subtract(1, "month").year();
  const currentMonth = moment().month();
  const currentMonthYear = moment().year();
  // const currentMonth = moment().add(1, "month").month(); // For testing purposes, set currentMonth to next month

  const userCreationDate = moment(userInfo.createDate);

  const prevMonthPaystub = paystubFiles.find((file) =>
      moment(file.createdAt).month() === previousMonth && moment(file.createdAt).year() === previousMonthYear);
  const currMonthPaystub = paystubFiles.find((file) =>
      moment(file.createdAt).month() === currentMonth && moment(file.createdAt).year() === currentMonthYear);

  const prevMonthPto = ptoFiles.find((file) =>
      moment(file.createdAt).month() === previousMonth && moment(file.createdAt).year() === previousMonthYear);
  const currMonthPto = ptoFiles.find((file) =>
      moment(file.createdAt).month() === currentMonth && moment(file.createdAt).year() === currentMonthYear);

  const hasPrevMonthPaystub = paystubFiles.length === 1 || prevMonthPaystub;
  const hasPrevMonthPto = ptoFiles.length === 1 || prevMonthPto;

  if (ptoFiles.length > 0) {
    timestamps.push(moment(ptoFiles[0].createdAt));
    if (hasPrevMonthPto) {
      if (currMonthPto) {
        userStatus = "state3_pto";
        timestamps.push(moment(currMonthPto.createdAt));
      } else {
        userStatus = "state1_pto";
      }
    } else {
      if(currMonthPto){
        const recentPtoFiles = ptoFiles.filter(file => moment(file.createdAt).isAfter(userCreationDate));
        if(recentPtoFiles.length > 1){
          userStatus = "state4_pto";
        } else {
          userStatus = "state3_pto";
        }
      } else {
        userStatus = "state2_pto";
      }
    }
  } else if (paystubFiles.length > 0) {
    timestamps.push(moment(paystubFiles[0].createdAt));
    if (hasPrevMonthPaystub) {
      if (currMonthPaystub) {
        userStatus = "state3_paystub";
        timestamps.push(moment(currMonthPaystub.createdAt));
      } else {
        userStatus = "state1_paystub";
      }
    } else {
      if(currMonthPaystub){
        const recentPaystubFiles = paystubFiles.filter(file => moment(file.createdAt).isAfter(userCreationDate));
        if(recentPaystubFiles.length > 1) {
          userStatus = "state4_paystub";
        } else {
          userStatus = "state3_paystub";
        }
      } else {
        userStatus = "state2_paystub";
      }
    }
  }

  const payStubUploadedThisMonth = !!currMonthPaystub || !!currMonthPto;

  return {
    userStatus,
    timestamps,
    payStubUploadedThisMonth,
    hasPtoBalanceDoc: ptoFiles.length > 0,
  };
};
