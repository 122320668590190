import styled from "styled-components";
import { Box, TextField, FormHelperText, Button, Select, MenuItem } from "@mui/material";
import { device } from "../../config/devices";
import { Colors } from "../../assets/styles/constants";

export const StyledCashAdvanceContainer = styled(Box)`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
`;

export const StyledApplyHeader = styled(Box)`
  padding: 12px 24px;
`;

export const StyledHeaderTitle = styled(Box)`
  color: #242323;
  font-size: 18px;
  font-weight: 500;
  margin-top: 45px;
  margin-bottom: 6px;
`;

export const StyledHeaderAmount = styled(Box)`
  color: #242323;
  font-weight: 400;
  font-size: 62px;
  line-height: 68px;
`;

export const StyledHeaderDescription = styled(Box)`
  color: #585866;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  margin-bottom: 24px;
  margin-top: 8px;
  margin-right: 48px;
  @media ${device.laptop} {
    margin-top: 0;
  }
`;
export const StyledHeaderDisclaimer = styled(Box)`
  color: #585866;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  max-width: 415px;
  @media ${device.laptop} {
    margin-top: 8px;
  }
  @media ${device.mobileS} {
    margin-bottom: 14px;
  }
`;

export const StyledBTCSelector = styled(Select)`
  width: 100%;
  height: 56px;
  @media ${device.laptop} {
    height: 40px;
  }
  &:focus-visible {
    border: 1px solid red;
  }
  & .MuiInputBase-input {
    color: #242323;
    font-size: 15px;
    font-family: "Poppins";
  }
  &.Mui-focused .MuiOutlinedInput-notchedOutline {
    box-shadow: "0 0 0 1px #7a56fc, 0 0 0 3px #e2daff";
    border: none;
    border-radius: "4px";
  }
`;

export const StyledSelectorItem = styled(MenuItem)`
  margin-bottom: 4px;
  font-family: Poppins, sans-serif !important;
  font-size: 14px !important;
  white-space: unset !important;
  word-break: break-word !important;
`;

export const StyledSelectorLabel = styled(Box)<{ required: boolean }>`
  margin-bottom: 4px;
  font-size: 15px;
  line-height: 24px;
  color: ${Colors.darkText};
  :after {
    content: ${(props) => (props.required ? "' *'" : "")};
    color: red;
  }
`;
export const StyledHelperTextPlaid = styled.div`
  font-size: 15px;
  color: #585866;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  flex-grow: 1;
`;

export const StyledBodyTitleText = styled(Box)`
  font-weight: 600;
  color: #242323;
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 8px;
`;

export const StyledApplyForm = styled.form`
  display: flex;
  flex-direction: column;
`;

export const StyledBodyText2 = styled(Box)`
  font-weight: 400;
  color: #585866;
  margin-bottom: 0;
  max-width: 480px;
  line-height: 24px;
  font-size: 16px;
  @media ${device.laptop} {
    margin-bottom: 24px;
    font-size: 14px;
  }
`;

export const ShortStyledBodyText2 = styled(Box)`
  font-weight: 400;
  color: #585866;
  max-width: 320px;

  line-height: 24px;
  font-size: 16px;
  @media ${device.laptop} {
    font-size: 14px;
  }
`;

export const StyledBTCSelectorWrapper = styled(Box)`
  margin-top: 24px;
  .MuiFormHelperText-root {
    color: #32536a;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0;
    margin-left: 0;
  }
  .MuiFormHelperText-root.Mui-error {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #ee4433;
    margin-left: 0px;
    letter-spacing: 0;
  }
`;

export const StyledSorbetLogo = styled.img`
  width: 90px;
  height: 20px;
`;

export const StyledActionButtonsWrapper = styled.div`
  margin-top: 16px;
  display: flex;
  align-items: end;
  flex-direction: column;
  @media ${device.tablet} {
    justify-content: space-between;
    flex-direction: row-reverse;
    button {
      width: auto;
    }
  }
`;

export const StyledCenteredBody = styled(Box)`
  background-color: #fff;
  align-self: center;
  height: inherit;
  @media ${device.laptop} {
    width: 528px;
  }
  @media ${device.mobileS} {
    max-width: 528px;
  }
`;

export const StyledEmptyDiv = styled.div`
  @media ${device.laptop} {
    margin-top: 32px;
  }
`;

export const StyledBTCSSubmitButton = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 32px;
  width: 100%;
  height: 56px;
  @media ${device.laptop} {
    height: 42px;
  }

  transition: background-color 500ms;

  color: #fff;
  background-color: #ff5277;
  border-radius: 8px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;

  flex: none;
  order: 0;
  flex-grow: 0;
  z-index: 0;

  @media ${device.laptop} {
    width: fit-content;
    align-self: end;
  }
  :hover {
    background-color: ${(p) => (p.disabled ? "#ff5277" : "#000000")};
  }
`;

export const StyledBTCSBackButton = styled(Button)`
  width: 100%;
  height: 56px;
  font-weight: 400 !important;
  font-size: 15px !important;
  line-height: 24px !important;
  color: #000000 !important;
  text-transform: capitalize !important;
  font-family: Poppins !important;

  @media ${device.laptop} {
    width: 65px;
  }
`;

export const StyledHelperText = styled(Box)`
  font-size: 13px;
  align-self: end;
  color: #585866;
  @media ${device.mobileS} {
    align-self: start;
  }
  @media ${device.laptop} {
    align-self: end;
  }
  margin-top: 4px;
`;

export const StyledInfoText = styled(Box)`
  font-size: 12px;
  align-self: start;
  color: #585866;
  font-weight: 400;
  line-height: 16px;
  margin-top: 8px;
`;

export const StyledBTCSelectError = styled(FormHelperText)<{ error: boolean }>`
  display: ${(props) => (props.error ? "flex" : "none")};
`;
export const StyledBTCTextFieldWrapper = styled(Box)`
  margin-top: 4px;

  .MuiFormHelperText-root {
    color: #585866;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0;
    margin-left: 0px;
  }
  .MuiFormHelperText-root.Mui-error {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #ee4433;
    margin-left: 0px;
    letter-spacing: 0;
  }
  #masked-phone-number {
    width: 100%;
    height: 56px;
    background: #ffffff;
    border: 1px solid #cacaca;
    border-radius: 4px;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    padding-left: 20px;
    font-size: 16px;
  }
`;
export const StyledBTCTextField = styled(TextField)`
  width: 100%;
  border: 1px solid #e2daff;
  input {
    ::placeholder {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      color: #585866;
    }
  }
  & .MuiOutlinedInput-root {
    font-family: "Poppins";
    height: 56px;
    @media ${device.laptop} {
      height: 40px;
    }
    color: #585866;
    ::placeholder {
      color: #9486a5;
      font-weight: 400;
    }
    &.Mui-focused fieldset {
      border: none;
      box-shadow: 0 0 0 1px #7a56fc, 0 0 0 3px #e2daff;
    }
  }
  :last-child {
    margin-bottom: 20px;
  }

  ::-webkit-date-and-time-value {
    text-align: right;
  }
`;

export const StyledInfoTxt = styled.div`
  margin-top: 8px;
  margin-bottom: 32px;
  font-weight: 400;
  font-family: "Poppins";
  font-size: 12px;
  line-height: 16px;
  color: #585866;
`;

export const StyledAgreeTxt = styled.div`
  background-color: #f8f8fc;
  border-radius: 6px;
  padding: 16px;
  margin-top: 16px;
  margin-bottom: 24px;
  font-weight: 400;
  font-family: "Poppins";
  font-size: 12px;
  line-height: 16px;
  color: #585866;
`;

export const StyledActiveMilitaryTxt = styled.div`
  width: 150px;
  font-weight: 400;
  font-family: "Poppins";
  font-size: 14px;
  line-height: 24px;
  color: #585866;
`;

export const StyledNativeInput = styled.input`
  width: 100%;
  height: 56px;
  @media ${device.laptop} {
    height: 40px;
  }
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  text-indent: 16px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #32536a;
  margin-bottom: 12px;
  ::placeholder {
    font-family: "Poppins" !important;
    font-weight: 400 !important;
    font-size: 15px;
    color: #b7bdc3;
    font-style: normal;
  }
  :hover {
    border: 1px solid #000 !important;
  }
  :focus {
    border: none;
    box-shadow: 0 0 0 1px #7a56fc, 0 0 0 3px #e2daff;
  }
`;

export const StyledHyperlinkTextPurple = styled.a`
  color: #ff5277;
  font-family: "Poppins";
  font-style: normal;
  text-decoration-line: underline;
  a {
    color: #ff5277;
  }
`;

export const StyledCheckbox = styled.input<{ error?: boolean }>`
  accent-color: white;
  width: 16px;
  height: 16px;
  margin-right: 12px;
  appearance: none;
  position: relative;
  cursor: pointer;
  ::after {
    width: 18px;
    height: 19px;
    content: " ";
    background-color: white;
    display: flex;
    border: 1px solid;
    border-color: ${(props) => (props.error ? "#ee4433" : "#242323")};
    border-radius: 4px;
  }
  :hover::after {
    background-color: rgba(255, 255, 255);
    border: 1px solid #242323;
  }
  :hover:checked::after {
    border: 1px solid #242323;
  }
  :checked::after {
    background-color: white;
  }
  :checked::before {
    content: "";
    position: absolute;
    left: 7px;
    top: 3px;
    width: 5px;
    height: 11px;
    border: solid #242323;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
    border-radius: 1px;
  }
`;

export const Styled18Text500 = styled.div`
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  text-align: center;
  color: #000000;
  margin-bottom: 24px;
  margin-top: 32px;
  padding-right: 24px;
  padding-left: 24px;
`;

export const Styled14Text400Centered = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #000000;
  margin-top: 8px;
`;

export const StyledBulletTextListItem = styled.div<{ withPadding?: boolean }>`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #000000;
  margin-top: 8px;
  position: relative;
  @media ${device.laptop} {
    margin-left: ${(props) => (props.withPadding ? "94px" : "0")};
  }
  ::before {
    content: "";
    display: block;
    height: 4px;
    width: 4px;
    background: #ff5277;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: -22px; // size of bullet
    transform: translateY(-50%); // vertical alignment
  }
`;

export const Styled14Text400 = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #000000;
  margin-top: 12px;
  margin-right: 24px;
`;

export const Styled14Text = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #000000;
  margin-top: 8px;
`;

export const StyledPageTitle = styled.div`
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: #242323;
`;

export const Styled20PAdding = styled.div`
  padding: 0px 20px;
`;

export const StyledBTCUploadFileButtonWrapper = styled(Box)`
  margin-top: 8px;
`;
export const StyledBTCUploadFileButton = styled.label<{ error: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  width: 100%;
  height: 56px;
  @media ${device.laptop} {
    height: 42px;
  }

  color: #242323;
  border-radius: 8px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;

  margin-bottom: 8px;

  flex: none;
  order: 0;
  flex-grow: 0;
  cursor: pointer;
  z-index: 0;
  border-style: dashed;
  border-width: 1px;
  border-color: ${(props) => (props.error ? "#ee4433" : "#242323")};

  span {
    padding-left: 9px;
  }
  :hover {
    background-color: #FFA8BB;
  }
  :focus {
    background-color: #FFA8BB;
  }
`;
export const StyledBTCUploadFileName = styled.span`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
export const StyledBTCUploadFileError = styled.span`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  margin-bottom: 8px;
  color: #ee4433;
`;
export const StyledBTCUploadFileSuccess = styled.span`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #242323;
`;
