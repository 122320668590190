import { LinearProgress, linearProgressClasses } from "@mui/material";
import { styled } from "@mui/styles";
import { StyledProgressAmount, StyledProgressBarWrapper } from "./styles";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 3,
  width: "70%",
  marginLeft: 0,
  marginRight: 18,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "rgba(0, 6, 36, 0.1)",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "#FF5277",
  },
}));

const ProgressBar = ({ progress }: any) => {
  return (
    <StyledProgressBarWrapper>
      <BorderLinearProgress variant="determinate" value={progress} />
      <StyledProgressAmount id="btc-progress-value">{progress}%</StyledProgressAmount>
    </StyledProgressBarWrapper>
  );
};

export default ProgressBar;
