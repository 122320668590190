import { observer } from "mobx-react";
import { Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";

import {
  StyledPtoCashAdvanceHeader,
  StyledPCAHeaderText,
  StyledPCABodyText,
  StyledPCABodyTitleText,
  StyledPCABody,
  StyledPtoImage,
  StyledCashAdvanceCustomContainer,
  StyledPtoAdvanceSorbetLogo,
  StyledPCABodyWrapper,
  StyledBoldText,
  sxSelect,
} from "./styles";
import {
  StyledBTCSelectorWrapper,
  StyledBTCSelector,
  StyledBTCSSubmitButton,
  StyledHelperText,
  StyledBTCSelectError,
  StyledSelectorItem,
  StyledSelectorLabel,
  StyledBodyText2,
  StyledApplyForm,
  StyledEmptyDiv,
  StyledInfoText,
} from "../styles";

import sorbetLogo from "assets/logos/white-logo.svg";
import ptoImage from "assets/images/woman-phone.png";
import TooltipIcon from "../../../assets/icons/tooltip.svg";

import {
  LeadIndustryType,
  SeniorityType,
  TAnnualSalaryRange,
  TPtoPolicyDesc,
} from "services/api/types";

import { useStep1 } from "./useStep1";
import { BTCLoader } from "../Components/Loader";

declare global {
  interface Window {
    dataLayer: any;
  }
}

const industriesSelector: LeadIndustryType[] = [
  "construction",
  "education",
  "finance",
  "government",
  "health_care",
  "information",
  "insurance",
  "law_firms_legal_services",
  "leisure_hospitality",
  "manufacturing",
  "natural_resources_mining",
  "other_services",
  "professional_business_services",
  "real_estate",
  "retail",
  "software",
  "transportation_warehousing",
  "utilities",
  "other",
];

const pageTranslationPrefix = "page_PtoCashAdvance";

const PtoCashAdvance = observer(() => {
  const { t } = useTranslation();
  const {
    selectedPtoPolicy,
    onSubmit,
    isLoading,
    register,
    handleSubmit,
    errors,
    leadsInitConfig,
    windowDimensions,
    getValues,
  } = useStep1();

  return (
    <StyledCashAdvanceCustomContainer>
      <StyledPtoCashAdvanceHeader>
        <div>
          <StyledPtoAdvanceSorbetLogo src={sorbetLogo}></StyledPtoAdvanceSorbetLogo>
          <StyledPCAHeaderText>{t(`${pageTranslationPrefix}.timeIsMoney`)}</StyledPCAHeaderText>
          <StyledBoldText>{t(`${pageTranslationPrefix}.onDemand`)}</StyledBoldText>
          <StyledPCABodyText>{t(`${pageTranslationPrefix}.accessTheCashValue`)}</StyledPCABodyText>
          <StyledPtoImage src={ptoImage} />
        </div>
      </StyledPtoCashAdvanceHeader>
      <StyledPCABody>
        <StyledPCABodyWrapper>
          <StyledPCABodyTitleText id="pto-cash-advance-title">
            {t(`${pageTranslationPrefix}.findOutWhatYourPto`)}
          </StyledPCABodyTitleText>
          <StyledBodyText2 id="pto-cash-advance-subtitle">
            {t(`${pageTranslationPrefix}.personalInformationKeptConfidential`)}
          </StyledBodyText2>
          <StyledApplyForm onSubmit={handleSubmit(onSubmit)}>
            {/* ######### Industry Selector ######### */}
            <StyledBTCSelectorWrapper>
              <StyledSelectorLabel required={true}>
                {t(`${pageTranslationPrefix}.industrySelector.description`)}
              </StyledSelectorLabel>

              <StyledBTCSelector
                style={{ maxWidth: windowDimensions.width - 40 }}
                defaultValue={""}
                SelectDisplayProps={{ style: { fontFamily: "Poppins", fontSize: 15 } }}
                MenuProps={{ PaperProps: { sx: { maxHeight: 400, top: 380 } } }}
                sx={sxSelect}
                {...register("industry", { required: true })}
                error={!!errors["industry"]}
                id="industry-selector">
                {industriesSelector.map((item: LeadIndustryType) => (
                  <StyledSelectorItem
                    key={item}
                    id={item}
                    value={item}
                    sx={{
                      "&:hover": {
                        background: "#E2DAFF",
                        fontFamily: "Poppins",
                        fontSize: 14,
                      },
                    }}>
                    {t(`${pageTranslationPrefix}.industrySelector.${item}`)}
                  </StyledSelectorItem>
                ))}
              </StyledBTCSelector>
              <StyledBTCSelectError error={!!errors["industry"]}>
                {t(`${pageTranslationPrefix}.errorSelect`)}
              </StyledBTCSelectError>
            </StyledBTCSelectorWrapper>

            {/* ######### Salary Selector ######### */}
            <StyledBTCSelectorWrapper>
              <StyledSelectorLabel required>
                {t(`${pageTranslationPrefix}.salaryRangeSelector.description`)}

                <Tooltip enterTouchDelay={0} title={t(`${pageTranslationPrefix}.tooltip`)}>
                  <img src={TooltipIcon} alt={"tooltip icon"}></img>
                </Tooltip>
              </StyledSelectorLabel>

              <StyledBTCSelector
                style={{ maxWidth: windowDimensions.width - 40 }}
                defaultValue={""}
                SelectDisplayProps={{ style: { fontFamily: "Poppins", fontSize: 15 } }}
                MenuProps={{ PaperProps: { sx: { maxHeight: 400, top: 380 } } }}
                sx={sxSelect}
                {...register("salary", { required: true })}
                error={!!errors["salary"]}
                id="base-salary-selector">
                {leadsInitConfig?.annualSalaries.map((item: TAnnualSalaryRange) => (
                  <StyledSelectorItem
                    key={item}
                    id={item}
                    value={item}
                    sx={{
                      "&:hover": {
                        background: "#E2DAFF",
                        fontFamily: "Poppins",
                        fontSize: 14,
                      },
                    }}>
                    {t(`${pageTranslationPrefix}.salaryRangeSelector.${item}`)}
                  </StyledSelectorItem>
                ))}
              </StyledBTCSelector>
              <StyledBTCSelectError error={!!errors["salary"]}>
                {t(`${pageTranslationPrefix}.errorSelect`)}
              </StyledBTCSelectError>
            </StyledBTCSelectorWrapper>

            {/* ######### Seniority Selector ######### */}
            <StyledBTCSelectorWrapper>
              <StyledSelectorLabel required={true}>
                {t(`${pageTranslationPrefix}.senioritySelector.description`)}
              </StyledSelectorLabel>

              <StyledBTCSelector
                style={{ maxWidth: windowDimensions.width - 40 }}
                defaultValue={""}
                SelectDisplayProps={{ style: { fontFamily: "Poppins", fontSize: 15 } }}
                MenuProps={{ PaperProps: { sx: { maxHeight: 400, top: 380 } } }}
                sx={sxSelect}
                {...register("seniority", { required: true })}
                error={!!errors["seniority"]}
                id="seniority-selector">
                {leadsInitConfig?.seniorities.map((item: SeniorityType) => (
                  <StyledSelectorItem
                    key={item}
                    id={item}
                    value={item}
                    sx={{
                      "&:hover": {
                        background: "#E2DAFF",
                        fontFamily: "Poppins",
                        fontSize: 14,
                      },
                    }}>
                    {t(`${pageTranslationPrefix}.senioritySelector.${item}`)}
                  </StyledSelectorItem>
                ))}
              </StyledBTCSelector>
              <StyledBTCSelectError error={!!errors["seniority"]}>
                {t(`${pageTranslationPrefix}.errorSelect`)}
              </StyledBTCSelectError>
            </StyledBTCSelectorWrapper>

            {/* ######### PTO Policy Selector ######### */}
            <StyledBTCSelectorWrapper>
              <StyledSelectorLabel required={true}>
                {t(`${pageTranslationPrefix}.ptoPolicySelector.description`)}
              </StyledSelectorLabel>
              <StyledBTCSelector
                style={{ maxWidth: windowDimensions.width - 40 }}
                multiline={true}
                defaultValue={""}
                SelectDisplayProps={{ style: { fontFamily: "Poppins", fontSize: 15 } }}
                MenuProps={{ PaperProps: { sx: { maxHeight: 400, top: 380 } } }}
                sx={sxSelect}
                error={!!errors["ptoPolicy"]}
                {...register("ptoPolicy", { required: true })}
                id="pto-policy-selector">
                {leadsInitConfig?.ptoPolicies.map((item: TPtoPolicyDesc, i) => (
                  <StyledSelectorItem value={item} id={item} key={item}>
                    {t(`${pageTranslationPrefix}.ptoPolicySelector.${item}`)}
                  </StyledSelectorItem>
                ))}
              </StyledBTCSelector>
              <StyledBTCSelectError error={!!errors["ptoPolicy"]}>
                {t(`${pageTranslationPrefix}.errorSelect`)}
              </StyledBTCSelectError>
            </StyledBTCSelectorWrapper>

            {/* ######### PTO Days Selector ######### */}
            {selectedPtoPolicy === "carry_over" || selectedPtoPolicy === "forfeit" ? (
              <StyledBTCSelectorWrapper>
                <StyledSelectorLabel required={true}>
                  {t(`${pageTranslationPrefix}.ptoInDaysSelector.description`)}
                </StyledSelectorLabel>
                <StyledBTCSelector
                  style={{ maxWidth: windowDimensions.width - 40 }}
                  defaultValue={getValues("currentlyAccruedPtoDays") || ""}
                  SelectDisplayProps={{ style: { fontFamily: "Poppins", fontSize: 15 } }}
                  MenuProps={{ PaperProps: { sx: { maxHeight: 400, top: 380 } } }}
                  sx={sxSelect}
                  error={!!errors["currentlyAccruedPtoDays"]}
                  {...register("currentlyAccruedPtoDays", {
                    required: true,
                  })}
                  id="pto-days-selector">
                  <StyledSelectorItem value={"dont_know"} id="-1">
                    {t(`${pageTranslationPrefix}.ptoInDaysSelector.idk`)}
                  </StyledSelectorItem>
                  <StyledSelectorItem value={"0"} id="0">
                    {t(`${pageTranslationPrefix}.ptoInDaysSelector.none`)}
                  </StyledSelectorItem>
                  <StyledSelectorItem value={"1"} id="1">
                    {t(`${pageTranslationPrefix}.ptoInDaysSelector.numericDay_one`)}
                  </StyledSelectorItem>
                  {Array.from({ length: 29 }, (_, index) => index + 2).map((item: number, i) => (
                    <StyledSelectorItem value={item.toString()} id={`${item}`} key={item}>
                      {t(`${pageTranslationPrefix}.ptoInDaysSelector.numericDay_other`, {
                        count: i + 2,
                      })}
                    </StyledSelectorItem>
                  ))}
                  <StyledSelectorItem value={"more_than_30"}>
                    {t(`${pageTranslationPrefix}.ptoInDaysSelector.morethan30`)}
                  </StyledSelectorItem>
                </StyledBTCSelector>
                <StyledBTCSelectError error={!!errors["currentlyAccruedPtoDays"]}>
                  {t(`${pageTranslationPrefix}.errorSelect`)}
                </StyledBTCSelectError>
              </StyledBTCSelectorWrapper>
            ) : null}

            <StyledInfoText>{t(`${pageTranslationPrefix}.verifiedPriorApproval`)}</StyledInfoText>

            <StyledEmptyDiv />
            <StyledBTCSSubmitButton
              type="submit"
              style={{ marginTop: "24px" }}
              id="pto-cash-advance-submit">
              {t(`${pageTranslationPrefix}.submitButton`)}
            </StyledBTCSSubmitButton>
            <StyledHelperText>
              {t(`${pageTranslationPrefix}.checkingEligibilityWontAffect`)}
            </StyledHelperText>
          </StyledApplyForm>
        </StyledPCABodyWrapper>
      </StyledPCABody>

      {isLoading ? <BTCLoader /> : null}
    </StyledCashAdvanceCustomContainer>
  );
});

export default PtoCashAdvance;
