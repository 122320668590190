import {
  BackIcon,
  Header,
  HeaderTitle,
  StyledWrapper,
  TopHeader,
  Wrapper,
  StyledPTOCenterDescription,
  PTOCenterContainer,
  StyledSelectOption,
  StyledOptionText,
  StyledOptionIcon,
  StyledOptionWrapper,
  Label,
  StyledUploadedOptions,
  StyledInnerUploadedOption,
  StyledUploadedDate,
  StyledUploadedOptionText,
} from "./styles";
import backIcon from "./icons/backIcon.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useStores } from "stores";
import { IconButton } from "@mui/material";
import { observer } from "mobx-react";

import rightArrow from "assets/icons/chevron-right.svg";
import { ConnectedToPayroll } from "./components/ConnectedToPayroll";
import { useGate } from "statsig-react";
import ActiveStepIcon from "assets/icons/greenCircleCheck.svg";
import moment from "moment";
import { TUserFile, UserFileTypeEnum } from "services/api/types";
import { EVENTS, useLeavePageAnalytics } from "services/analytics/events";

const translationKey = "PTOCenter";

const PTO_OPTIONS = {
  PAYROLL: "payroll",
  PAYSTUB: "paystub",
};

type Props = {
  type: string;
  disabled?: boolean;
  uploaded?: boolean;
  uploadDate?: string | null;
};

const SelectOption = ({ type, disabled, uploaded, uploadDate }: Props) => {
  const translationText = "PTOCenter.";
  const navigate = useNavigate();
  const { t } = useTranslation();

  const navigateToUpload = () => {
    if (!uploaded) {
      if (type === PTO_OPTIONS.PAYROLL) {
        EVENTS.ptoCenter.ptoCenter.leavePage("Continue (Payroll)");
      }
      if (type === PTO_OPTIONS.PAYROLL) {
        EVENTS.ptoCenter.ptoCenter.leavePage("Continue (Paystub Upload)");
      }
      navigate(`/pto-center/${type}`, { state: { originFrom: "PTO Center" } });
    }
  };

  return (
    <StyledSelectOption disabled={disabled} onClick={navigateToUpload}>
      <StyledOptionWrapper>
        {uploaded ? (
          <StyledUploadedOptions>
            <img src={ActiveStepIcon} alt={"ActiveStepIcon"} />
            <StyledInnerUploadedOption>
              <StyledUploadedOptionText>
                {t(`${translationText}${type}.title_uploaded`)}
              </StyledUploadedOptionText>
              <StyledUploadedDate>{moment(uploadDate).format("MMMM D, YYYY")}</StyledUploadedDate>
            </StyledInnerUploadedOption>
          </StyledUploadedOptions>
        ) : (
          <>
            <StyledOptionText disabled={disabled}>
              {t(`${translationText}${type}.title`)}
            </StyledOptionText>
            {type === PTO_OPTIONS.PAYROLL && <Label>{t(`${translationText}${type}.label`)}</Label>}
            {disabled && (
              <Label type="notification">{t(`${translationText}${type}.comingSoon`)}</Label>
            )}
          </>
        )}
      </StyledOptionWrapper>
      {!uploaded && <StyledOptionIcon src={rightArrow} />}
    </StyledSelectOption>
  );
};

export const PTOCenter = observer(() => {
  const {
    appStore: { setIsHeaderHidden },
    leadStore: { needToConnectPayroll },
    userStore: { userFiles, getUserFiles },
  } = useStores();

  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  useLeavePageAnalytics((eventType) => {
    EVENTS.ptoCenter.ptoCenter.leavePage(eventType);
  });

  const { value: fe_paystubs_upload } = useGate("fe_paystubs_upload");
  const [payStubUploadedThisMonth, setPayStubUploadedThisMonth] = useState<TUserFile>();
  // const [hasPtoBalanceDoc, setHasPtoBalanceDoc] = useState(false);

  const backClick = () => {
    navigate(-1); // Navigate back to the previous page
  };

  useEffect(() => {
    EVENTS.ptoCenter.ptoCenter.onView(location.state?.fromOrigin);
    setIsHeaderHidden(true);
    getUserFiles();
  }, []);

  useEffect(() => {
    // Check if there are any payStubOne files uploaded this month
    const payStubFile = userFiles.find(
      (file) =>
        (file.type === UserFileTypeEnum.payStubOne ||
          file.type === UserFileTypeEnum.ptoBalanceDoc) &&
        moment().isSame(file.createdAt, "month"),
    );

    setPayStubUploadedThisMonth(payStubFile);

    // Check if there are any ptoBalanceDoc files
    // const ptoBalanceFile = userFiles?.find((file: { type: string; }) => file.type === 'ptoBalanceDoc');
    // setHasPtoBalanceDoc(!!ptoBalanceFile);
  }, [userFiles]);

  return (
    <StyledWrapper>
      <TopHeader />
      <Wrapper>
        <Header>
          <IconButton onClick={backClick}>
            <BackIcon src={backIcon} />
          </IconButton>
          <HeaderTitle>{t(`${translationKey}.title`)}</HeaderTitle>
        </Header>
        {needToConnectPayroll ? (
          <PTOCenterContainer>
            <StyledPTOCenterDescription>
              {t(`${translationKey}.${"description_uploaded"}`)}
            </StyledPTOCenterDescription>
            <SelectOption type={PTO_OPTIONS.PAYROLL} />
            <SelectOption
              uploaded={!!payStubUploadedThisMonth}
              type={PTO_OPTIONS.PAYSTUB}
              disabled={!fe_paystubs_upload}
              uploadDate={payStubUploadedThisMonth?.createdAt}
            />
          </PTOCenterContainer>
        ) : (
          <ConnectedToPayroll accountName={""} /> // TODO user real data
        )}
      </Wrapper>
    </StyledWrapper>
  );
});
