import { useTranslation } from "react-i18next";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ProgressBar from "../Components/ProgressBar";

import {
  StyledCashAdvanceContainer,
  StyledSorbetLogo,
  StyledBTCSSubmitButton,
  StyledPageTitle,
  StyledBTCTextFieldWrapper,
  StyledBTCTextField,
  StyledBTCSBackButton,
  StyledHeaderDescription,
  StyledNativeInput,
  StyledActionButtonsWrapper,
  StyledSelectorLabel,
  StyledInfoTxt,
} from "../styles";

import sorbetLogo from "../../../assets/logos/color-logo.svg";

import { BTCLoader } from "../Components/Loader";
import { PHONE_LENGTH_LIMIT } from "../Constants";
import {
  StyledApplyAdvanceForPtoBody,
  StyledApplyAdvanceHeader,
  StyledApplyBody,
  StyledApplyForm,
} from "../ApplyInfo/styles";
import SSNField from "components/SsnField";
import BtcErrorBox from "../Components/ErrorBox";
import { useStep4 } from "./useStep4";
import moment from "moment";
import { addressRegex, stateRegex } from "../../../utils/regExps";

const pageTranslationPrefix = "page_ApplyInfo";

const ApplyInfo = () => {
  const { t } = useTranslation();
  const {
    errors,
    register,
    validateDateOfBirth,
    handleBackClick,
    validatePhone,
    handleSubmit,
    control,
    onSubmit,
    isLoading,
    errorBox,
    googleRef,
    googleAddress,
    onAddressInputChange,
    onAddressInputBlur,
    enterAddressManually,
  } = useStep4();
  const addYourSSNPlaceholder = t(`${pageTranslationPrefix}.addYourSSN`);

  const showZipCodeField = (googleAddress && !googleAddress.zipCode) || enterAddressManually;
  const showCityField = (googleAddress && !googleAddress.city) || enterAddressManually;
  const showAddressLineField =
    (googleAddress && !googleAddress.addressLine) || enterAddressManually;
  const showStateField = (googleAddress && !googleAddress.state) || enterAddressManually;

  return (
    <StyledCashAdvanceContainer>
      <StyledApplyAdvanceHeader>
        <StyledSorbetLogo src={sorbetLogo} />
      </StyledApplyAdvanceHeader>
      <StyledApplyBody>
        <ProgressBar progress={10} />
        <StyledApplyAdvanceForPtoBody>
          <StyledPageTitle id="apply-info-title">
            {t(`${pageTranslationPrefix}.tellUsALittle`)}
          </StyledPageTitle>
          <StyledHeaderDescription id="apply-info-subtitle">
            {t(`${pageTranslationPrefix}.yourPersonalInfo`)}
          </StyledHeaderDescription>
          {errorBox && <BtcErrorBox></BtcErrorBox>}
          <StyledApplyForm onSubmit={handleSubmit(onSubmit)}>
            {/* ######### Birth date field ######### */}
            <StyledBTCTextFieldWrapper>
              <StyledSelectorLabel required>
                {t(`${pageTranslationPrefix}.whenYourBirthday`)}
              </StyledSelectorLabel>
              <StyledBTCTextField
                id="apply-info-birthday"
                type="date"
                inputProps={{
                  min: "1910-01-01",
                  max: moment().subtract(18, "years").format("YYYY-MM-DD"),
                }}
                error={!!errors["birthDate"]}
                {...register("birthDate", { required: true, validate: validateDateOfBirth })}
                helperText={
                  errors["birthDate"] &&
                  t(`${pageTranslationPrefix}.errorBirthday.${errors["birthDate"].type}`)
                }
              />
            </StyledBTCTextFieldWrapper>
            {/* ######### Google Address autocomplete ######### */}
            <StyledBTCTextFieldWrapper>
              <StyledSelectorLabel required>
                {t(`${pageTranslationPrefix}.whatsYourHome`)}
              </StyledSelectorLabel>
              <StyledNativeInput
                id="apply-info-address"
                ref={googleRef}
                onChange={onAddressInputChange}
                maxLength={65}
                onBlur={onAddressInputBlur}
                required={!enterAddressManually}
                placeholder={`${t(`${pageTranslationPrefix}.selectAnAddress`)}`}
              />
            </StyledBTCTextFieldWrapper>
            {/* ######### State ######### */}
            {showStateField ? (
              <StyledBTCTextFieldWrapper>
                <StyledSelectorLabel required>
                  {t(`${pageTranslationPrefix}.whatsYourState`)}
                </StyledSelectorLabel>
                <StyledBTCTextField
                  id="apply-info-state"
                  type="text"
                  error={!!errors["state"]}
                  placeholder={`${t(`${pageTranslationPrefix}.whatsYourStatePlaceholder`)}`}
                  {...register("state", {
                    value: googleAddress?.addressLine ?? "",
                    required: true,
                    pattern: stateRegex,
                    maxLength: 30,
                  })}
                  helperText={
                    errors["state"] &&
                    t(`${pageTranslationPrefix}.errorState.${errors["state"].type}`)
                  }
                />
              </StyledBTCTextFieldWrapper>
            ) : null}
            {/* ######### City ######### */}
            {showCityField ? (
              <StyledBTCTextFieldWrapper>
                <StyledSelectorLabel required>
                  {t(`${pageTranslationPrefix}.whatsYourCity`)}
                </StyledSelectorLabel>
                <StyledBTCTextField
                  id="apply-info-city"
                  type="text"
                  error={!!errors["city"]}
                  placeholder={`${t(`${pageTranslationPrefix}.whatsYourCityPlaceholder`)}`}
                  {...register("city", {
                    value: googleAddress?.city ?? "",
                    required: true,
                    pattern: addressRegex,
                    maxLength: 30,
                  })}
                  helperText={
                    errors["city"] && t(`${pageTranslationPrefix}.errorCity.${errors["city"].type}`)
                  }
                />
              </StyledBTCTextFieldWrapper>
            ) : null}
            {/* ######### Street ######### */}
            {showAddressLineField ? (
              <StyledBTCTextFieldWrapper>
                <StyledSelectorLabel required>
                  {t(`${pageTranslationPrefix}.whatsYourStreet`)}
                </StyledSelectorLabel>
                <StyledBTCTextField
                  id="apply-info-street"
                  type="text"
                  error={!!errors["address"]}
                  placeholder={`${t(`${pageTranslationPrefix}.whatsYourStreetPlaceholder`)}`}
                  {...register("address", {
                    value: googleAddress?.addressLine ?? "",
                    required: true,
                    maxLength: 30,
                    pattern: addressRegex,
                  })}
                  helperText={
                    errors["address"] &&
                    t(`${pageTranslationPrefix}.errorStreet.${errors["address"].type}`)
                  }
                />
              </StyledBTCTextFieldWrapper>
            ) : null}
            {/* ######### Zipcode ######### */}
            {showZipCodeField ? (
              <StyledBTCTextFieldWrapper>
                <StyledSelectorLabel required>
                  {t(`${pageTranslationPrefix}.whatsYourZipCode`)}
                </StyledSelectorLabel>
                <StyledBTCTextField
                  id="apply-info-zipcode"
                  type="number"
                  error={!!errors["zipCode"]}
                  placeholder={`${t(`${pageTranslationPrefix}.yourZipCodePlaceholder`)}`}
                  {...register("zipCode", {
                    value: googleAddress?.zipCode ?? "",
                    required: true,
                    minLength: 5,
                    maxLength: 5,
                  })}
                  helperText={
                    errors["zipCode"] &&
                    t(`${pageTranslationPrefix}.errorZipcode.${errors["zipCode"].type}`)
                  }
                />
              </StyledBTCTextFieldWrapper>
            ) : null}

            {/* ######### Phone number ######### */}
            <StyledBTCTextFieldWrapper>
              <StyledSelectorLabel required>
                {t(`${pageTranslationPrefix}.whatsYourPhone`)}
              </StyledSelectorLabel>

              <StyledBTCTextField
                id="apply-info-phone"
                type="number"
                error={!!errors["phone"]}
                placeholder={`${t(`${pageTranslationPrefix}.typeYourPhone`)}`}
                {...register("phone", {
                  required: true,
                  validate: validatePhone,
                  maxLength: PHONE_LENGTH_LIMIT,
                  minLength: PHONE_LENGTH_LIMIT,
                })}
                helperText={
                  errors["phone"] &&
                  t(`${pageTranslationPrefix}.errorPhoneNumber.${errors["phone"].type}`)
                }
              />
            </StyledBTCTextFieldWrapper>

            {/* ######### Social security number field ######### */}
            <StyledBTCTextFieldWrapper>
              <StyledSelectorLabel required>
                {t(`${pageTranslationPrefix}.yourSocialSecurityNumber`)}
              </StyledSelectorLabel>
              <SSNField id={"ssn-field"} placeholder={addYourSSNPlaceholder} control={control} />
              <StyledInfoTxt id="apply-info-title">
                {t(`${pageTranslationPrefix}.yourReallyYou`)}
              </StyledInfoTxt>
            </StyledBTCTextFieldWrapper>

            <StyledActionButtonsWrapper>
              <StyledBTCSSubmitButton type="submit" id="apply-info-submit">
                {t(`${pageTranslationPrefix}.next`)}
              </StyledBTCSSubmitButton>
              <StyledBTCSBackButton
                onClick={handleBackClick}
                startIcon={<ArrowBackIcon />}
                id="step-4-go-back">
                {t(`${pageTranslationPrefix}.back`)}
              </StyledBTCSBackButton>
            </StyledActionButtonsWrapper>
          </StyledApplyForm>
        </StyledApplyAdvanceForPtoBody>
      </StyledApplyBody>
      {isLoading ? <BTCLoader /> : null}
    </StyledCashAdvanceContainer>
  );
};

export default ApplyInfo;
