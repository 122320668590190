import { observer } from "mobx-react";
import {
  ButtonsContainer,
  CloseIcon,
  ConsentContainer,
  ConsentText,
  Header,
  HeaderDescription,
  HeaderIcons,
  HeaderTitle,
  ModalContainer,
  SorbetDebitAuthorizationLink,
  StyledModal,
  Loader,
  BankAccountContainer,
} from "./styles";
import { useStores } from "stores";
import closeIcon from "../../../icons/closeIcon.svg";
import bankIcon from "../../../icons/bankIcon.svg";
import lockIcon from "../../../icons/lockIcon.svg";
import { useTranslation } from "react-i18next";
import { StyledCheckbox } from "pages/Btc/styles";
import { PrimaryButton } from "pages/Btc/ApplicationStatus/components/NMIUploads/styles";
import { useEffect, useState } from "react";
import { TGeneratedContract } from "services/api/types";
import { ROUTES } from "config/constants";
import loaderImage from "assets/images/loader.svg";
import { BankAccountItem } from "../BankAccountItem";

export const NewBackupAccountModal = observer(() => {
  const [backUpPaymentMethodContract, setBackUpPaymentMethodContract] =
    useState<TGeneratedContract>();

  const { t } = useTranslation();
  const {
    bankDetailsStore: {
      newBackupPaymentMethod,
      closeNewBackupAccountModal,
      setBackupAccountConsent,
      setNewBackupAccount,
      setNoConsentModal,
      generateBackupContract,
      signBackupContract,
      generateBackupContractLoading,
      openNoConsentModal,
    },
  } = useStores();

  const checkHandler = () => {
    if (newBackupPaymentMethod) {
      setNewBackupAccount({
        ...newBackupPaymentMethod,
        isBackupAccount: !newBackupPaymentMethod.isBackupAccount,
      });
    }
  };

  const signContract = () => {
    if (backUpPaymentMethodContract) {
      signBackupContract(backUpPaymentMethodContract.id);
    }
  };

  const continueClick = () => {
    if (newBackupPaymentMethod) {
      if (newBackupPaymentMethod.isBackupAccount) {
        setBackupAccountConsent(
          newBackupPaymentMethod.bankAccountDetailId,
          newBackupPaymentMethod.bankAccountLast4,
        );
        signContract();
        closeNewBackupAccountModal();
      } else {
        setNoConsentModal(true);
      }
    }
  };

  const generateContract = async () => {
    const contract = await generateBackupContract();
    setBackUpPaymentMethodContract(contract);
  };

  useEffect(() => {
    if (newBackupPaymentMethod) {
      generateContract();
    }
  }, [newBackupPaymentMethod?.bankAccountDetailId]);

  return (
    <StyledModal open={Boolean(newBackupPaymentMethod) && !openNoConsentModal}>
      <ModalContainer>
        <CloseIcon onClick={closeNewBackupAccountModal}>
          <img src={closeIcon} alt={"close"} />
        </CloseIcon>

        <Header>
          <HeaderIcons>
            <img src={bankIcon} alt={"bank-icon"} />
            <img src={lockIcon} alt={"lock-icon"} />
          </HeaderIcons>

          <HeaderTitle>{t("manageLinkedAccounts.addBackupPaymentMethod")}</HeaderTitle>
          <HeaderDescription>
            {t("manageLinkedAccounts.youCanSetUpBackupPayment")}
          </HeaderDescription>
        </Header>

        <BankAccountContainer>
          <BankAccountItem
            type={"backup_consent"}
            institutionName={newBackupPaymentMethod?.institutionName ?? ""}
            bankAccountLast4={newBackupPaymentMethod?.bankAccountLast4 ?? ""}
            showRelink={false}
            relinkClick={() => {}}
          />
        </BankAccountContainer>

        <ConsentContainer>
          <StyledCheckbox
            type="checkbox"
            id={"checkbox"}
            checked={newBackupPaymentMethod?.isBackupAccount}
            onChange={checkHandler}
          />

          <div>
            <ConsentText>
              <label htmlFor="checkbox">
                {t("manageLinkedAccounts.addTheAccountAboveWithBackupPayment")}
              </label>
            </ConsentText>
            <br />
            <ConsentText>
              {t("manageLinkedAccounts.iHaveRead")}
              <SorbetDebitAuthorizationLink
                to={{
                  pathname: ROUTES.BACKUP_PAYMENT_METHOD_AGREEMENT,
                  search: `fileId=${backUpPaymentMethodContract?.fileId}`,
                }}
                target="_blank">
                {t("manageLinkedAccounts.sorbetDebitAuthorization")}
              </SorbetDebitAuthorizationLink>
            </ConsentText>
            <br />
            <ConsentText>{t("manageLinkedAccounts.authorizeSorbetOrItsAgents")}</ConsentText>
          </div>
        </ConsentContainer>

        <ButtonsContainer>
          <PrimaryButton onClick={continueClick}>
            {t("manageLinkedAccounts.continue")}
          </PrimaryButton>
        </ButtonsContainer>

        {generateBackupContractLoading ? (
          <Loader>
            <img src={loaderImage} alt="loading" />
          </Loader>
        ) : null}
      </ModalContainer>
    </StyledModal>
  );
});
