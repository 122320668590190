import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {StyledCSBodyContainer, StyledThankYouBody} from "../Step8/styles";
import {
  StyledBodySubTitleText,
  StyledBodyTitleText,
  StyledQuestionsAndContactUs,
  StyledThankYouContainer,
  StyledThankYouWrapper,
  StyledTrackAppStatusContainer,
} from "./styles";

import {useStores} from "../../../stores";
import {Box, Divider} from "@mui/material";
import {COMPANY_EMAILS, FAQ_PTO_LINK} from "../../../config/constants";
import StatusTracker from "./components/StatusTracker";
import moment from "moment/moment";
import {EB2cApplicationStatus, UserContractStatusEnum} from "services/api/types";
import {observer} from "mobx-react";
import {BTCLoader} from "../Components/Loader";
import {SubmittingApplicationLoader} from "./components/SubmittingLoader";
import {SorbetContractDeclined} from "../../Home/components/SorbetContractDeclined";
import {RenderMessage} from "./components/RenderMessage";
import {RenderAdverseActionNotice} from "./components/RenderAdverseActionNotice";
import {snackbarUtils} from "components/Notification";
import {ApplicationStatusImage} from "./components/ApplicationStatusImage";

const pageTranslationPrefix = "application_status";
const ApplicationStatus = () => {
  const [status, setStatus] = useState(EB2cApplicationStatus.Incomplete);
  const { t } = useTranslation();
  const {
    btcStore: {errorText},
    appStore: { setIsHeaderHidden },
    leadStore: { leadInfo, filesToUploadNMIActive, needToConnectPayroll, fileUploading },
  } = useStores();

  const [openTracker, setOpenTracker] = useState(false);
  useEffect(() => {
    setIsHeaderHidden(false);
  }, []);

  useEffect(() => {
    if (leadInfo) {
      setStatus(leadInfo.b2cApplicationStatus);
      switch (leadInfo.b2cApplicationStatus) {
        case EB2cApplicationStatus.NeedMoreInfo:
        case EB2cApplicationStatus.Frozen:
        case EB2cApplicationStatus.Incomplete:
          snackbarUtils.error(t(`${pageTranslationPrefix}.statuses.Need More Info.info`));
          break;
        default:
          break;
      }
    }
    if (errorText.includes("application status is Canceled"))
        setStatus(EB2cApplicationStatus.Canceled)
  }, [leadInfo?.b2cApplicationStatus]);

  const handleClose = () => {
    setOpenTracker(false);
  };

  return (
    <StyledThankYouWrapper>
      <StyledThankYouContainer>
        <StyledCSBodyContainer>
          {leadInfo?.b2cContractStatus === UserContractStatusEnum.Declined && (
            <SorbetContractDeclined />
          )}

          <StyledThankYouBody>
            <StyledBodyTitleText>
              {t(`${pageTranslationPrefix}.statuses.${status}.header`)}
            </StyledBodyTitleText>
            {status === EB2cApplicationStatus.UnderReview ||
              (status === EB2cApplicationStatus.Pending && (
                <StyledBodySubTitleText>
                  {t(`${pageTranslationPrefix}.submitted`, {
                    date: moment(leadInfo?.timestampStepSix).format("MMMM Do, YYYY"),
                  })}
                </StyledBodySubTitleText>
              ))}
            {   status !== EB2cApplicationStatus.Declined &&
                status !== EB2cApplicationStatus.Canceled &&(
              <StyledTrackAppStatusContainer
                className="track-status-large-screen"
                onClick={() => setOpenTracker(true)}>
                {t(`${pageTranslationPrefix}.trackApplicationStatus`)}
              </StyledTrackAppStatusContainer>
            )}
            <ApplicationStatusImage status={status} needToConnectPayroll={needToConnectPayroll} />

            <RenderMessage status={status} />

            {   status !== EB2cApplicationStatus.Declined &&
                status !== EB2cApplicationStatus.Canceled && (
              <StyledTrackAppStatusContainer
                className="track-status-small-screen"
                onClick={() => setOpenTracker(true)}>
                {t(`${pageTranslationPrefix}.trackApplicationStatus`)}
              </StyledTrackAppStatusContainer>
            )}
            <Divider />
            <StyledQuestionsAndContactUs>
              {t(`${pageTranslationPrefix}.haveAnyQuestions_p1`)}
            </StyledQuestionsAndContactUs>
            <Box display="flex" alignItems="baseline" flexWrap="wrap" style={{marginTop:"12px"}}>
              <StyledQuestionsAndContactUs>
                {t(`${pageTranslationPrefix}.haveAnyQuestions_p2`)}

                <a href={FAQ_PTO_LINK} target="_blank" rel="noreferrer">
                  {"FAQs"}
                </a>
                {t(`${pageTranslationPrefix}.haveAnyQuestions_p3`)}
                <a
                    href={`mailto:${COMPANY_EMAILS.SUPPORT}?subject=Hey! I have a question about my Sorbet PTO Cash Advance application`}
                    target="_blank"
                    rel="noreferrer">
                  {COMPANY_EMAILS.SUPPORT}
                </a>
              </StyledQuestionsAndContactUs>
            </Box>

            <RenderAdverseActionNotice status={status} sponsor={leadInfo?.sponsor} />

            <StatusTracker
              timestamp={leadInfo?.timestampStepSix}
              status={status}
              open={openTracker}
              handleClose={handleClose}
            />
          </StyledThankYouBody>
        </StyledCSBodyContainer>
      </StyledThankYouContainer>

      {fileUploading ? <BTCLoader /> : null}
      {fileUploading && filesToUploadNMIActive.length === 1 ? (
        <SubmittingApplicationLoader />
      ) : null}
    </StyledThankYouWrapper>
  );
};

export default observer(ApplicationStatus);
