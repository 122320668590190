import { useTranslation } from "react-i18next";
import {
  BankInfoContainer,
  BankLastFour,
  BankLogo,
  BankName,
  Container,
  RelinkButton,
  SetUpAsBackup,
  BankInfo,
  BankLogoContainer,
  AlertIcon,
  BankDefaultLogo,
} from "./styles";
import { BankAccountItemActions } from "./Actions";
import alertIcon from "../../../icons/alertIcon.svg";
import bankIcon from "../../../icons/bankIcon.svg";

import {
  AMEX,
  BankOfAmerica,
  BMO,
  USBank,
  Chase,
  WellsFargo,
  TDBank,
  CapitalOne,
  CharlesSchwab,
  CitiBank,
  CitizensFinancial,
  FifthThird,
  FirstCitizens,
  GoldmanSachs,
  HSBC,
  Mellon,
  MorganStanley,
  PNC,
  Truist,
  StateStreet,
} from "./logos";

interface BankAccountPrimaryProps {
  type: "primary";
  institutionName: string;
  bankAccountLast4: string;
  showRelink?: boolean;
  relinkClick: () => void;
  showAuthorize?: boolean;
  authorizeClick: () => void;
}

interface BankAccountBackupProps {
  type: "backup";
  institutionName: string;
  bankAccountLast4: string;
  isBackupAccount: boolean;
  showRelink?: boolean;
  unLinkAccount: () => void;
  setUpAsBackup: () => void;
  relinkClick: () => void;
}

interface BankAccountBackupConsentProps {
  type: "backup_consent";
  institutionName: string;
  bankAccountLast4: string;
  showRelink?: boolean;
  relinkClick: () => void;
}

export const bankLogos: Record<string, string> = {
  "US Bank": USBank,
  Chase: Chase,
  "Wells Fargo": WellsFargo,
  "Goldman Sachs": GoldmanSachs,
  "Morgan Stanley": MorganStanley,
  "Bank Of America": BankOfAmerica,
  "TD Bank": TDBank,
  "Citibank Online": CitiBank,
  PNC: PNC,
  Truist: Truist,
  "Charles Schwab": CharlesSchwab,
  "Capital One": CapitalOne,
  "The Bank of New York Mellon": Mellon,
  "BMO USA": BMO,
  "State Street": StateStreet,
  AMEX: AMEX,
  "Citizens Financial Group": CitizensFinancial,
  HSBC: HSBC,
  "First Citizens Bank": FirstCitizens,
  "Fifth Third Bank": FifthThird,
};

type Props = BankAccountPrimaryProps | BankAccountBackupProps | BankAccountBackupConsentProps;

export const BankAccountItem = (props: Props) => {
  const { t } = useTranslation();
  const showAlert = props.showRelink || (props.type === "backup" && !props.isBackupAccount);

  return (
    <Container>
      <BankInfoContainer>
        <BankLogoContainer>
          {bankLogos[props.institutionName] ? (
            <BankLogo src={bankLogos[props.institutionName]} />
          ) : (
            <BankDefaultLogo src={bankIcon} />
          )}
          {showAlert ? <AlertIcon src={alertIcon} /> : null}
        </BankLogoContainer>
        <BankInfo>
          <BankName>{props.institutionName}</BankName>
          <BankLastFour>{props.bankAccountLast4}</BankLastFour>
          {props.type === "backup" && !props.isBackupAccount && !props.showRelink ? (
            <SetUpAsBackup>{t("manageLinkedAccounts.pleaseSetUpAsBackup")}</SetUpAsBackup>
          ) : null}
        </BankInfo>
      </BankInfoContainer>

      {props.showRelink ? (
        <RelinkButton onClick={props.relinkClick}>{t("manageLinkedAccounts.relink")}</RelinkButton>
      ) : null}
      
      {props.type === "primary" && !props.showRelink && props.showAuthorize ? (
        <RelinkButton onClick={props.authorizeClick}>{t("manageLinkedAccounts.authorize")}</RelinkButton>
      ) : null}

      {props.type === "backup" && !props.showRelink && !props.isBackupAccount ? (
        <BankAccountItemActions
          isBackupAccount={props.isBackupAccount}
          unLinkAccount={props.unLinkAccount}
          setUpAsBackup={props.setUpAsBackup}
        />
      ) : null}
    </Container>
  );
};
