import styled from "styled-components";
import { Box } from "@mui/material";
import { device } from "config/devices";

export const StyledCloseButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
`;

export const StyledModalWrapper = styled.div`
  margin: auto;
  position: relative;
`;

export const StyledModal = styled(Box)`
  background-color: #fff;
  max-width: 600px;
  position: relative;
  padding: 32px 0;
  border-radius: 8px;
`;

export const StyledModalBodyWithPadding = styled(Box)`
  padding: 0 32px;
`;

export const StyledTermsOfService = styled.div`
  margin-top: 24px;
  padding: 10px 16px;
  color: #32536a;
  font-size: 12px;
  background-color: #f8f8fc;
`;

export const StyledModalSubmitButton = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  max-width: 335px;
  width: 100%;
  height: 56px;
  @media ${device.laptop} {
    height: 42px;
  }

  color: #fff;
  background-color: #ff5277;
  border-radius: 8px;

  font-size: 15px;

  flex: none;
  order: 0;
  flex-grow: 0;
  z-index: 0;
  :hover {
    background-color: #000000;
  }
`;

export const StyledModalSubtitle = styled.p`
  margin-bottom: 32px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #585866;
`;

export const StyledModalTitle = styled.p`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 40px;
  color: #242323;
`;

export const StyledSeparator = styled.div`
  height: 1px;
  width: 100%;
  background-color: #f0eef5;
`;

export const StyledModalButtonsWrapper = styled(Box)`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media ${device.tablet} {
    flex-direction: row-reverse;
    justify-content: flex-start;
    button {
      width: auto;
      padding: 0px 32px;
    }
  }
  padding: 0 32px;
`;

export const StyledModalCancelButton = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  max-width: 335px;
  width: 100%;
  height: 56px;
  @media ${device.laptop} {
    height: 42px;
  }

  color: #ff5277;
  border-radius: 8px;

  font-size: 15px;

  flex: none;
  order: 0;
  flex-grow: 0;
  z-index: 0;
`;
