import { device } from "config/devices";
import styled from "styled-components";

export const Container = styled.div`
  margin-bottom: 48px;
  background: #ffffff;
  padding: 20px;
  padding-top: 0px;
  
  @media ${device.tablet} {
    border-radius: 6px;
    padding-top: 24px;
    border: 1px solid var(--color-primary-blue-30-cloud, #e4e2ed);
  }
`;
export const Title = styled.h1`
  color: var(--text-icon-dark, #242323);
  font-family: Poppins;
  font-style: normal;
  margin-bottom: 32px;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px; /* 142.857% */
  @media ${device.tablet} {
    font-size: 28px;
    font-weight: 600;
    line-height: 40px; /* 142.857% */
  }
`;

export const Description = styled.p`
  color: var(--text-icon-secondary, var(--text-icon-text-secondary, #585866));
  font-family: Poppins;
  font-style: normal;
  margin-bottom: 32px;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  font-size: 16px;
  @media ${device.tablet} {
    font-size: 14px;
  }
`;
export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 24px;
`;

export const StyledWrapper = styled.div`
  display: flex;
  margin-top: 16px;
  flex-direction: column;
  gap: 16px;
`;
export const Image = styled.img`
  width: 255px;
  height: 215px;
`;
export const InfoContainer = styled.div`
  padding: 20px 24px 24px 24px;
  @media ${device.tablet} {
    border-bottom: 1px solid var(--color-primary-blue-30-cloud, #e4e2ed);
  }
`;
export const ButtonContainer = styled.div`
  padding: 24px;
  display: flex;
  justify-content: flex-end;
  margin-top: auto;
`;
