import { PaymentTypeEnum } from "services/api/types";
import { analytics } from "../index";
import { Timer, TLeavePageEventReason } from "./utils";

export const mainEvents = {
  login: {
    onLogin(userId: string, loginMethod: string) {
      analytics.trackEvent({
        event: "User login to app",
        params: { "Login Method": loginMethod, "User ID": userId },
      });
      analytics.setUserProps({ "User ID": userId });
    },
  },
  contract: {
    timer: new Timer(),
    onView() {
      this.timer.init();
      analytics.trackEvent({ event: "View Loan Agreement Page" });
    },
    leavePage(reason: string | TLeavePageEventReason) {
      this.timer.update(reason);
      if (reason === "Focus") {
        return;
      }
      analytics.trackEvent({
        event: "Leave Loan Agreement",
        params: { Reason: reason, "Duration (s)": this.timer.duration },
      });
    },
    onSign(signed: boolean) {
      analytics.trackEvent({
        event: "Reply To Loan Agreement",
        params: { "Signed On Loan Agreement?": signed ? "Agree" : "Disagree" },
      });
    },
  },
  firstTimeConsent: {
    timer: new Timer(),
    onView() {
      this.timer.init();
      analytics.trackEvent({ event: "View Consent Page" });
    },
    leavePage(reason: string | TLeavePageEventReason) {
      this.timer.update(reason);
      if (reason === "Focus") {
        return;
      }
      analytics.trackEvent({
        event: "Leave Consent Page",
        params: { Reason: reason, "Duration (s)": this.timer.duration },
      });
    },
    onConsent(consentMethod: string) {
      analytics.trackEvent({
        event: "Select Consent Method",
        params: {
          "Consent Method": consentMethod === "oneTime" ? "Single Payment" : "Autopay",
          "Duration (s)": this.timer.duration,
        },
      });
    },
  },
  expressPayment: {
    timer: new Timer(),
    onView() {
      this.timer.init();
      analytics.trackEvent({ event: "View Express Transfer Page" });
    },
    leavePage(reason: string | TLeavePageEventReason, params: object = {}) {
      this.timer.update(reason);
      if (reason === "Focus") {
        return;
      }
      analytics.trackEvent({
        event: "Leave View Express Transfer Page",
        params: { Reason: reason, "Duration (s)": this.timer.duration, ...params },
      });
    },
    selectTransferMethod(type: PaymentTypeEnum, transferAmount: string) {
      analytics.trackEvent({
        event: "Select Transfer Method",
        params: {
          Action: type === PaymentTypeEnum.express_disbursement ? "Express" : "Standard",
          "Transfer Amount": transferAmount,
        },
      });
    },
  },
};
