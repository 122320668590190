import {
  StyledBTCTextFieldWrapper,
  StyledSelectorLabel,
  StyledBTCSelectorWrapper,
  StyledBTCSelector,
  StyledSelectorItem,
  StyledBTCSelectError,
  StyledBTCUploadFileError,
  StyledBTCSSubmitButton,
} from "../../styles";

import BtcErrorBox from "../../Components/ErrorBox";
import { sxSelect } from "../../Step1/styles";

import {
  StyledApplyForPtoBody,
  StyledApplyForm,
  StyledBlueText,
  StyledBTCTextFieldMoney,
  StyledPageTitle,
} from "../styles";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { useStores } from "stores";
import { EVENTS, useLeavePageAnalytics } from "services/analytics/events";
import { useForm } from "react-hook-form";
import { THearAboutUsFrom, THowToUseFunds } from "services/api/types";
import { DEFAULT_MAX_ADVANCE_MONEY } from "pages/Btc/Constants";
import { btcCheckUuid, btcErrorHandler } from "utils/btcErrorHandler";
import { useLocation, useNavigate } from "react-router-dom";
import { gtmService } from "services/gtm";
import moment from "moment";
import { currencyFormatter } from "utils/formatters";
import { LOCAL_STORAGE_KEYS, LocalStorage } from "utils/localStorage";
const pageTranslationPrefix = "page_Apply";

type FormValues = {
  centsToAdvance?: number;
  howToUseFunds?: THowToUseFunds;
  hearAboutUsFrom?: THearAboutUsFrom;
};

type Props = { showHearAboutSorbetQ: boolean };
export const ApplyForPtoForm = observer(({ showHearAboutSorbetQ }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const userUuid = LocalStorage.getItem(LOCAL_STORAGE_KEYS.uuid);
  const {
    appStore: { leadsInitConfig },
    btcStore: { errorBox, btcPTO, showCalculatorResult, updateBtcApplication },
  } = useStores();

  const {
    register,
    handleSubmit,
    watch,
    getValues,
    formState: { errors },
  } = useForm<FormValues>({ mode: "onBlur" });

  useLeavePageAnalytics((eventType) => {
    const data = getValues();
    EVENTS.btc.step3.leavePage({ action: eventType, ...data });
  });

  const validateMaxAdvance = (enterAmount: number | undefined) => {
    const validationAmount = showCalculatorResult ? btcPTO : DEFAULT_MAX_ADVANCE_MONEY;
    if (enterAmount) {
      return !(enterAmount > validationAmount / 100);
    } else {
      return false;
    }
  };

  const onSubmit = async (data: FormValues) => {
    EVENTS.btc.step3.actionButtonClick("Apply Now");
    btcCheckUuid();
    if (userUuid !== null) {
      try {
        const PageData = {
          centsToAdvance: Number(data.centsToAdvance) * 100,
          howToUseFunds: data.howToUseFunds,
          hearAboutUsFrom: data.hearAboutUsFrom || undefined,
          uuid: userUuid!,
          timestampStepTwo: moment().toISOString(),
          lastSubmittedStep: 3,
        };
        await updateBtcApplication(PageData);
        EVENTS.btc.step3.submit(data, true);
        LocalStorage.setItem(LOCAL_STORAGE_KEYS.lastSubmittedStep, "4");
        navigate(`/cash-advance/apply-info${location.search}`);
        gtmService.customEvent({
          event: "step3Submission",
          uuid: LocalStorage.getItem(LOCAL_STORAGE_KEYS.uuid),
        });
      } catch (error: any) {
        btcErrorHandler(error, "step3");
        EVENTS.btc.step3.submit({ ...data, failReason: error.message }, false);
      }
    }
  };

  const centsToAdvance = watch("centsToAdvance");

  return (
    <StyledApplyForPtoBody>
      <StyledPageTitle id="apply-form">
        {t(`${pageTranslationPrefix}.applyForPtoCash`)}
      </StyledPageTitle>
      <StyledBlueText>{t(`${pageTranslationPrefix}.applyingIsEasy`)}</StyledBlueText>

      {errorBox && <BtcErrorBox />}
      <StyledApplyForm onSubmit={handleSubmit(onSubmit)}>
        {/* Money Advance  */}
        <StyledBTCTextFieldWrapper>
          <StyledSelectorLabel required>
            {t(`${pageTranslationPrefix}.moneyAdvance`)}
          </StyledSelectorLabel>
          <StyledBTCTextFieldMoney
            empty={!centsToAdvance ? 1 : 0}
            type="number"
            error={!!errors["centsToAdvance"]}
            placeholder={`${t(`${pageTranslationPrefix}.moneyAdvance_placeholder`, {
              maxAmount: currencyFormatter(
                showCalculatorResult ? btcPTO : DEFAULT_MAX_ADVANCE_MONEY,
                { noFraction: true, rounding: "floor" },
              ),
            })}`}
            {...register("centsToAdvance", {
              required: true,
              validate: validateMaxAdvance,
            })}
            defaultValue={""}
            helperText={
              errors["centsToAdvance"]
                ? t(`${pageTranslationPrefix}.errorMoneyAdvance.${errors["centsToAdvance"].type}`, {
                    maxAmount: currencyFormatter(
                      showCalculatorResult ? btcPTO : DEFAULT_MAX_ADVANCE_MONEY,
                      { noFraction: true, rounding: "floor" },
                    ),
                  })
                : undefined
            }
            id="amount-to-advance-input"
          />
        </StyledBTCTextFieldWrapper>

        {/* ######### Funds Usage Selector ######### */}
        <StyledBTCSelectorWrapper sx={{ flexGrow: 1, marginTop: "24px" }}>
          <StyledSelectorLabel required={true}>
            {t(`${pageTranslationPrefix}.fundsUsageSelector.description`)}
          </StyledSelectorLabel>
          <StyledBTCSelector
            SelectDisplayProps={{ style: { fontFamily: "Poppins", fontSize: 15 } }}
            error={!!errors["howToUseFunds"]}
            MenuProps={{ PaperProps: { sx: { maxHeight: 400, top: 380 } } }}
            sx={{ ...sxSelect, backgroundColor: "#fff" }}
            {...register("howToUseFunds", { required: true })}
            defaultValue={""}
            id="use-funds-selector">
            {leadsInitConfig?.fundUsagePlans.map((item: THowToUseFunds) => (
              <StyledSelectorItem value={item} key={item} id={item}>
                {t(`${pageTranslationPrefix}.fundsUsageSelector.${item}`)}
              </StyledSelectorItem>
            ))}
          </StyledBTCSelector>
          <StyledBTCSelectError error={!!errors["howToUseFunds"]}>
            <StyledBTCUploadFileError>
              {t(`${pageTranslationPrefix}.errorSelect`)}
            </StyledBTCUploadFileError>
          </StyledBTCSelectError>
        </StyledBTCSelectorWrapper>

        {/* ######### How did you hear about us  ######### */}
        {showHearAboutSorbetQ && (
          <StyledBTCSelectorWrapper sx={{ marginTop: "24px" }}>
            <StyledSelectorLabel required={false}>
              {t(`${pageTranslationPrefix}.hearAboutUsSelector.description`)}
            </StyledSelectorLabel>
            <StyledBTCSelector
              SelectDisplayProps={{ style: { fontFamily: "Poppins", fontSize: 15 } }}
              error={!!errors["hearAboutUsFrom"]}
              MenuProps={{ PaperProps: { sx: { maxHeight: 400, top: 380 } } }}
              sx={{ ...sxSelect, backgroundColor: "#fff" }}
              {...register("hearAboutUsFrom", { required: false })}
              defaultValue={""}
              id="hear-about-us-selector">
              {leadsInitConfig?.hearAboutUsFrom.map((item: THearAboutUsFrom) => (
                <StyledSelectorItem value={item} key={item} id={item}>
                  {t(`${pageTranslationPrefix}.hearAboutUsSelector.${item}`)}
                </StyledSelectorItem>
              ))}
            </StyledBTCSelector>
          </StyledBTCSelectorWrapper>
        )}

        <StyledBTCSSubmitButton id="get-started-submit" type="submit" style={{ marginTop: "24px" }}>
          {t(`${pageTranslationPrefix}.${showCalculatorResult ? "applyNow" : "continueAnyway"}`)}
        </StyledBTCSSubmitButton>
      </StyledApplyForm>
    </StyledApplyForPtoBody>
  );
});
