import { BackIcon, Header, HeaderTitle, StyledWrapper, TopHeader, Wrapper } from "./styles";
import backIcon from "./backIcon.svg";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ROUTES } from "config/constants";
import { useEffect } from "react";
import { useStores } from "stores";
import { IconButton } from "@mui/material";
import { PaymentHistoryEmpty } from "./components/EmptyFlow";
import { Payments } from "./components/Payments";
import { observer } from "mobx-react";

const translationKey = "paymentHistory";
export const PaymentHistory = observer(() => {
  const {
    appStore: { setIsHeaderHidden },
    transfersInfoStore: { settledTransfers },
  } = useStores();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const backClick = () => {
    navigate(ROUTES.HOME);
  };
  useEffect(() => {
    setIsHeaderHidden(true);
  }, []);

  return (
    <StyledWrapper>
      <TopHeader />

      <Wrapper>
        <Header>
          <IconButton onClick={backClick}>
            <BackIcon src={backIcon} />
          </IconButton>
          <HeaderTitle>{t(`${translationKey}.title`)}</HeaderTitle>
        </Header>
        {settledTransfers.length > 0 ? <Payments /> : <PaymentHistoryEmpty />}
      </Wrapper>
    </StyledWrapper>
  );
});
