import { useEffect, useState } from "react";
import { Routes, Route, useNavigate, useSearchParams } from "react-router-dom";
import PtoCashAdvance from "../pages/Btc/Step1";
import Apply from "../pages/Btc/Step2";
import CreditScore from "pages/Btc/Step5";
import ApplyAdvance from "pages/Btc/Step3";
import SecuritySocialNumber from "pages/Btc/Step6";
import PayStubs from "pages/Btc/Step7";
import ApplyInfo from "../pages/Btc/Step4";
import ThankYou from "pages/Btc/Step8";
import { MainBtcContainer } from "./styles";
import BtcErrorModal from "pages/Btc/Components/ErrorModal";
import { errorLogger } from "../services/logger";
import { observer } from "mobx-react";
import { useStores } from "stores";
import { BTCLoader } from "pages/Btc/Components/Loader";
import { btcCheckUuid } from "../utils/btcErrorHandler";
import { STEPS_MAP, URLS, URLS_MAP } from "../pages/Btc/Constants";
import { GTMDataLayer } from "components/GTMDataLayer";
import { gtmService } from "services/gtm";
import { LOCAL_STORAGE_KEYS, LocalStorage } from "utils/localStorage";

const BtcRouter = observer(() => {
  const [initialization, setInitialization] = useState(true);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const {
    appStore: { leadsInitConfigLoading },
    btcStore: { btcInit },
  } = useStores();

  const sendErrorToDD = async () => {
    errorLogger.captureMessage(`uuid is null, returned url is: ${window.location.href}`);
  };

  function isUrlMatch(element: string) {
    return window.location.href.includes(element);
  }

  useEffect(() => {
    const init = async () => {
      try {
        const href = window.location.href;

        const uuid = LocalStorage.getItem(LOCAL_STORAGE_KEYS.uuid);
        const uuidFromUrl = searchParams.get("lhi");
        LocalStorage.setItem(
          LOCAL_STORAGE_KEYS.original_sponsor,
          searchParams.get("original_sponsor") ?? "sorbet",
        );

        const sameUser = uuidFromUrl === uuid;
        if (uuidFromUrl) {
          //user is coming back from email url
          //store the uuid in the localstorage.
          LocalStorage.setItem(
            LOCAL_STORAGE_KEYS.returnedEmailUrl,
            href.includes("localhost")
              ? `https://cash.qa.sorbetapp.com${window.location.pathname}${window.location.search}`
              : href,
          );
          LocalStorage.setItem(LOCAL_STORAGE_KEYS.uuid, uuidFromUrl);
          searchParams.delete("lhi");

          //store the lastSubmittedStep based on url
          const url: string | undefined = URLS.find(isUrlMatch);
          if (url) {
            const localLastSubmittedStep = LocalStorage.getItem(
              LOCAL_STORAGE_KEYS.lastSubmittedStep,
            );
            // local lastSubmittedStep should update step from link only if:
            // nothing stored locally,
            // link step is higher than local step for the same user,
            // or if this is not the same user.
            if (
              localLastSubmittedStep === null ||
              (URLS_MAP[url] > +localLastSubmittedStep && sameUser) ||
              !sameUser
            ) {
              LocalStorage.setItem(LOCAL_STORAGE_KEYS.lastSubmittedStep, URLS_MAP[url].toString());
            }
          }
          if (href.toLowerCase().includes("pay-stubs")) {
            gtmService.customEvent({ event: "enteredStep7", lead_uuid: uuid });
          }
        } else {
          if (!uuid) {
            sendErrorToDD();
          }
        }

        btcCheckUuid();
        // ========================================================================================================================================

        const storedLastSubmittedStep = LocalStorage.getItem(LOCAL_STORAGE_KEYS.lastSubmittedStep);
        if (storedLastSubmittedStep) {
          const isOAuthRedirect = href.includes("?oauth_state_id=");
          if (!isOAuthRedirect) {
            navigate(`/cash-advance/${STEPS_MAP[storedLastSubmittedStep]}?${searchParams}`, {
              replace: true,
            });
          }
        } else {
          navigate(`/cash-advance/pto-cash-advance?${searchParams}`, { replace: true });
        }
      } catch (error) {
      } finally {
        await btcInit();
        setInitialization(false);
      }
    };
    init();
  }, []);

  if (initialization) {
    return (
      <MainBtcContainer>
        <BTCLoader />
      </MainBtcContainer>
    );
  }

  return (
    <MainBtcContainer>
      <Routes>
        <Route
          path="/pto-cash-advance"
          element={
            <GTMDataLayer pageTitle="b2c step1">
              <PtoCashAdvance />
            </GTMDataLayer>
          }
        />

        <Route
          path="/apply"
          element={
            <GTMDataLayer pageTitle="b2c step2">
              <Apply />
            </GTMDataLayer>
          }
        />

        <Route
          path="/apply-advance"
          element={
            <GTMDataLayer pageTitle="b2c step3">
              <ApplyAdvance />
            </GTMDataLayer>
          }
        />

        <Route
          path="/apply-info"
          element={
            <GTMDataLayer pageTitle="b2c step4">
              <ApplyInfo />
            </GTMDataLayer>
          }
        />

        <Route
          path="/credit-score"
          element={
            <GTMDataLayer pageTitle="b2c step5">
              <CreditScore />
            </GTMDataLayer>
          }
        />

        <Route
          path="/security-social-number"
          element={
            <GTMDataLayer pageTitle="b2c step6">
              <SecuritySocialNumber />
            </GTMDataLayer>
          }
        />

        <Route
          path="/pay-stubs"
          element={
            <GTMDataLayer pageTitle="b2c step7">
              <PayStubs />
            </GTMDataLayer>
          }
        />

        <Route
          path="/thank-you"
          element={
            <GTMDataLayer pageTitle="b2c step8">
              <ThankYou />
            </GTMDataLayer>
          }
        />

        <Route
          path="/*"
          element={
            <GTMDataLayer pageTitle="b2c step1">
              <PtoCashAdvance />
            </GTMDataLayer>
          }
        />
      </Routes>
      {leadsInitConfigLoading ? <BTCLoader /> : null}
      <BtcErrorModal />
    </MainBtcContainer>
  );
});

export default BtcRouter;
