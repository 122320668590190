// Core
import styled from "styled-components";

// Assets
import { Colors, GRADIENT_COLORS } from "assets/styles/constants";

// Components
import { ButtonBase } from "../Buttons";
import { Modal } from "components/Modal";

export const ButtonsGroup = styled.div<{ isMobileView: boolean }>`
  display: flex;
  flex-direction: ${({ isMobileView }) => (isMobileView ? "column" : "row")};
  justify-content: center;
  align-items: center;
  margin-top: ${({ isMobileView }) => (isMobileView ? "24px" : "54px")} !important;

  & > button {
    margin: 12px;
    width: 136px;
  }
`;

export const StyledModal = styled(Modal)<{ width: number }>``;

export const StyledHeadsUp = styled.h1`
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 40px !important;
`;

export const StyledHeadsUpBigScreen = styled.h1`
  margin-top: 30px;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 60px !important;
`;

export const StyledWarningText = styled.p`
  color: ${Colors.pink};
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
`;

export const StyledContextMessage = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: ${Colors.darkBlue};
  padding-top: 10px;
`;

export const PrimaryButton = styled(ButtonBase).attrs({
  colors: GRADIENT_COLORS,
})<{ isMobileView: boolean }>`
  border-radius: 21px;
  color: ${Colors.textColorWhite};
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  width: 176px !important;
  margin-bottom: ${({ isMobileView }) => (isMobileView ? "4px" : "12px")} !important;
`;

export const SecondButton = styled(PrimaryButton)<{ isMobileView: boolean }>`
  background: ${Colors.textColorWhite};
  color: ${Colors.blue};
  border: 1px solid ${Colors.blue};
  border-radius: 21px;
  width: 176px !important;
  font-weight: 600;
  font-size: 16px;
  margin-top: ${({ isMobileView }) => (isMobileView ? "4px" : "12px")} !important;
`;
