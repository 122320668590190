import { useState } from "react";
import { FieldValues, UseFormRegister, FieldPath } from "react-hook-form";

import PlusSvg from "assets/images/plus";
import {
  StyledBTCUploadFileButton,
  StyledBTCUploadFileButtonWrapper,
  StyledBTCUploadFileError,
  StyledBTCUploadFileName,
  StyledBTCUploadFileSuccess,
  StyledSelectorLabel,
} from "pages/Btc/styles";
import { FILE_SIZE_LIMIT } from "../../Constants";
import { gtmService } from "services/gtm";
import { LOCAL_STORAGE_KEYS, LocalStorage } from "utils/localStorage";

type UploadFileButtonProps<T extends FieldValues> = {
  error?: boolean;
  errorMessage?: string;
  successMessage: string;
  title?: string;
  required?: boolean;
  register: UseFormRegister<T>;
  buttonText: string;
  name: FieldPath<T>;
  id?: string;
  onSuccess?: () => void;
  onClick?: () => void;
};

const validateType = (file: File[]) => {
  if (file[0]?.name) {
    const fileName = file[0].name.toLowerCase();
    return (
      fileName.endsWith(".doc") ||
      fileName.endsWith(".docx") ||
      fileName.endsWith(".jpg") ||
      fileName.endsWith(".jpeg") ||
      fileName.endsWith(".png") ||
      fileName.endsWith(".pdf")
    );
  }
  return true;
};
const validateSize = (file: File[]) => {
  if (file[0]?.size) {
    return file[0].size < FILE_SIZE_LIMIT;
  }
  return true;
};
const UploadFileButton = <T extends FieldValues>({
  error = false,
  errorMessage,
  successMessage,
  title,
  required = false,
  register,
  buttonText,
  name,
  id,
  onSuccess,
  onClick,
}: UploadFileButtonProps<T>) => {
  const [file, setFile] = useState<File | undefined>();

  const { onChange, ...rest } = register(name, {
    required,
    validate: {
      validateType,
      validateSize,
    },
  });
  return (
    <StyledBTCUploadFileButtonWrapper
      visibility={"visible"}
      onMouseDown={() => {
        if (typeof onClick === "function") {
          onClick();
        }
      }}>
      {title ? <StyledSelectorLabel required={required}>{title}</StyledSelectorLabel> : null}
      <StyledBTCUploadFileButton htmlFor={name} error={error} id={id}>
        {file ? (
          <StyledBTCUploadFileName>{file.name}</StyledBTCUploadFileName>
        ) : (
          <>
            <PlusSvg />
            <span>{buttonText}</span>
          </>
        )}
        <input
          id={name}
          hidden
          type="file"
          accept="image/JPG,image/JPEG,image/PNG, application/pdf, .doc, .docx"
          onChange={(e) => {
            setFile(e.target.files?.[0]);
            if (e.target.files?.[0] && typeof onSuccess === "function") {
              onSuccess();
            }
            if (name.toString() === "payStub1") {
              gtmService.customEvent({
                event: "paystubs1finish",
                uuid: LocalStorage.getItem(LOCAL_STORAGE_KEYS.uuid),
              });
            }

            onChange(e);
          }}
          {...rest}
        />
      </StyledBTCUploadFileButton>
      {error ? <StyledBTCUploadFileError>{errorMessage}</StyledBTCUploadFileError> : null}

      {file && !error ? (
        <StyledBTCUploadFileSuccess>{successMessage}</StyledBTCUploadFileSuccess>
      ) : null}
    </StyledBTCUploadFileButtonWrapper>
  );
};
export default UploadFileButton;
