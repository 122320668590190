import { Button, styled as muiStyled } from "@mui/material";
import { device } from "config/devices";
import styled from "styled-components";

export const Title = styled.h1`
  color: #242323;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px; /* 125% */
  margin-top: 8px;
  @media ${device.tablet} {
    margin-top: 0px;
    font-weight: 600;
    line-height: 30px; /* 125% */
  }
`;

export const Description = styled.p`
  color: var(--text-icon-secondary, var(--text-icon-text-secondary, #585866));
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px; /* 128.571% */
  margin-bottom: 24px;
  @media ${device.tablet} {
    margin-top: 6px;
    font-size: 14px;
    line-height: 18px; /* 128.571% */
  }
`;

export const Item = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
  margin-bottom: 32px;
`;
export const ItemTitle = styled.p`
  color: var(--text-icon-dark, #242323);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
`;
export const ItemDescription = styled.p`
  color: var(--text-icon-secondary, var(--text-icon-text-secondary, #585866));

  /* Text Medium/Regular */
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
`;

export const PayrollAccountButtons = styled.div`
  margin-top: 32px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column-reverse;
  gap: 16px;
  @media ${device.tablet} {
    align-items: center;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    button {
      width: auto;
    }
  }
`;

export const PayrollAccountActionButtons = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: column-reverse;
  @media ${device.tablet} {
    flex-direction: row;
  }
`;

export const PayrollAccountDoItManually = muiStyled(Button)`
  border-radius: var(--Radius-medium-radius, 8px);
  border: 1px solid var(--text-icon-text-link, #ff5277);
  color: var(--text-icon-text-link, #ff5277);

  /* Text Medium/Regular */
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  text-transform: none;
  padding: 0px 16px;
  height: 56px;
  width: 100%;
  @media ${device.laptop} {
    height: 42px;
    width: auto;
  }
`;
