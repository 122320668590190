import { useTranslation } from "react-i18next";
import { INewBackupAccount } from "stores/bankDetails/types";

type Props = {
  bankAccount: INewBackupAccount;
};

export const AccountAddedWithoutConsent = (props: Props) => {

  const { t } = useTranslation();

  return (
    <span>
      {t("manageLinkedAccounts.bankAccountSuccessfullyLinked", {
        bankAccountLast4: props.bankAccount?.bankAccountLast4,
      })}
    </span>
  );
};
