import { useTranslation } from "react-i18next";
import { StyledWrapper, Wrapper } from "pages/PtoCenter/styles";
import {
  ButtonContainer,
  Description,
  NoticeWrapper,
  StyledCloseIcon,
  StyledNoticeInnerWrapper,
  StyledPayStubWrapper,
  StyledSmallNotification,
  StyledSmallRedNotification,
  StyledText,
  Title,
  TopSkipHeader,
} from "./styles";
import { IconButton } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { useStores } from "stores";
import { ROUTES } from "../../../../config/constants";
import { observer } from "mobx-react";
import UploadFileButton from "../../../../components/PaystubUploadButton";
import { Loader } from "components/Loader";
import Stepper from "./Stepper";
import { Moment } from "moment";
import { EVENTS, useLeavePageAnalytics } from "services/analytics/events";
import { determineUserStatus } from "./determineUserStatus";
import CloseIcon from "assets/icons/close.svg";

const translationText = "PTOCenter.paystubs_page";
const Paystubs = observer(() => {
  const [initialization, setInitialization] = useState(true);
  const isDocumentUploaded = useRef<boolean>(false);
  const { t } = useTranslation();
  const {
    leadStore: { leadApprovalInfo },
    userStore: { userInfo, userFiles, getUserFiles, userFilesLoading },
    appStore: { setIsHeaderHidden },
  } = useStores();

  const navigate = useNavigate();
  const location = useLocation();
  const [userStatus, setUserStatus] = useState<string>("state1");
  const [timestamps, setTimestamps] = useState<Moment[]>([]);
  const [payStubUploadedThisMonth, setPayStubUploadedThisMonth] = useState(false);
  const [hasPtoBalanceDoc, setHasPtoBalanceDoc] = useState(false);
  useLeavePageAnalytics((eventType) =>
    EVENTS.ptoCenter.paystub.onLeave({
      reason: eventType,
      payStubUploadedThisMonth,
      documentType: hasPtoBalanceDoc ? "PTO Balance Proof" : "Paystub",
      isDocumentUploaded: isDocumentUploaded.current,
    }),
  );
  const backClick = () => {
    navigate(-1);
    EVENTS.ptoCenter.paystub.skip();
  };

  const navigateToPayroll = () => {
    navigate(ROUTES.PAYROLL_FROM_CENTER);
    EVENTS.ptoCenter.paystub.onLeave({
      reason: "Connect Payroll",
      payStubUploadedThisMonth,
      documentType: hasPtoBalanceDoc ? "PTO Balance Proof" : "Paystub",
      isDocumentUploaded: isDocumentUploaded.current,
    });
  };

  useEffect(() => {
    setIsHeaderHidden(true);
    const init = async () => {
      await getUserFiles();
      setInitialization(false);
    };
    init();
  }, []);

  useEffect(() => {
    if (userFiles.length > 0) {
      const result = determineUserStatus(userFiles, userInfo);

      setUserStatus(result.userStatus);
      setTimestamps(result.timestamps);
      setPayStubUploadedThisMonth(result.payStubUploadedThisMonth);
      setHasPtoBalanceDoc(result.hasPtoBalanceDoc);
    }
  }, [userFiles]);

  useEffect(() => {
    if (!initialization) {
      const isRedirectAfterLogin = location.state?.isRedirectAfterLogin;
      const originFrom = location.state?.originFrom;
      EVENTS.ptoCenter.paystub.onView({
        userId: userInfo?.id ?? "",
        origin: isRedirectAfterLogin ? "Email Campaign" : originFrom,
        payStubUploadedThisMonth,
      });
    }
  }, [payStubUploadedThisMonth, initialization]);

  return (
    <StyledWrapper>
      {userFilesLoading ? (
        <Loader />
      ) : (
        <Wrapper>
          <TopSkipHeader>
            <IconButton onClick={backClick}>
              {payStubUploadedThisMonth ? (
                <StyledCloseIcon src={CloseIcon} />
              ) : (
                <StyledText>{t(`${translationText}.skipForNow`)}</StyledText>
              )}
            </IconButton>
          </TopSkipHeader>
          <StyledPayStubWrapper>
            <Title>
              {t(
                `${translationText}.title_${
                  payStubUploadedThisMonth
                    ? hasPtoBalanceDoc
                      ? "uploadSuccess_ptoBalanceDoc"
                      : "uploadSuccess_payStubOne"
                    : "payStubOne"
                }`,
                { name: userInfo?.firstName },
              )}
            </Title>

            {payStubUploadedThisMonth && <Title>{t(`${translationText}.seeYouNextMonth`)}</Title>}
            <Description>
              {t(
                `${translationText}.description_${
                  payStubUploadedThisMonth
                    ? hasPtoBalanceDoc
                      ? "ptoBalanceDoc_success"
                      : "payStubOne_success"
                    : hasPtoBalanceDoc
                    ? "ptoBalanceDoc"
                    : "payStubOne"
                }`,
              )}
            </Description>
            {!payStubUploadedThisMonth && (
              <Description>
                {t(
                  `${translationText}.description_${
                    hasPtoBalanceDoc ? "ptoBalanceDoc_pt2" : "payStubOne_pt2"
                  }`,
                )}
              </Description>
            )}

            <Stepper status={userStatus} timestamps={timestamps} />
            <NoticeWrapper>
              {!payStubUploadedThisMonth && (
                <ButtonContainer>
                  <UploadFileButton
                    buttonText={
                      hasPtoBalanceDoc
                        ? t(`${translationText}.buttonProofOfContent`)
                        : t(`${translationText}.button`)
                    }
                    name={hasPtoBalanceDoc ? "ptoBalanceDoc" : "payStubOne"}
                    uuid={leadApprovalInfo?.uuid || ""}
                    successMessage={t(`${translationText}.successMessage`)}
                    errorMessage={t(`${translationText}.errorMessage`)}
                    onSuccess={() => {
                      EVENTS.ptoCenter.paystub.paystubUploadedSuccessfully();
                      isDocumentUploaded.current = true;
                    }}
                    onClick={() => {
                      EVENTS.ptoCenter.paystub.attemptUploadPaystub();
                    }}
                  />
                </ButtonContainer>
              )}
              <StyledNoticeInnerWrapper>
                <StyledSmallNotification>
                  {t(`${translationText}.preferNotice`)}
                </StyledSmallNotification>
                <StyledSmallRedNotification onClick={navigateToPayroll}>
                  {t(`${translationText}.connectPayRollNotice`)}
                </StyledSmallRedNotification>
              </StyledNoticeInnerWrapper>
            </NoticeWrapper>
          </StyledPayStubWrapper>
        </Wrapper>
      )}
    </StyledWrapper>
  );
});

export default Paystubs;
