import { IAnalytics, IEvent } from "./types";
export default class AnalyticsDevelopment implements IAnalytics {
  constructor() {
    this.configMixpanel();
  }
  configMixpanel = async () => {};
  trackEvent({ event, params }: IEvent) {}
  identifyUser(id: string) {}
  setSuperProps(props: Object) {}
  setUserProps(props: object) {}
  incrementProperty(property: string) {}
  reset() {}
}
