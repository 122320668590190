import { UserEntityTypeEnum } from "services/api/types";

/**
 * Supported auth types
 */
export enum AuthTypes {
  GOOGLE = "google",
  SORBET_QA = "test",
  PHONE_NUMBER = "PHONE_NUMBER",
  EMAIL_LINK = "EMAIL_LINK",
  EMAIL_LINK_LOGIN = "EMAIL_LINK_LOGIN",
}

export enum AuthServices {
  FIREBASE_RW = "firebase_rw",
  FIREBASE_RO = "firebase_ro",
  sorbet = "sorbet",
  sorbet_qa = "test",
}

export interface ILoginPayload {
  /**
   * User auth id
   */
  id?: string | null;
  success: boolean;
  error?: string;
  ignore?: boolean;
}

export interface IAuthTokenData {
  authToken?: string;
  serviceName: AuthServices;
  entityId?: string;
  entityType?: UserEntityTypeEnum;
}

export interface IAuthInstance {
  /**
   * Instance name
   */
  readonly name: AuthServices;
  /**
   * Is service initialized or not
   */
  readonly isInitialized: boolean;
  /**
   * Initializes the service
   */
  init: () => void;

  getAuthToken: () => Promise<string | undefined>;

  getPhotoUrl: () => Promise<string | null | undefined>;

  /**
   * Load auth state
   * @returns user.fid
   */
  loadAuthState: () => Promise<string | null>;
  /**
   * Log in with provided auth type. If auth with email, need to provide email and password
   */
  logInWith: (
    type: AuthTypes,
    email?: string,
    password?: string,
    smsCode?: string,
  ) => Promise<ILoginPayload>;
  /**
   * Log out and clears auth state
   */
  logOut: () => Promise<void>;

  initializeRecaptcha?: () => void;
  phoneNumberLogin?: (v: string) => Promise<{ errorMessage: string } | undefined>;
  verifyPhoneNumberCode?: (v: string) => Promise<string | null>;
}

export interface ISignInRequest {
  authToken?: string;
  authStrategy: AuthServices;
}

export interface ISignInResponse {
  sorbetAuthToken: string;
  entityId: string;
  entityType: UserEntityTypeEnum;
}
export interface IAuthService
  extends Omit<
    IAuthInstance,
    "isInitialized" | "init" | "signInWith" | "getAuthToken" | "name" | "logInWith"
  > {
  getAuthToken: () => Promise<IAuthTokenData | null>;
  /**
   * Log in with provided auth type. If auth with email, need to provide email and password
   * @param serviceName the name of AuthService to login with. Default: `AuthServices.DEFAULT`
   */

  logInWith: (
    type: AuthTypes,
    email?: string,
    password?: string,
    serviceName?: AuthServices,
    smsCode?: string,
  ) => Promise<ILoginPayload>;
}

export interface IAuthInstanceFactory {
  /**
   * Returns certain `authService` depending on provided `serviceName` param
   */
  getInstance: (serviceName: AuthServices) => IAuthInstance;
}
