import styled from "styled-components";
import { Colors } from "assets/styles/constants";
import footerLogo from "assets/logos/footer-logo.svg";
import { device } from "config/devices";

export const StyledBtcFooter = styled.footer`
  background-color: ${Colors.textColorWhite};
  position: relative;
`;

export const StyledCurvedBottom = styled.img`
  width: 100%;
  background: #f8f8fc;
  height: 31px;
`;

export const StyledCurvedTop = styled.img`
  width: 100%;
  position: absolute;
  height: 40px;
  @media ${device.mobileS} {
    top: -37px;
  }
  @media ${device.tablet} {
    margin-top: -20px;
    top: -18px;
  }
  @media ${device.laptop} {
    margin-top: -20px;
    top: -18px;
  }
`;

export const StyledIconSectionWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  @media ${device.laptop} {
    width: 100%;
    max-width: 891px;
    justify-content: space-between;
  }
`;

export const StyledAsSeenOn = styled.div`
  display: flex;
  flex: wrap;
  flex-direction: column;
  margin-top: 24px;
`;
export const StyledUsedByEmployeesFrom = styled.div`
  display: flex;
  flex: wrap;
  flex-direction: column;
  margin-top: 24px;
  margin-right: 24px;
`;

export const StyledAsSeenOnItems = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
`;

export const SmallStyledIcon = styled.img`
  width: 20px;
  height: 20px;
  margin-bottom: 4px;
`;

export const StyledIcon = styled.div`
  width: 86px;
  height: 86px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledSocialIcon = styled.img`
  width: 56px;
  height: 56px;
  cursor: pointer;
`;

export const FooterBottom = styled.div<{ hideFooter: boolean }>`
  background-color: ${Colors.lightGrey};
  display: flex;
  flex: wrap;
`;

export const FooterBottomContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
  flex-direction: column;
  padding: 0 20px;
`;

export const FooterLogo = styled.img.attrs({
  src: footerLogo,
})`
  width: 150px;
  height: 36px;
`;

export const FooterBottomText = styled.div`
  font-size: 12px;
  margin-bottom: 16px;
  color: ${Colors.black};
  a {
    color: inherit;

    &:hover {
      color: inherit;
      text-decoration: underline;
    }
  }
`;

export const StyledLinkToTermsWithoutUnderline = styled.a`
  text-decoration: none;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: ${Colors.black};
  margin-top: 24px;
  text-decoration: none;
  margin-right: 24px;
  @media ${device.laptop} {
    margin-top: 0px;
    color: ${Colors.black};
    text-decoration: none;
  }
`;

export const StyledLinkToTerms = styled.a`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #ff5277;
  text-decoration-line: underline;
  margin-top: 24px;
  margin-right: 24px;
  @media ${device.laptop} {
    margin-top: 0px;
  }
`;

export const StyledLinkToTermsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-direction: column;
  margin-top: 53px;
  max-width: 891px;
  width: 100%;
  @media ${device.laptop} {
    margin-top: 70px;
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const StyledInnerUrlsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 33px;
  @media ${device.laptop} {
    margin-bottom: 0px;
    flex-direction: row;
    align-items: center;
  }
`;

export const StyledDisclaimer = styled.div`
  background: #f8f8fc;
  padding-top: 40px;
  max-width: 891px;
  width: 100%;
`;
export const StyledDisclaimerText = styled.p`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #585866;
  white-space: pre-wrap;
`;
