import { action, computed, observable } from "mobx";
import { IDebitConsentStore, TConsentModal, TConsentModalSet } from "./types";
import * as types from "../../services/api/types";
import { ConsentStatusEnum } from "../../services/api/types";
import moment from "moment";
import { stores } from "stores";
import { apiService } from "services/api";
import { errorLogger } from "services/logger";
import { EVENTS } from "services/analytics/events";
import bankDetailsStore from "stores/bankDetails";
import i18n from "i18n";
import { snackbarUtils } from "components/Notification";
import transfersInfoStore from "../transfersInfo";
import { currencyFormatter } from "utils/formatters";
import leadStore from "stores/lead";

const consentModalInit: TConsentModal = {
  open: false,
  type: types.DebitAchAuthTypeEnum.AUTO_PAY,
  showSuccess: false,
  confirmButtonEnabled: false,
  version: types.DEBIT_CONSENT_VERSIONS.ONE_TIME,
  loading: false,
  showExpressPayment: false,
  fullScreen: false,
  file: undefined,
  rtpCreditEnabled: false,
};
class DebitConsentStore implements IDebitConsentStore {
  @observable consentModal = consentModalInit;
  @observable confirmButtonEnabled = false;
  @observable consentInfo?: types.IConsents;
  @observable firstConsentError = false;
  @observable _calculatedPayment: {
    [types.PaymentTypeEnum.debit]?: types.CalculatePaymentResponse;
    [types.PaymentTypeEnum.disbursement]?: types.CalculatePaymentResponse;
    [types.PaymentTypeEnum.express_disbursement]?: types.CalculatePaymentResponse;
  } = {};

  @action setConfirmButtonEnabled = (v: boolean) => {
    this.confirmButtonEnabled = v;
  };

  @action setConsentModal = (data: TConsentModalSet) => {
    const { bankDetails } = bankDetailsStore;
    const { leadApprovalInfo } = leadStore;
    const rtpCreditEnabled =
      (!!bankDetails?.rtpCreditEnabled ||
        leadApprovalInfo?.sponsor === types.LeadSponsorEnum.lead_bank) &&
      !this.isFirstConsentExist;
    this.consentModal = {
      ...this.consentModal,
      ...data,
      rtpCreditEnabled,
    };
    switch (data.type) {
      case types.DebitAchAuthTypeEnum.AUTO_PAY:
        this.consentModal.version = types.DEBIT_CONSENT_VERSIONS.AUTOPAY;
        break;
      case types.DebitAchAuthTypeEnum.ONE_TIME:
        this.consentModal.version = types.DEBIT_CONSENT_VERSIONS.ONE_TIME;
        break;
      case types.DebitAchAuthTypeEnum.OFF_CYCLE:
        this.consentModal.version = types.DEBIT_CONSENT_VERSIONS.OFF_CYCLE;
        break;
      default:
        break;
    }
  };

  @action closeConsentModal = () => {
    if (
      this.consentModal.type === types.DebitAchAuthTypeEnum.OFF_CYCLE &&
      this.consentModal.showSuccess
    ) {
      snackbarUtils.success(i18n.t("achDebitAuth.overdueSuccessNotification"));
    }
    this.consentModal = consentModalInit;
    this._calculatedPayment = {};
  };

  @action getConsentPdf = async (type: types.ContractTypeEnum) => {
    try {
      this.consentModal.loading = true;
      const contract = await apiService.generateContract({ type });
      const file = await apiService.getFileContent(contract.fileId);
      this.consentModal.file = file;
    } catch (error) {
    } finally {
      this.consentModal.loading = false;
    }
  };

  @action calculatePayment = async (type: types.PaymentTypeEnum, force: boolean = false) => {
    try {
      if (!this._calculatedPayment[type] || force) {
        if (force) {
          this._calculatedPayment[type] = undefined;
        }
        const payment = await apiService.calculatePayment(type);
        this._calculatedPayment[type] = payment;
      }
    } catch (error) {
    } finally {
    }
  };

  @action confirmConsent = async (type: types.PaymentTypeEnum) => {
    const {
      userStore: { userInfo, loadGeneralUserInfo },
      loanStore: { nextPaymentDate },
    } = stores;
    const { bankDetails } = bankDetailsStore;
    const { getPayments } = transfersInfoStore;
    if (userInfo && userInfo.bankIntegrationId && nextPaymentDate) {
      try {
        this.consentModal.loading = true;
        let consentDetails: types.ICreateConsent = {
          type: this.consentModal.type,
          status: ConsentStatusEnum.APPROVED,
          version: this.consentModal.version,
          bankIntegrationId: userInfo.bankIntegrationId,
          paymentDueDate: new Date(nextPaymentDate),
          bankAccountDetailId: Number(bankDetails?.bankAccountDetailId),
        };

        if (this.isFirstConsentExist) {
          await apiService.updateDebitConsentAuth(consentDetails);
        } else {
          await apiService.firstSetupConsent({ paymentType: type, ...consentDetails });
          EVENTS.main.firstTimeConsent.onConsent(consentDetails.type);
        }
        await loadGeneralUserInfo(true);
        await getPayments();
        if (this.consentModal.rtpCreditEnabled && type) {
          this.closeConsentModal();
          EVENTS.main.expressPayment.leavePage(
            type === types.PaymentTypeEnum.express_disbursement
              ? "Express Transfer"
              : "Standard Transfer",
            { "Transfer Amount": `${currencyFormatter(this.calculatedPayment[type]?.netAmount)}` },
          );
        } else {
          this.setConsentModal({ showSuccess: true, fullScreen: false });
        }
      } catch (error) {
        if (!this.isFirstConsentExist) {
          this.firstConsentError = true;
          this.closeConsentModal();
        }
        errorLogger.captureError(error);
      } finally {
        this.consentModal.loading = false;
        EVENTS.main.firstTimeConsent.leavePage("Confirm");
      }
    }
  };

  @action getConsentInfoByType = async (
    bankIntegrationId: number | null | undefined,
    type: types.DebitAchAuthTypeEnum,
  ) => {
    if (bankIntegrationId) {
      try {
        const consentInfoByType = await apiService.getDebitConsentAuth(bankIntegrationId, type);
        switch (type) {
          case types.DebitAchAuthTypeEnum.AUTO_PAY:
            this.consentInfo = { ...this.consentInfo, recurring: consentInfoByType };
            break;
          case types.DebitAchAuthTypeEnum.ONE_TIME:
            this.consentInfo = { ...this.consentInfo, oneTime: consentInfoByType };
            break;
          case types.DebitAchAuthTypeEnum.OFF_CYCLE:
            this.consentInfo = { ...this.consentInfo, offCycle: consentInfoByType };
            break;
          default:
            break;
        }
      } catch (error) {}
    }
  };

  @computed public get isActiveOneTimePay() {
    if (this.consentInfo?.oneTime?.status === types.ConsentStatusEnum.APPROVED) {
      return moment().isBefore(this.consentInfo.oneTime.paymentDueDate);
    }
    return false;
  }

  @computed public get shouldShowPendingOverdue() {
    if (this.consentInfo?.offCycle?.paymentDueDate) {
      const offCycleConstInfo = this.consentInfo.offCycle;
      const dueDatePlusThree = moment(offCycleConstInfo.paymentDueDate).add(3, "days");
      const now = moment();
      return (
        offCycleConstInfo.status === ConsentStatusEnum.APPROVED && now.isBefore(dueDatePlusThree)
      );
    } // assuming it might take up to 3 days to create debit transaction
    return false;
  }

  @computed public get isOffCycleConsentValid() {
    if (this.consentInfo?.offCycle?.paymentDueDate) {
      const offCycleConstInfo = this.consentInfo.offCycle;
      const offCycleConsentExpiry = moment(offCycleConstInfo.paymentDueDate).add(10, "days");
      const now = moment();
      return (
        offCycleConstInfo.status === ConsentStatusEnum.APPROVED &&
        now.isBefore(offCycleConsentExpiry)
      ); // assuming max of 10 days to have successful debit transaction in retry logic
    }
    return false;
  }

  @computed get isPrimaryConsentExist() {
    return (
      this.consentInfo?.recurring?.status === types.ConsentStatusEnum.APPROVED ||
      this.isActiveOneTimePay
    );
  }

  @computed get isFirstConsentExist() {
    return (
      (stores.loanStore &&
        stores.loanStore.loanInfo &&
        stores.loanStore.loanInfo.source !== types.LoanSourceEnum.LEAD) ||
      this.consentInfo?.oneTime?.status === types.ConsentStatusEnum.APPROVED ||
      this.consentInfo?.recurring?.status === types.ConsentStatusEnum.APPROVED
    );
  }

  @computed get disbursementAttemptFailed() {
    return (
      stores.loanStore.loanInfo?.source === types.LoanSourceEnum.LEAD &&
      (this.consentInfo?.oneTime?.status === types.ConsentStatusEnum.APPROVED ||
        this.consentInfo?.recurring?.status === types.ConsentStatusEnum.APPROVED)
    );
  }

  @computed get calculatedPayment() {
    return this._calculatedPayment;
  }

  @action clearStore = () => {
    this.confirmButtonEnabled = false;
    this.consentInfo = undefined;
    this.consentModal = consentModalInit;
    this._calculatedPayment = {};
  };
}

const debitConsentStore = new DebitConsentStore();
export default debitConsentStore;
