import styled from "styled-components";

export const Wrapper = styled.div<{ bottom: boolean; button: boolean }>`
  z-index: 9999;
  position: fixed;
  top: ${(props) => (props.bottom ? "none" : "0")};
  left: 0;
  right: 0;
  bottom: ${(props) => (props.bottom ? "25px" : props.button ? "-61px" : "0")};
  display: flex;
  align-content: center;
  justify-content: center;

  img {
    max-width: 250px;
  }
`;
