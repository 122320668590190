import { useTranslation } from "react-i18next";
import {
  UploadFileButtonContainer,
  UploadFileButtonIcon,
  UploadFileButtonText,
  UploadFileButtonSuccess,
  UploadFileButtonError,
} from "./styles";
import plusIcon from "./plusIcon.svg";
import { ChangeEvent, useState } from "react";
import { FILE_SIZE_LIMIT } from "pages/Btc/Constants";

const validateFile = (file: File): ErrorType | undefined => {
  if (file.name) {
    const fileName = file.name.toLowerCase();
    if (
      fileName.endsWith(".doc") ||
      fileName.endsWith(".docx") ||
      fileName.endsWith(".jpg") ||
      fileName.endsWith(".jpeg") ||
      fileName.endsWith(".png") ||
      fileName.endsWith(".pdf")
    ) {
    } else {
      return "unsupportedFileError";
    }
  }
  if (file.size) {
    if (file.size >= FILE_SIZE_LIMIT) {
      return "fileSizeError";
    }
  }
};

type ErrorType = "unsupportedFileError" | "fileSizeError";
const translationPrefix = "application_status";
type Props = { selectedFile: File | undefined; setSelectedFile: (file: File | undefined) => void };
export const UploadFileButton = ({ selectedFile, setSelectedFile }: Props) => {
  const [error, setError] = useState<ErrorType>();

  const { t } = useTranslation();

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const error = validateFile(file);
      if (error) {
        setError(error);
        setSelectedFile(undefined);
      } else {
        setError(undefined);
        setSelectedFile(file);
      }
    }
  };
  return (
    <div>
      <label htmlFor="upload-file-btn">
        <UploadFileButtonContainer _error={!!error}>
          <UploadFileButtonIcon src={plusIcon} />
          <UploadFileButtonText>
            {t(`${translationPrefix}.nmiUploads.uploadFile`)}
          </UploadFileButtonText>

          <input
            id="upload-file-btn"
            hidden
            type="file"
            accept="image/JPG,image/JPEG,image/PNG, application/pdf, .doc, .docx"
            onChange={onChange}
          />
        </UploadFileButtonContainer>
      </label>

      {selectedFile ? (
        <UploadFileButtonSuccess>
          {t(`${translationPrefix}.nmiUploads.uploadedSuccessfully`, {
            fileName: selectedFile.name,
          })}
        </UploadFileButtonSuccess>
      ) : null}
      {error ? (
        <UploadFileButtonError>
          {t(`${translationPrefix}.nmiUploads.${error}`)}
        </UploadFileButtonError>
      ) : null}
    </div>
  );
};
