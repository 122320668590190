import styled from "styled-components";
import { Box } from "@mui/material";
import { device } from "config/devices";

export const StyledCSContainer = styled(Box)`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  background: #fff;
`;
export const StyledCSBody = styled(Box)`
  display: flex;
  padding: 0px 20px 56px;
  background: #fff;
  flex-direction: column;
  max-width: 528px;
  align-self: center;
  height: inherit;
`;

export const StyledButtonsWrapper = styled(Box)`
  margin-top: 32px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media ${device.tablet} {
    justify-content: space-between;
    align-items: center;
    flex-direction: row-reverse;
    button {
      width: auto;
    }
  }
`;

export const StyledSubmitButton = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 32px;
  width: 100%;
  height: 56px;
  @media ${device.laptop} {
    height: 42px;
  }

  color: #fff;
  background-color: #ff5277;
  border-radius: 8px;

  font-size: 15px;

  flex: none;
  order: 0;
  flex-grow: 0;
  z-index: 0;
  :hover {
    background-color: #000000;
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  align-self: center;
  height: inherit;
  @media ${device.laptop} {
    width: 528px;
  }
  @media ${device.mobileS} {
    max-width: 528px;
  }
`;
export const Loader = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;
