import React, { forwardRef, useCallback } from "react";
import {
  useSnackbar,
  CustomContentProps,
  SnackbarOrigin,
  ProviderContext,
  VariantType,
  SnackbarMessage,
} from "notistack";
import CloseIcon from "@mui/icons-material/Close";
import {
  IconsContainer,
  StyledCard,
  StyledCardActions,
  StyledIconButton,
  StyledSnackbarContent,
  StyledTypography,
} from "./styles";

interface ReportCompleteProps extends CustomContentProps {
  allowDownload?: boolean;
}

export const anchorOrigin: SnackbarOrigin =
  window.innerWidth < 768 // 768 = size.tablet
    ? { vertical: "bottom", horizontal: "left" }
    : { vertical: "top", horizontal: "center" };

const ReportComplete = forwardRef<HTMLDivElement, ReportCompleteProps>(({ id, ...props }, ref) => {
  const { closeSnackbar } = useSnackbar();

  const handleDismiss = useCallback(() => {
    closeSnackbar(id);
  }, [id, closeSnackbar]);

  return (
    <StyledSnackbarContent ref={ref}>
      <StyledCard notificationVariant={props.variant}>
        <StyledCardActions>
          <StyledTypography variant="body2">{props.message}</StyledTypography>
          <IconsContainer>
            <StyledIconButton size="small" onClick={handleDismiss}>
              <CloseIcon fontSize="small" />
            </StyledIconButton>
          </IconsContainer>
        </StyledCardActions>
      </StyledCard>
    </StyledSnackbarContent>
  );
});

ReportComplete.displayName = "ReportComplete";

export default ReportComplete;

let useSnackbarRef: ProviderContext;

export const SnackbarUtilsConfigurator: React.FC = () => {
  useSnackbarRef = useSnackbar();
  return null;
};

type SnackbarUtilsOptions = { anchorOrigin?: SnackbarOrigin };
class SnackbarUtils {
  success(msg: SnackbarMessage, options?: SnackbarUtilsOptions) {
    this.toast(msg, "success", options);
  }
  warning(msg: SnackbarMessage, options?: SnackbarUtilsOptions) {
    this.toast(msg, "warning", options);
  }
  info(msg: SnackbarMessage, options?: SnackbarUtilsOptions) {
    this.toast(msg, "info", options);
  }
  error(msg: SnackbarMessage, options?: SnackbarUtilsOptions) {
    this.toast(msg, "error", options);
  }
  toast(msg: SnackbarMessage, variant: VariantType, options?: SnackbarUtilsOptions) {
    useSnackbarRef.enqueueSnackbar(msg, {
      variant,
      hideIconVariant: true,
      anchorOrigin: options?.anchorOrigin,
    });
  }
}

export const snackbarUtils = new SnackbarUtils();
