import { action, computed, observable } from "mobx";
import * as types from "./types";
import * as servicesApiTypes from "services/api/types";
import { apiService } from "services/api";

class WebAppStore implements types.IWebAppStore {
  @observable private _isSuperUserCashedOut = false;

  @observable private _isCopyCard = false;

  @observable private _cardPan?: servicesApiTypes.ICardPanInfo;

  @observable private _isFirstTimeCashOut = false;

  @observable private _transactions?: servicesApiTypes.ITransaction[];

  @observable private _isTransactionLoading = false;

  @observable private _isJustCashedOut = false;

  @computed get isSuperUserCashedOut() {
    return this._isSuperUserCashedOut;
  }

  @computed get isCopyCard() {
    return this._isCopyCard;
  }

  @computed get isJustCashedOut() {
    return this._isJustCashedOut;
  }

  @computed get transactions() {
    return this._transactions || [];
  }

  @computed get isTransactionLoading() {
    return this._isTransactionLoading;
  }

  @computed get isFirstTimeCashOut() {
    return this._isFirstTimeCashOut;
  }

  @computed
  public get cardPan(): servicesApiTypes.ICardPanInfo | undefined {
    return this._cardPan;
  }

  @action
  public setIsCopyCard = (copyCardAction: boolean) => {
    this._isCopyCard = copyCardAction;
  };

  @action
  private setCardPan(cardPan: servicesApiTypes.ICardPanInfo) {
    this._cardPan = cardPan;
  }

  @action
  public async setTransactions(transactions: servicesApiTypes.ITransaction[]) {
    this._transactions = transactions;
  }

  @action
  public setIsTransactionLoading(fetching: boolean) {
    this._isTransactionLoading = fetching;
  }

  @action
  public setJustCashedOut(value: boolean) {
    this._isJustCashedOut = value;
  }

  @action public clearStore = () => {
    this.setJustCashedOut(false);
    this.setTransactions([]);
    this.setIsTransactionLoading(false);
    this._cardPan = undefined;
  };

  private doLoadTransactions = async (
    transactions: servicesApiTypes.ITransaction[],
    nextToken?: string,
  ): Promise<servicesApiTypes.ITransaction[]> => {
    const { nextToken: nextPageToken, transactions: pageOfTransactions } =
      await apiService.getCardTransactions(nextToken);

    if (nextPageToken) {
      return await this.doLoadTransactions([...transactions, ...pageOfTransactions], nextPageToken);
    } else {
      return [...transactions, ...pageOfTransactions];
    }
  };

  public async loadCard(loadCard: servicesApiTypes.ICardLoad) {
    const card = await apiService.loadCard(loadCard);

    this.setCardPan(card);
    this.setJustCashedOut(true);
  }

  public async requestCardOtp() {
    await apiService.requestCardOtp();
  }

  public async revealCard(code: string) {
    const cardPan = await apiService.getCardPan(code);
    this.setCardPan(cardPan);
  }
}

const webAppStore: types.IWebAppStore = new WebAppStore();

export default webAppStore;
