import { useTranslation } from "react-i18next";
import {
  Title,
  UploadsContainer,
  UploadItem,
  UploadIcon,
  UploadText,
  AddIcon,
  UploadTextContainer,
  AddIconContainer,
} from "./styles";
import { IconButton } from "@mui/material";
import selfieIcon from "./selfieIcon.svg";
import ptoIcon from "./ptoIcon.svg";
import payStubIcon from "./payStubIcon.svg";
import addIcon from "./addIcon.svg";
import { UploadPayStubsModal } from "./Modals/UploadPayStubs";
import { useState } from "react";
import { UploadSelfieIdModal } from "./Modals/UploadSelfieId";
import { UploadPTOBalanceModal } from "./Modals/UploadPTOBalance";
import { observer } from "mobx-react";
import { useStores } from "stores";
import { FilesToUploadNMIType } from "stores/lead/types";

const translationPrefix = "application_status";

const uploadsButtons = {
  payStub: {
    icon: payStubIcon,
    translation: "uploadYourLastPayStub",
  },
  pto: {
    icon: ptoIcon,
    translation: "verifyYourPTOBalance",
  },
  idSelfie: {
    icon: selfieIcon,
    translation: "takeSelfie",
  },
};

export const NMIUploads = observer(() => {
  const { t } = useTranslation();
  const {
    leadStore: { filesToUploadNMI },
  } = useStores();
  const [activeModalName, setModalName] = useState<FilesToUploadNMIType>();
  const onClick = (key: FilesToUploadNMIType) => () => {
    setModalName(key);
  };

  const closebModal = () => {
    setModalName(undefined);
  };

  return (
    <div>
      <Title>{t(`${translationPrefix}.nmiUploads.pleaseCompleteTheFollowing`)}</Title>

      <UploadsContainer>
        {filesToUploadNMI.map((file, index) => (
          <UploadItem
            key={file.type}
            devider={index < filesToUploadNMI.length - 1}
            active={file.status === "active"}>
            <UploadIcon src={uploadsButtons[file.type].icon} />
            <UploadTextContainer>
              <UploadText active={file.status === "active"}>
                {t(`${translationPrefix}.nmiUploads.${uploadsButtons[file.type].translation}`)}
              </UploadText>
            </UploadTextContainer>
            <AddIconContainer active={file.status === "active"}>
              <IconButton disabled={file.status !== "active"} onClick={onClick(file.type)}>
                <AddIcon src={addIcon} />
              </IconButton>
            </AddIconContainer>
          </UploadItem>
        ))}
      </UploadsContainer>

      <UploadPayStubsModal open={activeModalName === "payStub"} onClose={closebModal} />
      <UploadSelfieIdModal open={activeModalName === "idSelfie"} onClose={closebModal} />
      <UploadPTOBalanceModal open={activeModalName === "pto"} onClose={closebModal} />
    </div>
  );
});
