import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import moment from "moment";

import { StyledApplyAdvanceHeader } from "../Step3/styles";
import ProgressBar from "../Components/ProgressBar";
import { StyledCSBody, StyledCSContainer } from "../Step5/styles";
import {
  StyledBodyText2,
  StyledBodyTitleText,
  StyledBTCSBackButton,
  StyledSorbetLogo,
  StyledCenteredBody,
} from "../styles";
import sorbetLogo from "../../../assets/logos/color-logo.svg";
import { BTCLoader } from "../Components/Loader";
import PlaidLink from "../../../components/PlaidLink";
import { useStores } from "../../../stores";
import { btcCheckUuid, btcErrorHandler } from "../../../utils/btcErrorHandler";
import BtcErrorBox from "../Components/ErrorBox";
import { observer } from "mobx-react";
import { gtmService } from "services/gtm";
import { EVENTS, useLeavePageAnalytics } from "services/analytics/events";
import { LOCAL_STORAGE_KEYS, LocalStorage } from "utils/localStorage";

const PayStubs = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    EVENTS.btc.step7.onload();
  }, []);

  useLeavePageAnalytics((eventType) => EVENTS.btc.step7.leavePage({ action: eventType }));

  const {
    btcStore: { errorBox, updateBtcApplication, btcLoading },
  } = useStores();

  const onSubmit = async () => {
    btcCheckUuid();
    const uuid = LocalStorage.getItem(LOCAL_STORAGE_KEYS.uuid);
    if (uuid !== null) {
      try {
        await updateBtcApplication({
          uuid: uuid,
          timestampStepSix: moment().toISOString(),
          lastSubmittedStep: 7,
        });
        LocalStorage.setItem(LOCAL_STORAGE_KEYS.lastSubmittedStep, "8");
        navigate(`/cash-advance/thank-you${location.search}`);
        gtmService.customEvent({
          event: "step7Submission",
          uuid: LocalStorage.getItem(LOCAL_STORAGE_KEYS.uuid),
        });

        gtmService.customEvent({
          event: "application-submission-b2c",
          uuid: LocalStorage.getItem(LOCAL_STORAGE_KEYS.uuid),
        });
      } catch (error) {
        btcErrorHandler(error, "step7");
      }
    }
  };

  const handleBackClick = () => {
    EVENTS.btc.step7.leavePage({ action: "Back" });
    navigate(`/cash-advance/security-social-number${location.search}`);
  };

  const setPlaidFinished = () => {
    gtmService.customEvent({
      event: "linkbankfinish",
      uuid: LocalStorage.getItem(LOCAL_STORAGE_KEYS.uuid),
    });
    onSubmit();
  };
  const pageTranslationPrefix = "page_PayStubs";

  return (
    <StyledCSContainer>
      <StyledApplyAdvanceHeader>
        <StyledSorbetLogo src={sorbetLogo} />
      </StyledApplyAdvanceHeader>
      <StyledCenteredBody>
        <ProgressBar progress={90} />
        <StyledCSBody>
          <StyledBodyTitleText>
            {t(`${pageTranslationPrefix}.verifyYourEligibility`)}
          </StyledBodyTitleText>

          {errorBox && <BtcErrorBox />}

          <StyledBodyText2 sx={{ marginBottom: 0 }}>
            {t(`${pageTranslationPrefix}.uploadPdfScreenshot`)}
          </StyledBodyText2>
          <PlaidLink error={false} setPlaidFinished={setPlaidFinished} />

          <StyledBTCSBackButton
            onClick={handleBackClick}
            startIcon={<ArrowBackIcon />}
            id="step-7-go-back">
            {t(`${pageTranslationPrefix}.back`)}
          </StyledBTCSBackButton>
        </StyledCSBody>
      </StyledCenteredBody>
      {btcLoading ? <BTCLoader /> : null}
    </StyledCSContainer>
  );
};

export default observer(PayStubs);
