import { ValueOf } from "./types";

export function omit<T = object>(obj: T, keys: (keyof T)[]): T {
  const result: { [key in keyof T]?: ValueOf<T> } = {};

  Object.entries(obj).forEach(([key, value]) => {
    if (keys.includes(key as keyof T)) {
      return;
    }
    result[key as keyof T] = value;
  });

  return result as T;
}

/**
 * Create an array range of numbers
 */
export function range(start: number, count: number): number[] {
  return Array(count)
    .fill(undefined)
    .map((_, idx) => start + idx);
}
