import qs, { ParseOptions, StringifyOptions } from "query-string";

export type QsOptions = StringifyOptions;

const PARSE_OPTIONS: ParseOptions = {
  arrayFormat: "comma",
  parseNumbers: true,
  parseBooleans: true,
};

const STRINGIFY_OPTIONS: StringifyOptions = {
  ...PARSE_OPTIONS,
  skipEmptyString: true,
  skipNull: true,
};

type QueryObject = { [key: string]: any };

class QueryStringService {
  stringify = (data: QueryObject, options?: StringifyOptions) =>
    qs.stringify(data, { ...STRINGIFY_OPTIONS, ...options });

  parse = <T extends QueryObject = QueryObject>(queryString: string, options?: ParseOptions) =>
    qs.parse(queryString, { ...PARSE_OPTIONS, ...options }) as T;
}

export const qsService = new QueryStringService();
