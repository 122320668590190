import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { useStores } from "stores";
import { IBankAccount } from "services/api/types";
import { PrimaryAccountConsent } from "pages/ManageLinkedAccounts/additionalAccountFlow/components/PrimaryAccountConsent";
import { useState } from "react";
import { NoConsentModal } from "pages/ManageLinkedAccounts/additionalAccountFlow/components/NoConsentModal";
import { NewBackupAccountModal } from "pages/ManageLinkedAccounts/additionalAccountFlow/components/NewBackupAccountModal";
import { StyledResolveButton } from "../../styles";
import { Issue, IssueIdEnum } from "stores/issues/types";
type Props = {
  fromStepper: boolean;
  issue: Issue;
};
export const ConsentActionStep = observer(({ issue, fromStepper }: Props) => {
  const [openPrimaryAccountConsent, setOpenPrimaryAccountConsent] = useState(false);
  const { t } = useTranslation();
  const {
    bankDetailsStore: { backupAccountsWithoutConsent, bankDetails, setNewBackupAccount },
  } = useStores();

  const primaryAccountConsent = () => {
    setOpenPrimaryAccountConsent(true);
  };

  const backupAccountConsent = (account: IBankAccount) => () => {
    setNewBackupAccount({ isBackupAccount: false, ...account });
  };

  if (issue.id === IssueIdEnum.primaryBankAccountUnAuthorized) {
    const closePrimaryAccountConsent = () => {
      setOpenPrimaryAccountConsent(false);
    };
    return (
      <>
        <StyledResolveButton fromStepper={fromStepper} onClick={primaryAccountConsent}>
          {t(issue.cta)}
        </StyledResolveButton>
        <PrimaryAccountConsent
          openPrimaryAccountConsent={openPrimaryAccountConsent}
          closePrimaryAccountConsent={closePrimaryAccountConsent}
          primaryAccount={bankDetails}
        />
      </>
    );
  }
  if (issue.id === IssueIdEnum.backupBankAccountUnAuthorized) {
    return (
      <>
        <StyledResolveButton
          fromStepper={fromStepper}
          onClick={backupAccountConsent(backupAccountsWithoutConsent[0])}>
          {t(issue.cta)}
        </StyledResolveButton>
        <NewBackupAccountModal />
        <NoConsentModal />
      </>
    );
  }

  return null;
});
