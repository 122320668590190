import { useState } from "react";
import {
  StyledBTCUploadFileButtonWrapper,
  StyledBTCUploadFileError,
  StyledSelectorLabel,
} from "pages/Btc/styles";
import { gtmService } from "services/gtm";
import { FILE_SIZE_LIMIT } from "../../pages/Btc/Constants";
import { apiService } from "services/api"; // Assuming apiService is exported from services/api
import { ConnectButton } from "pages/PtoCenter/styles";
import { LongEventsLoader } from "../LongEventsLoader";
import { StyledLabel } from "./styles";
import { useStores } from "../../stores";
import { LOCAL_STORAGE_KEYS, LocalStorage } from "utils/localStorage";

type UploadFileButtonProps = {
  error?: boolean;
  errorMessage?: any;
  successMessage: string;
  title?: string;
  required?: boolean;
  buttonText: string;
  name: string;
  id?: string;
  onSuccess?: () => void;
  onClick?: () => void;
  uuid: string; // Adding uuid to props to be used in API call
};

const validateType = (file: File) => {
  const fileName = file.name.toLowerCase();
  return (
    fileName.endsWith(".jpg") ||
    fileName.endsWith(".jpeg") ||
    fileName.endsWith(".png") ||
    fileName.endsWith(".pdf")
  );
};

const validateSize = (file: File) => file.size < FILE_SIZE_LIMIT;

const UploadFileButton = ({
  title,
  required = false,
  buttonText,
  name,
  id,
  onSuccess,
  onClick,
  uuid,
}: UploadFileButtonProps) => {
  const [file, setFile] = useState<File | undefined>();
  const [uploadError, setUploadError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  // const navigate = useNavigate();

  const {
    userStore: { getUserFiles },
  } = useStores();

  const loadingCallback = () => {
    if (file) {
    }
  };

  const loaderText = [["PTOCenter.paystubs_page.loaderText"]];

  const handleFileUpload = async (file: File) => {
    const formData = new FormData();
    formData.append(name, file);
    setLoading(true);
    try {
      // Simulate a 3-second delay
      await new Promise((resolve) => setTimeout(resolve, 4000));

      await apiService.updateBtcApplicationFiles({ uuid, [name]: file });
      getUserFiles();
      if (typeof onSuccess === "function") {
        onSuccess();
      }
    } catch (error) {
      setUploadError("Failed to upload file. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0];
    if (selectedFile) {
      if (!validateType(selectedFile)) {
        setUploadError("Invalid file type.");
        return;
      }
      if (!validateSize(selectedFile)) {
        setUploadError("File size exceeds limit.");
        return;
      }

      setFile(selectedFile);
      setUploadError(null);
      handleFileUpload(selectedFile);

      if (name === "payStubOne") {
        gtmService.customEvent({
          event: "paystubs1finish",
          uuid: LocalStorage.getItem(LOCAL_STORAGE_KEYS.uuid),
        });
      }
    }
  };

  return (
    <StyledBTCUploadFileButtonWrapper
      visibility={"visible"}
      onMouseDown={() => {
        if (typeof onClick === "function") {
          onClick();
        }
      }}>
      {title ? <StyledSelectorLabel required={required}>{title}</StyledSelectorLabel> : null}
      <StyledLabel htmlFor={name}>
        {uploadError ? (
          <StyledBTCUploadFileError>
            Please upload files in JPG, PNG, or PDF format
          </StyledBTCUploadFileError>
        ) : null}
        <ConnectButton selected id={id}>
          {buttonText}
        </ConnectButton>
      </StyledLabel>
      <input
        id={name}
        hidden
        type="file"
        accept="image/JPG,image/JPEG,image/PNG, application/pdf"
        onChange={handleFileChange}
      />
      <LongEventsLoader
        loading={loading}
        callback={loadingCallback}
        duration={6}
        interrupt={false}
        steps={loaderText}
      />
    </StyledBTCUploadFileButtonWrapper>
  );
};

export default UploadFileButton;
