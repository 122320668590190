import { useTranslation } from "react-i18next";
import calendarIcon from "../../../../assets/icons/calendar.svg";
import { StyledLargeText, StyledLoanDataContainer, StyledText } from "../style";
import { IBankAccount } from "services/api/types";

const translation = "achDebitAuth";

type Props = {
  formattedNextPaymentDate: string;
  nextAmountDue: string;
  bankDetails: IBankAccount | undefined;
};
export const AutoPayApproved = ({
  formattedNextPaymentDate,
  nextAmountDue,
  bankDetails,
}: Props) => {
  const { t } = useTranslation();
  return (
    <StyledLoanDataContainer>
      <img
        src={calendarIcon}
        style={{ width: "24px", height: "24px", marginBottom: "4px" }}
        alt={"calendar-icon"}
      />
      <StyledLargeText>
        {t(`${translation}.youHavePaymentDue`, { nextPaymentDate: formattedNextPaymentDate })}
      </StyledLargeText>
      <StyledText>
        {t(`${translation}.autoPayEnabled`, { nextPaymentAmount: nextAmountDue })}
      </StyledText>
      <StyledText>
        {t(`${translation}.linkedEndsWith`, {
          dueAmount: nextAmountDue,
          last4accountNumber: bankDetails?.bankAccountLast4,
        })}
      </StyledText>
    </StyledLoanDataContainer>
  );
};
