import styled from "styled-components";
import { device } from "../../../config/devices";
import { Breakpoints } from "../../../assets/styles/constants";
import { Modal} from "@mui/material";
import {ButtonBase} from "../../../components/Buttons";

export const StyledLoanContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  @media ${device.laptop} {
    flex-direction: row;
    margin-top: 24px;
  }
  border-radius: 8px;
  border: solid 1px #e4e2ed;
  background: #fff;
  padding: 20px;
  margin-bottom: 16px;
  @media ${device.laptop} {
    padding: 24px;
  }
`;
export const StyledLoanDataContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-bottom: 16px;
  @media ${device.laptop} {
    padding-bottom: 0px;
  }
`;
export const StyledLoanActionsContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media ${device.laptop} {
    align-items: flex-end;
  }
`;
export const StyledDescription = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #242323;
  margin-bottom: 16px;
`;
export const StyledConsentModalContent = styled.div`
  padding: 16px;
  @media ${device.laptop} {
    padding: 32px;
  }
  @media (max-width: ${Breakpoints.MD}px) {
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    width: 100%;
  }
  @media (min-width: ${Breakpoints.MD}px) {
    width: 600px;
    border-radius: 8px;
  }
  position: relative;
  background-color: #fff;
`;
export const StyledModalWrapper = styled.div`
  border-radius: 8px;
  position: relative;
  background-color: #fff;
  display: flex;
  flex: 1;
  max-width: 600px;
  flex-direction: column;
`;
export const StyledBoldButton = styled.button`
  align-items: center;
  padding: 0px 32px;
  width: 100%;
  height: 56px;
  @media ${device.laptop} {
    height: 40px;
    width: 258px;
  }
  color: #fff;
  background-color: #ff5277;
  border-radius: 8px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  transition: background-color 500ms;
  :hover {
    background-color: #000000;
  }
  :disabled {
    color: #fff;
    background: var(--selection-colors-border-disabled, #cfccdd);
    pointer-events: none;
  }
`;
export const StyledSecondaryButton = styled.button`
  align-items: center;
  width: 100%;
  height: 56px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  transition: color 500ms;
  color: #ff5277;
  :hover {
    color: #000000;
  }
  :disabled {
    color: #d4d1e0;
  }
  @media ${device.laptop} {
    height: 24px;
    width: 258px;
    margin-top: 8px;
  }
`;
export const StyledPrintButton = styled.button`
  margin-top: 24px;
  margin-bottom: 24px;
  width: fit-content;
  height: 56px;
  border-color: #ff5277;
  border-width: 1px;
  border-style: solid;
  color: #ff5277;
  border-radius: 8px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  padding-left: 16px;
  padding-right: 16px;
  justify-content: space-between;
  :hover {
    color: #000000;
    border-color: #000000;
  }
  @media ${device.laptop} {
    height: 40px;
    width: fit-content;
  }
`;
export const StyledLargeText = styled.p`
  font-weight: 500;
  font-family: "Poppins";
  font-size: 20px;
  color: #242323;
  line-height: 32px;
  @media ${device.tablet} {
    font-size: 18px;
    line-height: 24px;
  }
`;
export const StyledText = styled.p`
  font-weight: 400;
  font-family: "Poppins";
  font-size: 16px;
  color: #585866;
  line-height: 24px;
  @media ${device.tablet} {
    font-size: 14px;
  }
`;
export const StyledSuccessTitle = styled.div`
  font-weight: 400;
  font-family: "Poppins";
  font-size: 24px;
  color: #242323;
  font-style: normal;
`;
export const StyledSuccessSubtitle = styled.div`
  margin-top: 16px;
  font-weight: 400;
  font-family: "Poppins";
  font-size: 14px;
  color: #585866;
  font-style: normal;
`;
export const StyledSubUnit = styled.div`
  padding: 0 16px 16px 16px;
  @media ${device.tablet} {
    padding: 24px 40px 16px 40px;
  }
`;
export const StyledSuccessText = styled.div`
  font-weight: 400;
  font-family: "Poppins";
  font-size: 14px;
  color: #242323;
  font-style: normal;
`;
export const PriorConsentImg = styled.img`
  width: 109px;
  height: 94px;
`;
export const StyledConsentErrorImg = styled.img`
  width: 94px;
  height: 94px;
`;
export const StyledConsentErrorContainer = styled.div`
  flex-direction: column;
  display: flex;
`;
export const SuccessModalIcon = styled.img`
  width: 32px;
  height: 32px;
  @media ${device.tablet} {
    width: 112px;
    height: 112px;
  }
`;
export const StyledButtonsContainer = styled.div`
  padding: 16px;
  @media ${device.tablet} {
    padding: 24px 40px;
    display: flex;
    flex-flow: row-reverse;
    box-shadow: 0px 1px 0px 0px #f0eef5 inset;
  }
`;
export const StyledModal = styled(Modal)`
  align-items: flex-end;
  display: flex;
  justify-content: center;
  @media ${device.tablet} {
    align-items: center;
  }
`;
export const StyledCloseButton = styled.div`
  cursor: pointer;
  padding: 24px;
  @media (min-width: ${Breakpoints.MD}px) {
    padding: 0px;
    position: absolute;
    top: 22px;
    right: 22px;
  }
`;
export const StyledCancelButton = styled.div`
  border-radius: 8px;
  cursor: pointer;

  @media (max-width: ${Breakpoints.MD}px) {
    border: 1px #ff5277 solid;
    padding: 16px;
    margin-top: 24px;
  }
  @media (min-width: ${Breakpoints.MD}px) {
    padding: 8px 32px;
    margin-right: 16px;
  }

  p {
    text-align: center;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    color: #ff5277;
  }
`;
export const StyledPayActionButton = styled(ButtonBase)<{ disabled: boolean }>`
  border-radius: 8px;
  background: ${(props) => (props.disabled ?  "#CFCCDD" : "#ff5277")};
  width: 100%;
  height: 56px;
  
  @media (max-width: ${Breakpoints.MD}px) {
    padding: 16px;
    margin-top: 16px;
  }
  @media (min-width: ${Breakpoints.MD}px) {
    padding: 8px 32px;
    margin-right: 16px;
    width: fit-content;
    height: 40px;
  }

  p {
    text-align: center;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    color: #fff;
  }
`;
export const StyledTitle = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #242323;
  margin-top: 30px;

  @media (min-width: ${Breakpoints.MD}px) {
    margin-top: 0px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 40px;
  }
`;
