import {
  StyledContainer,
  StyledTitle,
  StyledDescription,
  StyledHeader,
  StyledImage,
  StyledDivider,
  StyledAmountLine,
  StyledBoldDescription,
  StyledLine,
  StyledSmallText,
  StyledProgressBar,
  StyledIcon,
  StyledToTheRight,
  StyledTopContainer,
  StyledBottomContainer,
} from "./styles";
import { useTranslation } from "react-i18next";
import TransMsg from "./TransMsg.svg";
import { useStores } from "../../../../stores";
import { observer } from "mobx-react";
import { DisbursementError } from "../DisbursementError/DisbursementError";
import { formattedDate } from "../../../../utils/date";
import { useEffect, useState } from "react";
import { currencyFormatter } from "../../../../utils/formatters";
import { PaymentTypeEnum } from "services/api/types";
import ProgressBarLean from "../../../Btc/Components/ProgressBarLean";
import Info from "../../../../assets/icons/Info.svg";
import { Backdrop, Fade, Paper, Popper, PopperPlacementType } from "@mui/material";
import moment from "moment";

export const CreditTransferMessage = observer(() => {
  const [anchorEl, setAnchorEl] = useState<EventTarget & HTMLImageElement>();
  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState<PopperPlacementType>();
  const { t } = useTranslation();

  const handleClick =
    (newPlacement: PopperPlacementType) => (event: React.MouseEvent<HTMLImageElement>) => {
      setAnchorEl(event.currentTarget);
      setOpen((prev) => placement !== newPlacement || !prev);
      setPlacement(newPlacement);
    };

  const {
    loanStore: { loanInfo, totalPaid, remainingToPay, totalPaybackAmount },
    bankDetailsStore: { bankDetails },
    transfersInfoStore: { latestCreditTransfer, payments, getPayments },
    leadStore: { isLeadBankSponsor },
  } = useStores();

  useEffect(() => {
    getPayments();
  }, []);

  const paymentType =
      isLeadBankSponsor?
      loanInfo?.disbursement?.paymentType :
      (payments && payments[0]?.paymentType) || PaymentTypeEnum.disbursement;

  if (isLeadBankSponsor && loanInfo && loanInfo.disbursement && loanInfo.loanStatus === "originated") {
      return (
          <StyledContainer>
              <StyledImage src={TransMsg} alt="sweet" />
              <StyledHeader>
                  <StyledTitle>
                      {t("yourCard.yourFundsAreOnTheWay", {
                          amount: currencyFormatter(loanInfo.disbursement.grossAmount),
                      })}
                  </StyledTitle>
              </StyledHeader>
              <StyledDescription>
                  {paymentType !== PaymentTypeEnum.express_disbursement &&
                      t("yourCard.transferredOn", {
                          createdAt: formattedDate(loanInfo.createdAt),
                          accountEnding: bankDetails?.bankAccountLast4,
                      })}
              </StyledDescription>
              <StyledDescription>
                  {paymentType === PaymentTypeEnum.express_disbursement
                      ? `${t("yourCard.youShouldSeeRTP", {
                          createdAt: formattedDate(loanInfo.createdAt),
                          accountNumber: bankDetails?.bankAccountLast4,
                      })}`
                      : t("yourCard.youShouldSee")}
              </StyledDescription>
              {paymentType === PaymentTypeEnum.express_disbursement ? (
                  <StyledDescription>
                      {t(`yourCard.${isLeadBankSponsor ? "itTakesLeadBank" : "itTakesSorbet"}`)}
                  </StyledDescription>
              ) : null}
          </StyledContainer>
      );
  }

  switch (latestCreditTransfer?.status) {
    case "settled":
      const totalPaidInPercent = (totalPaid / totalPaybackAmount) * 100;
      return (
        <StyledContainer>
          <StyledTitle>{t("settled.yourPtoAdvance")}</StyledTitle>

          <StyledDescription style={{ marginBottom: "24px" }}>
            {t("settled.loanId", { id: loanInfo?.externalId })}
          </StyledDescription>

          <StyledLine>
            <StyledBoldDescription style={{ marginRight: "4px" }}>
              {currencyFormatter(totalPaid)}
            </StyledBoldDescription>

            <StyledDescription>{t("settled.paid")}</StyledDescription>
          </StyledLine>

          <StyledTopContainer>
            <StyledProgressBar>
              <ProgressBarLean
                progress={totalPaidInPercent > 2 ? totalPaidInPercent : 2}
                showPercent={false}
                width={"100%"}
              />
            </StyledProgressBar>

            <StyledSmallText style={{ justifyItems: "center", marginBottom: "14px" }}>
              {t("settled.amountRemaining", { amount: currencyFormatter(remainingToPay) })}
            </StyledSmallText>
          </StyledTopContainer>

          <StyledDivider />

          <StyledBottomContainer>
            <StyledAmountLine>
              <StyledDescription>{t("settled.financed")} </StyledDescription>
              <StyledBoldDescription>
                {currencyFormatter(
                  paymentType === PaymentTypeEnum.express_disbursement
                    ? payments[0]?.grossAmount
                    : latestCreditTransfer.amount,
                )}
              </StyledBoldDescription>
            </StyledAmountLine>

            {paymentType === PaymentTypeEnum.express_disbursement && (
              <StyledAmountLine>
                <StyledDescription>{t("settled.transferred")}</StyledDescription>
                <StyledToTheRight>
                  <StyledBoldDescription>
                    {currencyFormatter(
                        isLeadBankSponsor?
                        loanInfo?.disbursement?.netAmount :
                        payments[0]?.netAmount)}
                  </StyledBoldDescription>
                  <StyledIcon
                    style={{ marginLeft: "2px" }}
                    src={Info}
                    onClick={handleClick("bottom-end")}
                    alt={"info"}
                  />
                  <Backdrop
                    invisible
                    open={open}
                    onClick={() => {
                      setOpen(false);
                    }}>
                    <Popper open={open} anchorEl={anchorEl} placement={placement} transition>
                      {({ TransitionProps }) => (
                        <Fade {...TransitionProps} timeout={350}>
                          <Paper
                            sx={{
                              width: "260px",
                              padding: "8px",
                              marginTop: "8px",
                              boxShadow: "1px 1px 8px #e6dfdf",
                              backgroundColor: "#242323",
                              borderRadius: "8px",
                            }}>
                            <StyledDescription style={{ padding: "8px", color: "#fff" }}>
                              {t(
                                `settled.${
                                  isLeadBankSponsor
                                    ? "youHaveChosen_LeadBank"
                                    : "youHaveChosen_Sorber"
                                }`,
                                {
                                  fee: isLeadBankSponsor?
                                      loanInfo?.disbursement?.feeRatePercentage :
                                      payments[0]?.feeRatePercentage,
                                },
                              )}
                            </StyledDescription>
                          </Paper>
                        </Fade>
                      )}
                    </Popper>
                  </Backdrop>
                </StyledToTheRight>
              </StyledAmountLine>
            )}
          </StyledBottomContainer>

          <StyledSmallText style={{ marginTop: "14px" }}>
            {t("settled.accountEndingIn", {
              last4: bankDetails?.bankAccountLast4,
              date: formattedDate(latestCreditTransfer.createdAt),
            })}
          </StyledSmallText>
        </StyledContainer>
      );
    case "posted":
    case "pending":
      return (
        <StyledContainer>
          <StyledImage src={TransMsg} alt="sweet" />
          <StyledHeader>
            <StyledTitle>
              {t("yourCard.yourFundsAreOnTheWay", {
                amount: currencyFormatter(
                  paymentType === PaymentTypeEnum.express_disbursement
                    ? payments[0]?.grossAmount
                    : latestCreditTransfer.amount,
                ),
              })}
            </StyledTitle>
          </StyledHeader>
          <StyledDescription>
            {paymentType !== PaymentTypeEnum.express_disbursement &&
              t("yourCard.transferredOn", {
                createdAt: formattedDate(latestCreditTransfer.createdAt),
                accountEnding: bankDetails?.bankAccountLast4,
              })}
          </StyledDescription>
          <StyledDescription>
            {paymentType === PaymentTypeEnum.express_disbursement
              ? `${t("yourCard.youShouldSeeRTP", {
                  createdAt: moment(payments[0]?.createdAt).format("MMMM Do, YYYY"),
                  accountNumber: bankDetails?.bankAccountLast4,
                })}`
              : t("yourCard.youShouldSee")}
          </StyledDescription>
          {paymentType === PaymentTypeEnum.express_disbursement ? (
            <StyledDescription>
              {t(`yourCard.${isLeadBankSponsor ? "itTakesLeadBank" : "itTakesSorbet"}`)}
            </StyledDescription>
          ) : null}
        </StyledContainer>
      );
    case "creation_failed":
    case "returned":
    case "canceled":
      return <DisbursementError />;
    default:
      return null;
  }
});
