import { useTranslation } from "react-i18next";

import { StyledApplyAdvanceHeader } from "../Step3/styles";
import { ShortStyledBodyText2, StyledSorbetLogo } from "../styles";
import sorbetLogo from "../../../assets/logos/color-logo.svg";
import {
  StyledThankYouContainer,
  StyledThankYouDivider,
  StyledThankText,
  StyledThankYouQuestionsTextPurple,
  StyledThankYouTipText,
  StyledThankYouTipTextBold,
  StyledThankYouTipWrapper,
  StyledThankYouFeedbackWrapper,
  StyledThankYouFeedbackName,
  StyledThankYouFeedbackAvatarWrapper,
  StyledThankYouFeedbackAvatar,
  StyledThankYouQuestionsWrapper,
  StyledThankYouFeedbackQuote,
  StyledCSBodyContainer,
  StyledThankYouImageContainer,
  StyledThankYouImage,
  StyledThankYouWrapper,
  StyledThankYouBody,
  StyledThankYouTrackNote,
  StyledLoginButton,
  ThankYouTitle,
} from "./styles";
import feedbackAvatar from "../../../assets/images/avatar.png";
import thankYou from "../../../assets/images/woman-thanks.png";
import { COMPANY_EMAILS, FAQ_PTO_LINK } from "../../../config/constants";
import { useEffect } from "react";
import { EVENTS } from "services/analytics/events";

const ThankYou = () => {
  const { t } = useTranslation();
  const pageTranslationPrefix = "page_ThankYou";
  useEffect(() => {
    EVENTS.btc.step8.onload();
  }, []);
  return (
    <StyledThankYouWrapper>
      <StyledThankYouContainer>
        <StyledApplyAdvanceHeader>
          <StyledSorbetLogo src={sorbetLogo} />
        </StyledApplyAdvanceHeader>

        <StyledCSBodyContainer>
          <StyledThankYouBody>
            <ThankYouTitle>{t(`${pageTranslationPrefix}.thankYou`)}</ThankYouTitle>
            <ShortStyledBodyText2>{t(`${pageTranslationPrefix}.hugeStep`)}</ShortStyledBodyText2>

            {/* ######### Pto-tip ######### */}
            <StyledThankYouTipWrapper>
              <StyledThankYouTipText>
                {t(`${pageTranslationPrefix}.reviewingYourApplication`)}
                <StyledThankYouTipTextBold>
                  {t(`${pageTranslationPrefix}.5BusinessDays`)}
                </StyledThankYouTipTextBold>
                <StyledThankYouTrackNote>
                  {t(`${pageTranslationPrefix}.pleaseKeepAnYye`)}
                </StyledThankYouTrackNote>
                <StyledLoginButton id={"thank-you-login-btn"} to={"/login"}>
                  {t(`${pageTranslationPrefix}.loginButton`)}
                </StyledLoginButton>
              </StyledThankYouTipText>
            </StyledThankYouTipWrapper>

            {/* ######### Questions ######### */}
            <StyledThankYouQuestionsWrapper>
              <StyledThankText>
                {t(`${pageTranslationPrefix}.haveAnyQuestions`)}
                <StyledThankYouQuestionsTextPurple>
                  <a href={FAQ_PTO_LINK} target="_blank" rel="noreferrer">
                    {t(`${pageTranslationPrefix}.faqs`)}
                  </a>
                </StyledThankYouQuestionsTextPurple>
                {t(`${pageTranslationPrefix}.getInTouch`)}
                <StyledThankYouQuestionsTextPurple>
                  <a
                    href={`mailto:${COMPANY_EMAILS.SUPPORT}?subject=Hey! I have a question about my Sorbet PTO Cash Advance application`}
                    rel="noreferrer"
                    target="_blank">
                    {COMPANY_EMAILS.SUPPORT}
                  </a>
                </StyledThankYouQuestionsTextPurple>
              </StyledThankText>
            </StyledThankYouQuestionsWrapper>

            <StyledThankYouDivider />

            {/* ######### Feedback ######### */}
            <StyledThankYouFeedbackWrapper>
              <StyledThankYouFeedbackAvatarWrapper>
                <StyledThankYouFeedbackQuote>
                  <svg
                    width="11"
                    height="8"
                    viewBox="0 0 11 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M11 6.07407C11 6.66667 10.7865 7.1358 10.3596 7.48148C9.93265 7.82716 9.39269 8 8.73973 8C7.93607 8 7.29566 7.76543 6.81849 7.2963C6.34132 6.82716 6.10274 6.1358 6.10274 5.22222C6.10274 4.38272 6.22831 3.65432 6.47945 3.03704C6.75571 2.41975 7.08219 1.91358 7.4589 1.51852C7.83562 1.09877 8.23744 0.765432 8.66438 0.518518C9.09132 0.271605 9.46804 0.0987656 9.79452 0L10.8116 1.48148C10.2089 1.75309 9.71918 2.11111 9.34247 2.55556C8.96575 3 8.80251 3.54321 8.85274 4.18519C9.50571 4.06173 10.0205 4.18519 10.3973 4.55556C10.7991 4.92593 11 5.4321 11 6.07407ZM4.89726 6.07407C4.89726 6.66667 4.68379 7.1358 4.25685 7.48148C3.82991 7.82716 3.28995 8 2.63699 8C1.83333 8 1.19292 7.76543 0.715753 7.2963C0.238584 6.82716 0 6.1358 0 5.22222C0 4.38272 0.125571 3.65432 0.376712 3.03704C0.652968 2.41975 0.979452 1.91358 1.35616 1.51852C1.73288 1.09877 2.1347 0.765432 2.56164 0.518518C2.98858 0.271605 3.3653 0.0987656 3.69178 0L4.7089 1.48148C4.10616 1.75309 3.61644 2.11111 3.23973 2.55556C2.86301 3 2.69977 3.54321 2.75 4.18519C3.40297 4.06173 3.91781 4.18519 4.29452 4.55556C4.69635 4.92593 4.89726 5.4321 4.89726 6.07407Z"
                      fill="white"
                    />
                  </svg>
                </StyledThankYouFeedbackQuote>

                <StyledThankYouFeedbackAvatar src={feedbackAvatar} />
              </StyledThankYouFeedbackAvatarWrapper>
              <StyledThankText>{t(`${pageTranslationPrefix}.feedback`)}</StyledThankText>
              <StyledThankYouFeedbackName>
                {t(`${pageTranslationPrefix}.feedbackName`)}
              </StyledThankYouFeedbackName>
            </StyledThankYouFeedbackWrapper>
          </StyledThankYouBody>
        </StyledCSBodyContainer>
      </StyledThankYouContainer>

      <StyledThankYouImageContainer>
        <StyledThankYouImage src={thankYou} />
      </StyledThankYouImageContainer>
    </StyledThankYouWrapper>
  );
};

export default ThankYou;
