import { useTranslation } from "react-i18next";
import {
    StyledLargeText,
    StyledText,
    StyledConsentErrorContainer,
} from "../../OnCyclePaymentInfo/style";
import { COMPANY_EMAILS } from "config/constants";
import {useStores} from "../../../../stores";
import {StyledButtonContainer, StyledButtonText, StyledGreyContainer} from "./styles";

const translation = "sorbetContractDeclined";

export const SorbetContractDeclined = () => {
    const { t } = useTranslation();
    const {
        userStore: { userInfo },
    } = useStores();

    const handleEmailButtonClick = () => {
        const receiver = COMPANY_EMAILS.SUPPORT;
        const subject = 'Following my decline to Sorbet’s PTO Cash Advance offer';
        const body = 'Hello Sorbet Support,' +
            '\nMy name is ' + userInfo?.firstName + ' ' + userInfo?.lastName +' and I declined the offer given to me by Sorbet.' +
            '\nThe reason for the decline is:' +
            '\n\nI’d like for Sorbet to contact me about an alternative offer.' +
            '\n\nThank you,' +
            '\n' + userInfo?.firstName + ' ' + userInfo?.lastName;

        const mailtoLink = `mailto:${receiver}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
        window.location.href = mailtoLink;
    };

    return (
        <StyledConsentErrorContainer>
            <StyledLargeText>{t(`${translation}.declineOffer`)}</StyledLargeText>
            <StyledGreyContainer>
                <StyledText>{t(`${translation}.whyYouDeclined`)}</StyledText>
                <StyledButtonContainer>
                    <StyledButtonText onClick={handleEmailButtonClick}>
                        {t(`${translation}.tellUsWhy`)}
                    </StyledButtonText>
                </StyledButtonContainer>
            </StyledGreyContainer>
        </StyledConsentErrorContainer>
    );
};
