import { Box, Button, Skeleton, styled as muiStyled } from "@mui/material";
import { Colors } from "assets/styles/constants";
import { device } from "config/devices";
import styled from "styled-components";

export const StyledBankSection = styled(Box)`
  display: flex;
  align-items: center;
  background: #f8f8fc;
  flex-direction: row;
  margin-bottom: 24px;
  height: 56px;
  padding: var(--Radius-large-radius, 16px);
  border-radius: var(--Radius-medium-radius, 8px);
`;

export const StyledBankContainer = styled(Box)`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: var(--Radius-large-radius, 16px);
  color: var(--text-icon-text-secondary, #585866);
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 153.846% */
`;

export const StyledRateAmountContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  margin-bottom: 16px;
`;

export const StyledContainer = styled(Box)`
  flex: 1;
  padding: 20px;
  @media ${device.tablet} {
    padding: 32px 40px 24px 40px;
  }
`;

export const StyledTitle = styled(Box)`
  margin-top: 16px;
  color: #242323;
  font-family: Poppins;
  font-style: normal;
  font-size: 20px;
  font-weight: 500;
  line-height: 32px; /* 160% */
  @media ${device.tablet} {
    font-size: 28px;
    font-weight: 600;
    line-height: 40px; /* 160% */
  }
`;

export const StyledPercentageRateText = styled(Box)`
  color: var(--text-icon-text-secondary, #585866);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 153.846% */
`;

export const StyledPercentageRateAmount = styled(Box)`
  color: var(--text-icon-text-primary, #242323);
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px; /* 160% */
  @media ${device.tablet} {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px; /* 160% */
  }
`;

export const StyledLoanAmount = styled(Box)`
  color: ${Colors.darkText};
  font-family: Poppins;
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-bottom: 135px;
  @media ${device.tablet} {
    font-weight: 600;
    padding-bottom: 0;
  }
`;

export const StyledSkeleton = styled(Skeleton)`
  margin-bottom: 135px;
  @media ${device.tablet} {
    margin-bottom: 0;
  }
`;

export const StyledBody = styled.div`
  display: flex;
  flex-direction: column;
`;
export const StyledRadioWrapper = styled.div`
  display: flex;
  margin-top: 24px;
  flex-direction: row;
  gap: 16px;
  @media ${device.tablet} {
    gap: 0;
    flex-direction: column;
  }
`;
export const StyledRadioContainer = muiStyled(Button)<{ selected: boolean }>`
  padding: 10px 16px;
  border-radius: var(--Radius-medium-radius, 8px);
  border: 1px solid ${(p) => (p.selected ? "#ff5277" : "#e4e2ed")};
  margin-bottom: 16px;
  justify-content: flex-start;
  flex: 1;
  align-items: flex-start;
  @media ${device.tablet} {
    align-items: center;
  }
`;

export const RadioButtonBody = styled.div``;
export const RadioButtonTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const RadioButtonTitle = styled.p<{ selected: boolean }>`
  text-transform: none;
  color: ${(p) => (p.selected ? "#242323" : "#585866")};
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 171.429% */
  text-align: left;
  display: flex;
  align-items: center;
  font-size: 16px;
  flex: 1;
  @media ${device.tablet} {
    font-size: 14px;
  }
`;
export const RadioButtonDescription = styled.p<{ selected: boolean }>`
  text-transform: none;
  color: ${(p) => (p.selected ? "#242323" : "#585866")};
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 153.846% */
  text-align: left;
`;
export const RadioButton = styled.div<{ mobileView: boolean }>`
  width: 16px;
  height: 16px;
  border-radius: 8px;
  border: 1px solid #9486a5;
  justify-content: center;
  align-items: center;

  div.selected-payment-type {
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background-color: #ff5277;
  }
  margin-right: 0px;
  display: ${(p) => (p.mobileView ? "flex" : "none")};
  @media ${device.tablet} {
    margin-right: 16px;
    display: ${(p) => (p.mobileView ? "none" : "flex")};
  }
`;

export const StyledButton = muiStyled(Button)`
  border-radius: var(--Radius-medium-radius, 8px);
  background: var(--Surface-surface-action, #ff5277);
  color: var(--text-icon-light, #FFF);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
  text-transform: none;
  .amount {
    font-weight: 700;
    margin-left: 4px;
  }
  height: 56px;
  width: 100%;
  @media ${device.tablet} {
    width: 200px;
    height: 40px;
    font-size: 14px;
  }
  :hover {
    background: var(--Surface-surface-action, #ff5277);
  }
  align-self: flex-end;
`;
