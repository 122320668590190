import React, { FC, ReactNode } from "react";
import { PAYMENT_SERVICE } from "services/api/types";

import { Container, Background, ModalContainer, ModalTop, ModalCloseButton, Body } from "./styles";



interface ModalProps {
  /**
   * Is modal opened
   */
  open: boolean;

  /**
   * Close the modal
   */
  close(): void;

  /**
   * Allow standart behaviour to close the modal,
   * else u don't have close button and can't close modal by background click
   */
  allowStandardClose?: boolean;

  /**
   * Styles for modal
   */
  className?: string;

  /**
   * Modal content
   */
  children?: ReactNode;

  width: number;

  height?: number;

  paymentService?: PAYMENT_SERVICE;

  isHasCard?: boolean

  "data-testid"?: string;
  
  hideModalTop?: boolean;
}

export const Modal: FC<ModalProps> = ({
  open = false,
  close,
  allowStandardClose = true,
  className,
  children,
  width = 0,
  ...props
}: ModalProps) => {
  return (
    <>
      {open && (
        <Container className={className}>
          <Background
            className="modal-background"
            onClick={allowStandardClose ? close : () => {}}
          />
          <ModalContainer
            className={"modalContainer"}
            width={width}
            height={props.height ? props.height : 0}>
            {!props.hideModalTop && (
              <ModalTop className="top" paymentService={props?.paymentService}>
                <ModalCloseButton onClick={close} />
              </ModalTop>
            )}
            <Body className={"body"} paymentService={props?.paymentService} isHasCard={props?.isHasCard}>
              {children}
            </Body>
          </ModalContainer>
        </Container>
      )}
    </>
  );
};
