import { DebitAchAuthTypeEnum } from "services/api/types";
import { StyledModalWrapper, StyledModal, CloseIcon } from "./styles";
import closeIcon from "./icons/closeIcon.svg";
import { observer } from "mobx-react";
import { useStores } from "stores";
import { Loader } from "components/Loader";
import { PaymentConsentSuccess } from "./Components/PaymentConsentSuccess";
import { PaymentConsent } from "./Components/PaymentConsent";
import ExpressPayment from "../../pages/Home/ExpressPayment";
import { currencyFormatter } from "utils/formatters";

export const PaymentConsentsModal = observer(() => {
  const {
    loanStore: { nextAmountDue, formattedNextPaymentDate, loanInfo, overdueTotalAmountCents },
    bankDetailsStore: { bankDetails },
    debitConsentStore: { consentModal, closeConsentModal },
  } = useStores();

  const overdueTotalAmount = currencyFormatter(
    overdueTotalAmountCents + (loanInfo?.feesAmountCents || 0),
  );

  const closeModal = () => {
    closeConsentModal();
  };

  const renderModalContent = () => {
    switch (consentModal.type) {
      case DebitAchAuthTypeEnum.AUTO_PAY:
      case DebitAchAuthTypeEnum.OFF_CYCLE:
      case DebitAchAuthTypeEnum.ONE_TIME: {
        if (consentModal.showSuccess) {
          return (
            <>
              <PaymentConsentSuccess
                formattedNextPaymentDate={formattedNextPaymentDate}
                nextAmountDue={nextAmountDue}
                overdueTotalAmount={overdueTotalAmount}
                handleClose={closeConsentModal}
                bankDetails={bankDetails}
                type={consentModal.type}
                file={consentModal.file}
                rtpEnabled={consentModal.rtpCreditEnabled}
              />
            </>
          );
        }
        if (consentModal.showExpressPayment) {
          return <ExpressPayment loading={consentModal.loading} />;
        }

        return (
          <PaymentConsent
            rtpEnabled={consentModal.rtpCreditEnabled}
            handleClose={closeModal}
            confirmButtonEnabled={consentModal.confirmButtonEnabled}
            type={consentModal.type}
            file={consentModal.file}
            fullScreen={consentModal.fullScreen}
          />
        );
      }
      default:
        return null;
    }
  };

  return (
    <StyledModal
      _fullScreen={consentModal.fullScreen}
      open={consentModal.open}
      onClose={closeModal}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description">
      <StyledModalWrapper _fullScreen={consentModal.fullScreen}>
        <CloseIcon onClick={closeModal}>
          <img src={closeIcon} alt={"close"} />
        </CloseIcon>
        {renderModalContent()}
        {consentModal.loading ? <Loader /> : null}
      </StyledModalWrapper>
    </StyledModal>
  );
});
