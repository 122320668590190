import { IconButton } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { StyledMenu, StyledMenuItem, StyledMenuItemUnlink, ActionItemIcon } from "./styles";
import threeDotsIcon from "../../../icons/threeDotsIcon.svg";
import trashIcon from "../../../icons/trashIcon.svg";
import authorizeBankIcon from "../../../icons/authorizeBankIcon.svg";

type Props = {
  isBackupAccount: boolean;
  unLinkAccount: () => void;
  setUpAsBackup: () => void;
};
export const BankAccountItemActions = ({
  isBackupAccount,
  unLinkAccount,
  setUpAsBackup,
}: Props) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onSetUpAsBackup = () => {
    setUpAsBackup();
    handleClose();
  };
  const onUnLinkAccount = () => {
    unLinkAccount();
    handleClose();
  };
  return (
    <div>
      <IconButton
        id="positioned-button"
        aria-controls={open ? "positioned-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}>
        <img src={threeDotsIcon} alt={"action"} />
      </IconButton>

      <StyledMenu
        id="positioned-menu"
        aria-labelledby="positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}>
        {!isBackupAccount ? (
          <StyledMenuItem onClick={onSetUpAsBackup}>
            <ActionItemIcon src={authorizeBankIcon} alt={"authorize bank"} />
            {t("manageLinkedAccounts.setupAsBackupMethod")}
          </StyledMenuItem>
        ) : null}

        {false ? (
          <StyledMenuItemUnlink onClick={onUnLinkAccount} disabled>
            <ActionItemIcon src={trashIcon} alt={"unlink"} />
            {t("manageLinkedAccounts.unlinkAccount")}
          </StyledMenuItemUnlink>
        ) : null}
      </StyledMenu>
    </div>
  );
};
