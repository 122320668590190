import { useTranslation } from "react-i18next";
import { EB2cApplicationStatus } from "services/api/types";
import { useStores } from "stores";
import { NMIUploads } from "./NMIUploads";
import { StyledStatusMessageContainer } from "../styles";
import moment from "moment";
import { observer } from "mobx-react";

const pageTranslationPrefix = "application_status";

export const RenderMessage = observer(({ status }: { status: EB2cApplicationStatus }) => {
  const { t } = useTranslation();
  const {
    leadStore: { filesToUploadNMIActive, leadInfo },
  } = useStores();
  if (status === EB2cApplicationStatus.NeedMoreInfo && filesToUploadNMIActive.length > 0) {
    return <NMIUploads />;
  }

  const noticeDate = moment(leadInfo?.b2cDeclinedTimestamp).format("MM-DD-YYYY");
  const submissionDate = moment(leadInfo?.timestampStepSix).format("MM-DD-YYYY");

  if (status === EB2cApplicationStatus.Declined) {
    return (
      <StyledStatusMessageContainer>
        {t(
          `${pageTranslationPrefix}.statuses.${status}.${
            leadInfo?.sponsor === "lead_bank" ? "leadBank" : "sorbet"
          }.content2`,
          {
            firstName: leadInfo?.firstName,
            lastName: leadInfo?.lastName,
            addressLine: leadInfo?.addressLine,
            city: leadInfo?.city,
            state: leadInfo?.state,
            zipCode: leadInfo?.zipCode,
            noticeDate: noticeDate,
            submissionDate: submissionDate,
            uuid: leadInfo?.uuid,
          },
        )}
        {Array.isArray(leadInfo?.b2cDeclinedReasons) ? (
          <ul>
            {Array.from(new Set(leadInfo?.b2cDeclinedReasons.map((reason) => reason.msg))).map(
              (uniqueMsg) => (
                <li key={uniqueMsg}>{uniqueMsg}</li>
              ),
            )}
          </ul>
        ) : null}
        {t(
          `${pageTranslationPrefix}.statuses.${status}.${
            leadInfo?.sponsor === "lead_bank" ? "leadBank" : "sorbet"
          }.content3`,
        )}
      </StyledStatusMessageContainer>
    );
  }

  return (
    <StyledStatusMessageContainer>
      {t(`${pageTranslationPrefix}.statuses.${status}.content`, {
        advanceValue: leadInfo?.b2cApprovedAmountCents ? leadInfo.b2cApprovedAmountCents / 100 : 0,
      })}
    </StyledStatusMessageContainer>
  );
});
