import { useState, useEffect, useRef } from "react";
import {
  StyledButtons,
  StyledCongrats,
  StyledCongratsDesc,
  StyledContractBody,
  StyledContractFooter,
  StyledContractHeader,
  StyledContractPage,
  StyledContractTitle,
  StyledLoadWrapper,
  StyledNotificationBox,
  StyledPDFViewer,
  StyledSubHeader,
} from "./styles";
import { pdfjs, Document, Page } from "react-pdf";
import { useTranslation } from "react-i18next";
import { apiService } from "services/api";
import { useNavigate } from "react-router-dom";
import { Loader } from "../../components";
import { StyledModalSubtitle } from "../Btc/Components/ErrorModal/styles";
import { DeclinedModal } from "./DeclinedModal";
import { useStores } from "../../stores";
import { COMPANY_EMAILS } from "config/constants";
import {
  ContractExecutionStatus,
  ContractTypeEnum,
  LeadSponsorEnum,
  TGeneratedContract,
} from "services/api/types";
import {
  PrimaryButton,
  SecondaryButton,
} from "../Btc/ApplicationStatus/components/NMIUploads/styles";
import { EVENTS, useLeavePageAnalytics } from "../../services/analytics/events";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import { observer } from "mobx-react";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const pageTranslationPrefix = "contract";

const Contract = () => {
  const {
    appStore: { windowDimensions, setIsHeaderHidden },
    contractsStore: { signContractAgreement },
    userStore: { userInfo },
    leadStore: { leadApprovalInfo },
  } = useStores();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [contractObject, setContractObject] = useState<TGeneratedContract>();
  const [numPages, setNumPages] = useState<number>();
  const [pdfFile, setPdfFile] = useState<string>();
  const [isLoading, setIsLoading] = useState(true);
  const [processingClick, setProcessingClick] = useState(false);
  const [error, setError] = useState<string>();
  const [modalOpen, setModalOpen] = useState(false);
  const agreeButtonRef = useRef<HTMLButtonElement>(null); // Create a reference

  const getFileContent = async (fileRecordId: number) => {
    try {
      const response = await apiService.getFileContent(fileRecordId);
      return response;
    } catch (error) {
      console.error("Error fetching file content:", error);
      throw error;
    }
  };

  // Fetch PDF file
  const fetchPDF = async () => {
    setIsLoading(true);
    setError(undefined);
    try {
      const generatedContract = await apiService.generateContract({
        type:
          leadApprovalInfo?.sponsor === LeadSponsorEnum.lead_bank
            ? ContractTypeEnum.loan_agreement_lead_bank
            : ContractTypeEnum.loan_agreement,
      });
      const pdfBlob = await getFileContent(generatedContract.fileId);
      const pdfFileUrl = URL.createObjectURL(pdfBlob);
      setPdfFile(pdfFileUrl);
      setContractObject(generatedContract);
    } catch (error) {
      console.error("Error loading the contract:", error);
      setError("Error loading the contract");
    } finally {
      setIsLoading(false);
    }
  };

  useLeavePageAnalytics((eventType) => {
    EVENTS.main.contract.leavePage(eventType);
  });

  useEffect(() => {
    EVENTS.main.contract.onView();
    fetchPDF();
    setIsHeaderHidden(false);
  }, []);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  if (isLoading) {
    return (
      <StyledLoadWrapper>
        <Loader />
      </StyledLoadWrapper>
    );
  }

  if (error) {
    return (
      <StyledLoadWrapper>
        <StyledModalSubtitle>
          {t(`btc_errors.generalError`)}
          <a
            href={`mailto:${COMPANY_EMAILS.SUPPORT}?subject=Hey! I have a question`}
            target="_blank"
            rel="noreferrer">
            {COMPANY_EMAILS.SUPPORT}
          </a>
        </StyledModalSubtitle>
      </StyledLoadWrapper>
    );
  }

  const handleDeclinedPressed = async () => {
    setModalOpen(true);
  };

  const signContract =
    (status: ContractExecutionStatus.rejected | ContractExecutionStatus.signed) => async () => {
      setProcessingClick(true);
      if (contractObject) {
        // Outputs the user's timezone, e.g., 'America/New_York'
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const data = {
          executionStatus: status,
          executedAtTimeZone: timeZone,
          executedAt: new Date().toISOString(),
        };
        try {
          const res = await signContractAgreement(data, contractObject.id);
          if (res.executionStatus === status) {
            navigate("/home", { state: { fromContract: status } });
          }
        } catch (error) {
          if (status === ContractExecutionStatus.signed) {
            console.error("Error signing contract:", error);
            setError("Error signing contract");
          } else {
            console.error("Error declining contract:", error);
          }
        } finally {
          setIsLoading(false);
          setProcessingClick(false);
          EVENTS.main.contract.onSign(status === ContractExecutionStatus.signed);
        }
      }
    };

  return (
    <StyledContractPage>
      <StyledContractHeader>
        <StyledContractTitle>{t(`${pageTranslationPrefix}.header.title`)}</StyledContractTitle>
      </StyledContractHeader>
      <StyledSubHeader>
        <StyledCongrats>
          {t(`${pageTranslationPrefix}.congrats.title`, {
            name: `${userInfo?.firstName} ${userInfo?.lastName}`,
          })}
        </StyledCongrats>
        <StyledCongratsDesc>
          {t(`${pageTranslationPrefix}.congrats.description`)}
        </StyledCongratsDesc>
      </StyledSubHeader>
      <StyledContractBody>
        {pdfFile && (
          <StyledPDFViewer>
            <Document file={pdfFile} onLoadSuccess={onDocumentLoadSuccess}>
              {Array.from(new Array(numPages), (_el, index) => (
                <Page
                  key={`page_${index + 1}`}
                  pageNumber={index + 1}
                  width={Math.min(windowDimensions.width, 1100)} // Use the smaller of viewerWidth and 528
                  renderAnnotationLayer={true}
                  renderTextLayer={true}
                />
              ))}
            </Document>
          </StyledPDFViewer>
        )}
      </StyledContractBody>
      <StyledContractFooter>
        <StyledNotificationBox>{t(`${pageTranslationPrefix}.notification`)}</StyledNotificationBox>
        <StyledButtons>
          <PrimaryButton
            disabled={processingClick}
            ref={agreeButtonRef}
            onClick={signContract(ContractExecutionStatus.signed)}>
            {t(`${pageTranslationPrefix}.buttons.agree`)}
          </PrimaryButton>
          <SecondaryButton
            id="decline-agreement-btn"
            onClick={handleDeclinedPressed}
            disabled={processingClick}>
            {t(`${pageTranslationPrefix}.buttons.decline`)}
          </SecondaryButton>
          {processingClick ? <Loader /> : null}
        </StyledButtons>
      </StyledContractFooter>
      <DeclinedModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        submitClick={signContract(ContractExecutionStatus.rejected)}></DeclinedModal>
    </StyledContractPage>
  );
};

export default observer(Contract);
