import styled from "styled-components";
import { StyledLargeText } from "../style";
import { device } from "config/devices";

export const StyledPriorConsentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const StyledLargeTextPriorConsent = styled(StyledLargeText)`
  margin: 8px 0;
`;

export const StyledPriorConsentTextAndButtons = styled.div`
  display: flex;
  border-radius: 8px;
  background: #f8f8fc;
  padding: 16px;
  flex-direction: column;
  @media ${device.laptop} {
    flex-direction: row;
  }
`;

export const StyledTextContainer = styled.div`
  margin-right: 0px;
  margin-bottom: 16px;
  @media ${device.laptop} {
    margin-right: 40px;
    margin-bottom: 0px;
  }
`;
