import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import {
  Styled18Text500,
  Styled14Text400,
  Styled20PAdding,
} from "../../styles";

import {
  StyledManAndTextContainer,
  StyledHowItWorksHeaderTitle,
  StyledPictureCard,
  StyledCardPicture,
  StyledCardText,
  StyledPicturesContainer,
  StyledCurvedBody,
  Styled56Px,
  StyledManPicture,
  Curve3,
  Curve4,
} from "../styles";
import curve3Svg from "../curves/curve3.svg";
import curve4Svg from "../curves/curve4.svg";
import manPower from "../../../../assets/images/man-power.svg";
import manWorks from "../../../../assets/images/man-work.png";
import bills from "../../../../assets/images/bills.svg";
import pig from "../../../../assets/images/pig.svg";
import card from "../../../../assets/images/alarm-clock.svg";
import womenDance from "../../../../assets/images/woman-dance.png";

const PICTURE_CARDS = [
  { src: manWorks, text: "theMoreYouWork" },
  { src: card, text: "yourTime" },
  { src: pig, text: "insteadOfThat" },
  { src: bills, text: "advanceTheValue" },
  { src: womenDance, text: "youDontNeed" },
];
const pageTranslationPrefix = "page_Apply";
export const HowDoesItWork = () => {
  const { t } = useTranslation();
  return (
    <StyledCurvedBody>
      <Curve3 src={curve3Svg} />
      <StyledHowItWorksHeaderTitle>
        {t(`${pageTranslationPrefix}.howDoesItWork`)}
      </StyledHowItWorksHeaderTitle>
      <StyledPicturesContainer>
        {PICTURE_CARDS.map((card, index) => (
          <StyledPictureCard key={card.src}>
            <StyledCardPicture src={card.src} />
            <StyledCardText>{t(`${pageTranslationPrefix}.${card.text}`)}</StyledCardText>
          </StyledPictureCard>
        ))}
      </StyledPicturesContainer>
      <Styled56Px />
      <div style={{ backgroundColor: "#fff" }}>
        <Curve4 src={curve4Svg} />
        <Styled18Text500>{t(`${pageTranslationPrefix}.simpleAndTransparent`)}</Styled18Text500>

        <StyledManAndTextContainer>
          <StyledManPicture src={manPower} />
          <Box sx={{ maxWidth: 378 }}>
            <Styled20PAdding>
              <Styled14Text400>{t(`${pageTranslationPrefix}.wePayYouNow`)}</Styled14Text400>
              <Styled14Text400>{t(`${pageTranslationPrefix}.wePayYouNow2`)}</Styled14Text400>
            </Styled20PAdding>
          </Box>
        </StyledManAndTextContainer>
      </div>
    </StyledCurvedBody>
  );
};
