import { device } from "config/devices";
import styled from "styled-components";
import { ModalContainer } from "pages/Btc/ApplicationStatus/components/NMIUploads/Modals/styles";

//-------------------------- Checkbox flow --------------------------//
export const CheckboxFlowWrapper = styled.div`
  border-radius: 8px;
  background: var(--Subtitle-Background, #f8f8fc);
  padding: 20px 16px;

  @media ${device.tablet} {
    padding: 16px 40px 16px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;
export const CheckboxFlowText = styled.p`
  color: var(--text-icon-secondary, #585866);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  @media ${device.tablet} {
    font-size: 14px;
    max-width: 299px;
  }
`;

export const CheckboxContainer = styled.div`
  padding: 12px 16px;
  border-radius: 8px;
  background: var(--text-icon-light, #fff);
  display: flex;
  align-items: center;

  margin-bottom: 16px;
  @media ${device.tablet} {
    margin-bottom: 8px;
  }
`;
export const CheckboxLabel = styled.div`
  cursor: pointer;
  color: var(--text-icon-dark, #242323);
  /* Text Medium/Regular */
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
`;
export const CheckboxFlowButtons = styled.div`
  flex-direction: column;
  display: flex;
  margin-top: 16px;
  @media ${device.tablet} {
    margin-top: 0px;
  }
`;
export const StyledCheckbox = styled.input<{ error?: boolean }>`
  accent-color: white;
  width: 16px;
  height: 16px;
  margin-right: 12px;
  appearance: none;
  position: relative;
  cursor: pointer;
  ::after {
    width: 18px;
    height: 19px;
    content: " ";
    background-color: white;
    display: flex;
    border: 1px solid;
    border-color: ${(props) => (props.error ? "#ee4433" : "#242323")};
    border-radius: 4px;
  }
  :hover::after {
    background-color: rgba(255, 255, 255);
    border: 1px solid #242323;
  }
  :hover:checked::after {
    border: 1px solid #242323;
  }
  :checked::after {
    background-color: white;
  }
  :checked::before {
    content: "";
    position: absolute;
    left: 7px;
    top: 3px;
    width: 5px;
    height: 11px;
    border: solid #242323;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
    border-radius: 1px;
  }
`;

export const StyledModalContainer = styled(ModalContainer)`
  max-width: 764px;
`;
