export enum LOCAL_STORAGE_KEYS {
  uuid = "uuid",
  link_token = "link_token",
  lastTime = "lastTime",
  lastSubmittedStep = "lastSubmittedStep",
  relink_bankAccountDetailId = "relink_bankAccountDetailId",
  emailForSignIn = "emailForSignIn",
  returnedEmailUrl = "returnedEmailUrl",
  original_sponsor = "original_sponsor",
  instanceAuthToken = "instanceAuthToken",
  authServiceName = "authServiceName",
  cypress_test_email = "cypress_test_email",
  AUTH_JWT_SECRET = "AUTH_JWT_SECRET",
  redirectAfterLoginUrl = "redirectAfterLoginUrl",
}
export const LocalStorage = {
  setItem: (key: LOCAL_STORAGE_KEYS, value: string) => localStorage.setItem(key, value),
  getItem: (key: LOCAL_STORAGE_KEYS) => localStorage.getItem(key),
  removeItem: (key: LOCAL_STORAGE_KEYS) => localStorage.removeItem(key),
  clear: () => localStorage.clear(),
};
