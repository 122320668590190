import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

import { useTranslation } from "react-i18next";
import ActiveStepIcon from "../../../../assets/icons/greenCircleCheck.svg";
// import InActiveStepIcon from "../../../../assets/icons/emptyCircle.svg";
import DeclinedStepIcon from "../../../../assets/icons/declined-paystub.svg";
import FutureStepIcon from "../../../../assets/icons/futureCircle.svg";
import { makeStyles } from "@mui/styles";
import moment, { Moment } from "moment";
import StepConnector from "@mui/material/StepConnector";
import { styled } from "@mui/system";
import { StyledTimeStampText } from "./styles";

const useStyles = makeStyles({
  stepLabel: {
    "& .MuiStepLabel-label": {
      color: "var(--text-icon-dark, #242323) !important",
      fontFamily: "Poppins !important",
      fontSize: "16px !important",
      fontStyle: "normal !important",
      marginLeft: "24px",
      fontWeight: "400 !important",
      lineHeight: "20px !important",
    },
    "& .MuiStepLabel-root": {
      alignItems: "flex-start",
    },
  },
});

const CustomConnector = styled(StepConnector)(() => ({
  "& .MuiStepConnector-line": {
    borderColor: "#E3E3EC", // Change this to the color you want
  },
}));

type StepState = "passed" | "declined" | "empty" | "future";

interface StepType {
  value: string;
  translationKey: string;
  state: StepState;
}

const steps: Record<string, StepType[]> = {
  state1_paystub: [
    { value: "submitted", translationKey: "previous_paystub", state: "passed" },
    { value: "current_month_pending", translationKey: "new_paystub", state: "empty" },
  ],
  state1_pto: [
    { value: "submitted", translationKey: "previous_doc", state: "passed" },
    { value: "current_month_pending", translationKey: "new_doc", state: "empty" },
  ],
  state2_paystub: [
    { value: "declined", translationKey: "previous_month_missing_paystub", state: "declined" },
    { value: "current_month_pending", translationKey: "new_paystub", state: "empty" },
  ],
  state2_pto: [
    { value: "declined", translationKey: "previous_month_missing_pto", state: "declined" },
    { value: "current_month_pending", translationKey: "new_doc", state: "empty" },
  ],
  state3_paystub: [
    { value: "submitted", translationKey: "previous_paystub", state: "passed" },
    { value: "submitted", translationKey: "today_paystub", state: "passed" },
    { value: "next_upload", translationKey: "next_paystub", state: "empty" },
  ],
  state3_pto: [
    { value: "submitted", translationKey: "previous_doc", state: "passed" },
    { value: "submitted", translationKey: "today_doc", state: "passed" },
    { value: "next_upload", translationKey: "next_doc", state: "empty" },
  ],
  state4_pto: [
    { value: "declined", translationKey: "previous_month_missing_pto", state: "declined" },
    { value: "submitted", translationKey: "today_doc", state: "passed" },
    { value: "next_upload", translationKey: "next_doc", state: "empty" },
  ],
  state4_paystub: [
    { value: "declined", translationKey: "previous_month_missing_paystub", state: "declined" },
    { value: "submitted", translationKey: "today_paystub", state: "passed" },
    { value: "next_upload", translationKey: "next_paystub", state: "empty" },
  ],
};

type Props = { status: keyof typeof steps; timestamps: Moment[] };

function StepIcon({ state }: { state: StepState }) {
  switch (state) {
    case "passed":
      return <img src={ActiveStepIcon} alt={"ActiveStepIcon"} />;
    case "declined":
      return <img src={DeclinedStepIcon} alt={"DeclinedStepIcon"} />;
    case "future":
      return <img src={FutureStepIcon} alt={"InActiveStepIcon"} />;
    case "empty":
    default:
      return <img src={FutureStepIcon} alt={"InActiveStepIcon"} />;
  }
}

const pageTranslationPrefix = "PTOCenter.paystubs_page.stepper";
export default function VerticalLinearStepper({ status, timestamps }: Props) {
  const { t } = useTranslation();
  const classes = useStyles();

  const nextUploadDate = moment().add(1, "month").date(28).format("MMMM D, YYYY");
  const previousMonthName = moment().subtract(1, "month").format("MMMM");

  return (
    <Box sx={{ marginBottom: "32px", marginTop: "16px" }}>
      <Stepper orientation="vertical" connector={<CustomConnector />}>
        {steps[status]?.map((step, index) => (
          <Step key={step.value}>
            <StepLabel
              className={classes.stepLabel}
              StepIconComponent={() => <StepIcon state={step.state} />}
              optional={
                step.state === "declined" ? (
                  <StyledTimeStampText>{previousMonthName}</StyledTimeStampText>
                ) : step.value === "next_upload" ? (
                  <>
                    <StyledTimeStampText>{nextUploadDate}</StyledTimeStampText>
                    <StyledTimeStampText>
                      {t(`${pageTranslationPrefix}.next_upload_notification`)}
                    </StyledTimeStampText>
                  </>
                ) : timestamps[index] ? (
                  <StyledTimeStampText>
                    {timestamps[index].format("MMMM D, YYYY")}
                  </StyledTimeStampText>
                ) : null
              }>
              {t(`${pageTranslationPrefix}.${step.translationKey}`)}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}
