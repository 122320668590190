import size, { device } from "config/devices";
import styled from "styled-components";

export const StyledWrapper = styled.div`
  background: #ffffff;
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: column;
  padding-bottom: 24px;
`;

export const TopHeader = styled.div`
  @media ${device.tablet} {
    height: 56px;
    width: 100%;
    box-shadow: 0px -1px 0px 0px #f0eef5 inset;
  }
`;

export const Wrapper = styled.div`
  max-width: 702px;
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
`;

export const Header = styled.div`
  padding: 40px 0 32px 0;
  display: flex;
  align-items: center;
  flex: 0;
  margin: 0 24px;

  @media (max-width: ${size.tablet}) {
    margin: 0 0;
    margin-bottom: 40px;
    padding: 16px 24px;
    box-shadow: 0px -1px 0px 0px #f0eef5 inset;
  }
`;

export const BackIcon = styled.img`
  width: 24px;
  height: 24px;
`;

export const HeaderTitle = styled.h1`
  color: #242323;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;

  font-size: 16px;
  line-height: 24px;
  @media ${device.tablet} {
    font-size: 28px;
    line-height: 40px;
  }
`;

export const EmptyContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 16px;
`;
export const EmptyImg = styled.img`
  width: 150px;
  height: 160px;
`;
export const EmptyTitle = styled.p`
  color: #000;
  text-align: center;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  font-size: 16px;
  @media ${device.tablet} {
    font-size: 14px;
  }
`;
export const EmptySubtitle = styled.p`
  color: var(--text-icon-secondary, #585866);
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  @media ${device.tablet} {
    line-height: 20px; /* 150% */
    font-size: 13px;
  }
`;

export const EmptyButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media ${device.tablet} {
    align-items: center;
    margin-top: 24px;
  }
`;

export const EmptyContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  padding: 64px 0;
  @media ${device.tablet} {
    flex: 0;
    padding: 0;
  }
`;
