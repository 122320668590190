import { ENV, Environments } from "config/env";
import {
  IUserInfo,
  IApiError,
  IAppInitConfig,
  ICardPanInfo,
  IUserInfoResponse,
  AddressType,
  API_ERROR_CODES,
  ICardLoad,
  IGeneralUserInfo,
  ITransactions,
  IBTCPatchData,
  ILeadsInitConfig,
  ILoanDataInfo,
  ICreateConsent,
  IConsentResponse,
  IAchTransfers,
  DebitAchAuthTypeEnum,
  ILeadInfo,
  LeadFileType,
  IAmortizeLoanInfo,
  TGeneratedContract,
  TSignContractBody,
  ContractTypeEnum,
  TContractTemplatesInfo,
  ContractExecutionStatus,
  IBankAccountsResponse,
  IBankAccount,
  IBackupAccountConsent,
  IBackupAccountConsentsResponse,
  BankAccountsStatusEnum,
  EmploymentsLinkProviderResponse,
  IPaymentsResponse,
  PaymentTypeEnum,
  CalculatePaymentResponse,
  TLeadApprovalInfo,
  TEmploymentAccount,
  TEmploymentConnection,
  TUserFile,
  TPtoPolicyDesc,
  IBTCUpdateFiles,
} from "./types";
import { authService } from "services/auth";
import userStore from "stores/user";
import { ISignInRequest, ISignInResponse } from "services/auth/types";
import { omit } from "utils/helpers";
import { errorLogger } from "../logger";
import { requestBodyTrim } from "utils/formatters";
import uuid from "react-uuid";
import { getAnalyticHeader } from "./helpers";

type FetchMethod = "GET" | "PUT" | "POST" | "DELETE" | "GET-FILE" | "PATCH";
/**
 * Set of fields that must be converted from string to number format during response parsing
 */
const STRING_TO_NUMBER_FIELDS = [
  "availableUnits",
  "initialUnitsBalance",
  "hoursPerDay",
  "workingHoursPerDayMinutes",
];

interface IFetchParams {
  method?: FetchMethod;
  requestBody?: object | FormData;
  fileInResponse?: boolean;
  shouldAppendAnalytic?: boolean;
}

class ApiService {
  private async makeFetch<R extends object | Blob = object>(
    url: string,
    params: IFetchParams = {},
    withToken: boolean = true,
    isDealiased: boolean = false,
    otpAuthToken: string | undefined = undefined,
  ): Promise<R> {
    const { method, requestBody, fileInResponse, shouldAppendAnalytic } = params;

    const headers = new Headers({
      accept: "application/json, text/plain",
    });

    if (shouldAppendAnalytic) {
      headers.append("cookie-sorbet", getAnalyticHeader());
    }

    if (withToken) {
      let instanceToken = await authService.getAuthToken();
      if (instanceToken) {
        const { authToken, serviceName } = instanceToken;

        if (authToken) {
          headers.append("auth-token", authToken);
          headers.append("auth-strategy", serviceName);
          headers.append("idempotency-key", uuid());

          if (otpAuthToken) {
            headers.append("otp-auth-token", otpAuthToken);
          }
        }
      }
    }

    let body: string | FormData | undefined;

    if (["POST", "PUT"].includes(method!)) {
      if (requestBody && typeof requestBody === "object") {
        requestBodyTrim(requestBody);
      }
    }

    if (requestBody) {
      if (requestBody instanceof FormData) {
        body = requestBody;
      } else {
        headers.append("Content-Type", "application/json");
        body = JSON.stringify(requestBody);
      }
    }

    errorLogger.captureMessage(`Request ${method ?? "GET"} ${url}`, body ? { body } : undefined);

    const baseUri = isDealiased ? ENV.REACT_APP_MOBILE_API_ALIASING_URL : ENV.REACT_APP_API_URL;
    const response = await fetch(`${baseUri}${url}`, {
      method: method === "GET-FILE" ? "GET" : method,
      headers,
      body,
    });

    if (response.ok) {
      if (fileInResponse || method === "GET-FILE") {
        return response.blob() as Promise<R>;
      }
      const textBody = await response.text();
      errorLogger.captureMessage(`Response ${method ?? "GET"} ${response.status} ${url}`, {
        body: textBody,
        "transaction-id": response.headers.get("transaction-id"),
      });
      if (!textBody) {
        return {} as R;
      }
      return JSON.parse(textBody, this.responseJsonParser);
    }
    let textBody = await response.text();
    let error: Partial<IApiError>;
    try {
      error = JSON.parse(textBody);
      errorLogger.captureError(`Response ${method ?? "GET"} ${response.status} ${url}`, {
        body: error,
        "transaction-id": response.headers.get("transaction-id"),
      });
    } catch (e) {
      errorLogger.captureError(`Response ${method ?? "GET"} ${response.status} ${url}`, {
        body: JSON.stringify(e),
        "transaction-id": response.headers.get("transaction-id"),
      });
      const matchResult = textBody.match(/<title>(.+)<\/title>/);

      if (matchResult) {
        textBody = matchResult[1] as string;
      }
      error = { details: [] };
    }
    if (!error.status) {
      error.status = response.status;
    }

    if (response.status === 401) {
      if (error.code === API_ERROR_CODES.UserNotFound) {
        userStore.logOut();
      }
    }
    // eslint-disable-next-line no-throw-literal
    throw error as IApiError;
  }

  /* API CALLS */

  /* App API calls */
  public signIn = (data: ISignInRequest): Promise<ISignInResponse> => {
    return this.makeFetch<ISignInResponse>(
      `/v1/auth/signIn`,
      {
        method: "POST",
        requestBody: data,
      },
      false,
    );
  };

  public async getInitConfig(): Promise<IAppInitConfig> {
    return this.makeFetch("/v1/initConfig");
  }

  public async getLeadsInitConfig(): Promise<ILeadsInitConfig> {
    return this.makeFetch("/v1/initConfig/leads");
  }

  /* User API calls */
  public async getUserInfo(): Promise<IUserInfo> {
    const { addresses, phone, ...userInfo } = await this.makeFetch<IUserInfoResponse>(
      "/v1/user?myAccount=1",
    );

    const personalAddress = addresses?.find((address) => address.type === AddressType.PERSONAL);
    return {
      ...userInfo,
      phone: phone?.split("-").join("").trim(),
      personalAddress: personalAddress ? omit(personalAddress, ["id", "type", "userId"]) : {},
    };
  }

  /* Lead API calls */
  public async getLeadInfo(uuid: string): Promise<ILeadInfo> {
    const data = await this.makeFetch<ILeadInfo>(`/v1/leads/${uuid}/applicationState`);
    return { ...data, uuid };
  }

  /* BTC API calls */
  public async getEmploymentsLinkProvider(uuid: string): Promise<EmploymentsLinkProviderResponse> {
    return await this.makeFetch<EmploymentsLinkProviderResponse>(
      `/v1/employments/link-provider/${uuid}`,
      {
        method: "POST",
      },
    );
  }

  public async createBtcApplication(data: IBTCPatchData): Promise<any> {
    return await this.makeFetch<any>(
      `/v1/leads`,
      {
        method: "POST",
        requestBody: data,
        shouldAppendAnalytic: true,
      },
      false,
    );
  }
  /* BTC API calls */
  public async updateBtcApplication(data: IBTCPatchData): Promise<any> {
    return await this.makeFetch<any>(
      `/v1/leads/${data.uuid}`,
      {
        method: "PATCH",
        requestBody: data,
      },
      false,
      ENV.REACT_APP_ENV === Environments.QAT ? false : true,
    );
  }

  public async emptyRequestBtcApplication(uuid: string): Promise<any> {
    return await this.makeFetch<any>(
        `/v1/leads/${uuid}`,
        {
          method: "PATCH",
          requestBody: {uuid},
        },
        false,
        ENV.REACT_APP_ENV === Environments.QAT ? false : true,
    );
  }

  public async checkUnsignedContract(): Promise<TContractTemplatesInfo> {
    return await this.makeFetch<TContractTemplatesInfo>(`/v1/contract-templates/unapproved`, {
      method: "GET",
    });
  }

  public async signContract(
    data: TSignContractBody,
    contractId: number,
  ): Promise<TGeneratedContract> {
    return await this.makeFetch<TGeneratedContract>(`/v1/contracts/${contractId}`, {
      method: "PATCH",
      requestBody: data,
    });
  }

  public async btcCalculatePto(
    uuid: string,
  ): Promise<{ calculatedPtoCashCents: number; ptoPolicyDesc: TPtoPolicyDesc }> {
    return await this.makeFetch<{ calculatedPtoCashCents: number; ptoPolicyDesc: TPtoPolicyDesc }>(
      `/v1/leads/${uuid}/calculate-pto`,
      {
        method: "POST",
      },
      false,
    );
  }

  public async updateBtcApplicationFiles(data: IBTCUpdateFiles): Promise<any> {
    const formData = new FormData();
    if (data.payStubOne) {
      formData.append(LeadFileType.payStubOne, data.payStubOne);
    }
    if (data.ptoBalanceDoc) {
      formData.append(LeadFileType.ptoBalanceDoc, data.ptoBalanceDoc);
    }
    if (data.idSelfie) {
      formData.append(LeadFileType.idSelfie, data.idSelfie);
    }
    if (data.ptoPolicyProof) {
      formData.append(LeadFileType.ptoPolicyProof, data.ptoPolicyProof);
    }
    return await this.makeFetch<any>(
      `/v1/leads/${data.uuid}/files`,
      {
        method: "POST",
        requestBody: formData,
      },
      false,
    );
  }

  /* User API calls */
  public async getGeneralUserInfo(): Promise<IGeneralUserInfo> {
    return await this.makeFetch<IGeneralUserInfo>("/v1/user/general");
  }

  /* User API calls */
  public async getUserFiles(): Promise<TUserFile[]> {
    return await this.makeFetch<TUserFile[]>("/v1/user/files");
  }

  public requestCardOtp() {
    return this.makeFetch("/v1/cards/sorbet/sendCode");
  }

  public getCardPan(otp: string): Promise<ICardPanInfo> {
    return this.makeFetch(`/v1/cards/sorbet/getCardPan/${otp}`, undefined, true, false);
  }

  public async getCardTransactions(nextToken?: string): Promise<ITransactions> {
    let url = `/v1/transactions?limit=100`;
    if (nextToken) url += `&nextToken=${nextToken}`;
    return this.makeFetch(url);
  }

  public async loadCard(loadCard: ICardLoad): Promise<ICardPanInfo> {
    await this.verifyOtpCode(loadCard.code);
    return this.makeFetch<ICardPanInfo>(
      "/v1/cards/loadByMoney",
      {
        method: "POST",
        requestBody: { ...loadCard, amount: loadCard.amount * 100 },
      },
      true,
    );
  }

  public async verifyOtpCode(code: string) {
    return this.makeFetch<{ data: { token: string } }>(
      `/v1/user/otp/verify/${code}`,
      {
        method: "POST",
        requestBody: {},
      },
      true,
    );
  }

  public async getPlaidAccessToken(leadUuid: string): Promise<{
    token: string;
    expiration: string;
  }> {
    return await this.makeFetch(
      "/v1/bank-integrations/create-link-token",
      {
        method: "POST",
        requestBody: {
          leadUuid,
        },
      },
      true,
    );
  }

  public async exchangePublicToken(
    publicToken: string,
    leadUuid: string,
  ): Promise<{ bankAccountId: number }> {
    return await this.makeFetch(
      "/v1/bank-integrations/exchange-token",
      {
        method: "POST",
        requestBody: { publicToken, leadUuid },
      },
      true,
    );
  }
  public async bankAccountDetails(bankIntegrationId: number): Promise<{
    cardAccountNumber: string;
    bankLogo: string;
    bankName: string;
  }> {
    return await this.makeFetch(
      `/v1/bank-integrations/${bankIntegrationId}/account-details`,
      {
        method: "GET",
      },
      true,
    );
  }

  public async getLeadApprovalInfo(userId: string): Promise<TLeadApprovalInfo> {
    return await this.makeFetch(`/v1/user/${userId}/leads`);
  }
  public async getEmploymentAccount(): Promise<TEmploymentAccount> {
    return await this.makeFetch("/v1/employments/employment-account");
  }
  public async updateEmploymentAccountInfo(data: TEmploymentConnection) {
    return await this.makeFetch(`/v1/employments/employment-account`, {
      method: "PATCH",
      requestBody: data,
    });
  }

  public async getFileContent(fileRecordId: number): Promise<File> {
    return await this.makeFetch(
      `/v1/files/${fileRecordId}/contents`,
      {
        method: "GET-FILE",
      },
      true,
    );
  }

  public async generateContract({
    type,
    signStatus,
    signedAt,
    signAtTimezone,
  }: {
    type: ContractTypeEnum;
    signStatus?: ContractExecutionStatus;
    signedAt?: string;
    signAtTimezone?: string;
  }): Promise<TGeneratedContract> {
    return await this.makeFetch(`/v1/contracts`, {
      method: "POST",
      requestBody: {
        type,
        signStatus,
        signedAt,
        signAtTimezone,
      },
    });
  }

  public async getContractList(userId: string, type: string): Promise<any> {
    return await this.makeFetch(`/v1/contracts?type=${type}`);
  }
  //################ USER BANK ACCOUNT INTEGRATION ##################

  public async getUserPlaidAccessToken(userId: string): Promise<{
    token: string;
    expiration: string;
  }> {
    return await this.makeFetch(
      `/v1/user/${userId}/bank-integrations`,
      {
        method: "POST",
        requestBody: {
          userId,
        },
      },
      true,
    );
  }

  public async getPlaidUpdateAccessToken(
    userId: string,
    bankIntegrationId: number,
  ): Promise<{
    token: string;
    expiration: string;
  }> {
    return await this.makeFetch(
      `/v1/user/${userId}/bank-integrations/${bankIntegrationId}/update-token`,
      {
        method: "POST",
      },
      true,
    );
  }

  public async setBankIntegrationTokenValid(
    userId: string,
    bankIntegrationId: number,
  ): Promise<{
    token: string;
    expiration: string;
  }> {
    return await this.makeFetch(
      `/v1/user/${userId}/bank-integrations/${bankIntegrationId}/set-valid`,
      {
        method: "POST",
      },
      true,
    );
  }

  public async exchangeUserPublicToken(
    publicToken: string,
    userId: string,
  ): Promise<{ bankAccountId: number; bankIntegrationId: number; bankAccountDetailId: number }> {
    return await this.makeFetch(
      `/v1/user/${userId}/bank-integrations/exchangeToken`,
      {
        method: "POST",
        requestBody: { publicToken, userId },
      },
      true,
    );
  }
  public async userBankAccountDetails(
    bankIntegrationId: number,
    userId: string,
  ): Promise<{
    cardAccountNumber: string;
    bankLogo: string;
    bankName: string;
  }> {
    return await this.makeFetch(
      `/v1/user/${userId}/bank-integrations/${bankIntegrationId}/details`,
      {
        method: "GET",
      },
      true,
    );
  }
  public async deleteUserBankAccount(bankIntegrationId: number, userId: string): Promise<void> {
    await this.makeFetch(
      `/v1/user/${userId}/bank-integrations/${bankIntegrationId}`,
      {
        method: "DELETE",
      },
      true,
    );
  }

  public async amortizeLoan(): Promise<IAmortizeLoanInfo> {
    return await this.makeFetch(`/v1/user/loans/amortize`, { method: "POST" });
  }

  public async getLoanDetails(userId: string): Promise<ILoanDataInfo> {
    return await this.makeFetch(`/v1/user/${userId}/loan`);
  }

  public async getAchTransfersList(): Promise<IAchTransfers> {
    return await this.makeFetch(`/v1/user/ach-transfers?orderDirection=DESC&orderBy=createdAt`);
  }

  public async updateDebitConsentAuth(createConsent: ICreateConsent): Promise<IConsentResponse> {
    return await this.makeFetch(`/v1/consents`, {
      method: "POST",
      requestBody: { ...createConsent },
    });
  }

  public async firstSetupConsent(consent: ICreateConsent): Promise<IConsentResponse> {
    return await this.makeFetch(`/v1/consents/first-setup`, {
      method: "POST",
      requestBody: { ...consent },
    });
  }

  public async getDebitConsentAuth(
    bankIntegrationId: number,
    type: DebitAchAuthTypeEnum,
  ): Promise<IConsentResponse> {
    return await this.makeFetch(
      `/v1/consents/bank-integration-id/${bankIntegrationId}/latest?type=${type}`,
    );
  }
  public async getPayments(): Promise<IPaymentsResponse> {
    return await this.makeFetch(`/v1/payments`);
  }

  public async getBankAccounts({
    userId,
    bankIntegrationId,
  }: {
    userId: string;
    bankIntegrationId: number;
  }) {
    return await this.makeFetch<IBankAccountsResponse>(
      `/v1/user/${userId}/bank-integrations/${bankIntegrationId}/account-details`,
    );
  }

  public async getBankAccountByAccountId({
    userId,
    bankIntegrationId,
    accountDetailId,
  }: {
    userId: string;
    bankIntegrationId: number;
    accountDetailId: number;
  }) {
    return await this.makeFetch<IBankAccount>(
      `/v1/user/${userId}/bank-integrations/${bankIntegrationId}/account-details/${accountDetailId}`,
    );
  }

  public async setBackupAccountConsent(
    createConsent: IBackupAccountConsent,
  ): Promise<IConsentResponse> {
    return await this.makeFetch(`/v1/consents`, { method: "POST", requestBody: createConsent });
  }

  public async getBackupConsent(bankIntegrationId: number, bankAccountDetailId: number) {
    return await this.makeFetch<IBackupAccountConsentsResponse>(
      `/v1/consents/bank-integration-id/${bankIntegrationId}?bankAccountDetailId=${bankAccountDetailId}&type=${DebitAchAuthTypeEnum.BACKUP_BA}&orderDirection=DESC&orderBy=createdAt`,
    );
  }

  public async requestUpdateAuthLinkTokenByBankAccountDetailId(
    userId: string,
    bankIntegrationId: number,
    accountDetailId: number,
  ): Promise<{
    token: string;
    expiration: string;
  }> {
    return await this.makeFetch(
      `/v1/user/${userId}/bank-integrations/${bankIntegrationId}/account-details/${accountDetailId}/update-token`,
      {
        method: "POST",
      },
    );
  }

  public async updateBankDetailById(
    userId: string,
    bankIntegrationId: number,
    accountDetailId: number,
    requestBody: {
      status?: BankAccountsStatusEnum;
      accountId?: string;
    },
  ) {
    return await this.makeFetch<IBankAccount>(
      `/v1/user/${userId}/bank-integrations/${bankIntegrationId}/account-details/${accountDetailId}`,
      { method: "PATCH", requestBody },
    );
  }
  /* Api Service utils */
  private responseJsonParser = (key: string, value: any) => {
    if (typeof value === "string" && STRING_TO_NUMBER_FIELDS.includes(key)) {
      return +value;
    }
    return value;
  };

  public getContractTemplate = async (type: ContractTypeEnum) => {
    return await this.makeFetch<File>(
      `/v1/contract-templates/${type}/contents`,
      { fileInResponse: true },
      false,
    );
  };

  public calculatePayment = async (type: PaymentTypeEnum) => {
    return await this.makeFetch<CalculatePaymentResponse>(
      `/v1/user/calculate-payment?paymentType=${type}`,
      {},
    );
  };
}

export const apiService = new ApiService();
