import { COMPANY_EMAILS } from "config/constants";
import { device } from "config/devices";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

export const Support = () => {
  const { t } = useTranslation();
  return (
    <StyledContainer>
      <StyledText>{t("yourCard.anyQuestions")}</StyledText>
      <StyledEmail
        href={
          `mailto:${COMPANY_EMAILS.SUPPORT}?subject=Hey! I have a question about my Sorbet PTO Cash Advance application`
        }
        target="_blank"
      >
        {COMPANY_EMAILS.SUPPORT}
      </StyledEmail>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  max-width: 702px;
  margin-top: 32px;
  margin-bottom: 32px;
  padding: 0 16px;
`;

const StyledText = styled.p`
  color: #585866;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  @media ${device.tablet} {
    font-size: 14px;
  }
`;
const StyledEmail = styled.a`
  color: #ff5277;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  @media ${device.tablet} {
    font-size: 14px;
  }
`;
