import styled from "styled-components";
import { device } from "../../../config/devices";
import { IconButton, Modal, styled as muiStyled } from "@mui/material";

export const StyledModal = styled(Modal)`
  padding: 8px;
  flex: 1;
  display: flex;
  flex-direction: column;
  position: absolute;
  justify-content: flex-end;
  @media ${device.tablet} {
    justify-content: start;
    align-items: center;
  }
`;

export const StyledModalContainer = styled.div`
  padding: 16px;
  background-color: #9486a5;
  max-width: 600px;
  border-radius: 8px;
  position: relative;
`;

export const Description = styled.p`
  color: #fff;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  margin-right: 24px;
  line-height: 24px;
  a {
    color: #fff;
    text-decoration: underline;
  }
`;

export const ButtonsContainer = styled.div`
  padding-top: 32px;
  flex: 1;
  display: flex;
  align-items: center;
  align-content: space-between;
  flex-direction: row;
`;

export const IconsContainer = styled.div`
  padding-bottom: 8px;
  flex: 1;
  display: flex;
  justify-content: space-between;
`;

export const PrimaryButton = styled.button`
  padding: 8px;
  justify-content: center;
  font-size: 14px;
  border-radius: 8px;
  font-family: Poppins;
  font-style: normal;
  flex: 0.5;
  font-weight: 200;
  line-height: 22px;
  background-color: #fff;
  color: #9486a5;
`;

export const SecondaryButton = styled.button`
  padding: 8px;
  color: #ffff;
  justify-content: center;
  font-size: 14px;
  border-radius: 8px;
  border-style: solid;
  border-color: #fff;
  border-width: 1px;
  margin-right: 24px;
  font-family: Poppins;
  font-style: normal;
  flex: 0.5;
  font-weight: 400;
  line-height: 22px;
`;

export const CloseIcon = muiStyled(IconButton)`
  position: absolute;
  top: 8px;
  right: 8px;
`;
