import { LinearProgress, linearProgressClasses } from "@mui/material";
import styled from "styled-components";

export const StyledProgressBarWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const StyledProgressAmount = styled.div`
  font-weight: 600;
  font-size: 12px;
  color: #000624;
  margin-left: 18px;
`;

export const BorderLinearProgress = styled(LinearProgress)<{ width: string }>((p) => ({
  height: 3,
  width: p.width,
  marginLeft: 0,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "rgba(0, 6, 36, 0.1)",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "#FF5277",
  },
}));
