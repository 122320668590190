import { device } from "config/devices";
import styled from "styled-components";

export const Body = styled.div`
  display: flex;
  flex: 1;
  background-color: white;
  padding: 0 20px;
  justify-content: center;
`;

export const BodyContainer = styled.div`
  max-width: 776px;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-bottom: 100px;
  padding-top: 24px;
`;

export const PageContainer = styled.div`
  box-shadow: 0px 2px 16px 1px rgba(36, 35, 35, 0.16);
  display: flex;
  justify-content: center;
  margin-bottom: 8px;
`;

export const Title = styled.h3`
  color: var(--text-icon-dark, #242323);
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 133.333% */
  padding: 0 0 16px 0;

  font-size: 16px;
  @media ${device.tablet} {
    font-size: 18px;
  }
`;

export const LoaderContainer = styled.div`
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;
