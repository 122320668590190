import { Colors } from "assets/styles/constants";
import { device } from "config/devices";
import styled from "styled-components";
import { HEADER_HEIGHT } from "config/constants";
import { IconButton, MenuItem, styled as muiStyled } from "@mui/material";

export const StyledHeader = styled.header<{ isHeaderHidden: boolean }>`
  display: flex;
  align-items: center;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.1);
  background-color: ${Colors.textColorWhite};
  padding: 0 16px;
  justify-content: space-between;

  height: ${(props) => (props.isHeaderHidden ? 0 : HEADER_HEIGHT)}px;
  visibility: ${(props) => (props.isHeaderHidden ? "hidden" : "visible")};
  z-index: 10;

  @media ${device.tablet} {
    position: sticky;
    top: 0;
    z-index: 101;
  }
`;

export const StyledLogo = styled.div``;

export const AvatarContainer = styled.div`
  height: 40px;
  width: 66px;
  border-radius: 40px;
  padding-left: 4px;
  display: flex;
  align-items: center;
  gap: 2px;
  background: var(--Surface-surface-secondary, #f8f8fc);
`;

export const StyledMenuItem = muiStyled(MenuItem)`
  padding: 8px 24px;
  :hover {
    background: #FFA8BB;
  }
`;
export const StyledIconButton = muiStyled(IconButton)`
  padding: 0;
  margin: 8px 0;
`;

export const MenuItemText = styled.span`
  color: var(--text-icon-dark, #242323);
  /* Text Medium/Regular */
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
`;
