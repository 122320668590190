import { firebaseROAuthInstance } from "./firebase/readOnly";
import { firebaseRWAuthInstance } from "./firebase/readWrite";
import { qaLoginInstance } from "./qaLogin";
import { AuthServices, IAuthInstance, IAuthInstanceFactory } from "./types";

class AuthInstanceFactory implements IAuthInstanceFactory {
  getInstance = (serviceName: AuthServices) => {
    let instance: IAuthInstance;
    switch (serviceName) {
      case AuthServices.FIREBASE_RO: {
        instance = firebaseROAuthInstance;
        break;
      }
      case AuthServices.sorbet_qa: {
        instance = qaLoginInstance;
        break;
      }

      case AuthServices.FIREBASE_RW:
      default:
        instance = firebaseRWAuthInstance;
    }
    if (!instance.isInitialized) {
      instance.init();
    }
    return instance;
  };
}

export const authInstanceFactory: IAuthInstanceFactory = new AuthInstanceFactory();
